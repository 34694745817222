import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { equals } from 'fp/objects'
import withProps from 'hoc/withProps'

const AppliedImageFilters = styled(
  withProps(Box, { 'data-testid': 'picture-this-output' }),
  {
    name: 'FeaturePictureThis-AppliedImageFilters',
    shouldForwardProp: equals('url'),
  },
)(({ theme: { mixins: { transition } }, url }) => ({
  ...transition(),

  height: '100%',
  width: '100%',
  position: 'absolute',
  backgroundSize: 'cover',
  backgroundImage: `url(${url})`,

  '&.sepia': {
    filter: 'sepia(100%)',
  },

  '&.ocean': {
    background: `linear-gradient(180deg, #366EFF 0%, rgba(22, 199, 255, 0.79) 100%), url(${url})`,
    backgroundSize: 'cover',
  },

}))

export default AppliedImageFilters
