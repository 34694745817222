import { ChevronLeft, ChevronRight } from 'react-feather'
import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { useParams } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import {
  getCurrentViewContent,
  getNextPrevForContentViewer,
  getTopLevelItemsForViewerContent,
} from 'selectors/contentViewer'
import { last } from 'fp/arrays'
import { getUserAssignmentIsSubmittable } from 'selectors/userAssignments'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE } from 'core/consts'
import useIsSubmittingAssignment from './useIsSubmittingAssignment'
import NavButton from './NavButton'

const iconSize = 32
const iconStyle = { height: iconSize, width: iconSize }

const extantProps = { // for `isEcho`
  [false]: {
    color: 'secondary',
    'data-bodyvariant': 'body1',
    variant: 'tertiary',
  },
  [true]: {
    color: 'primary',
    'data-bodyvariant': 'body2',
    variant: 'tertiary',
  },
}

export const produceLinkTo = content => content?.parent?.contentType === CONTENT_TYPE_SOURCE
  // urg.  wish we didn't need a special carve out for structured sources 😕
  ? `../${CONTENT_TYPE_SOURCE}/${content.parent.id}/${content.contentType}/${content.id}`
  : `../${content?.contentType}/${content?.id}`

const LeftSide = () => {
  const isPinnedContent = useIsPinnedContent()
  const { next, prev } = useSelector(getNextPrevForContentViewer({ preferTeCopy: true }))
  const current = useSelector(getCurrentViewContent)
  const lastSubsection = last(useSelector(getTopLevelItemsForViewerContent()))
  const { userAssignmentId } = useParams()
  const isSubmittable = useSelector(getUserAssignmentIsSubmittable({ userAssignmentId }))
  const isSubmitting = useIsSubmittingAssignment()

  if (isPinnedContent || !current) return <div />

  const isLastSubsection = current === lastSubsection
  const isEcho = current.contentType === CONTENT_TYPE_ECHO

  const prevContent = isSubmitting ? lastSubsection : prev
  const nextContent = isLastSubsection && isSubmittable ? ({ name: 'Submit Assignment' }) : next

  const prevTo = produceLinkTo(prevContent)

  const nextTo = isLastSubsection
    ? '../submit'
    : produceLinkTo(nextContent)

  return (
    <Stack
      direction="row"
      spacing={1}
    >

      <Tooltip title={prevContent?.name || ''}>
        <NavButton
          aria-label={prevContent ? `Back to ${prevContent.name}` : null}
          disabled={!prevContent}
          to={prevTo}
          {...extantProps[isEcho]}
        >
          <ChevronLeft style={iconStyle} />
        </NavButton>
      </Tooltip>

      <Tooltip title={nextContent?.name || ''}>
        <NavButton
          aria-label={nextContent ? `Forward to ${nextContent.name}` : null}
          disabled={!nextContent}
          to={nextTo}
          {...extantProps[isEcho]}
        >
          <ChevronRight style={iconStyle} />
        </NavButton>
      </Tooltip>

    </Stack>
  )
}

export default LeftSide
