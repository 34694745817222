import PropTypes from 'prop-types'
import Image from 'common/indicators/Image'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import StyledContainer from './StyledContainer'

const DecorativeImage = (props) => {
  const { uploadsMap } = props
  const src = getImageUrlFromUploadsMap(uploadsMap)

  return (
    <StyledContainer>
      <div className="image-wrapper">
        <Image
          alt=""
          src={src}
        />
      </div>
    </StyledContainer>
  )
}

DecorativeImage.propTypes = {
  uploadsMap: PropTypes.object.isRequired,
}

export default DecorativeImage
