import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Dialog from 'common/dialogs/Dialog'
import InternalLinkTab from 'common/formControls/textInputs/RichTextEdit/plugins/linkPlugin/InternalLinkTab'
import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import actionTypes from 'reducers/actionTypes'
import { contentShape } from 'core/shapes'

const contentTypes = {
  sibling: [
    CONTENT_TYPE_ECHO,
    CONTENT_TYPE_SOURCE,
    CONTENT_TYPE_SUBSECTION,
  ],
  parent: [
    CONTENT_TYPE_SECTION,
  ],
}

const ContentInsertionDialog = (props) => {
  const { level, onClose, open, placement, relativeContent } = props
  const dispatch = useDispatch()

  const handleSelectionMade = ({ id }) => {
    dispatch({
      type: actionTypes.CONTENT_INSERTION_ALTERATION,
      payload: {
        insertionContentId: id,
        level,
        placement,
        relativeContent,
      },
    })
    onClose()
  }

  return (
    <Dialog
      dense
      disableAutoFocus
      maxWidth="sm"
      onClose={onClose}
      open={open}
      showCloseButton
      title="Select Content to Insert"
      TitleProps={{ mb: 3 }}
      variant="maximized-vertically"
    >
      <InternalLinkTab
        contentTypes={contentTypes[level]}
        mode="content-insertion"
        onComplete={handleSelectionMade}
      />
    </Dialog>
  )
}

ContentInsertionDialog.propTypes = {
  level: PropTypes.oneOf([
    'parent',
    'sibling',
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf([
    'above',
    'below',
  ]).isRequired,
  relativeContent: contentShape,
}

export default ContentInsertionDialog
