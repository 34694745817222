import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { useEffect } from 'react'
import { SECTION_CONTENT_TYPES } from 'core/consts'
import { suffix } from 'fp/strings'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { filter, first, map } from 'fp/arrays'
import { fallbackTo, identity } from 'fp/utils'
import { userAssignmentActions } from 'reducers/userAssignments'
import { get } from 'fp/objects'
import { callWith } from 'fp/call'
import { getUserAssignmentById } from 'selectors/userAssignments'

const paramNames = SECTION_CONTENT_TYPES.map(suffix('Id'))

/**
 * This component is similar to RecordPageView, except:
 * - This component records when the page is loaded, not when you scroll to the bottom of it.
 * - This component updates the "last viewed" content so the student can be redirected back to it later.
 */
const LastViewedContentTracker = () => {
  const { userAssignmentId } = useParams()
  const lastViewedContentId = compose(
    get('lastViewedContentId'),
    fallbackTo({}),
    useSelector,
    getUserAssignmentById,
  )({ userAssignmentId })
  const contentViewerParams = useSelector(getContentViewerParams())
  const contentId = compose(
    first,
    filter(identity),
    map(callWith(contentViewerParams)),
    map(get),
  )(paramNames)

  const dispatch = useDispatch()
  useEffect(
    () => {
      if (contentId && contentId !== lastViewedContentId) {
        dispatch(userAssignmentActions.updateLastViewedContent({ userAssignmentId, lastViewedContentId: contentId }))
      }
    },
    [contentId, dispatch, lastViewedContentId, userAssignmentId],
  )
}

export default LastViewedContentTracker
