import Container from '@mui/material/Container'
import AddToLibrary from './AddToLibrary'
import ActionMenu from './ActionMenu'
import Carousels from './Carousels'
import Hero from './Hero'

const Library = () => (
  <>
    <Hero />

    <Container
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'flex-end',
        marginTop: 2,
      }}
    >
      <AddToLibrary />

      <ActionMenu />
    </Container>

    <Carousels />
  </>
)

export default Library
