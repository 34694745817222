import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import DialogTitle from '@mui/material/DialogTitle'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from 'common/dialogs/Dialog'
import DataTable from 'common/tables/DataTable'
import useEffectOnce from 'hooks/useEffectOnce'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'
import { SimpleCell } from 'common/tables/DataTable/Cells'
import Html from 'common/text/Html'
import { actions } from 'reducers/credits'
import { getCreditsData, getCreditsFetchedStatus } from 'selectors/credits'
import { when } from 'fp/utils'
import Centered from 'common/layout/Centered'

const columns = [
  // Title cells may include HTML tags
  SimpleCell({ cell: ({ cell }) => (<Html body={cell.getValue()} />),
    enableSorting: false,
    header: 'Component',
    id: 'title' }),
  SimpleCell({ enableSorting: false, header: 'Title', id: 'imgNum' }),
  SimpleCell({ enableSorting: false, header: 'Credits', id: 'credit', size: 300 }),
]

const emptyTableText = 'There are no credits to display.'

const TabbedCredits = ({ activeTab, data, tableId, setActiveTab, tabTitles }) => (
  <TabContext value={activeTab}>
    <TabList
      aria-label="Tabbed Credits Data Tables"
      onChange={(e, value) => setActiveTab(value)}
    >
      {tabTitles.map((title, idx) => (
        <Tab
          key={idx}
          label={title}
          value={`${idx}`}
        />
      ))}
    </TabList>
    <>
      {data.map((section, idx) => (
        <TabPanel
          key={idx}
          value={`${idx}`} // type of `string` is expected by TabPanel
        >
          <DataTable
            columns={columns}
            containerProps={{ noMarginTop: true }}
            data={section.data}
            id={tableId}
            noDataText={emptyTableText}
          />
        </TabPanel>
      ))}
    </>
  </TabContext>
)

TabbedCredits.propTypes = {
  activeTab: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tableId: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const CreditsDialog = () => {
  const { toggleCreditsDialogIsOpen } = useContext(navDialogsContext)

  const creditsFetched = useSelector(getCreditsFetchedStatus)
  const creditsData = useSelector(getCreditsData)

  const tableId = 'credits-dialog-table'
  const dispatch = useDispatch()

  useEffectOnce(() => {
    when(!creditsFetched, dispatch, actions.fetchCredits())
  })

  const tabTitles = Array.isArray(creditsData)
    ? creditsData.map(({ title }) => title)
    : []

  const [activeTab, setActiveTab] = useState('0') // tab names correlate to their index value

  return (
    <Dialog
      fullScreen
      onClose={toggleCreditsDialogIsOpen}
      open
      showCloseButton
    >
      <DialogTitle>Credits</DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        {!creditsFetched
          ? (
            <Centered>
              <CircularProgress size={48} />
            </Centered>
          )
          : tabTitles.length ? (
            <TabbedCredits
              activeTab={activeTab}
              data={creditsData}
              setActiveTab={setActiveTab}
              tableId={tableId}
              tabTitles={tabTitles}
            />
          ) : (
            <DataTable
              columns={columns}
              containerProps={{ noMarginTop: true }}
              data={[]}
              id={tableId}
              noDataText={emptyTableText}
            />
          )}
      </DialogContent>
    </Dialog>
  )
}

export default CreditsDialog
