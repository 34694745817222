import { createElement, useContext } from 'react'
import { INTERACTIVE_TYPE_MULTIPLE_CHOICE } from 'core/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'

const MultipleChoice = () => {
  const { interactiveData: { allowMultipleSelection } } = useContext(interactiveContext)
  return createElement(allowMultipleSelection ? MultiSelect : SingleSelect)
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_MULTIPLE_CHOICE,
}

export default MultipleChoice
