import { useEffect, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from 'common/dialogs/Dialog'
import useSession from 'hooks/useSession'
import useInterval from 'hooks/useInterval'
import { now } from 'locale/dates'
import { actions as sessionActions } from 'reducers/session'

const timeLabel = seconds => `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')}`

const TimeoutWarning = () => {
  const dispatch = useDispatch()
  const { actions, session: { endsAt } } = useSession()
  const keepAlive = compose(dispatch, actions.keepAlive)

  const handleLogout = () => dispatch(sessionActions.logout())

  const [timeLeft, setTimeLeft] = useState(differenceInSeconds(endsAt, now()))

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1)
      }
    },
    1000,
  )

  useEffect(() => {
    setTimeLeft(differenceInSeconds(endsAt, now()))
  }, [endsAt])

  return (
    <Dialog
      open
      showCloseButton
      sx={{
        textAlign: 'center',
      }}
      tag="sessionEnding"
      title="Session Ending Soon"
      TitleProps={{
        mb: 3,
        variant: 'h3',
      }}
    >
      <DialogContent>
        <Typography variant="h1">{timeLabel(timeLeft)}</Typography>
        <Typography
          mb={0}
          mt={3}
          paragraph
          variant="feature-paragraph"
        >
          Your session is about to expire.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleLogout}
          variant="secondary"
        >
          Logout
        </Button>
        <Button
          color="secondary"
          onClick={keepAlive}
          variant="primary"
        >
          Keep Working
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimeoutWarning
