import { createElement, forwardRef } from 'react'
import PropTypes from 'prop-types'
import withEnterClicks from 'hoc/withEnterClicks'

const Clickable = withEnterClicks(({ passedRef, children, ...props }) => (
  <a
    ref={passedRef}
    {...props}
  >
    {children}
  </a>
))

const ClickableElement = forwardRef(({ onClick, type, WrapperProps, ...rest }, ref) => (
  <Clickable
    onClick={onClick}
    ref={ref}
    role={undefined}
    xlinkHref="#"
    {...WrapperProps}
  >
    {createElement(type, rest)}
  </Clickable>
))

ClickableElement.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  WrapperProps: PropTypes.object,
}

export default ClickableElement
