import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import Divider from '@mui/material/Divider'
import { get } from 'fp/objects'
import SplitHeadline from 'common/text/SplitHeadline'
import { context } from 'hss/views/Search/SearchProvider'
import SearchControls from 'hss/views/Search/SearchControls'
import ActiveFilters from 'hss/views/Search/Filters/ActiveFilters'
import { paginationHeader } from './utils'

const Filters = ({
  renderAdvancedFilter,
  renderPrimaryFilter,
  renderSecondaryFilter,
}) => {
  const { dataset } = useContext(context)
  const squery = get('metadata.squery')(dataset)
  const { limit, offset } = squery || {}
  const count = get('metadata.count')(dataset) || 0

  const hasFilters = renderPrimaryFilter || renderSecondaryFilter || renderAdvancedFilter

  return (
    <>

      {!!hasFilters && (
        <>
          <Typography
            gutterBottom
            variant="body1-semibold"
          >
            Filter by type
          </Typography>

          {renderPrimaryFilter?.()}

          {Boolean(renderPrimaryFilter && renderSecondaryFilter) && <Divider />}

          {renderSecondaryFilter?.()}

          {renderAdvancedFilter?.()}

          <Divider />

        </>
      )}

      <SplitHeadline
        left={paginationHeader(count, offset, limit)}
        leftProps={{ size: 5 }}
        right={<SearchControls />}
      >

        {!!hasFilters && <ActiveFilters />}

      </SplitHeadline>
    </>
  )
}

Filters.propTypes = {
  renderAdvancedFilter: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
}

export default Filters
