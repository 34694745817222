import { createContext, useContext, useRef } from 'react'

const answerKeyContext = createContext()

export const AnswerKeyContextProvider = (props) => {
  const value = useRef({})
  return (
    <answerKeyContext.Provider
      {...props}
      value={value.current}
    />
  )
}

export const useIsInAnswerKeyContext = () => !!useContext(answerKeyContext)
