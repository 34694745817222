import CardActions from '@mui/material/CardActions'
import LinkButton from 'common/navigation/links/LinkButton'
import { curriculumUrl } from 'routing/consts'
import { contentShape } from 'core/shapes'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import Menu from './Menu'

const Actions = ({ content }) => (

  <CardActions
    style={{
      justifyContent: 'space-between',
      padding: '2.5rem',
    }}
  >
    <LinkButton
      color="secondary"
      to={`${curriculumUrl}/${CONTENT_TYPE_COURSE}/${content.id}`}
      variant="secondary"
    >
      View Course
    </LinkButton>
    <Menu content={content} />
  </CardActions>

)

Actions.propTypes = { content: contentShape.isRequired }

export default Actions
