import FormGroup from '@mui/material/FormGroup'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import Headline from 'common/text/Headline'
import { get, set } from 'fp/objects'
import withSaveUserPreferences from '../withSaveUserPreferences'

const Notifications = withSaveUserPreferences(({ onChange, preferences }) => {
  const handleChange = propName => () => {
    const value = get(propName)(preferences)
    onChange(set(propName, !value)(preferences))
  }

  return (
    <Headline
      mb={12}
      textTransform="capitalize"
      title="Notifications"
    >
      <Headline
        gutterBottom
        size={4}
        title="Type"
      >
        <FormGroup>
          <SwitchFormControl
            checked={get('studentNotifs')(preferences)}
            color="secondary"
            helperText="Messages regarding student work"
            label="Student"
            onChange={handleChange('studentNotifs')}
          />

          <SwitchFormControl
            checked={get('teacherNotifs')(preferences)}
            color="secondary"
            helperText="Helpful system usage and best practice information"
            label="Teacher"
            onChange={handleChange('teacherNotifs')}
          />

          <SwitchFormControl
            checked={get('systemNotifs')(preferences)}
            color="secondary"
            helperText="Information about system functional upgrades, changes and content additions"
            label="System"
            onChange={handleChange('systemNotifs')}
          />
        </FormGroup>
      </Headline>
    </Headline>
  )
})

export default Notifications
