import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useId } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useSelector } from 'react-redux'
import Headline from 'common/text/Headline'
import LearnBanner from 'hss/ContentViewer/Banner/LearnBanner'
import Warning from 'common/layout/Warning'
import actionTypes from 'reducers/actionTypes'
import useReduxCallback, { ERROR } from 'hooks/useReduxCallback'
import { studentAssignmentsUrl } from 'routing/consts'
import { noop } from 'fp/utils'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { formatDateStrLong, formatTimeStr } from 'locale/i18n'
import LinkButton from 'common/navigation/links/LinkButton'
import Info from 'common/layout/Info'

// TODO: Use temporarily for displaying incomplete items, but remove once we can determine completeness for real.
// const mockIncompleteItems = [
//   {
//     id: '1',
//     label: 'Word Cloud in subsection X (no response)',
//   },
//   {
//     id: '2',
//     label: 'Group & Sort in subsection Y (partial response)',
//   },
//   {
//     id: '3',
//     label: 'Subsection Z (unread)',
//   },
// ]

// // TODO: Get real incomplete items, once we know how to do that.
// const getIncompleteItems = userAssignmentId => userAssignmentId === '1' ? mockIncompleteItems : []

const Submit = () => {
  const { userAssignmentId } = useParams()
  const { submittedDate } = useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const incompleteItems = [] // TODO: getIncompleteItems(userAssignmentId)
  const isIncomplete = Boolean(incompleteItems.length)
  const incompleteWarningId = useId()
  const submittingInfoId = useId()

  const navigate = useNavigate()
  const [dispatchSubmit, status] = useReduxCallback({
    actionType: actionTypes.USER_ASSIGNMENT_SUBMIT,
    onSuccess: () => { navigate(studentAssignmentsUrl) },
  })
  const onSubmit = () => {
    dispatchSubmit(({ userAssignmentId }))
  }

  return (
    <>
      <LearnBanner title="Submit Assignment" />
      <Container>
        {submittedDate
          ? (
            <>
              <Info>
                This assignment was submitted on {formatDateStrLong(submittedDate)} at {formatTimeStr(submittedDate)}.
              </Info>
              <LinkButton
                to={studentAssignmentsUrl}
                variant="primary"
              >
                Back to my assignments
              </LinkButton>
            </>
          )
          : (
            <Headline
              mb={4}
              title="Ready to submit?"
            >
              <Typography id={submittingInfoId}>
                After submitting, you will no longer be able to change your responses.
              </Typography>
              {isIncomplete
                ? (
                  <>
                    <Warning id={incompleteWarningId}>
                      The following parts of your assignment are incomplete.
                      <List variant="unstyled">
                        {incompleteItems.map(({ id, label }) => (
                          <ListItem key={id}>
                            <Chip
                              color="primary"
                              label={label}
                              onClick={noop}
                              variant="outlined"
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Warning>
                    <Button
                      aria-describedby={[incompleteWarningId, submittingInfoId].join(' ')}
                      onClick={onSubmit}
                      variant="secondary"
                    >
                      Submit incomplete assignment anyway
                    </Button>
                  </>
                )
                : (
                  <Button
                    aria-describedby={submittingInfoId}
                    onClick={onSubmit}
                    variant="primary"
                  >
                    Submit assignment
                  </Button>
                )}
              {status === ERROR && (
                <Warning role="alert">
                  Unable to submit your assignment. Please try again later.
                </Warning>
              )}
            </Headline>
          )}
      </Container>
    </>
  )
}

export default Submit
