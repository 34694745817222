import { useCallback, useContext } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Html from 'common/text/Html'
import Headline from 'common/text/Headline'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import { INTERACTIVE_TYPE_SHORT_ANSWER, QUESTION_PROMPT_TYPE_QUESTION } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'

const excludeToolButtons = ['Format', 'Highlight', 'Indentation', 'Link', 'Lists']

const ShortAnswer = withQuestionPrompt(() => {
  const {
    interactionData: { response = '' },
    interactiveData: { sampleAnswer },
    onInteract,
    previewing,
    submittable,
  } = useContext(interactiveContext)

  const onChange = useCallback(({ target }) => {
    const newValue = target?.value
    onInteract({ response: newValue })
  }, [onInteract])

  const showAnswerKey = useIsInAnswerKeyContext()

  return (
    <Box>
      {showAnswerKey
        ? sampleAnswer && (
          <Headline
            textAlign="center"
            title="Sample Answer"
            variant="feature-paragraph-semibold"
          >
            <Html body={sampleAnswer} />
          </Headline>
        )
        : (
          <Container variant="margin-auto">
            <Headline
              mb={2}
              mt={6}
              size={5}
              textAlign="center"
              title="Answer"
            >
              {!submittable
                ? <Html body={response || '(no response)'} />
                : (
                  <RichTextEdit
                    excludeToolButtons={excludeToolButtons}
                    onChange={onChange}
                    required={!previewing}
                    value={response}
                  />
                )}
            </Headline>
          </Container>
        )}
    </Box>
  )
}, QUESTION_PROMPT_TYPE_QUESTION)

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_SHORT_ANSWER,
}

export default ShortAnswer
