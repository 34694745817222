import AllCourses from './AllCourses'
import RecentCourses from './RecentCourses'
import Hero from './Hero'

const Curriculum = () => (
  <>
    <Hero />

    <RecentCourses />

    <AllCourses queryParams={{ childDepth: 2, namedQuery: 'course-list' }} />
  </>
)

export default Curriculum
