import PropTypes from 'prop-types'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'

const Customize = ({ customizeAssignment, toggleCustomizeAssignment }) => (
  <SwitchFormControl
    checked={customizeAssignment}
    label="Customize Assignment"
    onChange={toggleCustomizeAssignment}
  />
)

Customize.propTypes = {
  customizeAssignment: PropTypes.bool.isRequired,
  toggleCustomizeAssignment: PropTypes.func.isRequired,
}

export default Customize
