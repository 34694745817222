import Stack from '@mui/material/Stack'
import Overview from 'hss/views/Classroom/Grades/Overview'
import Link from 'common/navigation/links/Link'
import { groupsUrl, studentsUrl } from 'routing/consts'

const Grades = () => (
  <Stack spacing={2}>
    <Overview headlineTitle="Grades" />

    {/*
        This div is only here to prevent the following paragraphs from appearing side by side.
        It'll probably get removed when we implement this page.
      */}
    <div>
      <p>Eventually this page will allow selection of a group or student.</p>
      <p>
        For now, click a gradebook link
        in the <Link to={groupsUrl}>Groups</Link> or <Link to={studentsUrl}>Students</Link> pages.
      </p>
    </div>

  </Stack>
)

export default Grades
