import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Fragment, useContext } from 'react'
import { compose } from 'redux'
import { assignmentSettingsShape, contentShape } from 'core/shapes'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import { fallbackTo, isDefined } from 'fp/utils'
import { px, rem } from 'styling/theming/base/mixins'
import { get } from 'fp/objects'
import { CONTENT_STATE_DRAFT } from 'core/consts'
import Headline from 'common/text/Headline'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import withProps from 'hoc/withProps'
import Toolbar from '../Subsection/Toolbar'
import WrappedContentBlocks from '../Subsection/WrappedContentBlocks'
import EnhancedContentHtml from './EnhancedContentHtml'
import MaybeHasBackground from './MaybeHasBackground'
import LinkToEditor from './LinkToEditor'
import MaybeHasContentSubType from './MaybeHasContentSubType'
import BlockStandards from './BlockStandards'

const blockShape = PropTypes.shape({
  block: PropTypes.object.isRequired, // can't use contentShape in oneOfType
  seRowSpan: PropTypes.number.isRequired,
  teRowSpan: PropTypes.number.isRequired,
})

export const blockOrArrayOfBlocks = PropTypes.oneOfType([
  blockShape,
  PropTypes.arrayOf(blockShape),
])

const ToolbarPlaceholder = () => (
  <div
    data-toolbarcontainer="subsection"
    style={{
      border: '1px solid red',
      fontStyle: 'italic',
      padding: px(2, 10),
      textAlign: 'center',
    }}
  >
    POTENTIAL TOOLBAR LOCATION
  </div>
)

const SEContent = (props) => {
  const {
    assignmentSettings,
    blockBundle,
    content,
    content: { data: { body, leveledBody, spanishBody, variant } = {} } = {},
    fullWidth,
    inTabbedSection,
    previewing,
  } = props

  const { displayingLeveledContent, displayingSpanishContent } = useContext(contentViewerContext) || {}

  // eslint-disable-next-line react/no-unstable-nested-components
  const ToolbarRenderer = () => !inTabbedSection
    ? null
    : previewing
      ? <ToolbarPlaceholder />
      : (
        <Box
          data-toolbarcontainer="subsection"
          display="flex"
          justifyContent="center"
          style={{ margin: rem(1, 0, 8) }}
        >
          <Toolbar />
        </Box>
      )

  const preferredBody = displayingSpanishContent
    ? spanishBody
    : displayingLeveledContent
      ? leveledBody
      : null

  const MaybeSpanish = displayingSpanishContent && spanishBody
    ? withProps('div', { lang: 'es' })
    : Fragment

  return Array.isArray(blockBundle)
    ? <WrappedContentBlocks {...{ ...props, variant }} />
    : (
      <MaybeHasBackground
        content={content}
        dark={false}
      >
        {!previewing && <LinkToEditor content={blockBundle.block} />}

        <MaybeHasContentSubType
          block={blockBundle.block}
          previewing={previewing}
        >
          <MaybeSpanish>
            <EnhancedContentHtml
              assignmentSettings={assignmentSettings}
              body={preferredBody || body || ''}
              childrenMetadata={blockBundle.block.children}
              contained={!fullWidth}
              data-contentid={blockBundle.block.id}
              data-contenttype={blockBundle.block.contentType}
              previewing={previewing}
              ToolbarRenderer={ToolbarRenderer}
              variant={variant}
            />
          </MaybeSpanish>
        </MaybeHasContentSubType>

      </MaybeHasBackground>
    )
}

SEContent.propTypes = {
  assignmentSettings: assignmentSettingsShape,
  blockBundle: blockOrArrayOfBlocks,
  content: contentShape,
  fullWidth: PropTypes.bool,
  inTabbedSection: PropTypes.bool,
  previewing: PropTypes.bool,
}

const StyledTEContainer = styled(
  Container,
  { name: 'Block-TE' },
)(({ theme: { mixins: { important, importantRem }, palette, shadows } }) => ({

  backgroundColor: palette.background.paper,
  boxShadow: shadows[3],
  overflow: 'auto',
  padding: rem(6),
  'h2:not(.content-heading)': {
    color: palette.teacherEdition,
  },
  '.tr-typography.content-heading': { marginLeft: 0, marginTop: rem(4) },
  '.MuiContainer-root, .MuiContainer-root p': {
    padding: important(0),
    '&:first-of-type': { marginTop: important(0) },
  },
  'ul.standard': { left: importantRem(-2.2) },
}))

const TEContent = ({ assignmentSettings, content }) => {
  const bodyTe = content?.data?.body
  const variantTe = content?.data?.variant

  return (
    <StyledTEContainer className="te-content">
      <Headline
        size={3}
        textTransform="uppercase"
        title="Teacher Edition"
        weight={500}
      >

        <HeadlineStyleOffset offset={3}>
          {isDefined(bodyTe) && (

            <MaybeHasContentSubType block={content}>

              <EnhancedContentHtml
                assignmentSettings={assignmentSettings}
                body={bodyTe}
                childrenMetadata={[]}
                variant={variantTe}
              />

              <BlockStandards content={content} />

            </MaybeHasContentSubType>

          )}
        </HeadlineStyleOffset>
      </Headline>
    </StyledTEContainer>
  )
}

TEContent.propTypes = {
  assignmentSettings: assignmentSettingsShape,
  content: contentShape.isRequired,
  // inTabbedSection: PropTypes.bool.isRequired, available, should we need it
}

export const Block = (props) => {
  const {
    assignmentSettings,
    blockBundle,
    contentWrappingAllowed = false,
    disableFullWidth = false,
    displayingTeContent = false,
    inTabbedSection = false,
    isLast,
    previewing = false,
    sideBySideTeAllowed: providerPermitsSideBySideTe,
  } = props

  const { isEchoSidebarShrunk } = useContext(sidebarContext)

  const sideBySideTeAllowed = providerPermitsSideBySideTe && isEchoSidebarShrunk

  const { block, seRowSpan } = blockBundle
  const { data: { fullWidth } = {} } = block || {}

  const teRowSpan = block
    ? blockBundle.teRowSpan
    : get('0.teRowSpan')(blockBundle)

  const teacherEditionContent = compose(
    content => get('data.body')(content) ? content : null,
    fallbackTo(get('0.block.teacherEditionContent')(blockBundle)),
    get('teacherEditionContent'),
  )(block)

  const isFullWidth = fullWidth
    && !disableFullWidth
    && (!displayingTeContent || !sideBySideTeAllowed)

  if (contentWrappingAllowed) {
    return (
      <div className="block-layout">
        <SEContent
          assignmentSettings={assignmentSettings}
          blockBundle={blockBundle}
          content={block}
          fullWidth={Boolean(isFullWidth)}
          inTabbedSection={inTabbedSection}
          previewing={previewing}
        />

        {Boolean(displayingTeContent && teacherEditionContent) && (
          <Container>
            <hr />
            <TEContent
              assignmentSettings={assignmentSettings}
              content={teacherEditionContent}
              inTabbedSection={inTabbedSection}
            />
            <hr />
          </Container>
        )}
      </div>
    )
  }

  return (
    <tr
      className="block-layout"
      data-draft={block?.contentState === CONTENT_STATE_DRAFT ? 'true' : 'false'}
      {...isLast ? null : { height: 1 }}
    >
      <td
        className="block-layout"
        rowSpan={seRowSpan}
      >

        <SEContent
          assignmentSettings={assignmentSettings}
          blockBundle={blockBundle}
          content={block}
          fullWidth={Boolean(isFullWidth)}
          inTabbedSection={inTabbedSection}
          previewing={previewing}
        />

        {Boolean(teRowSpan && !sideBySideTeAllowed && displayingTeContent && teacherEditionContent) && (
          <TEContent content={teacherEditionContent} />
        )}

      </td>

      {Boolean(teRowSpan && sideBySideTeAllowed && displayingTeContent && teacherEditionContent) && (
        <td
          aria-hidden={!displayingTeContent}
          className="block-layout"
          rowSpan={teRowSpan}
        >

          <LinkToEditor
            content={block}
            isTe
          />

          <TEContent
            assignmentSettings={assignmentSettings}
            content={teacherEditionContent}
            inTabbedSection={inTabbedSection}
          />

        </td>
      )}
    </tr>
  )
}

Block.propTypes = {
  assignmentSettings: assignmentSettingsShape,
  blockBundle: blockOrArrayOfBlocks.isRequired,
  contentWrappingAllowed: PropTypes.bool,
  disableFullWidth: PropTypes.bool,
  displayingTeContent: PropTypes.bool,
  inTabbedSection: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
  previewing: PropTypes.bool,
  sideBySideTeAllowed: PropTypes.bool,
}
export default Block
