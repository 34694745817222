import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useSelector } from 'react-redux'
import EyebrowHeadline from 'common/text/EyebrowHeadline'
import { componentShape } from 'core/shapes'
import { isStudent } from 'selectors/users'
import { proficiencyLabels } from 'projections/scaffolds'
import useContent from 'hooks/useContent'

const ScaffoldContainer = styled(
  Container,
  { name: 'Block-Wrapper-ScaffoldContainer' },
)(({ theme: { mixins: { borderS, rem }, palette } }) => ({
  '> .MuiBox-root': {
    ...borderS(palette.cobalt[0]),
    flexDirection: 'column',
    padding: rem(3.2, 3.6),
  },
  marginTop: rem(4),
}))

const Scaffold = (props) => {
  const {
    children,
    className,
    'data-contentid': contentId,
    'data-contenttype': contentType,
  } = props

  const { name, proficiencyIds } = useContent({ contentId, contentType })

  let proficiencyNames = proficiencyLabels(proficiencyIds)
  proficiencyNames = proficiencyNames.length ? proficiencyNames : ['No proficiency assigned']

  const showProficiency = !useSelector(isStudent) && proficiencyNames.length

  return className === 'standalone-scaffold'
    ? (
      <ScaffoldContainer>
        <Box className="block-full-width">
          {Boolean(name || showProficiency) && (
            <Box mb={4}>
              <Container>
                <EyebrowHeadline
                  eyebrow={showProficiency ? proficiencyNames : null}
                  headlineVariant="list-header"
                  title={name || 'Scaffold'}
                />
              </Container>
            </Box>
          )}
          {children}
        </Box>
      </ScaffoldContainer>
    )
    : children
}

Scaffold.propTypes = {
  children: componentShape.isRequired,
  'data-contentid': PropTypes.string.isRequired,
  'data-contenttype': PropTypes.string.isRequired,
}

export default Scaffold
