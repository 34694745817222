import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

const CardFooter = props => (
  <Box>
    <Divider />
    <Stack
      className="CardFooter"
      direction="row"
      {...props}
    />
  </Box>
)

export default CardFooter
