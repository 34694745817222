import PropTypes from 'prop-types'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import { arraySequence } from 'fp/arrays'

const levels = arraySequence(3)

const ZoomLevel = (props) => {
  const { setZoomLevel, zoomLevel } = props
  const handleChange = (e, newMode) => {
    setZoomLevel(newMode)
  }

  return (
    <ToggleButtonGroup
      aria-label="Zoom level"
      color="secondary"
      exclusive
      onChange={handleChange}
      size="small"
      value={zoomLevel}
      variant="overlay"
    >
      {levels.map(level => (

        <ToggleButton
          aria-label={`Zoom level ${ level + 1}`}
          key={level}
          value={level}
        >
          <Tooltip title={`Zoom level ${ level + 1}`}>
            <span>
              {level + 1}x
            </span>
          </Tooltip>
        </ToggleButton>

      ))}

    </ToggleButtonGroup>
  )
}

ZoomLevel.propTypes = {
  setZoomLevel: PropTypes.func.isRequired,
  zoomLevel: PropTypes.oneOf([0, 1, 2]).isRequired,
}

export default ZoomLevel
