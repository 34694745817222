/* istanbul ignore file */
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import { position, transparentize } from 'polished'
import Box from '@mui/material/Box'
import { pausesShape } from './utils'
import Pause from './Pause'

const Container = styled(
  Box,
  { name: 'Animator-Scrubber' },
)(({ theme: { mixins: { rem, transition }, palette } }) => ({
  ...position('relative', rem(-1.8), null, null),

  '> div': {
    backgroundColor: transparentize(0.4, palette.accent.main),
    height: rem(1),
  },

  '.pause': {
    backgroundColor: palette.accent.main,
    cursor: 'pointer',
    height: rem(1),
    position: 'absolute',
    bottom: 0,
    width: '20%',
    ...transition('height', 150),
    '&:hover': {
      height: rem(2),
      borderTopLeftRadius: rem(0.5),
      borderTopRightRadius: rem(0.5),
    },
    pointerEvents: 'initial',
  },
}))

const Scrubber = (props) => {
  const { currentFrame, frameCount, pauses, setCurrentFrame } = props

  const percentComplete = useMemo(
    () => (currentFrame / frameCount) * 100,
    [currentFrame, frameCount],
  )

  return (
    <Container>
      <div style={{ width: `${percentComplete}%` }} />
      {pauses.map(pause => (
        <Pause
          className="pause"
          currentFrame={currentFrame}
          frameCount={frameCount}
          key={pause.id}
          pause={pause}
          setCurrentFrame={setCurrentFrame}
        />
      ))}
    </Container>
  )
}

Scrubber.propTypes = {
  currentFrame: PropTypes.number.isRequired,
  frameCount: PropTypes.number.isRequired,
  setCurrentFrame: PropTypes.func.isRequired,
  pauses: pausesShape.isRequired,
}

export default Scrubber
