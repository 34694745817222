import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Icon from '@mui/material/Icon'
import { Clipboard } from 'react-feather'
import { isDefined } from 'fp/utils'
import { classroomAssignmentsUrl } from 'routing/consts'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { contentShape } from 'core/shapes'
import Link from 'common/navigation/links/Link'
import { alter, assert } from 'core/store/search/squery'
import { buildUrl } from 'fp/internet'
import { pluralize } from 'fp/strings'
import withAbilityCheck from 'hoc/withAbilityCheck'
import ClipboardChecked from 'hss/images/indicators/clipboard-checked.svg'

const ChapterAssignmentLink = withAbilityCheck(({ content }) => {
  const {
    assignmentCount,
    id: contentId,
    name,
  } = content

  const filteredAssignmentsUrl = buildUrl(
    classroomAssignmentsUrl,
    {
      squery: {
        sq: alter.set.where('contentId').is(String(contentId))(assert()),
      },
    },
  )

  return (
    <Stack direction="row">
      {isDefined(assignmentCount) && assignmentCount > 0
        ? (
          <>
            <Icon sx={{ mr: 1 }}>
              <ClipboardChecked />
            </Icon>
            <Box>
              Currently used in&nbsp;
              <Link
                aria-label={`${pluralize('assignment')(assignmentCount)} of chapter, ${name}`}
                data-testid="assignment-count-link"
                to={filteredAssignmentsUrl}
              >
                {pluralize('assignment')(assignmentCount)}
              </Link>
            </Box>
          </>
        )
        : (
          <>
            <Icon sx={{ mr: 1 }}>
              <Clipboard />
            </Icon>
            Not assigned
          </>
        )}

    </Stack>
  )
}, ABILITY_ASSIGNMENT_CREATION)

ChapterAssignmentLink.propTypes = {
  content: contentShape.isRequired,
}

export default ChapterAssignmentLink
