import { useContext, useMemo } from 'react'
import cl from 'classnames'
import { styled } from '@mui/material/styles'
import TabContext from '@mui/lab/TabContext'
import { useSelector } from 'react-redux'
import { ECHO_FEATURE_ACT, ECHO_FEATURE_BLASTY_BLAST, ECHO_FEATURE_PICTURE_THIS, ECHO_FEATURE_POLL, ECHO_FEATURE_RATE, ECHO_FEATURE_TALK_BACK, ECHO_FEATURE_TOP_TEN } from 'hss/ContentBuilder/consts'
import useNavigation from 'hooks/useNavigation'
import actionTypes from 'reducers/actionTypes'
import Form from 'common/formControls/Form'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { featuresOnlyAvailableOnceSubmitted, isEnabled, mutexFeatures } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import { first } from 'fp/arrays'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { unwrap } from 'fp/strings'
import { isEchoSubmitted } from 'selectors/echo'
import { px } from 'styling/theming/base/mixins'
import Tabs from './Tabs'
import NavStrip from './NavStrip'
import Panels from './Panels'

const StyledWrapper = styled(
  'div',
  { name: 'Features-index' },
)({
  overflow: 'hidden',
  height: '100vh',
  '&, > form': {
    position: 'relative',
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  button: {
    outlineOffset: '-5px !important',
  },
  '.MuiTabPanel-root': {
    width: '100%',
    padding: px(10, 24),
  },
})

// features, then response, then act tab
const sortOrder = [
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TOP_TEN,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_ACT,
]

const Features = () => {
  const { subsection: echo } = useContext(contentViewerContext) || {}
  const { isEchoSidebarShrunk } = useContext(sidebarContext)
  const { location: { hash } } = useNavigation()

  const isSubmitted = useSelector(isEchoSubmitted(echo))
  const responseType = echo?.data.responseType || ECHO_FEATURE_BLASTY_BLAST

  const features = useMemo(() => {
    const available = Object
      .entries(echo?.data.features || {})
      .filter(isEnabled)
      .map(first)
      .filter(f => !mutexFeatures.includes(f) || f === responseType)
      .filter(feature => !featuresOnlyAvailableOnceSubmitted.includes(feature) || isSubmitted)
    return sortOrder.filter(key => available.includes(key))
  }, [echo?.data.features, isSubmitted, responseType])

  const currentTab = unwrap('#')(hash || features[0])

  return (
    <StyledWrapper className={cl({ 'drawer-expanded': !isEchoSidebarShrunk })}>

      <Form actionType={actionTypes.CONTENT_SAVE}>

        <TabContext value={currentTab}>

          <NavStrip>
            <Tabs {...{ currentTab, features }} />
          </NavStrip>

          {!isEchoSidebarShrunk && <Panels {...{ features, responseType }} />}

        </TabContext>

      </Form>

    </StyledWrapper>
  )
}

export default Features
