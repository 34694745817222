import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { Trash } from 'react-feather'
import { useTheme } from '@mui/material/styles'
import RangeSlider from 'common/formControls/sliders/RangeSlider'
import { identity } from 'fp/utils'
import BaseColorPicker from 'common/formControls/selects/ColorPicker'

const ColorPicker = (props) => {
  const { palette: { gradebook } } = useTheme()

  return (
    <BaseColorPicker
      colors={gradebook}
      includeLabels
      {...props}
    />
  )
}

const Segment = (props) => {
  const {
    begin,
    className,
    colorId,
    colorName,
    disabled = false,
    end,
    fenceMax,
    fenceMin,
    idx,
    onChange = identity,
    onColorChange,
    onDelete,
  } = props

  return (
    <Grid
      className={className}
      container
      spacing={5}
    >
      <Grid
        item
        xs={2}
      >
        <ColorPicker
          colorId={colorId}
          onChange={onColorChange}
        />
      </Grid>

      <Grid
        item
        xs={8}
      >
        <RangeSlider
          data-testid={`slider-${idx}`}
          fenceMax={fenceMax}
          fenceMin={fenceMin}
          leftTrackLabelAfterPercentage={5}
          onChange={onChange}
          readOnly={disabled}
          rightTrackLabelAfterPercentage={0}
          thumb="pill"
          thumbVisible={disabled ? 'never' : 'always'}
          value={[begin, end]}
          valueLabelDisplay={disabled ? 'off' : 'on'}
          valueLabelFormat={identity}
        />
      </Grid>

      <Grid
        item
        xs={2}
      >
        {Boolean(onDelete) && (
          <Tooltip
            placement="top"
            title={`Remove ${colorName} Segment`}
          >
            <IconButton
              aria-label={`Remove ${colorName} Segment`}
              className="delete-button"
              color="secondary"
              data-bodyvariant="body1"
              onClick={onDelete}
              size="large"
              variant="secondary"
            >
              <Trash />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}

Segment.propTypes = {
  begin: PropTypes.number.isRequired,
  colorId: PropTypes.number.isRequired,
  colorName: PropTypes.string,
  disabled: PropTypes.bool,
  end: PropTypes.number.isRequired,
  fenceMax: PropTypes.number,
  fenceMin: PropTypes.number,
  idx: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onColorChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
}

export default Segment
