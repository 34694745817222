import { useContext } from 'react'
import PropTypes from 'prop-types'
import cl from 'classnames'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import StyledContent from 'hss/ContentViewer/StyledContent'
import { generateId } from 'fp/utils'
import { CONTENT_STATE_DRAFT, INTERACTIVE_TYPE_TIMELINE, TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { getContextualAssignment } from 'selectors/assignments'
import { getLocalSetting } from 'selectors/localSettings'
import { contentViewerContext } from '../../ContentViewerProvider'
import Block, { blockOrArrayOfBlocks } from '../Block'
import { processForHorizontalWrappers } from './utils'

const itemsFromBlock = block => block?.children || []

const someChildrenFloat = items => items.some(({ data: { float } = {} }) => ['left', 'right'].includes(float))

const Margined = styled(
  'div',
  { name: 'Interactive-InteractiveContainer' },
)(({ theme: { mixins: { featuredMaxWidth } } }) => ({
  maxWidth: featuredMaxWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const MaybeMargined = ({
  contentWrappingAllowed,
  blockBundle,
  children,
}) => {
  const items = Array.isArray(blockBundle)
    ? blockBundle.reduce((acc, block) => [...acc, ...itemsFromBlock(block)], [])
    : itemsFromBlock(blockBundle.block)

  return items.some(({ contentSubType }) => [INTERACTIVE_TYPE_TIMELINE].includes(contentSubType))
    ? children
    : contentWrappingAllowed && someChildrenFloat(items)
      ? <Margined>{children}</Margined>
      : children
}

MaybeMargined.propTypes = {
  contentWrappingAllowed: PropTypes.bool.isRequired,
  blockBundle: blockOrArrayOfBlocks.isRequired,
  children: PropTypes.node.isRequired,
}

const WrapWithTable = ({
  children,
  displayingTeContent,
  inTabbedSection,
  sideBySideTeAllowed,
  videosInTheaterMode,
}) => (
  <table
    className={cl({
      'block-layout': true,
      displayingTeContent,
      sideBySideTeAllowed,
      videosInTheaterMode,
    })}
    role="presentation"
    style={{ width: '100%' }}
    {...inTabbedSection
      ? null
      : {
        cellPadding: 0,
        cellSpacing: 0,
      }
    }
  >
    <tbody className="block-layout">
      {children}
    </tbody>
  </table>
)

WrapWithTable.propTypes = {
  children: PropTypes.node.isRequired,
  displayingTeContent: PropTypes.bool.isRequired,
  inTabbedSection: PropTypes.bool.isRequired,
  sideBySideTeAllowed: PropTypes.bool.isRequired,
  videosInTheaterMode: PropTypes.bool.isRequired,
}

const WrapWithoutTable = ({ children, videosInTheaterMode }) => (
  <div
    className={cl({
      'block-layout-wrapper': true,
      'block-layout': true,
      videosInTheaterMode,
    })}
  >
    {children}
  </div>
)
WrapWithoutTable.propTypes = WrapWithTable.propTypes // not a typo

const Blocks = ({ inTabbedSection }) => {
  const {
    blockBundles: blocks,
    contentWrappingAllowed,
    displayingTeContent,
    sideBySideTeAllowed,
    subsection: { contentState, contentType },
    teContentAvailable,
    videosInTheaterMode,
  } = useContext(contentViewerContext)

  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))

  const blockBundles = processForHorizontalWrappers({ contentType, presenterModeEnabled })(blocks)

  const assignmentSettings = useSelector(getContextualAssignment)?.data?.settings

  const Wrapper = contentWrappingAllowed ? WrapWithoutTable : WrapWithTable

  return (
    <StyledContent data-draft={contentState === CONTENT_STATE_DRAFT ? 'true' : 'false'}>
      <Wrapper
        {...{
          displayingTeContent,
          inTabbedSection,
          sideBySideTeAllowed,
          videosInTheaterMode,
        }}
      >
        {blockBundles.map((blockBundle, blockIdx) => (
          <MaybeMargined
            blockBundle={blockBundle}
            contentWrappingAllowed={contentWrappingAllowed}
            key={`block-${blockBundle.block?.id ?? generateId()}`}
          >
            <Block
              isLast={blockIdx === blockBundles.length - 1}
              {...{
                assignmentSettings,
                blockBundle,
                contentWrappingAllowed,
                displayingTeContent,
                inTabbedSection,
                sideBySideTeAllowed,
                teContentAvailable,
              }}
            />
          </MaybeMargined>
        ))}
      </Wrapper>
    </StyledContent>
  )
}

Blocks.propTypes = {
  inTabbedSection: PropTypes.bool.isRequired,
}

export default Blocks
