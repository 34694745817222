import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import { compose } from 'redux'
import { X } from 'react-feather'
import { alter, inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { context } from 'hss/views/Search/SearchProvider'
import { labels } from 'hss/ContentBuilder/consts'

const ActiveFilters = () => {
  const {
    alterContentSubTypeFilters,
    alterContentTypeFilters,
    contentSubTypes,
    contentTypes,
    dataset,
    setSquery,
  } = useContext(context)
  const squery = get('metadata.squery')(dataset)
  const { modifiers } = squery
  const selectedContentFilters = inspect(squery).get.where('contentType').in()
  const selectedSubContentFilters = inspect(squery).get.where('contentSubType').in()
  const removeKeywordFilter = () => {
    setSquery(alter.remove.modifier('keywordSearch')(squery))
  }

  const clearAllFilters = () => {
    compose(
      setSquery,
      alter.set.offset(0),
      alter.set.where('contentType').in(contentTypes),
      alter.remove.modifier('contentSubTypes'),
      alter.remove.modifier('keywordSearch'),
    )(squery)
  }

  return (
    selectedContentFilters.length !== contentTypes.length
    || (modifiers?.keywordSearch)
    || selectedSubContentFilters.length > 0
      ? (
        <>
          <Typography
            gutterBottom
            variant="body1-semibold"
          >
            Active filters:
          </Typography>
          <Stack
            direction="row"
            my={2}
            spacing={2}
          >
            {selectedContentFilters.length !== contentTypes.length
            && selectedContentFilters.map(filter => (
              <Button
                endIcon={<X />}
                key={filter}
                onClick={alterContentTypeFilters(filter)}
              >
                Type: {labels[filter]}
              </Button>
            ))}

            {selectedSubContentFilters.length !== contentSubTypes.length
            && selectedSubContentFilters.map(filter => (
              <Button
                endIcon={<X />}
                key={filter}
                onClick={alterContentSubTypeFilters(filter)}
              >
                Type: {labels[filter]}
              </Button>
            ))}

            {Boolean(modifiers?.keywordSearch) && (
              <Button
                endIcon={<X />}
                onClick={removeKeywordFilter}
              >
                {modifiers.keywordSearch}
              </Button>
            )}

            <Button
              color="secondary"
              onClick={clearAllFilters}
              variant="tertiary"
            >
              Clear All
            </Button>
          </Stack>

        </>
      )
      : null)
}

export default ActiveFilters
