import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { compose } from 'redux'
import Box from '@mui/material/Box'
import { componentShape } from 'core/shapes'
import { equals, get, omit } from 'fp/objects'
import { featuredContentMaxWidth } from 'styling/theming/base'
import withProps from 'hoc/withProps'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { importantPx, rem } from 'styling/theming/base/mixins'
import Scaffold from './Scaffold'

const CalloutInner = ({ children, ...rest }) => (
  <Container {...rest}>
    <Box
      border="1px solid"
      borderColor="accent.dark"
      maxWidth={importantPx(featuredContentMaxWidth)}
      mx="auto"
      padding={rem(3.2, 3.2, 2)}

    >
      {children}
    </Box>
  </Container>
)

CalloutInner.propTypes = {
  children: componentShape,
}

const Callout = styled(
  CalloutInner,
  { name: 'Block-Wrapper-Callout' },
)(({ theme: { mixins: { important }, typography } }) => ({
  margin: rem(3.2, 'auto'),
  paddingLeft: important('var(--containedPaddingLeft)'),
  paddingRight: important('var(--containedPaddingRight)'),
  paddingTop: 0,
  paddingBottom: 0,
  'h1:first-of-type,h2:first-of-type,h3:first-of-type,h4:first-of-type,h5:first-of-type,h6:first-of-type ': {
    ...typography.variants['paragraph-semibold'],
    margin: rem(0, 0, 1.2),
  },
}))

const OrderedFeatureList = styled(
  withProps('div', { className: 'block-full-width block-partially-contained' }),
  { name: 'Block-Wrapper-OrderedFeatureList' },
)(({ theme: { mixins: { important, borderS }, palette } }) => ({
  color: palette.grey.contrastText,
  backgroundColor: palette.grey[0],
  padding: rem(9.6, 2.4),
  '.MuiTypography-advanced-heading': {
    marginTop: important(0),
    marginBottom: rem(6.4),
  },
  li: {
    padding: rem(4.8),
    ...borderS(palette.primary.contrastText),
  },
}))

const SourceIntroduction = styled(
  Container,
  { name: 'Block-Wrapper-SourceIntroduction' },
)(({ theme: { mixins: { importantRem }, palette, typography } }) => ({
  backgroundColor: palette.grey[5],
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: rem(8.5, 0, 5.5),
  marginTop: rem(5),
  marginBottom: rem(7),
  ...typography.variants['feature-paragraph'],
  '> *': {
    maxWidth: featuredContentMaxWidth,
    margin: 'auto',
  },
  'h1,h2,h3,h4,h5,h6': {
    ...typography.h4,
    marginTop: importantRem(0),
    marginBottom: importantRem(3.2),
  },
}))

export const supportedBlockVariants = [
  {
    value: 'none',
    label: '[normal]',
    title: 'Standard - No frills',
  },

  {
    value: 'callout-question',
    label: 'Callout',
    Component: Callout,
    title: `
      Adds an accent-colored border around the block.`,
  },

  {
    value: 'chapter-recap',
    label: 'Chapter Recap',
    Component: 'div',
    title: `
      These types of blocks arrange themselves horizontally if there are more
      than one back-to-back`,
  },

  {
    value: 'ordered-feature-list',
    label: 'Ordered Feature List',
    Component: OrderedFeatureList,
    shortLabel: 'Features',
    title: `
      Adds a dark background to the block.  Any ordered list items will have
      large numerals`,
  },

  {
    value: 'research-link',
    label: 'Research Link',
    Component: 'div',
    title: `
      These types of blocks arrange themselves horizontally if there are more
      than one back-to-back`,
  },

  {
    value: 'source-introduction',
    label: 'Source Introduction',
    Component: SourceIntroduction,
    shortLabel: 'Intro',
    title: `
      A full-width block using a light-grey background.  All contained content
      will be center-aligned
      `,
  },
]

const Wrapper = (props) => {
  const { children, variant = 'none', ...rest } = props

  let { Component } = supportedBlockVariants.find(compose(
    equals(variant),
    get('value'),
  )) || {}

  /**
   * TODO: (if ever scaffolds require their own variants)
   * This will need to be refactored.
   */
  if (!Component && rest['data-contenttype'] === CONTENT_TYPE_SCAFFOLD) {
    Component = withProps(Scaffold, { className: 'standalone-scaffold' })
  }

  return Component ? <Component {...omit('previewing')(props)} /> : (
    <div {...omit('previewing')(rest)}>
      {children}
    </div>
  )
}

Wrapper.propTypes = {
  children: componentShape.isRequired,
  variant: PropTypes.oneOf([
    '',
    ...supportedBlockVariants.map(get('value')),
  ]),
}

export default Wrapper
