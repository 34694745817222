import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import withContent from 'hoc/withContent'
import { formProps } from 'hss/ContentBuilder/utils'
import Hero from './Hero'
import Unit from './Unit'

const Units = ({ content }) => {
  const { children } = content

  return (
    <>
      <Hero content={content} />

      <List>

        {children.map(unit => (
          <ListItem key={unit.id}>
            <Unit contentId={unit.id} />
          </ListItem>
        ))}

      </List>
    </>
  )
}

Units.propTypes = formProps

const UnitsWithContent = withContent(Units, {
  contentType: CONTENT_TYPE_COURSE,
  queryParams: { childDepth: 2 },
})

export default UnitsWithContent
