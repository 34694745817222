import { useContext } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import useEffectOnce from 'hooks/useEffectOnce'
import { when } from 'fp/utils'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Rubric from '../Rubric'

const Activity = () => {
  const { completed, markComplete, previewing, scoreData } = useContext(interactiveContext)
  const isGraded = scoreData?.requiresGrading === false

  useEffectOnce(() => { when(!previewing && !completed, markComplete) })

  return !isGraded && (
    <Container>
      <Box pt={6}>
        <p>This activity will use the following rubric for scoring.</p>
        <Rubric />
      </Box>
    </Container>
  )
}

export default Activity
