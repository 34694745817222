import PropTypes from 'prop-types'
import { createElement, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material/styles'
import Radio from 'common/formControls/switches/Radio'
import { first } from 'fp/arrays'
import { isDefined } from 'fp/utils'
import { omit } from 'fp/objects'
import withProps from 'hoc/withProps'
import Html from 'common/text/Html'
import { numberOrString } from 'core/shapes'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import Explanation from './Explanation'
import BaseMultipleChoice from './BaseMultipleChoice'
import AnswerChoiceBox from './AnswerChoiceBox'
import { getCorrectAnswerChoiceIds } from './utils'

const ItemRenderer = ({
  isCorrect = false,
  explanation,
  isSelected = false,
  label,
  ...rest
}) => {
  const { completed, scoreData: { hidden: isScoreHidden } = {} } = useContext(interactiveContext)

  const showAnswers = useIsInAnswerKeyContext() || (completed && !isScoreHidden)

  const { palette, shadows } = useTheme()

  const selectedBoxShadow = { boxShadow: shadows.border, color: palette.boxshadowPrimary }

  const noBoxShadow = { boxShadow: 'none' }

  const barColor = showAnswers
    ? isSelected
      ? isCorrect
        ? 'success.main'
        : 'error.main'
      : 'transparent'
    : 'transparent'

  return (
    <AnswerChoiceBox
      barColor={barColor}
      style={
        showAnswers
          ? { ...noBoxShadow, pointerEvents: 'none' }
          : isSelected
            ? selectedBoxShadow
            : {}
      }
    >
      <Radio
        description={showAnswers
          ? (
            <Explanation
              explanation={explanation}
              isCorrectAnswer={isCorrect}
            />
          )
          : null}
        label={(
          <Box
            color="grey.0"
            component="span"
          >
            <Html
              body={label}
              substituteInlineBlocks
            />
          </Box>
        )}
        {...omit(['id'])(rest)}
      />
    </AnswerChoiceBox>
  )
}

ItemRenderer.propTypes = {
  explanation: PropTypes.string,
  label: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  isSelected: PropTypes.bool,
}

const RadioGroupRenderer = ({
  handleChange,
  options,
  previewing,
  selectedAnswerId,
  ...rest
}) => {
  const onChange = useCallback(
    ({ target: { value } }) => { handleChange(value) },
    [handleChange],
  )

  return (
    <RadioGroup
      name="selectedAnswer"
      onChange={onChange}
      required={!previewing}
      value={selectedAnswerId}
      {...rest}
    >
      {options.map((option) => {
        const { id } = option
        return createElement(ItemRenderer, {
          ...option,
          key: id,
          isSelected: id === selectedAnswerId,
          value: id,
        })
      })}
    </RadioGroup>
  )
}

RadioGroupRenderer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  previewing: PropTypes.bool.isRequired,
  selectedAnswerId: numberOrString,
}

const SingleSelect = () => {
  const {
    // API grading expects an array for all multiple choice interactives,
    // regardless of how many correct answers there are.
    interactionData: { selectedAnswerChoiceIds },
    interactiveData,
    onInteract,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const { previewing = false } = interactiveData
  const [selectedAnswerIdState, setSelectedAnswerIdState] = useState()

  const selectedAnswerChoiceId = first(useIsInAnswerKeyContext()
    ? getCorrectAnswerChoiceIds(interactiveData)
    : selectedAnswerChoiceIds)

  useLayoutEffect(
    () => { setSelectedAnswerIdState(selectedAnswerChoiceId) },
    [selectedAnswerChoiceId],
  )

  const handleChange = useCallback((newAnswerIdState) => {
    setSelectedAnswerIdState(newAnswerIdState)
    onInteract({
      selectedAnswerChoiceIds: [newAnswerIdState],
    })
  }, [onInteract])

  useEffect(() => {
    setSubmitEnabled(isDefined(selectedAnswerIdState))
  }, [selectedAnswerIdState, setSubmitEnabled])

  const GroupRenderer = useMemo(
    () => withProps(RadioGroupRenderer, {
      handleChange,
      previewing,
      selectedAnswerId: selectedAnswerIdState,
    }),
    [
      handleChange,
      previewing,
      selectedAnswerIdState,
    ],
  )

  return (
    <BaseMultipleChoice GroupRenderer={GroupRenderer} />
  )
}

export default SingleSelect
