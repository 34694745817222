import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { animated, useSpring } from 'react-spring'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useId, useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import { percent, toInt } from 'fp/strings'
import { hotspotCalloutShape } from 'core/shapes'
import Point from 'hss/images/controls/image-title-drag/square.svg'
import { when, whenPresent } from 'fp/utils'
import useHoverFocus from 'hooks/useHoverFocus'
import CalloutBody from './CalloutBody'

const Container = styled(
  animated.div,
  { name: 'Hotspot-Callout' },
)(({ theme: { palette, mixins: { sizeR, transition } } }) => ({
  position: 'absolute',
  userSelect: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  svg: {
    ...transition(),
    ...sizeR(3.2),
  },
  path: {
    ...transition(),
    fill: palette.cobalt[0],
    fillOpacity: 0.6,
    stroke: palette.background.paper,
  },
  '&:hover': {
    svg: {
      ...transition(),
      ...sizeR(4.8),
      transform: ('translate(0, -10%)'),
    },
    path: {
      ...transition(),
      fillOpacity: 1,
    },
  },
}))

const Callout = (props) => {
  const {
    callout,
    callout: { imagePosition },
    idx,
    imageUrl,
    onOpened,
    totalCallouts,
  } = props
  const [, isHovered] = useHoverFocus()
  const { zIndex } = useTheme()
  const [open, setOpen] = useState(false)
  const tooltipId = useId()

  const { calloutCoord } = callout
  const [left, top] = calloutCoord.split(',').map(toInt)

  const ref = useRef()

  const toggleOpen = () => {
    setOpen(!open)
  }
  const handleTooltipClose = () => {
    setOpen(false)
  }

  useEffect(
    () => { when(open, whenPresent, onOpened) },
    [onOpened, open],
  )

  const springStyle = useSpring({
    config: {
      mass: 0.5, tension: 300, friction: 12,
    },
    delay: idx * (500 / totalCallouts),
    from: {
      opacity: 0,
      top: percent(-100),
      left: percent(left),
      transform: 'translateX(-50%)',
    },
    to: {
      opacity: 1,
      top: `calc(${percent(top)} - 24px)`,
    },
  })

  return (
    <Container
      ref={ref}
      style={{
        ...springStyle,
        zIndex: isHovered ? zIndex.tooltip : 0,
      }}
    >
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          id={tooltipId}
          onClose={handleTooltipClose}
          open={open}
          PopperProps={{
            'data-image-position': imagePosition,
            'data-variant': 'inverted',
            container: ref?.current?.closest('.MuiBackdrop-root'),
          }}
          title={(
            <CalloutBody
              callout={callout}
              idx={idx}
              imageUrl={imageUrl}
            />
          )}
        >
          <IconButton
            aria-controls={tooltipId}
            aria-expanded={open}
            aria-label={callout.label}
            onClick={toggleOpen}
          >
            <Point />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Container>
  )
}

Callout.propTypes = {
  callout: hotspotCalloutShape.isRequired,
  idx: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  onOpened: PropTypes.func,
  totalCallouts: PropTypes.number.isRequired,
}

export default Callout
