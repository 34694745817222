import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import Button from '@mui/material/Button'
import { useContext, useId } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { List } from 'react-feather'
import Collapse from '@mui/material/Collapse'
import Overview from 'hss/views/Classroom/Grades/Overview'
import actionTypes from 'reducers/actionTypes'
import withFetchedItem from 'hoc/withFetchedItem'
import { get } from 'fp/objects'
import { isUndefined } from 'fp/utils'
import { prefix } from 'fp/strings'
import { ASSIGNMENT_TARGET_GROUP, ASSIGNMENT_TARGET_STUDENT } from 'core/consts'
import { getUserById } from 'selectors/users'
import { getGroupStudents } from 'selectors/groups'
import Select from 'common/formControls/selects/Select'
import useToggleState from 'hooks/useToggleState'
import withProps from 'hoc/withProps'
import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import BusySpinner from 'common/indicators/BusySpinner'
import Well from 'common/layout/Well'
import { rem } from 'styling/theming/base/mixins'
import GradebookContextProvider, { gradebookContext } from './GradebookContextProvider'
import Filters from './Filters'
import withSearchProvider from './searchConfig'
import Stats from './Stats'

const GradebookContent = () => {
  const [showStats, toggleShowStats] = useToggleState(false)
  const {
    setView,
    userAssignmentIds,
    view,
  } = useContext(gradebookContext)
  const statsId = useId()

  return isUndefined(userAssignmentIds)
    ? <BusySpinner />
    : (
      <CommonTableSearch
        renderPrimaryFilter={() => <Filters />}
        renderSearchControls={() => (
          <>
            <Select
              fullWidth={false}
              label="View"
              name="View"
              onChange={compose(setView, get('target.value'))}
              style={{ width: '20rem' }}
              value={view}
              variant="filled"
            >
              <MenuItem value="scores">Scores</MenuItem>
              <MenuItem value="percentages">Percentages</MenuItem>
              <MenuItem value="standards">Standards</MenuItem>
              <MenuItem value="standardsMastery">Standards Mastery</MenuItem>
            </Select>

            <Button
              aria-controls={statsId}
              aria-expanded={showStats}
              endIcon={<List />}
              onClick={toggleShowStats}
            >
              Stats
            </Button>
          </>
        )}
        renderStats={() => (
          <Collapse in={showStats}>
            <Well
              id={statsId}
              sxProps={{
                background: ({ palette }) => palette.background.paper,
                border: 'none',
                padding: rem(2.4, 3.6),
              }}
            >
              <Stats />
            </Well>
          </Collapse>
        )}
        title=""
      />
    )
}

const GradebookContainer = (props) => {
  const { getName, item: target } = props
  const headline = compose(prefix('Grades: '), getName)(target)
  return (
    <Stack spacing={2}>
      <Overview headlineTitle={headline} />
      {/*
        Not using ClassroomInnerContainer for Gradebook at this point.
        It might make sense to start using ClassroomInnerContainer later, but
        Gradebook isn't quite the same as the other Classroom pages, so
        some of ClassroomInnerContainer's assumptions don't work.
        For example:
        - Gradebook will have many views, one of which won't have a table.
        - The "actions" button won't be limited to just bulk actions for the selected rows.
        - Row selection won't be allowed unless you're in export mode, which doesn't exist yet.
        So when Gradebook is closer to being done, we might consider adapting ClassroomInnerContainer
        to be able to support Gradebook's needs too. Or maybe we could split it into smaller pieces,
        some that get used by Gradebook and some that don't.
      */}
      <GradebookContextProvider {...props}>
        <GradebookContent {...props} />
      </GradebookContextProvider>

    </Stack>
  )
}
GradebookContainer.propTypes = {
  getName: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

const GroupGradebook = withProps(
  withFetchedItem(GradebookContainer, { actionType: actionTypes.GROUP_FETCH }),
  {
    getName: get('name'),
    targetTypeId: ASSIGNMENT_TARGET_GROUP,
  },
)
const StudentGradebook = withProps(
  withFetchedItem(GradebookContainer, { actionType: actionTypes.USER_FETCH }),
  {
    getName: ({ firstName, lastName } = {}) => `${lastName}, ${firstName}`,
    targetTypeId: ASSIGNMENT_TARGET_STUDENT,
  },
)

const Gradebook = withSearchProvider(() => {
  const { groupId, studentId } = useParams()

  return groupId
    ? <GroupGradebook studentsSelector={getGroupStudents({ groupId })} />
    : studentId
      ? <StudentGradebook studentsSelector={getUserById({ userId: studentId })} />
      : null
})

export default Gradebook
