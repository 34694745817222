import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import withProps from 'hoc/withProps'

const FunFactLink = withProps(
  styled(
    Button,
    { name: 'FunFacts-PopperControl' },
  )(({ theme: { palette } }) => ({
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      color: palette.cobalt[0],
      fontWeight: 600,
      svg: { 'path[stroke]': { stroke: palette.cobalt[0] } },
    },

    svg: {
      height: 14,
    },
  }
  )),
  { variant: 'mock-link' },
)

export default FunFactLink
