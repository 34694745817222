import { contentShape } from 'core/shapes'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import CommonVocabRollup from '../CommonVocabRollup'

const VocabRollup = ({ block }) => {
  const { contentType, id: contentId } = block
  const contentVocabPhrases = useChapterVocabPhrases({ contentId, contentType })

  return (
    <CommonVocabRollup vocabPhrases={contentVocabPhrases} />
  )
}

VocabRollup.propTypes = {
  block: contentShape.isRequired,
}

export default VocabRollup
