
import { styled } from '@mui/material/styles'
import { position } from 'polished'

const StyledContainer = styled(
  'div',
  { name: 'Image-StyledContainer' },
)(({

  theme: {
    mixins: {
      featuredMaxWidth,
      important,
      rem,
      transition,
    },
    palette,
    zIndex,
  },
}) => ({

  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },

  figure: {

    img: {
      width: '100%',
      display: 'block',
      objectFit: 'contain',
    },

    '.sensitivity-overlay-wrapper': {
      ...position('absolute', 0, 0, 0, 0),
      ...transition('opacity'),

      '.sensitivity-message': {
        ...position('absolute', 0, 0, 0, 0),
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: zIndex.modal + 1,
        display: 'flex',
        flexFlow: 'column nowrap',
        button: {
          marginTop: 20,
        },
      },

      '.sensitivity-overlay': {
        backgroundColor: 'rgba(255,255,255,128)',
        filter: 'blur(30px)',
        zIndex: zIndex.modal,
        ...position('absolute', 0, 0, 20, 0),
      },
    },

    '.image-wrapper': {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  },

  '&:not(.image-no-gutters)': {
    maxWidth: featuredMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: important('var(--containedPaddingLeft)'),
    paddingRight: important('var(--containedPaddingRight)'),

    figure: {
      '.image-color-swatch': {
        lineHeight: 0,
        '&::after, &::before': {
          aspectRatio: '9 / 16',
          backgroundColor: palette.accent.dark,
          bottom: 0,
          content: '""',
          height: rem(15),
          maxHeight: '50%',
          mixBlendMode: 'multiply',
          position: 'absolute',
          transform: 'translateX(-50%)',
        },
      },

      '&.caption-left, &.caption-right': {
        display: 'flex',
        width: '100%',
        '.image-wrapper': {
          maxWidth: '66%',
          width: 'auto',
        },
        figcaption: {
          minWidth: '33%',
          backgroundColor: palette.common.white,
        },
      },

      '&.caption-bottom': {
        width: '100%',
        '.image-wrapper': {
          width: 'auto',
        },
        figcaption: {
          backgroundColor: palette.common.white,
          display: 'inline-block',
        },
      },

      '&.caption-left': {
        flexDirection: 'row-reverse',
      },

      '&.caption-right': {
        flexDirection: 'row',
      },

      '&.caption-left .image-color-swatch::after': {
        display: 'none',
      },

      '&.caption-right .image-color-swatch::before': {
        display: 'none',
      },

      '&.caption-bottom .image-color-swatch': {
        '&::after, &::before': {
          display: 'none',
        },
      },
    },
  },

  '&.image-no-gutters': {
    figure: {
      figcaption: {
        marginLeft: rem(4),
        marginRight: rem(4),

        p: {
          maxWidth: '100%',
        },
      },
    },
  },

  '&.image-embedded': {
    marginBottom: rem(2),
  },

  '&.image-scroll-horizontally': {
    '.image-wrapper': {
      maxWidth: '100%',
      overflowX: 'scroll',
    },
    img: {
      width: 'auto',
      maxWidth: 'none',
      maxHeight: '75vh',
    },
    '&.image-sensitivity-warning': {
      '.image-wrapper': {
        overflowX: 'hidden',
      },
    },
  },
}))

export default StyledContainer
