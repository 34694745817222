import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useContext } from 'react'
import { featuredContentMaxWidth } from 'styling/theming/base'
import Centered from 'common/layout/Centered'
import { INTERACTIVE_TYPE_LINEAR_SCALE, QUESTION_PROMPT_TYPE_QUESTION } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Results from './Results'
import EntryForm from './EntryForm'

const LinearScale = withQuestionPrompt(({ displayResults = false }) => {
  const {
    completed,
    isGrading,
    submittable,
  } = useContext(interactiveContext)

  return (
    <Box
      maxWidth={featuredContentMaxWidth}
      mx="auto"
    >
      {displayResults
        ? <Results />
        : isGrading
          ? completed
            ? <EntryForm /> // show student's response
            : <Centered>(no response)</Centered>
          : !submittable
            ? <Results />
            : <EntryForm />}

    </Box>

  )
}, QUESTION_PROMPT_TYPE_QUESTION)

LinearScale.propTypes = {
  displayResults: PropTypes.bool,
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_LINEAR_SCALE,
}

export default LinearScale
