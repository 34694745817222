import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { getUserAssignment } from 'selectors/userAssignments'
import useAnnotations from 'hooks/useAnnotations'
import Pane from '../Pane'
import SubsectionAnnotations from './SubsectionAnnotations'

const AllAnnotations = ({ hideHeader = false }) => {
  const userAssignment = useSelector(getUserAssignment) || {}
  const { id: userAssignmentId } = userAssignment
  const { annotations, subsectionsWithAnnotations } = useAnnotations({ userAssignmentId })

  const Header = useMemo(() => (hideHeader
    ? ({ children }) => <div>{children}</div>
    : ({ children }) => <Pane title="All Annotations">{children}</Pane>
  ), [hideHeader])

  return (
    <Header>
      {subsectionsWithAnnotations.map((subsection, idx) => (
        <SubsectionAnnotations
          annotations={annotations[subsection.id]}
          content={subsection}
          isLast={idx === subsectionsWithAnnotations.length - 1}
          key={subsection.id}
        />
      ))}
    </Header>
  )
}

AllAnnotations.propTypes = {
  hideHeader: PropTypes.bool,
}

export default AllAnnotations
