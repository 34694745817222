import ContentCarousel from './ContentCarousel'

const items = []// TODO: how to retrieve/save bookshelf items
const Bookshelf = () => (

  <ContentCarousel
    items={items}
    subtitle="Just for you."
    title="Bookshelf."
  />

)

export default Bookshelf
