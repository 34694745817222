import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useMemo } from 'react'
import { getNextPrevForContentViewer } from 'selectors/contentViewer'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import RecordPageView from 'hss/ContentViewer/RecordPageView'
import withProps from 'hoc/withProps'
import useToggleState from 'hooks/useToggleState'
import SubmitDialog from 'hss/sections/Assignments/SubmitDialog'
import withCanSubmitAssignment from 'hss/ContentViewer/withCanSubmitAssignment'
import NavigationButton from './NavigationButton'
import SubmitAssignmentButton from './SubmitAssignmentButton'

const Navigation = withCanSubmitAssignment(({ canSubmit = false, ...rest }) => {
  const { next, prev } = useSelector(getNextPrevForContentViewer({ preferTeCopy: true }))
  const isPinned = useIsPinnedContent()

  const [isSubmitDialogOpen, toggleIsSubmitDialogOpen] = useToggleState()

  const NextComponent = useMemo(
    () => next
      ? withProps(NavigationButton, {
        baseUrl: `../${next?.contentType}`,
        content: next,
        id: prev ? null : 'bottom-navigation',
        prevOrNext: 'next',
      })
      : canSubmit
        ? withProps(SubmitAssignmentButton, { onClick: toggleIsSubmitDialogOpen })
        : null,
    [canSubmit, next, prev, toggleIsSubmitDialogOpen],
  )

  return isPinned
    ? null
    : (
      <>

        {/**
          * We record a 'page-view' user interaction whenever the user scrolls
          * down to the bottom of the page.
          *
          * This recorder has been placed at the bottom of the page, above the
          * margin of the navigation controls.  This ensures that most of the
          * content has been viewed without requiring the user to scroll all the
          * way to the very bottom of the page.
          */}
        <RecordPageView />

        <Box
          bgcolor="grey.0"
          mt={12}
          {...rest}
        >

          {Boolean(prev && !NextComponent) && (
            <NavigationButton
              baseUrl={`../${prev?.contentType}`}
              content={prev}
              id="bottom-navigation"
              prevOrNext="prev"
            />
          )}

          {Boolean(NextComponent && !prev) && (
            <NextComponent />
          )}

          {Boolean(prev && NextComponent) && (
            <Grid
              container
              gap={0}
            >
              <Grid
                item
                md={6}
                sm={12}
              >
                <NavigationButton
                  baseUrl={`../${prev?.contentType}`}
                  content={prev}
                  id="bottom-navigation"
                  prevOrNext="prev"
                />
              </Grid>

              <Grid
                item
                md={6}
                sm={12}
              >
                <NextComponent />
              </Grid>
            </Grid>
          )}

        </Box>

        {Boolean(isSubmitDialogOpen) && (
          <SubmitDialog onClose={toggleIsSubmitDialogOpen} />
        )}

      </>
    )
})

Navigation.propTypes = {
  canSubmit: PropTypes.bool,
}

export default Navigation
