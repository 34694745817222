import { useDrag, useDrop } from 'react-dnd'
import PropTypes from 'prop-types'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { DND_TYPE_GROUP_AND_SORT_ITEM } from 'core/consts'
import { whenPresent } from 'fp/utils'
import { get } from 'fp/objects'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import DragItem from '../DragItem'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import { context } from './context'
import OptionListItem from './OptionListItem'

const Option = (props) => {
  const {
    draggedFrom,
    idx,
    item: optionItem,
    moveOption,
    onRemove,
    showRemoveBtn = false,
  } = props
  const { completed, dispatch } = useContext(context)
  const { interactive, isGrading, submittable } = useContext(interactiveContext)
  const { actualAnswer, droppedId, id, isCorrect, label, newItem } = optionItem
  const showAnswerKey = useIsInAnswerKeyContext()
  const ref = useRef(null)

  const scoringMode = get('scoring.mode')(interactive) || SCORING_MODE_NONE

  /* istanbul ignore next */
  const [{ handlerId }, drop] = useDrop({
    accept: DND_TYPE_GROUP_AND_SORT_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current || !moveOption) {
        return
      }
      const dragIndex = item.idx
      const hoverIndex = idx

      if (item.draggedItem.id === optionItem.id) return

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      const moveAfter = hoverClientY > hoverMiddleY

      moveOption(dragIndex, hoverIndex, item.draggedFrom, item.draggedItem, moveAfter)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.idx = hoverIndex
    },
  })

  /* istanbul ignore next */
  const [{ isDragging }, drag] = useDrag({
    type: DND_TYPE_GROUP_AND_SORT_ITEM,
    item: () => ({
      draggedFrom,
      draggedItem: optionItem,
      idx,
    }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging || newItem ? 0.2 : 1
  drag(drop(ref))

  const handleRemove = useCallback(() => {
    // put this item back in the well of available items
    dispatch({ type: 'DROP-ON-LIST', droppedId: id })

    // let the parent set the focus back to the "Add to Column" button
    whenPresent(onRemove)
  }, [dispatch, id, onRemove])

  const testId = `${actualAnswer}-${isCorrect ? 'correct' : 'incorrect'}`

  const removeButtonRef = useRef()
  useEffect(() => {
    if (showRemoveBtn && !isGrading && submittable) {
      removeButtonRef.current?.focus()
    }
  }, [submittable, isGrading, showRemoveBtn])

  const dragItemStyle = droppedId
    ? { display: 'block', opacity, width: '100%' }
    : { opacity }

  return (!submittable || isGrading || showAnswerKey)
    ? (
      <OptionListItem
        data-testid={testId}
        {...{
          isCorrect,
          scoringMode,
        }}
      >
        <div>
          {actualAnswer || label}
          {Boolean(scoringMode !== SCORING_MODE_NONE && isCorrect === false) && (
            <div>{`Expected: ${label}`}</div>
          )}
        </div>
      </OptionListItem>
    )
    : (
      <DragItem
        data-handler-id={handlerId}
        data-testid={`draggable-option-${idx}`}
        ref={ref}
        style={dragItemStyle}
      >
        <Box
          alignItems="center"
          display="flex"
          width="100%"
        >
          <Box flexGrow={1}>{label}</Box>
          {Boolean(showRemoveBtn && !completed) && (
            <IconButton
              aria-label={`Remove answer: ${label}`}
              data-testid={`remove-button-${id}`}
              onClick={handleRemove}
              ref={removeButtonRef}
            >
              <RemoveCircleIcon />
            </IconButton>
          )}
        </Box>
      </DragItem>
    )
}

Option.propTypes = {
  draggedFrom: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  moveOption: PropTypes.func,
  onRemove: PropTypes.func,
  showRemoveBtn: PropTypes.bool,
}

export default Option
