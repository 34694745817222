import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { useEffect, useMemo, useState } from 'react'
import { userAssignmentActions } from 'reducers/userAssignments'
import { getListedUserAssignmentIds, getOpenUserAssignmentsWithParentContent } from 'selectors/userAssignments'
import useEffectOnce from 'hooks/useEffectOnce'
import SplitHeadline from 'common/text/SplitHeadline'
import CheckboxMultiSelect from 'common/formControls/selects/CheckboxMultiSelect'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import AssignmentCard from '../AssignmentCards/AssignmentCard'
import { assignmentsSort, filterListNeedsDivider, groupedAssignments, nonChapterSortOrder } from './utils'

const OpenTasks = () => {
  const isLargeViewport = useContainerQuery().up('lg')

  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(userAssignmentActions.fetchOpenAssignments())
  })
  const userAssignments = useSelector(getOpenUserAssignmentsWithParentContent)
  const listedUserAssignmentIds = useSelector(getListedUserAssignmentIds)
  const userAssignmentsDisplayOrder = useMemo(
    () => [
      listedUserAssignmentIds,
      nonChapterSortOrder,
    ]
      .flat()
      .filter(Boolean),
    [listedUserAssignmentIds],
  )

  const [selectedCourses, setSelectedCourses] = useState([]) // Course ids selected in filter dropdown
  const handleSetSelectedCourses = ({ target }) => setSelectedCourses(target.value)

  const [nextUserAssignment, setNextUserAssignment] = useState()
  const [otherUserAssignments, setOtherUserAssignments] = useState()

  const groupedUserAssignments = useMemo(
    () => groupedAssignments(userAssignments, filterListNeedsDivider(userAssignments)),
    [userAssignments],
  )

  // Update visible assignments when the user makes a change to the filter
  useEffect(() => {
    if (selectedCourses?.length) {
      const filteredAssignments = selectedCourses
        .map(id => groupedUserAssignments?.[id].assignments)
        .flat(1)
        .sort((a, b) => {
          // Order of assignments should be consistent with initial, unfiltered order
          const aIndex = userAssignmentsDisplayOrder.findIndex(index => a.id === index)
          const bIndex = userAssignmentsDisplayOrder.findIndex(index => b.id === index)
          return aIndex - bIndex
        })

      const [newNextUserAssignment, ...newOtherUserAssignments] = filteredAssignments

      setNextUserAssignment(newNextUserAssignment)
      setOtherUserAssignments(newOtherUserAssignments)
    } else {
      // If no courses are selected, default to initial viewing settings (view all)
      const [initialNextUserAssignment, ...initialOtherUserAssignments] = userAssignments

      setNextUserAssignment(initialNextUserAssignment)
      setOtherUserAssignments(initialOtherUserAssignments)
    }
  }, [groupedUserAssignments, selectedCourses, userAssignments, userAssignmentsDisplayOrder])

  return (
    <SplitHeadline
      left="Open Tasks"
      mt={4}
      right={(
        <Box style={{ width: 220 }}>
          <CheckboxMultiSelect
            data={groupedUserAssignments}
            disabled={Object.entries(groupedUserAssignments).length < 2}
            handleSelected={handleSetSelectedCourses}
            name="courseFilterSelect"
            sortFn={assignmentsSort}
          />
        </Box>
      )}
      rightProps={{ style: { textAlign: 'right' } }}
      style={{ alignItems: 'end' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        mt={4}
      >
        {Boolean(nextUserAssignment) && (
          <AssignmentCard
            isOpen
            userAssignment={nextUserAssignment}
            viewMode="list"
          />
        )}

        <Box
          display="grid"
          // Setting column gap to 2% prevents visual jittering of card width when cards are filtered
          gap="24px 2%"
          gridTemplateColumns={
            /* istanbul ignore next */
            (otherUserAssignments?.length === 1 && isLargeViewport
              ? '49%'
              : 'repeat(auto-fit, minmax(342px, 1fr))'
            )
          }
          sx={{ '& .MuiPaper-root': { height: 'unset' } }}

        >
          {otherUserAssignments?.map(userAssignment => (
            <AssignmentCard
              aspectRatio="9/16"
              gridFlexVariant="vertical-flex"
              isOpen
              key={userAssignment.id}
              userAssignment={userAssignment}
              viewMode="grid"
            />
          ))}
        </Box>
      </Box>
    </SplitHeadline>
  )
}

export default OpenTasks
