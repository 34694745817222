import { compose } from 'redux'
import { styled, useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { darken } from 'polished'
import { fallbackTo, matches } from 'fp/utils'
import { fallsWithin } from 'fp/numbers'
import useCurrentUser from 'hooks/useCurrentUser'
import { get, pick } from 'fp/objects'

const asPercentage = value => Number.isNaN(value) ? 0 : Math.round(value * 100)

const StyledCell = styled(
  'td',
  { name: 'GradebookTableCell' },
)(({ assignmentId, scoringGradebookColor: { backgroundColor, hoverColor, textColor }, theme: { palette } }) => ({
  backgroundColor,
  color: textColor,

  '.MuiButtonBase-root.MuiButton-root': {
    backgroundColor,
    color: textColor,

    '&:hover, &:focus': {
      transition: 'all 250ms ease-in-out 0ms',
      backgroundColor: hoverColor,
      color: palette.grey.contrastText,

      a: {
        textDecorationThickness: '0.3rem',
      },
    },
  },

  ...assignmentId && {
    '&:hover, &:focus': {
      transition: 'all 250ms ease-in-out 0ms',
      backgroundColor: hoverColor,
      color: palette.grey.contrastText,

      a: {
        textDecorationThickness: '0.3rem',
      },
    },
  },
}))

const GradebookCellWrapper = (props) => {
  const { palette: { gradebook } } = useTheme()
  const { user: { preferences: { gradeColors: { segmentColors, defaultColor } } } } = useCurrentUser()

  const { cell, children, className } = props
  const cellValue = cell.renderValue()
  const defaultGradebookColor = gradebook.find(matches('colorId', defaultColor))

  const scoringGradebookColor = compose(
    fallbackTo(defaultGradebookColor),
    colorId => gradebook.find(matches('colorId', colorId)),
    get('colorId'),
    finalScore => segmentColors.find(({ begin, end }) => fallsWithin(finalScore, begin, end)),
    fallbackTo(0),
    compose(
      item => asPercentage(item.score / item.maxScore),
      pick(['score', 'maxScore']),
    ),
  )(cellValue)

  return (
    <StyledCell
      assignmentId={cellValue.assignmentId}
      className={className}
      scoringGradebookColor={{
        backgroundColor: scoringGradebookColor.value,
        textColor: scoringGradebookColor.textColor,
        hoverColor: scoringGradebookColor.hoverColor || darken(0.3, scoringGradebookColor.value),
      }}
    >
      {children}
    </StyledCell>
  )
}

GradebookCellWrapper.propTypes = {
  cell: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default GradebookCellWrapper
