import { compose } from 'redux'
import { filter, join } from 'fp/arrays'
import { fallbackTo } from 'fp/utils'

// eslint-disable-next-line import/prefer-default-export
export const lastNameFirstName = compose(
  ({ firstName, lastName }) => compose(
    join(', '),
    filter(Boolean),
  )([lastName, firstName]),
  fallbackTo({}),
)
