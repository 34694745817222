// TODO: Stop ignoring this file postMVP
/* istanbul ignore file */
import { useContext, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { matches } from 'fp/utils'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { get } from 'fp/objects'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { baseSx, closedMixin, openedMixin } from 'hss/sections/contentBlocks/Interactive/Scaffolds/ScaffoldsWrapper'
import DrawerButtons from 'hss/sections/contentBlocks/Interactive/Scaffolds/DrawerButtons'
import ScaffoldRenderer from 'hss/sections/contentBlocks/Interactive/Scaffolds/ScaffoldRenderer'
import { importantRem, px, rem } from 'styling/theming/base/mixins'
import useNavigation from 'hooks/useNavigation'
import { unwrap } from 'fp/strings'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import useCurrentUser from 'hooks/useCurrentUser'
import { isStudent } from 'selectors/users'
import { getCurrentAssignmentSubmitted } from 'selectors/userAssignments'

const styleProp = 'width'

const scaffoldSupportedFeatures = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
]

const EchoScaffolds = () => {
  const theme = useTheme()

  const { blockBundles } = useContext(contentViewerContext)
  const { isEchoScaffoldOpen, setIsEchoScaffoldOpen } = useContext(sidebarContext)
  const [currentScaffoldIndex, setCurrentScaffoldIndex] = useState(0)
  const { user: { proficiencyId } } = useCurrentUser()
  const notStudent = !useSelector(isStudent)
  const isAssignmentSubmitted = useSelector(getCurrentAssignmentSubmitted)

  const attachedScaffolds = blockBundles
    .map(get('block'))
    .filter(matches('contentType', CONTENT_TYPE_SCAFFOLD))
    .filter(({ proficiencyIds }) => notStudent || proficiencyIds?.includes(proficiencyId))

  const { location: { hash } } = useNavigation()

  const scaffold = attachedScaffolds[currentScaffoldIndex]

  return !isAssignmentSubmitted && !!scaffold && scaffoldSupportedFeatures.includes(unwrap('#')(hash))
    ? (
      <>

        <Drawer
          anchor="right"
          open={isEchoScaffoldOpen}
          sx={{
            ...baseSx,
            ...isEchoScaffoldOpen
              ? openedMixin(theme, styleProp, '100%')
              : closedMixin(theme, styleProp, 0),

            '.MuiDrawer-paper': {
              borderLeftStyle: 'solid',
              borderWidth: 7,
              borderColor: 'cobalt.0',
              zIndex: theme.zIndex.drawer + 1,
              ...isEchoScaffoldOpen
                ? openedMixin(theme, styleProp, '100%')
                : closedMixin(theme, styleProp, 0),
            },
            '.MuiPaper-root': {
              paddingLeft: isEchoScaffoldOpen ? rem(6) : 0,
            },
            '.MuiPaper-root .MuiContainer-root:first-of-type.MuiContainer-root:first-of-type': {
              marginTop: importantRem(4),
              paddingRight: importantRem(2),
              paddingLeft: importantRem(2),
              '.block-full-width': {
                padding: rem(2, 0),
                borderStyle: 'none',
              },
            },
          }}
          variant="permanent"
        >

          <ScaffoldRenderer scaffold={scaffold} />

        </Drawer>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: theme.zIndex.drawer + 1,
            display: 'flex',
            ...isEchoScaffoldOpen
              ? {
                justifyContent: 'flex-start',
                marginLeft: px(5),
              }
              : {
                marginLeft: 'calc(100% - 36px)',
              },
          }}
        >
          <DrawerButtons
            {...{
              attachedScaffolds,
              currentScaffoldIndex,
              direction: 'vertical',
              open: isEchoScaffoldOpen,
              reverseChevronDirection: true,
              setCurrentScaffoldIndex,
              setOpen: setIsEchoScaffoldOpen,
            }}
          />
        </Box>

      </>
    )
    : null
}

export default EchoScaffolds
