import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { PercentageTableCell } from 'common/tables/DataTable/Cells'
import { get } from 'fp/objects'
import { getStandardsGradebook } from 'selectors/gradebook'
import StandardItem from 'common/standards/StandardItem'
import { map, orderBy } from 'fp/arrays'
import { gradebookContext } from './GradebookContextProvider'
import GradebookDataTable from './GradebookDataTable'
import StandardScoreCell from './StandardScoreCell'
import { lastNameFirstName } from './utils'
import GradebookCellWrapper from './GradebookCellWrapper'

const staticColumns = [
  {
    accessorFn: lastNameFirstName,
    header: 'Student',
    id: 'student',
    isCellHeader: true,
  },
  {
    accessorKey: 'score',
    cell: PercentageTableCell,
    header: 'Overall Grade',
    id: 'overallGrade',
  },
]

const createStandardColumn = (standard) => {
  const { id: standardId } = standard
  return ({
    accessorFn: ({ id: userId, firstName, lastName, standardScoresById }) => ({
      firstName,
      lastName,
      id: standardId,
      userId,
      ...get(standardId)(standardScoresById),
    }),
    cell: StandardScoreCell,
    cellProps: { className: 'gradebook-cell', cellWrapper: GradebookCellWrapper },
    data: standard,
    header: <StandardItem standard={standard} />,
    id: standardId,
    maxSize: 140,
  })
}

const StandardsView = () => {
  const { userAssignmentIds = [] } = useContext(gradebookContext)
  const { requiresGrading, standards = [], students = [] } = useSelector(getStandardsGradebook({ userAssignmentIds }))

  const standardsColumns = compose(
    map(createStandardColumn),
    orderBy('shortCode'), // TODO: Use displayOrder, then order by each shortCode segment, numerically when possible.
  )(standards)

  return students.length
    ? standards.length
      ? (
        <GradebookDataTable
          columns={staticColumns.concat(standardsColumns)}
          data={students}
          disableCardView
          requiresGrading={requiresGrading}
          variant="gradebook"
        />
      )
      : <p>No standards are associated with the selected assignments.</p>
    : null
}

export default StandardsView
