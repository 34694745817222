import PropTypes from 'prop-types'
import { compose } from 'redux'
import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import { curryRight } from 'fp/utils'
import withFetchedItem from 'hoc/withFetchedItem'
import actionTypes from 'reducers/actionTypes'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'lastName',
    desc: false,
  },
  {
    id: 'firstName',
    desc: false,
  },
]

const Report = ({ item }) => (
  <CommonTableSearch
    initialSorting={initialSorting}
    title={item.name}
  />
)

Report.propTypes = { item: PropTypes.object.isRequired }

const ReportOnUser = compose(
  withSearchProvider,
  curryRight(withFetchedItem, {
    actionType: actionTypes.GROUP_FETCH,
  }),
)(Report)

export default ReportOnUser
