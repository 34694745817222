import PropTypes from 'prop-types'
import ReactImageAnnotation from '@studysync/react-image-annotation'
import { RectangleSelector } from '@studysync/react-image-annotation/lib/selectors'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateId } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import { actions as interactionActions } from 'reducers/interactions'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { entityIdShape } from 'core/shapes'
import { INTERACTION_SUBTYPE_IMAGE, INTERACTION_TYPE_ANNOTATION } from 'core/consts'
import { getUserAssignment } from 'selectors/userAssignments'
import HighlightRenderer from './HighlightRenderer'
import AnnotationsOverlay from './AnnotationsOverlay'
import AnnotationEditor from './AnnotationEditor'
import { filterAnnotations } from './utils'

// cannot forward ref here (unless you want to refactor ReactImageAnnotation to not be a class component)
const withProps = (WrappedComponent, mergeProps) => props => <WrappedComponent {...{ ...mergeProps, ...props }} />

const AnnotatableImage = (props) => {
  const {
    allowAnnotations,
    allowPanAndZoom,
    contentId,
    imageProps,
    src,
    ...rest
  } = props

  const dispatch = useDispatch()
  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const { annotations: allAnnotations = [], subsection = {} } = useContext(contentViewerContext) || {}
  const annotations = filterAnnotations(allAnnotations, contentId)
  const { id: contextContentId } = subsection

  const [value, setValue] = useState({})
  const [annotationsEnabled, toggleAnnotationsEnabled] = useToggleState(false)

  const cancelEdit = () => setValue({})

  const renderEditor = withProps(AnnotationEditor, { cancelEdit })

  const renderHighlight = useMemo(() => {
    const removeHighlight = ({ data: { id } }) => {
      dispatch(interactionActions.deleteInteraction({
        interactionId: id,
      }))
    }

    return withProps(HighlightRenderer, { removeHighlight })
  }, [dispatch])

  const renderOverlay = withProps(AnnotationsOverlay, {
    allowAnnotations,
    annotationsEnabled,
    toggleAnnotationsEnabled,
  })

  const handleSubmit = useCallback((annotation) => {
    const { data, geometry } = annotation

    const interactionData = {
      geometry,
      data: {
        ...data,
        id: generateId(),
      },
    }

    dispatch(interactionActions.postInteraction({
      contentId,
      contextContentId,
      interactionData,
      interactionSubType: INTERACTION_SUBTYPE_IMAGE,
      interactionType: INTERACTION_TYPE_ANNOTATION,
      userAssignmentId,
    }))

    setValue({})
  }, [contentId, contextContentId, dispatch, userAssignmentId])

  return (
    <ReactImageAnnotation
      {...rest}
      allowTouch
      annotations={annotations}
      disableAnnotation={!annotationsEnabled || !allowAnnotations}
      disableZoom={!allowPanAndZoom}
      imageProps={imageProps}
      onChange={setValue}
      onSubmit={handleSubmit}
      renderEditor={renderEditor}
      renderHighlight={renderHighlight}
      renderOverlay={renderOverlay}
      src={src}
      type={RectangleSelector.TYPE}
      value={value}
    />
  )
}

AnnotatableImage.propTypes = {
  allowAnnotations: PropTypes.bool.isRequired,
  allowPanAndZoom: PropTypes.bool.isRequired,
  contentId: entityIdShape.isRequired,
  imageProps: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
}

export default AnnotatableImage
