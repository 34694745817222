import { useContext, useRef } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useSelector } from 'react-redux'
import Headline from 'common/text/Headline'
import { containedPaddingLeft } from 'styling/theming/base'
import { CONTENT_TYPE_SOURCE } from 'core/consts'
import { getParentOfContentByType } from 'selectors/contentViewer'
import { contentViewerContext } from '../../ContentViewerProvider'
import Toolbar from './Toolbar'

const SubsectionHeadline = () => {
  const ref = useRef()
  const {
    subsection,
    subsection: {
      id: subsectionId,
      name,
    } = {},
  } = useContext(contentViewerContext)

  const { contentType: childContentType, id: childContentId } = (subsection.children || [])[0] || {}

  const {
    children,
    contentType,
    data: {
      assetDate,
      creator,
      creatorType,
      genre,
    } = {},
    id: parentId,
  } = useSelector(getParentOfContentByType({
    contentType: childContentType,
    contentId: childContentId,
    parentContentType: CONTENT_TYPE_SOURCE,
  })) || {}

  // for a regular source, the subsection and the parent are the same content
  // for a multi-subsection source, we only want to show the header for the first subsection
  const showSourceHeader = contentType === CONTENT_TYPE_SOURCE
    && (subsectionId === parentId || subsectionId === children[0].id)

  const listItems = [
    { label: 'Genre', value: genre },
    { label: creatorType, value: creator },
    { label: 'Date', value: assetDate },
  ].filter(item => item.value)

  return subsection
    ? (
      <>
        <Toolbar sticky />
        <Headline
          pl={containedPaddingLeft}
          ref={ref}
          swatch
          title={name}
        />
        {Boolean(showSourceHeader) && (
          <List
            component="ul"
            variant="library-source-header"
          >
            {listItems.map(({ label, value }) => (
              <ListItem
                key={label}
                variant="source-header"
              >
                <strong>{label}</strong>: {value}
              </ListItem>
            ))}
          </List>
        )}
      </>
    )
    : null
}

export default SubsectionHeadline
