import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { XCircle } from 'react-feather'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { forwardRef, useCallback } from 'react'
import { set } from 'fp/objects'
import withConfirm from 'hoc/withConfirm'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import InlineEditText from './InlineEditText'

const ResponseTextField = styled(DebouncedTextField)(() => (
  {
    height: '100%',
    '&::after': {
      border: 0,
    },
    '&::placeholder': {
      fontStyle: 'italic',
    },
    '&:disabled': {
      background: 'transparent',
      opacity: 0.75,
    },

  }))

const ConfirmButton = styled(withConfirm(IconButton))(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  minWidth: 'unset',
}))

const Item = forwardRef(({
  index,
  removeItem,
  replaceItem,
  item,
  readOnly,
}, ref) => {
  const { content, header, id } = item

  const handleChange = useCallback(fieldName => ({ target: { value } }) => {
    replaceItem(index, set(fieldName, value)(item))
  }, [index, item, replaceItem])

  const sectionLabel = `Item ${index + 1}`

  return (
    <Stack
      alignItems="center"
      component="li"
      flex={1}
    >
      <Stack
        border="1px dotted"
        borderColor="border.0"
        mb={2}
        p={2}
        position="relative"
        width="100%"
      >
        {readOnly ? (
          <Typography
            aria-label={header.length ? `${sectionLabel} Header: ${header}` : `No Header for ${sectionLabel}`}
            component="h3"
            size="large"
            weight={700}
          >
            {header}
          </Typography>
        ) : (
          <InlineEditText
            id={id}
            index={index}
            inputProps={{ title: `${sectionLabel} Header` }}
            onChange={handleChange('header')}
            placeholder="Add header..."
            title={`${sectionLabel} Header${header ? `: ${header}` : ''}`}
          >
            {header}
          </InlineEditText>
        )}

        <ResponseTextField
          disabled={readOnly}
          inputProps={{ title: readOnly ? (content.length ? `${sectionLabel} Response: ${content}` : `No Response for ${sectionLabel}`) : `Response for ${sectionLabel}` }}
          label=""
          multiline
          name={`items.${index}.content`}
          onChange={handleChange('content')}
          placeholder={readOnly ? '(No response)' : 'Add thoughts...'}
          ref={ref}
          value={content}
          variant="standard"
        />

        {!readOnly
          && (
            <ConfirmButton
              aria-label={`Remove item ${index + 1}, ${header}`}
              confirmationMessage={`Are you sure you want to remove row ${sectionLabel}${header ? `, '${header}'` : ''}?`}
              confirmationTitle="Remove?"
              onClick={() => removeItem(index)}
            >
              <XCircle />
            </ConfirmButton>
          )}
      </Stack>
    </Stack>
  )
})

Item.propTypes = itemRendererProps
export default Item
