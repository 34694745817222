import { useContext } from 'react'
import { context } from 'hss/views/Search/SearchProvider'
import FiltersContainer from 'hss/views/Search/Filters/FiltersContainer'
import DateRangePicker from 'hss/views/Search/Filters/Controls/DateRangeFilter'

const Filters = () => {
  const { primaryFiltersExpanded } = useContext(context)

  return primaryFiltersExpanded
    ? (
      <FiltersContainer mt={5}>
        <DateRangePicker
          label="Creation Date"
          name="dateCreated"
        />
      </FiltersContainer>
    )
    : null
}

export default Filters
