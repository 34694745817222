import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { componentShape, contentShape, refShape } from 'core/shapes'
import { pluralize, titleCase } from 'fp/strings'
import Headline from 'common/text/Headline'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { CONTENT_SUBTYPE_UNIT_NORMAL } from 'core/consts'

const ContentHeader = ({
  children,
  content,
  titleProps,
  subtitleProps,
  headerRef,
}) => {
  const {
    children: unitChildren,
    data: { subtitle },
    name,
  } = content
  const unitCount = unitChildren.filter(item => item.contentSubType === CONTENT_SUBTYPE_UNIT_NORMAL
    || !item.contentSubType).length
  const eyebrow = pluralize('Unit')(unitCount)

  return (
    <Headline
      swatch
      title={(
        <>
          <ScreenReaderText>
            {`${eyebrow ? `${eyebrow}, ` : ''}${name}${subtitle ? `: ${subtitle}` : ''}`}
          </ScreenReaderText>
          {children}
        </>
      )}
    >
      <div
        aria-hidden="true"
        ref={headerRef}
      >

        {Boolean(eyebrow) && (
          <Typography variant="eyebrow">
            {eyebrow}
          </Typography>
        )}

        <Typography
          component="div"
          variant="h3"
          {...titleProps}
        >
          {name}
        </Typography>

        {Boolean(subtitle) && (
          <Typography
            component="div"
            fontWeight={300}
            mt={1.5}
            textTransform="none"
            variant="h4"
            {...subtitleProps}
          >
            {titleCase(subtitle)}
          </Typography>
        )}
      </div>
    </Headline>
  )
}

ContentHeader.propTypes = {
  children: componentShape,
  content: contentShape.isRequired,
  subtitleProps: PropTypes.object,
  titleProps: PropTypes.object,
  headerRef: refShape,
}

export default ContentHeader
