/* eslint-disable function-call-argument-newline */
import PropTypes from 'prop-types'
import Instructions from 'common/layout/Instructions'
import ImageContentHtml from 'hss/ContentViewer/Chapter/Block/ImageContentHtml'

const QuestionPrompt = ({ body, ...rest }) => body
  ? (
    <Instructions>
      <ImageContentHtml
        body={body}
        renderProps={{ disableMaximizeImage: true, hideCaption: true }}
        {...rest}
      />
    </Instructions>
  )
  : null

QuestionPrompt.propTypes = {
  body: PropTypes.string,
}

export default QuestionPrompt
