import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Fragment, memo } from 'react'
import TeachBanner from 'hss/ContentViewer/Banner/TeachBanner'
import Headline from 'common/text/Headline'
import Interactive from 'hss/sections/contentBlocks/Interactive'
import InteractiveRenderer from 'hss/sections/contentBlocks/Interactive/InteractiveRenderer'
import { containedPaddingLeft, containedPaddingRight } from 'styling/theming/base'
import StyledContent from 'hss/ContentViewer/StyledContent'
import { getContextualAssignment } from 'selectors/assignments'
import { getContentNav } from 'selectors/contentViewer'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_SECTION, CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { getAssignmentScorableNav } from 'projections/chapterGrading'
import { get } from 'fp/objects'
import { numberOrString } from 'core/shapes'
import withProps from 'hoc/withProps'
import StudentAssignmentPicker from 'hss/sections/contentBlocks/Interactive/Teacher/StudentAssignmentPicker'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import GradingToolbar from './GradingToolbar'
import GradingContentProvider from './GradingContentProvider'

const ContentItem = memo((props) => {
  const { children, contentType, id } = props
  const ChildrenContainer = [CONTENT_TYPE_SECTION, CONTENT_TYPE_SUBSECTION].includes(contentType)
    ? withProps(Headline, { title: get('name')(props) })
    : Fragment
  const MaybeGradingContentProvider = contentType === CONTENT_TYPE_ECHO
    ? withProps(GradingContentProvider, { content: props })
    : Fragment

  return contentType === CONTENT_TYPE_INTERACTIVE
    ? (
      <Interactive
        contentid={id}
        hideStudentPicker
        key={id}
        Renderer={InteractiveRenderer}
      />
    )
    : (
      <MaybeGradingContentProvider>
        <ChildrenContainer>
          {children.map(item => (
            <ContentItem
              key={item.id}
              {...item}
            />
          ))}
        </ChildrenContainer>
      </MaybeGradingContentProvider>
    )
})
ContentItem.propTypes = {
  children: PropTypes.array.isRequired,
  contentType: PropTypes.string.isRequired,
  id: numberOrString.isRequired,
}

const Grading = () => {
  const assignment = useSelector(getContextualAssignment)
  const contentId = get('contentId')(assignment)
  const chapterNav = useSelector(getContentNav({ contentId, leafContentTypes: [CONTENT_TYPE_INTERACTIVE] }))
  const scorableChapterNav = useDeepCompareMemo(
    () => getAssignmentScorableNav(assignment, chapterNav),
    [assignment, chapterNav],
  )

  return (
    <>
      <TeachBanner title="Chapter Grading">
        <Box
          display="flex"
          justifyContent="space-around"
          mb={6}
          pl={containedPaddingLeft}
          pr={containedPaddingRight}
        >
          <StudentAssignmentPicker width="40rem" />
        </Box>
      </TeachBanner>
      <GradingToolbar />
      <Headline
        contained
        gutterBottom
        swatch
        title="Chapter Grading"
      >
        <StyledContent>
          <Stack
            gap={8}
            mt={12}
          >
            {scorableChapterNav.map(item => (
              <ContentItem
                key={item.id}
                {...item}
              />
            ))}
          </Stack>
        </StyledContent>
      </Headline>
    </>
  )
}

export default Grading
