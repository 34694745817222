import { Routes } from 'react-router-dom'
import { configToRoutes } from 'routing/TraverseRoute'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import routeConfig from './routeConfig'

const ContentViewerRoutes = () => (
  <ErrorBoundary moduleName="ContentViewerRoutes">

    <Routes>

      {configToRoutes(routeConfig)}

    </Routes>

  </ErrorBoundary>
)

export default ContentViewerRoutes
