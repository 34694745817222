import { useCallback, useContext, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { compose } from 'redux'
import useComponentSize from 'hooks/useComponentSize'
import Well from 'common/layout/Well'
import ImageContainer from 'common/Draggable/ImageContainer'
import { INTERACTIVE_TYPE_IMAGE_TITLE_DRAG } from 'core/consts'
import { map, shuffle } from 'fp/arrays'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import Callout from './Callout'
import Options from './Options'

const setDroppedId = droppedId => callout => ({ ...callout, droppedId })
const resetDroppedId = callout => ({ ...callout, droppedId: null })

const ImageTitleDrag = withQuestionPrompt(() => {
  const {
    interactionData: { callouts: interactionCallouts },
    interactiveData: {
      callouts: interactiveCallouts,
      darkBackdropImage,
      imageAltText,
    },
    isGrading,
    onInteract,
    submittable,
    uploadsMap,
  } = useContext(interactiveContext)

  const showAnswerKey = useIsInAnswerKeyContext() || isGrading || !submittable

  const [callouts, setCallouts] = useState(compose(showAnswerKey
    ? map(c => setDroppedId(c.id)(c))
    : shuffle)(interactionCallouts || interactiveCallouts))

  const handleChange = useCallback((droppedId, droppedOnId) => {
    setCallouts((prevCallouts) => {
      const newSelections = droppedOnId
        ? prevCallouts.map(c => c.id === droppedOnId
          ? setDroppedId(droppedId)(c)
          : c.droppedId === droppedId
            ? resetDroppedId(c)
            : c)
        : prevCallouts.map(c => c.droppedId === droppedId
          ? resetDroppedId(c)
          : c)

      onInteract({ callouts: newSelections })
      return newSelections
    })
  }, [onInteract])

  const imageRef = useRef()
  const { height, width } = useComponentSize(imageRef)

  return (
    <Box>
      {!showAnswerKey && (
        <Well>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              minHeight: 5,
              padding: '0 0.75rem',
            }}
          >
            <Options
              callouts={callouts}
              onChange={handleChange}
            />
          </Box>
        </Well>
      )}

      <ImageContainer ref={imageRef}>
        <img
          alt={imageAltText}
          src={uploadsMap.backdrop.url}
        />

        {callouts.map(callout => (
          <Callout
            callout={callout}
            callouts={callouts}
            darkBackdropImage={darkBackdropImage}
            key={callout.label}
            onChange={handleChange}
            parentHeight={height}
            parentWidth={width}
          />
        ))}

      </ImageContainer>
    </Box>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
}

export default ImageTitleDrag
