import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const HeaderLink = ({ onClick, readOnly = false, header }) => readOnly
  ? <Box m={2}>{header}</Box>
  : (
    <Button
      color="secondary"
      onClick={onClick}
      variant="tertiary"
    >
      {header || 'Add Header'}
    </Button>
  )

HeaderLink.propTypes = {
  header: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default HeaderLink
