import PropTypes from 'prop-types'
import Headline from 'common/text/Headline'
import Accepted from './Accepted'
import NotAccepted from './NotAccepted'

const Turnitin = (props) => {
  const { hasValidTIIEULA = false } = props

  return (
    <>
      <Headline
        mt={1}
        size={4}
        title="End User License Agreement (EULA)"
      />
      {hasValidTIIEULA ? <Accepted {...props} /> : <NotAccepted {...props} />}
    </>
  )
}

Turnitin.propTypes = {
  hasValidTIIEULA: PropTypes.bool,
}

export default Turnitin
