import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import { useSelector } from 'react-redux'
import TabList from 'common/layout/TabList'
import { getCurrentSection, getCurrentViewerContentId } from 'selectors/contentViewer'
import useNavigation from 'hooks/useNavigation'
import { CONTENT_TYPE_SUBSECTION } from 'core/consts'
import useIsPinnedContent from 'hooks/useIsPinnedContent'

const Tabs = () => {
  const { navigate } = useNavigation()

  const isPinned = useIsPinnedContent()
  const { children } = useSelector(getCurrentSection({ isPinned }))
  const subsectionId = useSelector(getCurrentViewerContentId({ isPinned, preferTeCopy: true }))

  const handleChange = (e, id) => {
    const url = `../${CONTENT_TYPE_SUBSECTION}/${id}`
    navigate(url)
  }

  return (
    <TabContext value={subsectionId}>
      <TabList
        aria-label="Sections"
        contained
        onChange={handleChange}
      >
        {children.map(({ id, name }) => (
          <Tab
            key={`tab${id}`}
            label={name}
            value={id}
          />
        ))}
      </TabList>
    </TabContext>
  )
}

export default Tabs
