import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Card from 'hss/views/Card'
import { userAssignmentShape } from 'core/shapes'
import { getAssignmentById } from 'selectors/assignments'
import { getContentById } from 'selectors/content'
import withProps from 'hoc/withProps'
import { noop } from 'fp/utils'
import PosterImage from 'hss/views/Card/PosterImage'
import Link from 'common/navigation/links/Link'
import { getStudentAssignmentRoute } from 'routing/consts'
import BinderContent from './BinderContent'
import OpenAssignmentContent from './OpenAssignmentContent'
import useCardContent from './useCardContent'

const ImageWrapper = ({ children, style, content, isOpen, userAssignment }) => {
  const { assignmentId, id } = userAssignment || {}
  const { name } = useSelector(getAssignmentById({ assignmentId }))
  const { contentType } = content || {}
  const { callToAction, cardContentType } = useCardContent(userAssignment, content, isOpen)

  return (
    <Link
      aria-label={`${callToAction}, ${cardContentType} ${name}`}
      style={{
        ...style,
        display: 'block',
      }}
      to={getStudentAssignmentRoute({ contentType, id })}
    >
      {children}
    </Link>
  )
}

ImageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.object,
  content: PropTypes.object.isRequired,
  userAssignment: userAssignmentShape.isRequired,
}

const AssignmentCard = ({
  userAssignment,
  userAssignment: {
    assignmentId,
    id,
    progress,
    score,
    submittedDate,
  },
  isOpen,
  viewMode,
  ...rest
}) => {
  const assignment = useSelector(getAssignmentById({ assignmentId }))
  const {
    contentId,
    endDate,
    name,
  } = assignment
  const content = useSelector(getContentById({ contentId }))
  const { contentType } = content || {}

  const RendererWithAssignment = withProps(
    isOpen
      ? OpenAssignmentContent
      : BinderContent,
    {
      assignment,
      assignmentId,
      content,
      id,
      progress,
      contentType,
      endDate,
      name,
      score,
      submittedDate,
      userAssignment,
      viewMode,
    },
  )

  return (
    <Card
      content={content}
      contentCreator={false}
      ContentRenderer={RendererWithAssignment}
      gridModeImageAspectRatio="16/9"
      handlePreview={noop}
      ImageRenderer={withProps(
        PosterImage,
        { Wrapper: withProps(ImageWrapper, { isOpen, userAssignment }) },
      )}
      listModeDirection="rtl"
      viewMode={viewMode}
      {...rest}
    />
  )
}

AssignmentCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userAssignment: userAssignmentShape.isRequired,
  viewMode: PropTypes.oneOf(['list', 'grid']).isRequired,
}

export default AssignmentCard
