import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { isDefined } from 'fp/utils'
import withContent from 'hoc/withContent'
import { formatDateTimeStrWithFormatString } from 'locale/i18n'
import { componentShape, contentShape } from 'core/shapes'
import Left from 'hss/images/nav/arrow-left-small.svg'
import BackButton from 'common/formControls/buttons/BackButton'
import { isEmptyString } from 'fp/strings'
import Html from 'common/text/Html'
import LinkToEditor from 'hss/ContentViewer/Chapter/Block/LinkToEditor'

const VocabSection = ({ title, children }) => (
  <>
    <Headline
      my={2}
      size={4}
      title={title}
    />
    {children}
  </>
)

const Vocabulary = (props) => {
  const {
    content,
    content: {
      name,
      tags,
      contentVocabPhrases,
      data: {
        abbreviation,
        antonyms,
        definition,
        pronunciation,
        sampleSentence,
        synonyms,
        wordForm,
        bullets,
        geotag,
        otherMatches,
        startDate,
        endDate,
      },
    },
  } = props

  return (
    <Container>
      <LinkToEditor content={content} />
      <Stack
        alignItems="flex-start"
        spacing={2}
      >
        <Headline
          mb={2}
          size={3}
          title={name}
        >
          {Boolean(wordForm) && `(${wordForm})`}
          {wordForm && (pronunciation || abbreviation) ? ' | ' : ''}
          {Boolean(pronunciation) && `${pronunciation} `}
          {abbreviation}
          <Box
            mt={2}
            sx={{ '& p': { my: 0 } }}
          >
            <Html body={definition} />
          </Box>
          {bullets?.length > 0
          && (
            <ul>
              {bullets.map(({ id, value }) => (
                <li key={id}>{value} </li>
              ))}
            </ul>
          )}

          {Boolean(synonyms) && (
            <VocabSection title="Synonyms">{synonyms}</VocabSection>
          )}

          {Boolean(antonyms) && (
            <VocabSection title="Antonyms">{antonyms}</VocabSection>
          )}

          {Boolean(sampleSentence) && (
            <VocabSection title="Sample Sentence">{sampleSentence}</VocabSection>
          )}

          {tags?.length > 0 && (
            <VocabSection title="Term Type">{tags[0].name}</VocabSection>
          )}

          {contentVocabPhrases?.length > 0 && (
            <VocabSection title="Related Terms">
              <ul>
                {contentVocabPhrases.map(({ id, name: vocabTerm }) => <li key={id}>{vocabTerm}</li>)}
              </ul>
            </VocabSection>
          )}

          {Boolean(geotag?.latitude && geotag?.longitude)
        && (
          // TODO: some kind of map display?
          <VocabSection title="Geo Tag">
            {`${geotag.longitude}, ${geotag.latitude}`}
          </VocabSection>
        )}

          {Boolean(!isEmptyString(startDate) || !isEmptyString(endDate))
        && (
          <VocabSection title="Timeline Tag">
            {`${formatDateTimeStrWithFormatString('yyyy')(startDate)}${isDefined(endDate) ? ' - ' : ''}${formatDateTimeStrWithFormatString('yyyy')(endDate)}`}
          </VocabSection>
        )}

          {otherMatches?.length > 0
        && (
          <VocabSection title="Other Matches">
            {otherMatches.map(({ value }) => value).join(', ')}
          </VocabSection>
        )}
        </Headline>

        <BackButton
          color="secondary"
          startIcon={<Left />}
          sx={
            {
              background: 'none',
              border: 0,
              marginTop: 3,
              padding: 0,
              typography: 'variants.nav-item-uppercase-semibold',
              ':active, :hover': {
                background: 'none',
                color: 'inherit',
                boxShadow: 'none',
                typography: 'variants.nav-item-uppercase-semibold',
                textDecoration: 'underline',
              },
            }
          }
        >
          Back

        </BackButton>
      </Stack>
    </Container>
  )
}

Vocabulary.propTypes = {
  content: contentShape.isRequired,
}

VocabSection.propTypes = {
  children: componentShape.isRequired,
  title: PropTypes.string.isRequired,
}

const VocabularyWithContent = withContent(Vocabulary, {
  contentType: CONTENT_TYPE_VOCABULARY,

})
export default VocabularyWithContent
