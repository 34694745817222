import { produce } from 'immer'
import { border } from 'polished'
import { keyframes } from '@mui/styled-engine'

const openDetails = keyframes`
  0% { opacity: 0; margin-top: -20px }
  100% { opacity: 1; margin-top: 0px }
`

const plugin = theme => produce(theme, (draft) => {
  const { breakpoints, mixins: { important, importantRem, rem, transition }, palette } = theme

  const anchorColor = palette.accentColor === 'canary'
    ? palette.cobalt
    : palette.accent

  draft.globals = {
    ':root': {
      '--containedPaddingLeft': rem(7.1),
      '--containedPaddingRight': rem(6),
      [breakpoints.down('sm')]: {
        '--containedPaddingLeft': rem(5.1),
        '--containedPaddingRight': rem(4),
      },
    },

    a: {
      color: anchorColor.dark,
      textDecoration: 'underline',
      textDecorationColor: 'inherit',
      textUnderlineOffset: rem(0.4),
      textDecorationThickness: 'from-font',
      ...transition('text-decoration-thickness', 250, 'ease-in-out'),
      '&:hover': {
        color: anchorColor.dark,
        textDecorationThickness: rem(0.3),
      },
    },

    b: { fontWeight: 600 },

    blockquote: {
      ...border('left', 2, 'solid', palette.accent.main),
      margin: rem(4.8, 0),
      paddingLeft: rem(4.8),
    },

    'details[open] summary ~ *': {
      animation: `${openDetails} .25s ease-in-out`,
    },

    figure: {
      margin: 0,
    },

    hr: {
      borderStyle: 'none',
      background: palette.grey[4],
      height: 1,
      margin: rem(3, 0),
    },

    legend: {
      fontSize: importantRem(1.4),
      maxWidth: 'max-content',
    },

    main: {
      flexGrow: 1,
      position: 'relative',
    },

    pre: {
      lineHeight: 1.5,
      overflow: 'auto',
      borderRadius: 3,
      background: palette.border[3],
      padding: rem(1),
      wordWrap: 'normal',
      margin: 0,
      fontFamily: important('Consolas, Menlo, Monaco, "Courier New", monospace'),
    },

    strong: { fontWeight: 600 },
  }
})

export default plugin
