import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import StandardItem from 'common/standards/StandardItem'
import { contentShape } from 'core/shapes'

const Standards = ({ config }) => {
  const { applicationStandards } = config
  const isSmallScreen = useContainerQuery().down('md')

  return applicationStandards.length
    ? (
      <div>
        <Typography variant="small-semibold">Practice/Application Standards:&nbsp;</Typography>

        <List variant={isSmallScreen ? 'csv' : 'csv-right'}>
          {applicationStandards.map(standard => (

            <li key={standard.id}>
              <StandardItem standard={standard} />
            </li>

          ))}
        </List>
      </div>
    )
    : null
}

Standards.propTypes = {
  config: contentShape.isRequired,
}

export default Standards
