import { margin } from 'polished'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { AlertTriangle } from 'react-feather'
import Typography from '@mui/material/Typography'
import DataTable from 'common/tables/DataTable'

const InlineErrorIcon = styled(AlertTriangle)(({ theme: { mixins: { rem } } }) => ({
  display: 'inline-block',
  ...margin(0, rem(0.5), rem(-0.5)),
}))

const TableContainerWithIconLegend = styled('div')(({ theme: { mixins: { rem } } }) => ({
  position: 'relative',
  '.icon-legend': {
    position: 'absolute',
    top: rem(16),
  },
}))

const initialSorting = [
  {
    id: 'student',
    desc: false,
  },
]

const GradebookDataTable = ({ columns, data, requiresGrading, ...rest }) => columns?.length && data?.length && (
  <TableContainerWithIconLegend>
    {Boolean(requiresGrading) && (
      <Typography
        className="icon-legend"
        variant="information"
      >
        <InlineErrorIcon
          aria-label="warning triangle"
          role="img"
        />
        = Grading Required
      </Typography>
    )}

    <DataTable
      {...{
        columns,
        data,
        ...rest,
      }}
      disableCardView
      id="gradebook-data-table"
      initialSorting={initialSorting}
      variant="gradebook"
    />
  </TableContainerWithIconLegend>
)

GradebookDataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  requiresGrading: PropTypes.bool.isRequired,
}

export default GradebookDataTable
