import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'

const BinderRow = ({ left, right }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
  >
    <div>{left}</div>
    <div>{right}</div>
  </Stack>
)

BinderRow.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.node,
}

export default BinderRow
