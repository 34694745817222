import { useContext, useEffect } from 'react'
import ReactImageAnnotation from '@studysync/react-image-annotation'
import { styled } from '@mui/material/styles'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { get } from 'fp/objects'
import useToggleState from 'hooks/useToggleState'
import BaseImage from 'common/indicators/Image'
import { itemShape } from '../utils'
import Expander from '../../Image/Expander'

const Container = styled(
  'div',
  { name: 'ChartItem-ImageRenderer' },
)({ img: { cursor: 'pointer', maxWidth: '100%' } })

const ImageRenderer = ({ item: { id, imageDescription } }) => {
  const { uploadsMap } = useContext(interactiveContext)
  const { url } = get(id)(uploadsMap)
  const { setBoosted } = useContext(interactiveContext)

  const [expanded, setExpanded] = useToggleState()
  const handleClick = () => {
    setExpanded()
  }

  useEffect(() => {
    setBoosted(expanded)
  }, [expanded, setBoosted])

  const renderOverlay = () => null // if you pass in nothing, the default overlay gets rendered

  return (
    <Container aria-label={imageDescription}>
      <BaseImage
        alt={imageDescription}
        onClick={handleClick}
        src={url}
      />
      {Boolean(expanded) && (
        <Expander
          expanded
          onClick={handleClick}
        >
          <ReactImageAnnotation
            allowTouch
            annotations={[]}
            disableAnnotation
            disableZoom={false}
            imageProps={{ alt: imageDescription }}
            renderOverlay={renderOverlay}
            src={url}
            value={{}}
          />
        </Expander>
      )}
    </Container>
  )
}

ImageRenderer.propTypes = {
  item: itemShape.isRequired,
}

export default ImageRenderer
