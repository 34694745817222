import { useContext, useId, useRef } from 'react'
import SvgDraw from '@studysync/svg-draw'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import { merge } from 'fp/objects'
import { INTERACTIVE_TYPE_DRAW_TOOL } from 'core/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const DrawToolContainer = styled(Box)(() => ({
  minHeight: 600,
  height: '100%',
  position: 'relative',
  margin: 'auto',
  width: '100%',
}))

const DrawTool = () => {
  const {
    interactionData,
    interactiveData: {
      canvasHeight,
      canvasWidth,
      dimension = 'horizontal',
      distance = 0,
      unit = '',
    },
    isGrading,
    onInteract,
    submittable,
    uploadsMap,
  } = useContext(interactiveContext)

  const id = useId()
  const src = getImageUrlFromUploadsMap(uploadsMap)

  const distPx = dimension === 'horizontal' ? canvasWidth : canvasHeight
  const scale = (unit && distance)
    ? { ratio: distance / distPx, unit }
    : { ratio: 1, unit: 'px' }

  const initialState = merge({
    page: {
      id,
      shapes: {},
      canvas: {
        size: [canvasWidth, canvasHeight],
        src,
        scale,
      },
    },
  }, interactionData)

  const doc = useRef(initialState)

  return (
    <DrawToolContainer>
      <SvgDraw
        data={doc.current}
        onChange={onInteract}
        readonly={!submittable || isGrading}
      />
    </DrawToolContainer>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_DRAW_TOOL,
}

export default DrawTool
