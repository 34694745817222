import PropTypes from 'prop-types'
import { entityIdShape } from 'core/shapes'
import { find, findObj, replaceById } from 'fp/arrays'
import { matches } from 'fp/utils'

export const itemShape = PropTypes.shape({
  column: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf(['text', 'image', 'input']).isRequired,
  id: entityIdShape.isRequired,
  isRichInput: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
  row: PropTypes.string.isRequired,
})

export const responseItemShape = PropTypes.shape({
  id: entityIdShape.isRequired,
  value: PropTypes.string,
})

export const findByRowColumn = (
  rowIdToFind,
  columnIdToFind,
) => find(({ column, row }) => column === columnIdToFind && row === rowIdToFind)

export const actions = {
  SET_ITEM: 'SET_ITEM',
  DROP_ITEM: 'DROP_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  MOVE_ITEM: 'MOVE_ITEM', // TODO: this will be for mobile view arrows
}
export const reducer = (state, action) => {
  switch (action.type) {
    // text input - update existing response by id or append to response items
    case actions.SET_ITEM:
      return {
        ...state,
        items: findObj('id', action.item.id)(state.items)
          ? replaceById(action.item)(state.items)
          : [...state.items, action.item],
      }
    // drag and drop
    case actions.DROP_ITEM: {
      const existing = findByRowColumn(action.item.row, action.item.column)(state.items)
      return {
        ...state,
        items: [...state.items.filter(({ id }) => id !== existing?.id), action.item],
      }
    }

    case actions.REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== action.item.id),
      }

    case actions.MOVE_ITEM: {
      const currentDroppedIndex = action.moveableItems.findIndex(matches('id', action.item.id))
      const destinationItem = action.moveableItems[currentDroppedIndex + action.direction]

      // remove any existing answer in the targeted destination
      const existingAnswer = findByRowColumn(destinationItem.row, destinationItem.column)(state.items)

      return {
        ...state,
        items: [
          ...state.items.filter(({ id }) => id !== existingAnswer?.id && id !== action.selectedItem.id),
          { id: action.selectedItem.id, row: destinationItem.row, column: destinationItem.column },
        ],
      }
    }

    /* istanbul ignore next */
    default:
      throw new Error(`Unknown action ${action.type}.`)
  }
}
