import { useCallback, useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { generateId } from 'fp/utils'
import ResponsiveTable from 'common/layout/ResponsiveTable'
import { findObj, push, replaceById } from 'fp/arrays'
import { set } from 'fp/objects'
import { INTERACTIVE_TYPE_DGO_TABLE } from 'core/consts'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { findByRowColumn } from '../ChartCompletion/utils'
import CellRenderer from './CellRenderer'

const DGOTable = withQuestionPrompt(() => {
  const {
    interactionData,
    interactiveData: {
      allowStudentRowEdits,
      columns,
      items,
      rows,
    },
    onInteract,
  } = useContext(interactiveContext)

  const [response, setResponse] = useState({ rows, columns, items, ...interactionData })

  const handleChange = useCallback(({ newItem }) => {
    setResponse((res) => {
      const updatedResponse = findObj('id', newItem.id)(res.items)
        ? replaceById(newItem)(res.items)
        : [...res.items, newItem]
      return set('items', updatedResponse)(res)
    })
  }, [])

  const handleAddRow = useCallback(() => {
    const newRowId = generateId()
    const newItems = columns.map(({ id: columnId }) => ({
      id: generateId(),
      column: columnId,
      displayText: false,
      row: newRowId,
    }))

    setResponse(res => ({ ...res,
      items: [...res.items, ...newItems],
      rows: push({ id: newRowId })(res.rows) }))
  }, [columns])

  const handleRemoveRow = useCallback(rowId => () => {
    setResponse(res => (
      { ...res,
        items: res.items.filter(({ row }) => rowId !== row),
        rows: res.rows.filter(({ id }) => id !== rowId) }
    ))
  }, [])

  useDeepCompareEffect(
    () => { onInteract(response) },
    [onInteract, response],
  )

  return (
    <>
      <ResponsiveTable aria-label="Table">
        <thead>
          <tr>
            {columns.map(({ id, header }) => (
              <th key={id}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(response.rows).map(({ id: rowId }, rowIdx) => (
            <tr key={rowId}>
              {columns.map(({ id: columnId, header }, colIdx) => {
                const item = findByRowColumn(rowId, columnId)(response.items)

                return (
                  <CellRenderer
                    heading={header}
                    item={item}
                    key={item.id}
                    onChange={handleChange}
                    onRemoveRow={handleRemoveRow(rowId)}
                    rowIdx={rowIdx}
                    showRemoveRowIcon={Boolean(allowStudentRowEdits) && colIdx === columns.length - 1}
                  />
                )
              })}
            </tr>
          ))}
        </tbody>

      </ResponsiveTable>
      {Boolean(allowStudentRowEdits) && (
        <Button
          color="secondary"
          onClick={handleAddRow}
          size="small"
          style={{ marginTop: 12 }}
          variant="secondary"
        >
          Add Row
        </Button>
      )}
    </>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_DGO_TABLE,
}

export default DGOTable
