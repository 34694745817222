import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const Subheader = ({ backgroundColor, children, ...rest }) => (
  <Box
    bgcolor={backgroundColor}
    mx="auto"
    p={1.5}
    {...rest}
  >
    {children}
  </Box>
)

Subheader.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Subheader
