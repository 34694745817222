import { useContext } from 'react'
import { INTERACTIVE_TYPE_PLOT_DIAGRAM } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Content from './Content'

const PlotDiagram = withQuestionPrompt(() => {
  const contextProps = useContext(interactiveContext)
  return (
    <Content {...contextProps} />
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_PLOT_DIAGRAM,
}

export default PlotDiagram
