import PropTypes from 'prop-types'
import { useContext } from 'react'
import { compose } from 'redux'
import { get } from 'fp/objects'
import { interactiveContext } from '../../InteractiveProvider'
import ReopenButtonForSingleStudent from './ReopenButtonForSingleStudent'
import ReopenButtonForAllStudents from './ReopenButtonForAllStudents'

const ReopenButton = ({ reopenAssignment = false }) => {
  const userAssignmentId = compose(
    get('interaction.userAssignmentId'),
    useContext,
  )(interactiveContext)

  return userAssignmentId || reopenAssignment
    ? <ReopenButtonForSingleStudent reopenAssignment={reopenAssignment} />
    : <ReopenButtonForAllStudents />
}

ReopenButton.propTypes = {
  reopenAssignment: PropTypes.bool,
}

export default ReopenButton
