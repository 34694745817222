import useContent from 'hooks/useContent'
import { entityIdShape } from 'core/shapes'
import Image from 'apps/common/indicators/Image'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'

const ImageAnnotation = ({ contentId }) => {
  const {
    data: { imageAltText },
    uploadsMap,
  } = useContent({ contentId, contentType: CONTENT_TYPE_INTERACTIVE })

  const src = getImageUrlFromUploadsMap(uploadsMap, 'thumb')

  return (
    <Image
      alt={imageAltText}
      src={src}
      style={{ maxWidth: '100%' }}
    />
  )
}

ImageAnnotation.propTypes = {
  contentId: entityIdShape.isRequired,
}

export default ImageAnnotation
