
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EyebrowHeadline from 'common/text/EyebrowHeadline'
import { contentShape } from 'core/shapes'
import { isDefined } from 'fp/utils'
import HelpCenterLink from 'common/navigation/links/HelpCenterLink'

const SlideContent = ({ content: { data: { body, byline, guideId, linkText = 'View Article', linkUrl, subtitle }, name } }) => (

  <Box maxWidth="70%">
    <EyebrowHeadline
      HeadlineProps={{ textTransform: 'capitalize', gutterBottom: true }}
      headlineVariant="h2"
      title={`${name} ${subtitle || ''}`}
    />

    {isDefined(byline) && `By ${byline}`}
    <Typography
      component="div"
      my={2}
      variant="body1-semibold"
    >
      {body}
    </Typography>

    {isDefined(guideId)
      ? (
        <HelpCenterLink
          guideId={guideId}
          Renderer={Button}
        >
          {linkText}
        </HelpCenterLink>
      )
      : (
        <Button
          color="secondary"
          href={linkUrl}
          sx={{ alignSelf: 'flex-start', fontWeight: 500, '&:hover': { fontWeight: 500 } }}
          target="_blank"
          variant="secondary"
        >
          {linkText}
        </Button>
      )}
  </Box>
)

SlideContent.propTypes = {
  content: contentShape.isRequired,
}

export default SlideContent
