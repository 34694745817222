import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import { createElement, useEffect, useState } from 'react'
import Dialog from 'common/dialogs/Dialog'
import All from './assetTypes/All'

const SelectAssetTypeDialog = (props) => {
  const { onClose, onProceed, open } = props
  const [selectedAssetType, setSelectedAssetType] = useState(null)

  useEffect(() => {
    if (selectedAssetType && open) {
      onClose()
      setTimeout(() => onProceed(selectedAssetType))
    }
  }, [onClose, onProceed, open, selectedAssetType])

  return (
    <Dialog
      disableAutoFocus
      onClose={onClose}
      open={open}
      showCloseButton
      swatch
      title="Add to Library"
      TitleProps={{ mb: 1 }}
      variant="maximized-vertically"
    >

      <DialogContent>

        {createElement(All, {
          itemHeight: 80,
          itemWidth: 163,
          ListProps: {
            style: {
              overflowY: 'auto',
              maxHeight: '75vh',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              justifyItems: 'center',
              justifyContent: 'space-between',
            },
          },
          onSelect: setSelectedAssetType,
        })}

      </DialogContent>

    </Dialog>
  )
}

SelectAssetTypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onProceed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SelectAssetTypeDialog
