import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useFormContext, useWatch } from 'react-hook-form'
import Box from '@mui/material/Box'
import { useEffect } from 'react'
import Radio from 'common/formControls/switches/Radio'
import HFRadioGroup from 'common/formControls/switches/HFRadioGroup'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import { when } from 'fp/utils'
import { SCORING_FEEDBACK_CONTENT_ALL, SCORING_FEEDBACK_CONTENT_NONE, SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS, SCORING_FEEDBACK_CONTENT_TOTAL_SCORE, SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES, SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END, SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION, SCORING_FEEDBACK_VISIBILITY_IMMEDIATE } from 'core/consts'
import AssessmentSwitchHeader from './AssessmentSwitchHeader'

const TestDetails = () => {
  const { getValues, setValue } = useFormContext()
  const [id] = getValues(['id'])
  const getFieldName = field => `data.settings.${field}`
  const [scoringFeedbackContent, timeLimit] = useWatch({ name: [
    getFieldName('scoringFeedbackContent'),
    getFieldName('timeLimit'),
  ] })

  useEffect(() => {
    when(!timeLimit, setValue, getFieldName('timeLimitVisible'), false)
  }, [setValue, timeLimit])

  return (
    <Stack
      direction="column"
      gap={4}
      mt={2}
    >
      <div>
        <HFRichTextEdit
          label="Instructions"
          name={getFieldName('instructions')}
        />
        <Typography
          display="block"
          variant="footnote"
        >
          These instructions will appear before the student begins the test.
        </Typography>
      </div>
      <div>
        <AssessmentSwitchHeader>
          After submission, allow students to see
        </AssessmentSwitchHeader>
        <HFRadioGroup
          label="after submission, allow students to see"
          name={getFieldName('scoringFeedbackContent')}
          style={{ marginTop: 0 }}
        >
          <Radio
            label="per-question score, responses and explanations"
            value={SCORING_FEEDBACK_CONTENT_ALL}
          />
          <Radio
            label="per-question score and responses"
            value={SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS}
          />
          <Radio
            label="total score and responses"
            value={SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES}
          />
          <Radio
            label="total score only"
            value={SCORING_FEEDBACK_CONTENT_TOTAL_SCORE}
          />
          <Radio
            label="nothing"
            value={SCORING_FEEDBACK_CONTENT_NONE}
          />
        </HFRadioGroup>
      </div>
      {scoringFeedbackContent !== SCORING_FEEDBACK_CONTENT_NONE && (
        <div>
          <AssessmentSwitchHeader>
            Display scoring information
          </AssessmentSwitchHeader>
          <HFRadioGroup
            label="display scoring information"
            name={getFieldName('scoringFeedbackVisibility')}
            style={{ marginTop: 0 }}
          >
            <Radio
              label="immediately after submission"
              value={SCORING_FEEDBACK_VISIBILITY_IMMEDIATE}
            />
            <Radio
              label="the day after submission"
              value={SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION}
            />
            <Radio
              label="after the assignment end date"
              value={SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END}
            />
          </HFRadioGroup>
        </div>
      )}
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <HFNumberField
          label="Time Limit (minutes)"
          name={getFieldName('timeLimit')}
          style={{ width: 'auto' }}
        />
        {Boolean(timeLimit) && (
          <HFSwitch
            label="Show Timer to Students"
            name={getFieldName('timeLimitVisible')}
            style={{ width: 'auto' }}
          />
        )}
      </Box>
      {Boolean(id) && (
        <HFSwitch
          helperText="Inactive tests are hidden from students completely, and hidden in your test list unless Show Inactive Tests is selected."
          label="Active"
          name="isActive"
        />
      )}
    </Stack>
  )
}

export default TestDetails
