import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Stack from '@mui/material/Stack'
import { Clock } from 'react-feather'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import LinkButton from 'common/navigation/links/LinkButton'
import { getStudentAssignmentRoute } from 'routing/consts'
import { entityIdShape, userAssignmentShape } from 'core/shapes'
import { formatRelativeTime } from 'locale/i18n'
import { asPercentageString } from 'fp/numbers'
import CardTitle from 'common/text/CardTitle'
import { capitalize } from 'fp/strings'
import CardFooter from 'hss/views/Card/CardFooter'
import useCardContent from './useCardContent'

const OpenAssignmentContent = (props) => {
  const {
    content,
    contentType,
    endDate,
    id,
    name,
    progress,
    userAssignment,
    viewMode,
  } = props

  const { callToAction, cardContent, cardContentType, cardTitle } = useCardContent(userAssignment, content, true)

  return (

    <Stack
      flex={1}
      justifyContent="space-between"
    >
      <Stack
        height="100%"
        justifyContent="space-between"
        spacing={1}
      >
        <CardHeader
          title={(
            <CardTitle
              eyebrow={capitalize(cardTitle)}
              title={name}
            />
          )}
        />

        <CardContent>{cardContent}</CardContent>

        <CardActions>
          <LinkButton
            aria-label={`${callToAction}, ${cardContentType} ${name}`}
            to={getStudentAssignmentRoute({ contentType, id })}
            {...(viewMode === 'list' ? { variant: 'primary' } : { variant: 'secondary', color: 'secondary', size: 'small' })}
          >
            {callToAction}
          </LinkButton>
        </CardActions>
      </Stack>

      <CardFooter
        flex={1}
        justifyContent="space-between"
      >
        {asPercentageString(progress || 0)} complete

        <Stack
          direction="row"
          spacing={1}
        >
          <Clock />
          <Typography>Due {formatRelativeTime(endDate)}</Typography>
        </Stack>
      </CardFooter>
    </Stack>

  )
}

OpenAssignmentContent.propTypes = {
  content: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  id: entityIdShape.isRequired,
  name: PropTypes.string.isRequired,
  progress: PropTypes.number,
  userAssignment: userAssignmentShape.isRequired,
  viewMode: PropTypes.oneOf(['list', 'grid']).isRequired,
}

export default OpenAssignmentContent
