import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { forwardRef, useContext } from 'react'
import { AdvancedList } from 'common/formControls/lists/AdvancedList'
import { get } from 'fp/objects'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Label = props => (
  <Typography
    color="grey.2"
    component="div"
    display="flex"
    fontWeight={600}
    justifyContent="flex-end"
    letterSpacing={0.1}
    maxWidth={featuredContentMaxWidth}
    mx="auto"
    textTransform="uppercase"
    {...props}
  />
)

const ItemRenderer = forwardRef(({ value }, ref) => <span ref={ref}>{value}</span>)

ItemRenderer.propTypes = {
  value: PropTypes.any.isRequired,
}

const sx = {
  alignItems: 'center',
  backgroundColor: 'background.paper',
  border: 1,
  borderColor: 'grey.4',
  counterIncrement: 'list',
  fontSize: '1.6rem',
  maxWidth: 'unset !important',
  px: 3,
  py: 2,
  mt: '-1px',
  '&::before': {
    backgroundColor: 'tableCell',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: 'border.1',
    content: 'counter(list)',
    fontSize: 15.25,
    lineHeight: '24px',
    height: 26,
    marginRight: 2,
    textAlign: 'center',
    width: 26,
  },
  '&.dragging': {
    '&::before': { visibility: 'hidden' },
    boxShadow: 4,
  },
  'span:first-of-type': { marginRight: 'auto' },
  '&>button, &>span:nth-of-type(2)': {
    color: 'grey.2',
  },
  zIndex: 'tooltip', // so the dragging works in the preview modal
}

const RankingList = (props) => {
  const {
    interactiveData: {
      firstLabel,
      lastLabel,
    },
  } = useContext(interactiveContext)

  const { disabled = false } = props
  return (
    <>
      <Label>
        {firstLabel}
      </Label>

      <AdvancedList
        allowReordering={!disabled}
        getter={get('label')}
        itemContainerProps={{ sx }}
        ItemRenderer={ItemRenderer}
        name="items"
        noLabel
        utilizeDragHandle={!disabled}
        {...props}
      />

      <Label>
        {lastLabel}
      </Label>
    </>
  )
}

RankingList.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
}

export default RankingList
