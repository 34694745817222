import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { startTransition } from 'react'
import { Monitor } from 'react-feather'
import LinkButton from 'common/navigation/links/LinkButton'
// import { contentShape } from 'core/shapes'
import { when } from 'fp/utils'
import { assignmentEditorUrl } from 'routing/consts'
import useToggleState from 'hooks/useToggleState'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'

const AssignmentLaunchActions = ({ assignment }) => {
  const assignmentId = assignment?.id

  const [presenterModeEnabled, togglePresenterMode] = useToggleState(false, TOGGLE_STATE_PRESENTER_MODE)

  const handleViewClick = (willPresent) => {
    startTransition(() => {
      when(
        (willPresent && !presenterModeEnabled) || (!willPresent && presenterModeEnabled),
        togglePresenterMode,
      )
    })
  }

  return (
    <Stack
      direction="row"
      mb={6}
      mt={5}
      spacing={6}
    >

      <LinkButton
        onClick={() => handleViewClick(true)}
        startIcon={<Monitor />}
        sx={{ minWidth: 120 }}
        to={`${assignmentEditorUrl}/${assignmentId}`}
        variant="primary"
      >
        Present
      </LinkButton>

      <Stack
        direction="row"
        spacing={1}
      >
        <LinkButton
          onClick={() => handleViewClick(false)}
          to={`${assignmentEditorUrl}/${assignmentId}`}
        >
          View
        </LinkButton>

        <LinkButton
          onClick={() => handleViewClick(false)}
          to={`${assignmentEditorUrl}/${assignmentId}/settings`}
        >
          Edit
        </LinkButton>
      </Stack>

    </Stack>
  )
}

AssignmentLaunchActions.propTypes = {
  assignment: PropTypes.object,
}

export default AssignmentLaunchActions
