import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import Headline from 'common/text/Headline'
import { componentShape } from 'core/shapes'
import ContainerGrid from 'common/layout/ContainerGrid'
import withColorSwatch from 'common/text/withColorSwatch'

const Overview = ({ leftComponent, headlineTitle, rightComponent, ...rest }) => {
  const headlineRef = useRef()
  const ColorSwatchBox = withColorSwatch(Box, headlineRef)

  return (
    <ColorSwatchBox
      mb={8}
      mt={4}
      {...rest}
    >
      <Container>

        <ContainerGrid
          alignRightWhenSplit={false}
          spacing={4}
          splitBelow="lg"
        >
          <Headline
            ref={headlineRef}
            title={headlineTitle}
          >
            {Boolean(leftComponent) && (
              <Box mt={6}>
                {leftComponent}
              </Box>
            )}
          </Headline>

          {/* ContainerGrid requires 2 children */}
          {rightComponent || <div />}
        </ContainerGrid>
      </Container>
    </ColorSwatchBox>
  )
}

Overview.propTypes = {
  leftComponent: componentShape,
  headlineTitle: PropTypes.string.isRequired,
  rightComponent: componentShape,
}

export default Overview
