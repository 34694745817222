import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Legal from 'common/layout/Legal'
import Warning from 'common/layout/Warning'
import { isStaff } from 'selectors/users'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import actionTypes from 'reducers/actionTypes'
import useCurrentUser from 'hooks/useCurrentUser'
import EULA from './EULA'

const NotAccepted = () => {
  const isMentor = useSelector(isStaff)
  const user = useCurrentUser()
  const [acceptEULA, status] = useReduxCallback({ actionType: actionTypes.USER_ACCEPT_TURNITIN_EULA })

  const handleAccept = useCallback(() => {
    acceptEULA({ user })
  }, [acceptEULA, user])

  return (
    <>
      <Warning>
        <p>
          Before using Turnitin features, you must accept the current End User
          License Agreement.
        </p>
        {Boolean(isMentor)
          && (
            <p>
              Accepting the current EULA and turning on the feature for an assignment
              will prompt each student to sign the current EULA as well.
            </p>
          )}
      </Warning>

      <Legal>
        <EULA />
      </Legal>

      <Button
        color="secondary"
        disabled={status === BUSY}
        onClick={handleAccept}
        variant="secondary"
      >
        Accept EULA
      </Button>
    </>
  )
}

export default NotAccepted
