import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import useSubscribeToInteractions from 'hooks/useSubscribeToInteractions'
import { getRankedEchoResponses } from 'selectors/echo'
import FeatureHeadline from '../FeatureHeadline'
import ThereAreNone from './ThereAreNone'
import Ranking from './Ranking'

const FeatureTopTen = () => {
  const { interactive } = useContext(interactiveContext)

  useSubscribeToInteractions({ interactive })

  const ranked = useSelector(getRankedEchoResponses(interactive))

  return (
    <>

      <FeatureHeadline
        mb={2}
        title="Top 10"
      />

      {!ranked.length && <ThereAreNone />}

      {!!ranked.length && <Ranking ranked={ranked} />}

    </>
  )
}

export default FeatureTopTen
