import { useContext } from 'react'
import Box from '@mui/material/Box'
import { interactiveGradingContext } from 'sections/contentBlocks/Interactive/InteractiveGradingProvider'
import { set } from 'fp/objects'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import Rubric from 'sections/contentBlocks/interactives/Rubric'
import Headline from 'common/text/Headline'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'

const ActivityGrader = () => {
  const {
    scoreData,
    setSaveButtonDisabled,
    setScoreData,
  } = useContext(interactiveGradingContext)
  const { gradingEnabled } = useContext(interactiveContext) || {}

  const { comment = '', rubricSelections = [] } = scoreData || {}

  const handleRubricChange = (newValues) => {
    setSaveButtonDisabled(false)
    setScoreData(set('rubricSelections', newValues))
  }

  const handleCommentChange = ({ target: { value } }) => {
    setSaveButtonDisabled(false)
    setScoreData(set('comment', value))
  }

  return (
    <Box
      component="fieldset"
      mt={4}
      width="100%"
    >
      <Box
        component="legend"
        style={{ textAlign: 'center', width: '100%', maxWidth: 'none' }}
      >
        <Headline
          hr
          title="Grading & Review"
          variant="feature-paragraph-semibold"
        />
      </Box>
      <Rubric
        onChange={handleRubricChange}
        values={rubricSelections}
      />
      <DebouncedTextField
        disabled={gradingEnabled === false}
        label="Optional Comment"
        multiline
        name="comment"
        onChange={handleCommentChange}
        value={comment}
        variant="filled"
      />
    </Box>
  )
}

export default ActivityGrader
