import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'
import { position } from 'polished'
import Container from '@mui/material/Container'
import { compose } from 'redux'
import TraverseLogo from 'hss/images/login/traverse-logo.svg'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { componentShape } from 'core/shapes'
import Centered from 'common/layout/Centered'
import { first, shuffle } from 'fp/arrays'
import ILLogo from 'hss/images/home/il-logo.png'
import FooterLinks from '../Home/FooterLinks'

// TODO: replace these placeholder images
const loginImages = [
  'https://s3.us-east-1.amazonaws.com/staging-content.studysync.com/hssdev/2022/12/8/78-19596810_original',
  'https://s3.us-east-1.amazonaws.com/staging-content.studysync.com/hssdev/2022/12/9/87-11461215_original',
  'https://s3.us-east-1.amazonaws.com/staging-content.studysync.com/hssdev/2022/12/8/84-20080315_original',
]

const backgroundAnimation = keyframes`
  0% {
    opacity:0;
  }

  30%{
    opacity:0.75;
  }
  100%{
    right:0;
    opacity:1;
  }`

const positionAnimation = keyframes`
  0% {

    right:'calc(var(--right-offset) - 500px)'
  }

  100% {
    right:var(--right-offset)
  }`

const ColorBar = styled(Box, { name: 'login-colorbar' })(() => ({
  ...position('absolute', '-20%', 'calc(var(--right-offset) - 500px)', null, null),
  animation: `${positionAnimation} 1s ease-in-out forwards`,
  height: '200%',
  transform: 'rotate(20deg)',
  width: 136,
  '@media (prefers-reduced-motion: reduce)': {
    animationDuration: '0ms !important',
  },
}))

const ImageContainer = styled(
  Box,
  { name: 'login-image-container' },
)(({ theme: { breakpoints } }) => ({
  ...position('absolute', 0, -100, 0, 0),
  animation: `${backgroundAnimation} 2s forwards`,
  background: 'no-repeat bottom right/auto 100%',
  height: '100vh',
  opacity: 0,
  overflow: 'hidden',
  [breakpoints.down('xl')]: {
    backgroundSize: 'auto 80%',
  },
  [breakpoints.down('lg')]: {
    background: 'none',
  },
  '@media (prefers-reduced-motion: reduce)': {
    animationDuration: '0ms !important',
  },
}))

const StyledILLogo = styled('img')(({ theme: { breakpoints, zIndex } }) => ({
  ...position('absolute', 50, 120, null, null),
  width: 111,
  zIndex: zIndex.appBar + 1,
  [breakpoints.down('lg')]: {
    right: 0,
    top: -80,
  },
}))

const LoginLayout = ({ children }) => {
  const isSmallScreen = useContainerQuery().down('lg')
  const selectedImage = compose(first, shuffle)(loginImages)
  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <ColorBar sx={{ backgroundColor: 'tangerine.0', '--right-offset': '350px' }} />
      <ColorBar sx={{ backgroundColor: 'canary.0', '--right-offset': '80px', animationDelay: '500ms' }} />
      <ColorBar sx={{ backgroundColor: 'babyBlue.0', '--right-offset': '-65px', animationDelay: '750ms' }} />
      {Boolean(!isSmallScreen) && (
        <StyledILLogo
          alt="Imagine Learning Logo"
          src={ILLogo}
        />
      )}

      <ImageContainer sx={{ backgroundImage: `url(${selectedImage})` }} />

      <Box
        component="main"
        minHeight="100vh"
      >
        <Container>
          <Stack
            bgcolor={{ xs: 'grey.5', lg: 'transparent' }}
            boxShadow={{ xs: 3, lg: 0 }}
            height={{ lg: '100vh' }}
            justifyContent="space-between"
            maxWidth="55rem"
            mt={{ xs: 15, lg: 0 }}
            mx={{ xs: 'auto', lg: 0 }}
            padding={6}
            position="relative"
          >
            <div>
              {Boolean(isSmallScreen) && (
                <StyledILLogo
                  alt="Imagine Learning Logo"
                  src={ILLogo}
                />
              )}

              <TraverseLogo />

              {children}
            </div>
            {/* TODO: link these items */}
            <div>
              <FooterLinks />
              <Centered>Copyright © {new Date().getFullYear()} Imagine Learning, LLC.</Centered>
            </div>

          </Stack>
        </Container>
      </Box>
    </Box>

  )
}

LoginLayout.propTypes = {
  children: componentShape.isRequired,
}

export default LoginLayout
