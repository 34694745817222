import Stack from '@mui/material/Stack'
import { contentShape } from 'core/shapes'
import ApplicationStandards from '../Subsection/Footnotes/ApplicationStandards'
import InstructionalStandards from '../Subsection/Footnotes/InstructionalStandards'

const BlockStandards = ({ content: { applicationStandards, instructionStandards } }) => (
  <Stack>

    <InstructionalStandards instructionStandards={instructionStandards} />

    <ApplicationStandards applicationStandards={applicationStandards} />

  </Stack>
)

BlockStandards.propTypes = {
  content: contentShape.isRequired,
}

export default BlockStandards
