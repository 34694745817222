import { useContext } from 'react'
import CommonCardSearch from 'hss/views/Search/CommonCardSearch'
import ContentSubTypeFilter from 'hss/views/Search/Filters/Controls/ContentSubTypeFilter'
import ContentTypeFilter from 'hss/views/Search/Filters/Controls/ContentTypeFilter'
import AdvancedFilters from 'hss/views/Search/Filters/AdvancedFilters'
import useAbilityCheck from 'hooks/useAbilityCheck'
import {
  FEATURE_FLAG_ADVANCED_FILTERS,
} from 'core/consts'
import { context } from 'hss/views/Search/SearchProvider'
import AddToLibrary from '../AddToLibrary'
import Preview from './Preview'

const Search = () => {
  const { contentSubTypes } = useContext(context)
  const hasAdvFilters = useAbilityCheck(FEATURE_FLAG_ADVANCED_FILTERS)

  return (
    <CommonCardSearch
      renderActions={() => <AddToLibrary />}
      renderAdvancedFilter={hasAdvFilters ? () => <AdvancedFilters /> : undefined}
      renderPrimaryFilter={() => <ContentTypeFilter />}
      renderSecondaryFilter={contentSubTypes.length > 0 ? () => <ContentSubTypeFilter /> : undefined}
    >

      <Preview />

    </CommonCardSearch>
  )
}

export default Search
