import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import { useContext } from 'react'
import BusySpinner from 'common/indicators/BusySpinner'
import { context } from 'hss/views/Search/SearchProvider'
import Filters from 'hss/views/Search/Filters'
import ResultsHeader, { defaultRenderHeadline } from 'hss/views/Search/Results/ResultsHeader'
import Results from './Results'
import TableResults from './TableResults'

const CommonCardSearch = (props) => {
  const {
    children,
    renderActions,
    renderAdvancedFilter,
    renderHeadline = defaultRenderHeadline,
    renderPrimaryFilter,
    renderSecondaryFilter,
  } = props

  const { dataset, viewMode } = useContext(context)

  return dataset
    ? (
      <Container>

        <ResultsHeader
          {...{
            renderActions,
            renderHeadline,
          }}
        >

          <Filters
            {...{
              renderAdvancedFilter,
              renderPrimaryFilter,
              renderSecondaryFilter,
            }}
          />

        </ResultsHeader>

        {viewMode === 'table'
          ? <TableResults />
          : <Results />}

        {children}

      </Container>
    )
    : <BusySpinner />
}

CommonCardSearch.propTypes = {
  children: PropTypes.element,
  renderActions: PropTypes.func,
  renderAdvancedFilter: PropTypes.func,
  renderHeadline: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
}

export default CommonCardSearch
