/* eslint-disable function-call-argument-newline */
import { createElement, memo } from 'react'
import PropTypes from 'prop-types'
import Html from 'common/text/Html'
import { mapKeys } from 'fp/objects'
import PreviewInteractive from './PreviewInteractive'

const pullDataAttributes = mapKeys(key => String(key).replace(/^data-/, ''))

const PreviewContentHtml = ({ body }) => {
  const processDualHeadlineNode = ({ attribs }, children, index) => {
    const { title1, title2 } = pullDataAttributes(attribs)

    return <div key={index}>{title1} {title2} {children}</div>
  }

  const processDualHeaders = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'advanced-heading',
    processNode: processDualHeadlineNode,
  }

  const processInteractiveNode = ({ attribs }, children, index) => createElement(
    PreviewInteractive, {
      ...pullDataAttributes(attribs),
      key: `interactive-${index}`,
    },
    children,
  )

  const processInteractives = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'interactive',
    processNode: processInteractiveNode,
    replaceChildren: true,
  }

  return body
    ? (
      <Html
        additionalInstructions={[
          processDualHeaders,
          processInteractives,
        ]}
        body={body}
        options={{ xmlMode: true }}
      />
    )
    : null
}

PreviewContentHtml.propTypes = {
  body: PropTypes.string,
}

const Memoized = memo(PreviewContentHtml)

export default Memoized
