import { useCallback, useId, useState } from 'react'
import PropTypes from 'prop-types'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { isDefined, whenPresent } from 'fp/utils'
import { get, merge, pick, set } from 'fp/objects'
import { isEmpty, removeByIndex } from 'fp/arrays'
import { interactionShape } from 'core/shapes'
import StoryPlotSVG from './StoryPlotSVG'
import PlotDiagramDialog from './PlotDiagramDialog'

const diagramPropNames = ['exposition', 'risingAction', 'climax', 'fallingAction', 'resolution', 'readOnly']

const Content = ({ interaction, interactiveData, onInteract, onSave, submittedDate }) => {
  const diagramProps = pick(diagramPropNames)(isEmpty(interaction?.interactionData)
    ? interactiveData
    : interaction?.interactionData)

  const { climax, exposition, fallingAction, readOnly, resolution, risingAction } = diagramProps
  const [focusedPoint, setFocusedPoint] = useState()
  const [selectedNote, setSelectedNote] = useState()
  const id = useId()

  const saveDiagram = useCallback((data) => {
    whenPresent(onInteract, merge(diagramProps, data)) // for interactive
    whenPresent(onSave, data) // for builder
    setSelectedNote(null)
  }, [diagramProps, onInteract, onSave])

  const handleChange = value => () => {
    const [diagramKey, selectedIdx] = selectedNote.key.split('.')
    saveDiagram({ [diagramKey]: isDefined(selectedIdx)
      ? set(selectedIdx, value)(get(diagramKey)(diagramProps))
      : value })
    setFocusedPoint(selectedNote.key)
  }

  const handleRemove = () => {
    const [diagramKey, selectedIdx] = selectedNote.key.split('.')
    saveDiagram({ [diagramKey]: isDefined(selectedIdx)
      ? removeByIndex(selectedIdx)(get(diagramKey)(diagramProps))
      : '' })
  }

  const handleReorder = actionType => ({ currentIndex, newIndex }) => {
    if (currentIndex !== newIndex) {
      const actionPoints = get(`${actionType}Action`)(diagramProps)
      const points = actionPoints.filter((_, idx) => idx !== currentIndex)
      const updatedPoints = [
        ...points.slice(0, newIndex),
        actionPoints[currentIndex],
        ...points.slice(newIndex),
      ]
      saveDiagram({ [`${actionType}Action`]: updatedPoints })
      setFocusedPoint(`${actionType}Action.${newIndex}`)
    }
  }

  const handleDialogClose = () => { setSelectedNote(null) }
  const handleSelectNote = (note) => { setSelectedNote(note) }

  return (
    <div style={{ position: 'relative' }}>
      <StoryPlotSVG
        focusedPoint={focusedPoint}
        id={id}
        onClick={handleSelectNote}
        onReorder={handleReorder}
        {...diagramProps}
        readOnly={isDefined(submittedDate) || readOnly}
      />
      <ScreenReaderText id={id}>
        A timeline that shows sections of a story.
        {!readOnly && <span>Use the tab key to navigate this diagram and add your thoughts.</span>}
        From left to right, the sections are: exposition, rising action, climax, falling action, and resolution.
        The timeline slopes upward for rising action and slopes downward for falling action.
        Exposition: {exposition || 'no response'}.
        Rising Action:
        {risingAction?.length > 0
          ? risingAction.map((item, idx) => <span key={item + idx}>{`Item ${idx + 1}: ${item || /* istanbul ignore next */ 'no response'}.`}</span>)
          : <span>No items.</span>}
        Climax: {climax || 'no response'}.
        Falling Action:
        {fallingAction?.length > 0
          ? fallingAction.map((item, idx) => <span key={item + idx}>{`Item ${idx + 1}: ${item || /* istanbul ignore next */ 'no response'}.`}</span>)
          : <span>No items.</span>}

        Resolution: {resolution || 'no response'}.
      </ScreenReaderText>
      {Boolean(!readOnly && selectedNote) && (
        <PlotDiagramDialog
          handleRemove={handleRemove}
          handleSave={handleChange}
          note={selectedNote}
          onClose={handleDialogClose}
          open
        />
      )}

    </div>
  )
}

Content.propTypes = {
  interaction: interactionShape,
  interactiveData: PropTypes.any.isRequired,
  onInteract: PropTypes.func,
  onSave: PropTypes.func,
  submittedDate: PropTypes.string,
}

export default Content
