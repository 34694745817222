import { Trash as Icon } from 'react-feather'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { ABILITY_CONTENT_RESTRICTION } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import withConfirm from 'hoc/withConfirm'
import { contentShape } from 'core/shapes'
import actionTypes from 'reducers/actionTypes'
import { getNextPrevForContentViewer } from 'selectors/contentViewer'
import { produceLinkTo } from 'hss/ContentViewer/Banner/LearnBanner/LeftSide'
import useContent from 'hooks/useContent'
import { renameKeys } from 'fp/objects'

const ConfirmMenuItem = withConfirm(MenuItem)

const ContentInsertionSectionRemovalMenuItem = withAbilityCheck(({ content: { parent } }) => {
  const section = useContent(renameKeys({ id: 'contentId' }, parent))

  const dispatch = useDispatch()

  const { prev } = useSelector(getNextPrevForContentViewer({ preferTeCopy: true }))
  const destUrl = produceLinkTo(prev)

  const onClick = useCallback(() => {
    dispatch({
      type: actionTypes.CONTENT_INSERTION_ALTERATION,
      payload: {
        insertionData: section.insertionData,
      },
      passThrough: { destUrl },
    })
  }, [section?.insertionData, destUrl, dispatch])

  return section?.insertionData
    ? (
      <>
        <Divider />

        <ConfirmMenuItem
          {...{
            onClick,
            confirmationTitle: 'Remove Inserted Content',
            confirmationMessage: `Are you sure you want to remove '${section.name}'?`,
          }}
        >
          <Icon />
          Remove inserted section...
        </ConfirmMenuItem>
      </>
    )
    : null
}, ABILITY_CONTENT_RESTRICTION)

ContentInsertionSectionRemovalMenuItem.propTypes = {
  content: contentShape.isRequired,
}

export default ContentInsertionSectionRemovalMenuItem
