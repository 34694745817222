import Typography from '@mui/material/Typography'
import { componentShape } from 'core/shapes'

const AssessmentSwitchHeader = ({ children }) => (
  <Typography
    display="block"
    style={{ marginBottom: '1.5rem' }}
    variant="paragraph-semibold"
  >
    {children}
  </Typography>
)

AssessmentSwitchHeader.propTypes = {
  children: componentShape.isRequired,
}

export default AssessmentSwitchHeader
