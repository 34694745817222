import { useContext } from 'react'
import { context } from 'hss/views/Search/SearchProvider'
import FiltersContainer from 'hss/views/Search/Filters/FiltersContainer'

const Filters = () => {
  const { primaryFiltersExpanded } = useContext(context)

  return primaryFiltersExpanded
    ? <FiltersContainer>No filters available.</FiltersContainer>
    : null
}

export default Filters
