import PropTypes from 'prop-types'
import { createElement, useContext } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { compose } from 'redux'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useParams } from 'react-router-dom'
import Radio from 'common/formControls/switches/Radio'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import BaseMultipleChoice from 'hss/sections/contentBlocks/interactives/MultipleChoice/BaseMultipleChoice'
import Html from 'common/text/Html'
import { get } from 'fp/objects'
import Explanation from 'hss/sections/contentBlocks/interactives/MultipleChoice/Explanation'
import { includes } from 'fp/arrays'
import Popper from 'common/indicators/Popper'
import { getUserById } from 'selectors/users'
import Checkbox from 'common/formControls/switches/Checkbox'
import AnswerChoiceBox from 'hss/sections/contentBlocks/interactives/MultipleChoice/AnswerChoiceBox'
import { componentShape } from 'core/shapes'
import { interactiveContext } from '../../InteractiveProvider'

const StudentName = ({ studentId }) => {
  const { firstName, lastName } = useSelector(getUserById({ userId: studentId })) || {}
  return `${firstName} ${lastName}`.trim()
}

const SubmittedStudentsPopper = ({ idsOfUsersWhoChoseThisAnswer, isCorrect }) => (
  <Popper paperStyle={{ minWidth: 250 }}>
    <Popper.Control>
      <Button
        sx={{ color: isCorrect ? 'success.dark' : 'error.main' }}
        variant="mock-link"
      >
        {idsOfUsersWhoChoseThisAnswer.length} submitted
      </Button>
    </Popper.Control>

    <Popper.Content>
      <Box textAlign="left">
        Submitted by:
        <List component="ul">
          {idsOfUsersWhoChoseThisAnswer.map(studentId => (
            <ListItem key={studentId}>
              <StudentName studentId={studentId} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Popper.Content>
  </Popper>
)
SubmittedStudentsPopper.propTypes = {
  idsOfUsersWhoChoseThisAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
  isCorrect: PropTypes.bool.isRequired,
}

const ItemRenderer = ({
  id,
  isCorrect,
  explanation,
  label,
  ...rest
}) => {
  const { contentId, interactiveData: { allowMultipleSelection } } = useContext(interactiveContext)
  const { assignmentId } = useParams()
  const Control = allowMultipleSelection ? Checkbox : Radio

  const allInteractions = useSelector(getInteractionsForAssignmentContent({ contentId, assignmentId }))
  const idsOfUsersWhoChoseThisAnswer = allInteractions
    .filter(compose(includes(id), get('interactionData.selectedAnswerChoiceIds')))
    .map(get('userId'))

  const barColor = isCorrect
    ? 'success.main'
    : 'transparent'

  return (
    <AnswerChoiceBox barColor={barColor}>
      <Control
        checked={isCorrect}
        description={(
          <Stack
            alignItems="flex-start"
            gap={2}
          >
            <Explanation
              explanation={explanation}
              isCorrectAnswer={isCorrect}
            />
            {Boolean(idsOfUsersWhoChoseThisAnswer.length) && (
              <SubmittedStudentsPopper {...{ idsOfUsersWhoChoseThisAnswer, isCorrect }} />
            )}
          </Stack>
        )}
        disabled
        label={(
          <Box color="grey.0">
            <Html body={label} />
          </Box>
        )}
        {...rest}
      />
    </AnswerChoiceBox>
  )
}
ItemRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  explanation: PropTypes.string,
  isCorrect: PropTypes.bool.isRequired,
  label: componentShape.isRequired,
}

const GroupRenderer = ({ options }) => options.map((option) => {
  const { id } = option
  return createElement(ItemRenderer, {
    ...option,
    key: id,
    value: id,
  })
})

const MultipleChoiceAggregate = () => (
  <BaseMultipleChoice GroupRenderer={GroupRenderer} />
)

export default MultipleChoiceAggregate
