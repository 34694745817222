import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import ListItem from '@mui/material/ListItem'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useCallback, useContext } from 'react'
import List from '@mui/material/List'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import Dialog from 'common/dialogs/Dialog'
import { groupAndSortGroupShape } from 'core/shapes'
import { context } from './context'

const StyledList = styled(
  List,
  { name: 'GroupAndSort-List' },
)(({ theme: { mixins: { rem } } }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: rem(3),
  li: {
    width: 'auto',
    margin: rem(0, 1.2, 1.2, 0),
    padding: 0,

    svg: {
      marginRight: rem(0.5),
    },
  },
}))

const AddToGroupDialog = ({ group, dialogOpen, setDialogOpen }) => {
  const { handleDrop, state: { options } } = useContext(context)

  const dropFromDialog = useCallback((id) => {
    handleDrop(id, group.id)
    setDialogOpen(false)
  }, [group.id, handleDrop, setDialogOpen])

  const handleClose = () => setDialogOpen(false)

  return (
    <Dialog
      onClose={handleClose}
      open={dialogOpen}
      showCloseButton
    >
      <DialogTitle>{`${group.heading} Options`}</DialogTitle>
      <DialogContent>
        <StyledList>
          {options
            .filter(option => !option.droppedId)
            .map(item => (
              <ListItem key={item.id}>
                <Button
                  onClick={() => dropFromDialog(item.id)}
                  p="1.2rem"
                >
                  <AddCircleIcon /> {item.label}
                </Button>
              </ListItem>
            ))}
        </StyledList>
      </DialogContent>
    </Dialog>
  )
}

AddToGroupDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  group: groupAndSortGroupShape.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
}

export default AddToGroupDialog
