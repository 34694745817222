import Add from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import LinkButton from 'common/navigation/links/LinkButton'
import { contentBuilderUrl } from 'routing/consts'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_STATE_PUBLISHED,
  CONTENT_TYPE_COURSE,
} from 'core/consts'
import ContentHero from 'hss/sections/Heros/ContentHero'
import { px } from 'styling/theming/base/mixins'
import withAbilityCheck from 'hoc/withAbilityCheck'
import CurriculumHero from 'hss/images/content/curriculum-bg.webp'

const content = {
  academicVocabPhraseIds: [],
  academicVocabPhrases: [],
  applicationStandardIds: [],
  applicationStandards: [],
  contentState: CONTENT_STATE_PUBLISHED,
  contentType: CONTENT_TYPE_COURSE,
  contentVocabPhraseIds: [],
  contentVocabPhrases: [],
  footnoteVocabPhraseIds: [],
  footnoteVocabPhrases: [],
  id: 'NA',
  instructionStandardIds: [],
  instructionStandards: [],
  tagIds: [],
  tags: [],
  uploadsMap: {
    full: {
      url: 'https://s3.us-east-1.amazonaws.com/staging-content.studysync.com/hssdev/2022/3/8/17-75370120.jpg',
    },
  },
  vocabContent: [],
  vocabContentIds: [],
}

const Background = styled('div')(() => ({
  backgroundImage: `url(${CurriculumHero})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: 318,
}))

export const AddButton = withAbilityCheck(() => (
  <LinkButton
    color="secondary"
    startIcon={<Add />}
    to={`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/create`}
    variant="secondary"
  >
    Add Course
  </LinkButton>
), ABILITY_CONTENT_CREATION)

const Hero = () => (
  <Background
    style={{
      paddingTop: '8rem',
    }}
  >
    <ContentHero
      color="grey.5"
      content={content}
      heroProps={{ sx: { height: px(150) } }}
      title={(
        <div>
          Curriculum
          <br />
          <Typography
            component="div"
            fontWeight={300}
            mt={1}
            variant="h4"
          >
            All Courses
          </Typography>
        </div>
      )}
    />
  </Background>
)

export default Hero
