import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Typography from '@mui/material/Typography'
import { useId } from 'react'

const hidingStyle = {
  opacity: 0,
  pointerEvents: 'none', // If we didn't do this, the See Photo button would still be clickable even though it's transparent.
}

const showingStyle = {
  opacity: 1,
  pointerEvents: 'all',
}

const SensitivityOverlay = ({ sensitiveWarning, showWarning, toggleShowingImage }) => {
  const sensitiveWarningId = useId()

  return (
    <div
      aria-hidden={!showWarning}
      className="sensitivity-overlay-wrapper"
      data-testid="sensitivity-overlay-wrapper"
      style={showWarning ? showingStyle : hidingStyle}
    >

      <div className="sensitivity-overlay" />

      <div className="sensitivity-message">
        <VisibilityOffIcon />
        <Typography variant="eyebrow">Sensitive Content</Typography>

        <div id={sensitiveWarningId}>{sensitiveWarning}</div>

        <div>
          <Button
            aria-describedby={sensitiveWarningId}
            onClick={toggleShowingImage}
            tabIndex={showWarning ? null : -1}
          >
            See Photo
          </Button>
        </div>
      </div>

    </div>
  )
}

SensitivityOverlay.propTypes = {
  sensitiveWarning: PropTypes.string,
  showWarning: PropTypes.bool.isRequired,
  toggleShowingImage: PropTypes.func.isRequired,
}

export default SensitivityOverlay
