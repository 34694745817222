import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Html from 'common/text/Html'
import ScreenReaderText from 'common/text/ScreenReaderText'
import useCurrentUser from 'hooks/useCurrentUser'
import Well from 'common/layout/Well'
import { get } from 'fp/objects'

const LongTextAlternative = ({ forceVisible = false, html, id, label, ...rest }) => {
  const currentUser = useCurrentUser()
  const visible = forceVisible
    || get('user.preferences.imagesLongTextAlts')(currentUser)
    || false

  const Container = visible
    ? props => (
      <Well
        mt={2}
        {...props}
      />
    )
    : props => (
      <ScreenReaderText
        component="div"
        {...props}
      />
    )

  return Boolean(html) && (
    <Box {...rest}>
      <Container
        data-testid={id}
        id={id}
      >
        {Boolean(label) && (<Typography variant="small-semibold">{label}</Typography>)}
        <Html body={html} />
      </Container>
      <ScreenReaderText>End of {label}</ScreenReaderText>
    </Box>
  )
}

LongTextAlternative.propTypes = {
  forceVisible: PropTypes.bool,
  html: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default LongTextAlternative
