import Stack from '@mui/material/Stack'
import CardFooter from 'hss/views/Card/CardFooter'
import { contentShape } from 'core/shapes'
import ContentRestrictionControls from 'hss/views/Curriculum/ContentRestrictionControls'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import ChapterAssignmentLink from './ChapterAssignmentLink'

const Footer = withAbilityCheck(({ content }) => (
  <CardFooter>
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
    >

      <ChapterAssignmentLink content={content} />

      <ContentRestrictionControls content={content} />

    </Stack>
  </CardFooter>
), ABILITY_ASSIGNMENT_CREATION)

Footer.propTypes = {
  content: contentShape.isRequired,
}

export default Footer
