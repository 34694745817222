import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { useCallback, useContext, useState } from 'react'
import withConfirm from 'hoc/withConfirm'
import { actions as contentActions } from 'reducers/content'
import { entityIdShape } from 'core/shapes'
import useReduxPromise from 'hooks/useReduxPromise'
import ContentIsInUseDialog from 'common/dialogs/ContentIsInUseDialog'
import { context } from 'hss/views/Search/SearchProvider'
import { whenPresent } from 'fp/utils'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'

const ConfirmItem = withConfirm(MenuItem)

const DeletionMenuItem = withConfirm(({ content }) => {
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)
  const [contentInUseMeta, setContentInUseMeta] = useState({})
  const { updateSearchResults } = useContext(context) || {}

  const deleteIt = useReduxPromise(contentActions.deleteContentById({}).type)

  const handleDeleteClick = useCallback(() => {
    const doIt = async () => {
      deleteIt({ contentId: content.id || content.contentId })
        .then(() => {
          whenPresent(updateSearchResults)
        })
        .catch((err) => {
          setContentInUseMeta(err)
        })
    }
    if (isContentCreator) { // double check
      doIt()
    }
  }, [content.contentId, content.id, deleteIt, isContentCreator, updateSearchResults])

  return (
    <>
      <ConfirmItem
        cancelLabel="ABORT"
        confirmationMessage="Are you sure you want to delete this content?"
        confirmationTitle="Confirm"
        confirmLabel="Proceed"
        onClick={handleDeleteClick}
      >
        Delete
      </ConfirmItem>

      <ContentIsInUseDialog meta={contentInUseMeta} />

    </>
  )
})

DeletionMenuItem.propTypes = {
  content: PropTypes.shape({
    contentId: entityIdShape,
    id: entityIdShape,
  }).isRequired,
}

export default DeletionMenuItem
