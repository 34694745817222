import Stack from '@mui/material/Stack'
import { useContext } from 'react'
import Box from '@mui/material/Box'
import Carousel from 'common/carousels/Carousel'
import Html from 'common/text/Html'
import { singleSlidesWithNavigation } from 'common/carousels/configurations'
import Headline from 'common/text/Headline'
import Lightbulb from 'hss/images/controls/draft-toolbar/lightbulb.svg'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { titleCase } from 'fp/strings'
import FallbackImage from 'common/indicators/FallbackImage'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import FunFactTooltip from './Tooltip'
import FunFactDialog from './Dialog'

const Content = () => {
  const context = useContext(interactiveContext)
  const {
    interactive: { name },
    interactiveData: { displayHeader, facts, imageAltText, imagePlacement },
    uploadsMap: { image },
  } = context

  const isLargeScreen = useContainerQuery().up('sm')
  const Renderer = isLargeScreen ? FunFactTooltip : FunFactDialog

  return (
    <Renderer>
      <Box
        bgcolor="background.inverted"
        height={72}
        p={2}
        sx={{ svg: { color: 'primary.contrastText' } }}
        width={72}
      >
        <Lightbulb />
      </Box>
      <Stack direction={imagePlacement === 'left' ? 'row' : 'column'}>

        {image
          ? (
            <img
              alt={imageAltText}
              className={imagePlacement}
              src={image.url}
            />
          )
          : <FallbackImage />}

        <Stack
          sx={{
            flex: 1,
            padding: 4,
            minHeight: 0,
            minWidth: 0,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            '.swiper-wrapper': {
              textAlign: 'center',
              paddingTop: 0,
              p: { margin: 0 },
            },
            button: {
              '&:last-of-type': {
                right: 0,
              },
            },
            '> h2:first-of-type': { // headline styles are overridden in popper
              fontSize: '4rem',
            },
          }}
        >
          <Headline
            mb={3}
            size={3}
            textTransform="none"
            title={titleCase(displayHeader || name)}
            weight={300}
          >
            <Carousel
              configuration={singleSlidesWithNavigation}
              sx={{ maxWidth: '100%' }}
            >
              {facts.map((fact, idx) => <div key={idx}><Html body={fact} /></div>)}
            </Carousel>
          </Headline>

        </Stack>
      </Stack>
    </Renderer>
  )
}

export default Content
