import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

const usePacingGuideTheme = () => {
  const { palette } = useTheme()

  return useMemo(
    () => {
      const pacingOptionHeaderStyles = [
        palette.grey[1],
        palette.grey[0],
        palette.common.black,
      ].map(background => ({
        background,
        color: palette.grey.contrastText,
      }))

      return ({
        pacingOptionHeaderStyle: index => pacingOptionHeaderStyles[index],
      })
    },
    [palette],
  )
}

export default usePacingGuideTheme
