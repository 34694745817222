import { produce } from 'immer'
import draftjs from 'styling/thirdParty/draftjs'
import notistack from 'styling/thirdParty/notistack'
import recharts from 'styling/thirdParty/recharts'
import splitterLayout from 'styling/thirdParty/react-splitter-layout'
import swiper from 'styling/thirdParty/swiper'
import videoJs from 'styling/thirdParty/video-js'
import 'feather-webfont/dist/feather-icons.css'

const plugin = theme => produce(theme, (draft) => {
  draft.thirdParty = {
    ...draftjs(theme),
    ...notistack(theme),
    ...recharts(theme),
    ...splitterLayout(theme),
    ...swiper(theme),
    ...videoJs(theme),
  }
})

export default plugin
