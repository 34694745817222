import Box from '@mui/material/Box'
import { useContext } from 'react'
import { context } from './context'
import Group from './Group'
import StyledGroups from './StyledGroups'

const Groups = () => {
  const { state: { groups } } = useContext(context)

  return (
    <StyledGroups
      display="flex"
      width="100%"
    >
      {groups.map((group, idx) => (
        <Box key={group.id}>
          <Group
            group={group}
            idx={idx}
          />
        </Box>
      ))}
    </StyledGroups>
  )
}

export default Groups
