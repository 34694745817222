import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { memo, useCallback } from 'react'
import { AlertOctagon, Eye, EyeOff } from 'react-feather'
import { margin } from 'polished'
import Box from '@mui/material/Box'
import EyeDisabled from 'hss/images/indicators/eye-disabled.svg'
import {
  ABILITY_CONTENT_RESTRICTION,
  CONTENT_RESTRICTION_TYPE_CENSORED,
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  CONTENT_RESTRICTION_TYPE_NONE,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { contentShape } from 'core/shapes'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import actionTypes from 'reducers/actionTypes'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import MenuButton from 'common/menus/MenuButton'
import { get } from 'fp/objects'
import { labels as contentTypeLabels } from 'hss/ContentBuilder/consts'
import ContentInsertionRemoval from './ContentInsertionRemoval'
import ContentInsertionSectionRemovalMenuItem from './ContentInsertionSectionRemovalMenuItem'

export const fallback = CONTENT_RESTRICTION_TYPE_NONE
const CONTENT_RESTRICTION_TYPE_MIXED_CHILDREN = 'mixed-children'

export const labels = {
  [CONTENT_RESTRICTION_TYPE_CENSORED]: {
    label: 'Disabled for teachers and students',
    description: 'This content is not available to teachers or students.',
  },
  [CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]: {
    label: 'Enabled for teachers, hidden for students',
    description: 'This content is hidden by default and must be enabled for students.',
  },
  [CONTENT_RESTRICTION_TYPE_NONE]: {
    label: 'Enabled for teachers and students',
    description: 'This content is available to teachers and students.',
  },
  [CONTENT_RESTRICTION_TYPE_MIXED_CHILDREN]: {
    label: 'Some subsections are hidden',
    description: 'Some subsections are hidden.',
  },
}

export const Icons = {
  [CONTENT_RESTRICTION_TYPE_CENSORED]: <EyeDisabled />,
  [CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]: <EyeOff />,
  [CONTENT_RESTRICTION_TYPE_NONE]: <Eye />,
  [CONTENT_RESTRICTION_TYPE_MIXED_CHILDREN]: <AlertOctagon />,
}

const AllOrNothing = ({ checked: initiallyChecked, content, onChange }) => {
  const [checked, setChecked] = useStateWithDynamicDefault(initiallyChecked)

  return (
    <SwitchFormControl
      checked={checked}
      color="secondary"
      label={`Enable ${contentTypeLabels[content.contentType]}`}
      onChange={() => {
        setChecked(!checked)
        onChange(checked
          ? CONTENT_RESTRICTION_TYPE_CENSORED
          : CONTENT_RESTRICTION_TYPE_NONE)
      }}
      style={{ width: 'unset', marginTop: 0 }}
    />
  )
}

AllOrNothing.propTypes = {
  checked: PropTypes.bool.isRequired,
  content: contentShape.isRequired,
  onChange: PropTypes.func.isRequired,
}

const SelectiveRestriction = memo(({ content, onChange, type: initialType }) => {
  const [type, setType] = useStateWithDynamicDefault(initialType)

  const iconButtonMode = [
    CONTENT_TYPE_SECTION,
    CONTENT_TYPE_SUBSECTION,
    CONTENT_TYPE_ECHO,
  ].includes(content.contentType)

  const isEcho = content.contentType === CONTENT_TYPE_ECHO

  const handleClick = ({ target }) => {
    const { value } = target.attributes['data-value']
    setType(value)
    onChange(value)
  }

  if (content.insertionData) {
    return <ContentInsertionRemoval content={content} />
  }

  return iconButtonMode
    ? (
      <MenuButton
        color={isEcho ? 'primary' : 'secondary'}
        data-bodyvariant={isEcho ? 'body2' : 'body1'}
        icon={Icons[type]}
        label={labels[type].description}
        sx={{ mr: 1 }}
      >
        <Box sx={{ 'li svg': { marginRight: margin(8) } }}>
          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_NONE}
            onClick={handleClick}
          >
            {Icons[CONTENT_RESTRICTION_TYPE_NONE]}
            {labels[CONTENT_RESTRICTION_TYPE_NONE].label}
          </MenuItem>

          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT}
            onClick={handleClick}
          >
            {Icons[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT]}
            {labels[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT].label}
          </MenuItem>

          <MenuItem
            data-value={CONTENT_RESTRICTION_TYPE_CENSORED}
            onClick={handleClick}
          >
            {Icons[CONTENT_RESTRICTION_TYPE_CENSORED]}
            {labels[CONTENT_RESTRICTION_TYPE_CENSORED].label}
          </MenuItem>

          <ContentInsertionSectionRemovalMenuItem content={content} />
        </Box>
      </MenuButton>
    )
    : (
      <Select
        // aria-description={labels[type].description}
        aria-label="content restrictions"
        onChange={({ target }) => {
          setType(target.value)
          onChange(target.value)
        }}
        style={{ minWidth: 170 }}
        value={type}
      >
        <MenuItem value={CONTENT_RESTRICTION_TYPE_NONE}>
          {labels[CONTENT_RESTRICTION_TYPE_NONE].label}
        </MenuItem>
        <MenuItem value={CONTENT_RESTRICTION_TYPE_CENSORED}>
          {labels[CONTENT_RESTRICTION_TYPE_CENSORED].label}
        </MenuItem>
        <MenuItem value={CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT}>
          {labels[CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT].label}
        </MenuItem>
      </Select>
    )
})

SelectiveRestriction.propTypes = {
  content: contentShape.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    CONTENT_RESTRICTION_TYPE_CENSORED,
    CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
    CONTENT_RESTRICTION_TYPE_NONE,
  ]).isRequired,
}

const ContentRestrictionControls = withAbilityCheck(({ content }) => {
  const { contentType, id: contentId } = content
  const type = get('contentRestriction.type', { fallback })(content)

  const allOrNothingMode = [
    CONTENT_TYPE_COURSE,
    CONTENT_TYPE_CHAPTER,
  ].includes(contentType)

  const dispatch = useDispatch()

  const handleChange = useCallback((newType) => {
    const { id } = content.contentRestriction || {}

    dispatch({
      type: actionTypes.CONTENT_RESTRICTION_ALTERATION,
      payload: {
        contentId,
        contentRestrictionId: id,
        contentType,
        type: newType,
      },
    })
  }, [content.contentRestriction, contentId, contentType, dispatch])

  return allOrNothingMode
    ? (
      <AllOrNothing
        checked={type !== CONTENT_RESTRICTION_TYPE_CENSORED}
        content={content}
        onChange={handleChange}
      />
    )
    : (
      <SelectiveRestriction
        content={content}
        onChange={handleChange}
        type={type}
      />
    )
}, ABILITY_CONTENT_RESTRICTION)

ContentRestrictionControls.propTypes = {
  content: contentShape.isRequired,
}

export default ContentRestrictionControls
