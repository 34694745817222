import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { matches } from 'fp/utils'
import { getFeaturesForEcho } from 'selectors/contentViewer'
import { get } from 'fp/objects'
import { gradingContentContext } from 'hss/ContentViewer/Chapter/Grading/GradingContentProvider'

const useConfig = ({ feature }) => {
  const { subsection } = useContext(contentViewerContext) || {}
  const { content: gradingContent } = useContext(gradingContentContext) || {}
  const echo = gradingContent || subsection

  const contentIds = (echo?.children || [])
    .filter(matches('contentType', CONTENT_TYPE_INTERACTIVE))
    .map(get('id'))

  const features = useSelector(getFeaturesForEcho({ contentIds }))

  return features[feature] || {}
}

export default useConfig
