import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import AssignmentStatusFilter from 'hss/views/Search/Filters/Controls/AssignmentStatusFilter'
import StatusLegend from 'hss/views/Search/Filters/Controls/StatusLegend'
import Filters from './Filters'
import HeroActions from './HeroActions'
import Actions from './Actions'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'deadline',
    desc: false,
  },
]

const Assignments = () => (

  <CommonTableSearch
    initialSorting={initialSorting}
    renderActions={() => <Actions />}
    renderHeroActions={() => <HeroActions />}
    renderPrimaryFilter={() => <Filters />}
    renderRightSideControls={() => <StatusLegend />}
    renderSearchControls={() => <AssignmentStatusFilter />}
    title="Assignments"
  />

)

const Searchable = withSearchProvider(Assignments)

export default Searchable
