import PropTypes from 'prop-types'
import { Edit as Icon } from 'react-feather'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { contentShape } from 'core/shapes'
import withAbilityCheck from 'hoc/withAbilityCheck'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
} from 'core/consts'
import { contentBuilderUrl } from 'routing/consts'
import withProps from 'hoc/withProps'
import useLocalSetting from 'hooks/useLocalSetting'

const NewTabLink = withProps('a', { rel: 'opener', target: '_blank', style: { zIndex: 2112 } })

export const getUrlForContentEditor = ({ content = {}, isTe = false }) => {
  const { contentSubType, contentType, id } = content

  switch (contentType) {
    case CONTENT_TYPE_BLOCK:
    case CONTENT_TYPE_CHAPTER:
    case CONTENT_TYPE_COURSE:
    case CONTENT_TYPE_ECHO:
    case CONTENT_TYPE_PAGE:
    case CONTENT_TYPE_PAGESET:
    case CONTENT_TYPE_SCAFFOLD:
    case CONTENT_TYPE_SECTION:
    case CONTENT_TYPE_SOURCE:
    case CONTENT_TYPE_SUBSECTION:
    case CONTENT_TYPE_UNIT:
    case CONTENT_TYPE_VOCABULARY:
      return `${contentBuilderUrl}/curriculum/${contentType}/${id}${isTe ? '?type=te' : ''}`
    case CONTENT_TYPE_RUBRIC:
      return `${contentBuilderUrl}/${contentType}/${id}`
    case CONTENT_TYPE_INTERACTIVE:
      return `${contentBuilderUrl}/interactive/${contentSubType}/${id}`
    default:
      return null
  }
}

const Positioned = styled(
  withAbilityCheck('div', ABILITY_CONTENT_CREATION),
  { name: 'Block-LinkToEditor' },
)(({ theme: { mixins: { important }, palette, zIndex } }) => ({
  position: 'absolute',
  right: 10,
  top: 10,
  backgroundColor: palette.background.default,
  a: {
    color: important(palette.error.main),
    lineHeight: 1,
  },
  zIndex: zIndex.fab,
}))

const Link = props => (
  <Positioned>
    <NewTabLink
      {...props}
      tabIndex={-1}
    >
      <Tooltip title="Edit in new tab">
        <Icon />
      </Tooltip>
    </NewTabLink>
  </Positioned>
)

const LinkToEditor = (props) => {
  const [hideLinkToEditor] = useLocalSetting('hideLinkToEditor', false)
  if (hideLinkToEditor) {
    return null
  }

  const { content: { contentSubType, contentType } = {} } = props
  const isRollup = contentSubType?.endsWith('-rollup')

  if (isRollup) {
    return null
  }

  const url = getUrlForContentEditor(props)

  switch (contentType) {
    // Only linking a limited subset here
    case CONTENT_TYPE_BLOCK:
    case CONTENT_TYPE_INTERACTIVE:
    case CONTENT_TYPE_SCAFFOLD:
    case CONTENT_TYPE_VOCABULARY:
      return <Link href={url} />

    default:
      return null
  }
}

LinkToEditor.propTypes = {
  content: contentShape,
  // eslint-disable-next-line react/no-unused-prop-types
  isTe: PropTypes.bool,
}

export default LinkToEditor
