import MenuItem from '@mui/material/MenuItem'
import MoreIcon from '@mui/icons-material/MoreVert'
import MenuButton from 'common/menus/MenuButton'

const ActionLibrary = () => (
  <MenuButton
    data-bodyvariant="body1"
    icon={<MoreIcon />}
    label="Actions"
    variant="tertiary"
  >
    <MenuItem onClick={f => f}>TODO:</MenuItem>
  </MenuButton>
)

export default ActionLibrary
