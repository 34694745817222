import { useContext } from 'react'
import QuestionPrompt from 'hss/sections/contentBlocks/Interactive/QuestionPrompt'
import { QUESTION_PROMPT_TYPE_PROMPT } from 'core/consts'
import { interactiveContext } from './InteractiveProvider'

/**
 * Default is QUESTION_PROMPT_TYPE_PROMPT -- almost every interactive utilizing this HOC has their Question/Prompt data
 * tied to the 'prompt' key in 'interactiveData'.
 * Known outliers are:
 *   - LinearScale and ShortAnswer, which use 'question'
 *   - BaseMultipleChoice, which uses 'questionHtml'
 * When using this HOC, if the data you're targeting is not within 'prompt', please pass the appropriate type const
 */
const withQuestionPrompt = (WrappedComponent, questionPromptKey = QUESTION_PROMPT_TYPE_PROMPT) => {
  const Enhanced = (props) => {
    const { interactiveData: { [questionPromptKey]: questionPrompt } } = useContext(interactiveContext)
    return (
      <>
        {Boolean(questionPrompt) && (
          <QuestionPrompt body={questionPrompt} />
        )}
        <WrappedComponent {...props} />
      </>
    )
  }
  return Enhanced
}

export default withQuestionPrompt
