import { useMemo } from 'react'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import { withStaticInteractiveProvider } from '../providerUtils'
import FeatureBlastStaticRenderer from '../FeatureBlast/FeatureBlastStaticRenderer'
import FeatureTalkBackStaticRenderer from '../FeatureTalkBack/FeatureTalkBackStaticRenderer'
import FeaturePictureThisStaticRenderer from '../FeaturePictureThis/ImageRenderer'

const FeatureBlast = withStaticInteractiveProvider(
  FeatureBlastStaticRenderer,
  ECHO_FEATURE_BLASTY_BLAST,
)

const FeaturePictureThis = withStaticInteractiveProvider(
  FeaturePictureThisStaticRenderer,
  ECHO_FEATURE_PICTURE_THIS,
)

const FeatureTalkBack = withStaticInteractiveProvider(
  FeatureTalkBackStaticRenderer,
  ECHO_FEATURE_TALK_BACK,
)

const useStaticRenderer = ({ responseType }) => {
  const result = useMemo(() => {
    switch (responseType) {
      case 'echo-text': return FeatureBlast
      case 'echo-picture': return FeaturePictureThis
      case 'echo-audio': return FeatureTalkBack
      default: return null
    }
  }, [responseType])

  return result
}

export default useStaticRenderer
