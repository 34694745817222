import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { memo, useState } from 'react'
import MenuList from '@mui/material/MenuList'
import Accordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { BookOpen, ChevronDown, ChevronUp } from 'react-feather'
import Chip from '@mui/material/Chip'
import Student from '@mui/icons-material/Face6'
import Staff from '@mui/icons-material/Person'
import PersonAdd from '@mui/icons-material/PersonAdd'
import { compose } from 'redux'
import Stack from '@mui/material/Stack'
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined'
import { isDevEnv, isPreviewEnv } from 'selectors/index'
import MenuItemWithTooltip from 'common/menus/MenuItemWithTooltip'
import withProps from 'hoc/withProps'
import { not } from 'fp/utils'
import { get, set } from 'fp/objects'
import { ROLE_STAFF, ROLE_STUDENT, ROLE_SYSTEM } from 'core/consts'

const System = withProps(PersonAdd, { style: { transform: 'scaleX(-1)' } })

const rotateGradient = rotation => gradient => gradient.replace(/([\d.]+)deg/, (match, p1) => `${(parseInt(p1, 10) + rotation) % 360}deg`)

const AccordionSummary = styled(
  MuiAccordionSummary,
  {
    name: 'Login-LogMeInQuik-AccordionSummary',
    shouldForwardProp: prop => !['background', 'backgroundRotation'].includes(prop),
  },
)(({ theme: { mixins: { important }, palette }, background, backgroundRotation }) => ({
  '[class*="-content"] p': {
    margin: important(0),
  },
  '&.Mui-expanded': {
    background: compose(
      rotateGradient(backgroundRotation),
      get(`${background}.gradient`),
    )(palette),
    color: get(`${background}.contrastText`)(palette),
    span: {
      color: get(`${background}.contrastText`)(palette),
    },
  },
  p: {
    display: 'inline-flex',
    div: { marginRight: 6 },
  },
}))

const AccordionDetails = styled(
  MuiAccordionDetails,
  { name: 'Login-LogMeInQuik-AccordionDetails' },
)({
  '.MuiTypography-body1-semibold': {
    display: 'flex',
    svg: {
      marginRight: 4,
    },
  },
  '.MuiTypography-footnote': {
    paddingLeft: 28,
    maxWidth: 400,
    whiteSpace: 'break-spaces',
  },
})

const Button = withProps(MuiButton, {
  sx: { fontSize: '1.2rem', textDecoration: 'none' },
  variant: 'mock-link',
})

const tree = [
  {
    role: ROLE_STUDENT,
    roleLabel: 'Students',
    members: [
      { label: 'Student 1', username: 'student1', password: 'student1' },
      { label: 'Student 2', username: 'student2', password: 'student2' },
      { label: 'Student 3', username: 'student3', password: 'student3' },
      { label: 'Student 4', username: 'student4', password: 'student4' },
    ].map(compose(
      set('tags', ['ROLE_STUDENT']),
      set('Icon', <Student />),
    )),
    background: 'emerald',
    backgroundRotation: 120,
  },
  {
    role: ROLE_STAFF,
    roleLabel: 'Staff',
    members: [
      { label: 'Teacher',
        username: 'teacher1',
        password: 'teacher1',
        tags: [
          'ROLE_STAFF',
        ] },
      { label: 'Principal',
        username: 'principal',
        password: 'principal',
        tags: [
          'ROLE_STAFF',
          'PRIVILEGE_SCHOOL_ADMIN',
          'curriculum',
          'reporting',

        ] },
      { label: 'District Admin',
        username: 'super',
        password: 'super',
        tags: [
          'ROLE_STAFF',
          'PRIVILEGE_DISTRICT_ADMIN',
          'curriculum',
          'reporting',
        ] },
    ].map(set('Icon', <Staff />)),
    background: 'violet',
    backgroundRotation: 120,
  },
  {
    role: ROLE_SYSTEM,
    roleLabel: 'System',
    members: [
      { label: 'Curriculum Manager',
        username: 'sysadmin',
        password: 'sysadmin',
        tags: [
          'ROLE_SYSTEM',
          'CURRICULUM_MANAGER',
        ] },
      { label: 'Curriculum Manager (w/super)',
        username: 'sysadmin2',
        password: 'sysadmin2',
        tags: [
          'ROLE_SYSTEM',
          'CURRICULUM_MANAGER',
          'SUPER',
        ] },
      { label: 'Content Editor',
        username: 'content-editor-sysadmin',
        password: 'content-editor-sysadmin',
        tags: [
          'ROLE_SYSTEM',
          'CONTENT_EDITOR',
          'SUPER',
        ] },
      { label: 'Content Creator',
        username: 'content-creator-sysadmin',
        password: 'content-creator-sysadmin',
        tags: [
          'ROLE_SYSTEM',
          'CONTENT-CREATOR',
          'SUPER',
        ] },
    ].map(set('Icon', <System />)),
    background: 'canary',
    backgroundRotation: 120,
  },
]

const LogMeInQuik = ({ logMeInQuikEngaged, setLogMeInQuikEngaged, setValue, submitRef, tabIndex }) => {
  const [selectedTab, setSelectedTab] = useState()

  const handleChange = expanded => (event, newExpanded) => {
    setSelectedTab(newExpanded ? expanded : false)
  }

  const shortcut = ({ username, password }) => {
    setValue('username', username)
    setValue('password', password)

    submitRef.current.focus()
    setTimeout(() => {
      submitRef.current.click()
    }, 100)
  }

  const openDocs = kind => () => {
    let url

    if (isDevEnv()) {
      url = kind === 'docs'
        ? 'http://localhost:9004'
        : 'http://localhost:9003'
    } else {
      // eslint-disable-next-line no-restricted-globals
      url = location.origin.replace('build-', `${kind}-build-`)
    }

    window.open(url, '_blank')
  }

  return (
    <>
      <Collapse
        collapsedSize={70}
        in={logMeInQuikEngaged}
      >
        <Button
          color="primary"
          endIcon={logMeInQuikEngaged ? <ChevronUp /> : <ChevronDown />}
          onClick={() => setLogMeInQuikEngaged(not)}
          style={{ marginTop: 20, marginBottom: 20, width: '100%', height: 50 }}
          variant="secondary"
        >
          <Typography variant="body1-semibold">
            LogMeInQuik©®™℠ (dev env only)
          </Typography>
        </Button>

        <Box
          marginBottom={5}
          maxHeight={500}
          minHeight={500}
        >

          {tree.map(({ role, roleLabel, members, background, backgroundRotation }) => (
            <Accordion
              elevation={0}
              expanded={selectedTab === role}
              key={role}
              onChange={handleChange(role)}
              variant="peer-review"
            >
              <AccordionSummary
                aria-controls={`${role}-content`}
                background={background}
                backgroundRotation={backgroundRotation}
                id={`${role}-header`}
                variant="peer-review"
              >
                <Typography component="div">
                  <Chip
                    label={members.length}
                    size="small"
                  />
                  {' '}
                  {roleLabel}
                </Typography>
              </AccordionSummary>

              <AccordionDetails variant="interactive-grading">
                <MenuList>
                  {members.map(({ label, username, password, Icon, tags = [] }) => (
                    <MenuItemWithTooltip
                      key={username}
                      onClick={() => shortcut({ username, password })}
                      tabIndex={tabIndex}
                      title={`Login as '${username}'`}
                      value={username}
                    >

                      <Typography variant="body1-semibold">
                        {Icon}
                        {label}
                      </Typography>

                      <Typography
                        component="div"
                        variant="footnote"
                      >
                        {tags.join(', ')}
                      </Typography>
                    </MenuItemWithTooltip>
                  ))}
                </MenuList>
              </AccordionDetails>
            </Accordion>
          ))}

        </Box>

      </Collapse>

      <Stack
        direction="row"
        gap={2}
        mt={2}
      >
        <MuiButton
          color="primary"
          onClick={openDocs('docs')}
          startIcon={<DescriptionOutlined />}
          style={{ width: '100%' }}
          variant="secondary"
        >
          View Docs
        </MuiButton>

        <MuiButton
          color="primary"
          onClick={openDocs('storybook')}
          startIcon={<BookOpen />}
          style={{ width: '100%' }}
          variant="secondary"
        >
          Open Storybook
        </MuiButton>
      </Stack>
      <Typography
        component="div"
        textAlign="center"
        variant="footnote"
      >
        documentation links will open new tabs
      </Typography>
    </>
  )
}

LogMeInQuik.propTypes = {
  setValue: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
  logMeInQuikEngaged: PropTypes.bool.isRequired,
  setLogMeInQuikEngaged: PropTypes.func.isRequired,
}

const ConditionalLogMeInQuik = memo((props) => {
  const available = isDevEnv() || isPreviewEnv()
  return available ? <LogMeInQuik {...props} /> : null
})

export default ConditionalLogMeInQuik
