import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { classroomUrl } from 'routing/consts'
import { get } from 'fp/objects'
import BreadcrumbsRenderer from '../BreadcrumbsRenderer'

const breadcrumbConfig = [
  {
    path: `${classroomUrl }/:contentType`,
    breadcrumb: get('match.params.contentType'),
  },
  {
    path: `${classroomUrl }/:contentType/:contentId/edit`,
    breadcrumb: 'Edit',
  },
]

const ClassroomEditBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(breadcrumbConfig, { disableDefaults: true })

  return (
    <BreadcrumbsRenderer breadcrumbs={breadcrumbs} />
  )
}

export default ClassroomEditBreadcrumbs
