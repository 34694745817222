import { compose } from 'redux'
import PropTypes from 'prop-types'
import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import { curryRight } from 'fp/utils'
import withFetchedItem from 'hoc/withFetchedItem'
import actionTypes from 'reducers/actionTypes'
import withSearchProvider from './searchConfig'
import Actions from './Actions'

const initialSorting = [
  {
    id: 'assignmentId',
    desc: false,
  },
]

const Report = ({ item }) => (
  <CommonTableSearch
    initialSorting={initialSorting}
    renderActions={() => <Actions />}
    title={`${item?.firstName} ${item?.lastName}`}
  />
)

Report.propTypes = { item: PropTypes.object.isRequired }

const ReportOnUser = compose(
  withSearchProvider,
  curryRight(withFetchedItem, {
    actionType: actionTypes.USER_FETCH,
  }),
)(Report)

export default ReportOnUser
