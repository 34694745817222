import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'
import { CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET } from 'core/consts'
import useContent from 'hooks/useContent'
import { entityIdShape } from 'core/shapes'

const TabRenderer = (props) => {
  const { contentType, id, value, ...rest } = props
  const content = useContent({ contentType, contentId: id })

  return (
    <Tab
      id={`timeline-tab-${value}`}
      label={content.name}
      value={value}
      variant="timeline-tab"
      {...rest}
    />
  )
}

TabRenderer.propTypes = {
  contentType: PropTypes.oneOf([CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET]).isRequired,
  id: entityIdShape.isRequired,
  value: PropTypes.number.isRequired,
}

const TimelineTabs = ({ children, ...rest }) => (
  <Tabs
    aria-label="Timeline Events"
    orientation="vertical"
    scrollButtons
    {...rest}
    data-variant="timeline"
    variant="scrollable"
  >
    {children.map(({ id, contentType, onChange }, index) => (
      <TabRenderer
        aria-controls={`timeline-tabpanel-${index}`}
        contentType={contentType}
        id={id}
        key={id}
        onChange={onChange}
        value={index}
      />
    ))}
  </Tabs>
)

TimelineTabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({
    contentType: PropTypes.oneOf([CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET]).isRequired,
    id: entityIdShape.isRequired,
  })).isRequired,
}

export default TimelineTabs
