import { useContext } from 'react'
import { compose } from 'redux'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import AudioPlayer from 'common/avclub/audio/AudioPlayer'
import { get } from 'fp/objects'

const FeatureTalkBackStaticRenderer = () => {
  const audioAssetUrl = compose(
    get('interaction.uploadsMap.audio.url'),
    useContext,
  )(interactiveContext)

  return (
    <AudioPlayer
      narrow
      url={audioAssetUrl}
    />
  )
}

export default FeatureTalkBackStaticRenderer
