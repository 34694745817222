import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useWatch } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import Dialog from 'common/dialogs/Dialog'
import Form from 'common/formControls/Form'
import HFSelect from 'common/formControls/selects/HFSelect'
import HFTextArea from 'common/formControls/textInputs/HFTextArea'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { isDefined, when } from 'fp/utils'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import useApiFromEffect from 'hooks/useApiFromEffect'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import { actions } from 'reducers/notifications'
import useCurrentUser from 'hooks/useCurrentUser'
import TextField from 'common/formControls/textInputs/TextField'
import { find } from 'fp/arrays'
import CourseSelect from './CourseSelect'

const topicHelpers = {
  contentFeedback: (
    <>
      If your feedback relates to existing content, please provide the following in your message:
      <ul>
        <li>Detailed description of the issue</li>
        <li>Course name</li>
        <li>Chapter name</li>
        <li>Ideally, the URL from the browser address bar while viewing that content</li>
      </ul>
    </>
  ),
  technicalIssue: (
    <>
      For technical issues, please include the following information.
      <ul>
        <li>The steps you took before you encountered the issue</li>
        <li>The type of device and browser you are using</li>
        <li>The address from your browser's address bar</li>
        <li>The error message, if any</li>
      </ul>
    </>
  ),
}

const FormFields = () => {
  const topic = useWatch({ name: 'topic' })
  const { user } = useCurrentUser()
  const { district, schools } = user

  return (
    <Stack spacing={6}>
      <TextField
        defaultValue={[user.firstName, user.lastName].join(' ')}
        disabled
        label="Your Name"
        name="name"
      />
      <HFTextField
        autoComplete="email"
        label="Your Email Address"
        name="emailAddress"
        required
        type="email"
      />

      {Boolean(district) && (
        <TextField
          defaultValue={district.name || ''}
          disabled
          label="School District"
          name="district"
          required
        />
      )}

      {schools?.length > 1
        ? (
          <HFSelect
            label="School Name"
            name="school"
            required
          >
            {schools.map(school => (<MenuItem value={school.name}>{school.name}</MenuItem>))}
          </HFSelect>

        )
        : schools?.length === 1
          ? (
            <TextField
              defaultValue={schools[0].name || ''}
              disabled
              label="School Name"
              name="schoolname"
              required
            />
          )
          : null}

      <CourseSelect
        label="Course"
        name="course"
      />

      <HFTextField
        label="Chapter Title"
        name="chapter"
      />

      <HFTextField
        label="Section Title"
        name="section"
      />

      <HFTextField
        label="Subsection Title"
        name="subsection"
      />

      <HFSelect
        name="topic"
        required
      >
        <MenuItem value="contentFeedback">Content Feedback</MenuItem>
        <MenuItem value="technicalIssue">Technical Issue</MenuItem>
        <MenuItem value="featureRequest">Feature Request</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </HFSelect>
      <HFTextArea
        FormHelperTextProps={{ component: 'div' }} // default is p, which cannot contain ul elements
        helperText={topicHelpers[topic]}
        name="message"
        required
      />
      <div
        style={{
          marginTop: 0,
        }}
      >
        <HFSwitch
          label="Receive a copy of your message"
          name="sendCopyToMailer"
        />
      </div>
    </Stack>
  )
}

const useSendContactForm = () => {
  const callApi = useApiFromEffect()
  const [isSent, setIsSent] = useState(false)

  const send = ({ payload }) => callApi({
    url: buildUrl(restEndpoint.contactForm),
    options: {
      body: payload,
      method: 'POST',
    },
  }).then(({ isRightValue: success }) => {
    when(success, setIsSent, true)
  })

  return ({
    send,
    isSent,
  })
}

/**
 * Unlike some of the answers for https://stackoverflow.com/q/46155,
 * this regex is not intended to be a 100% reliable test.
 * We're just auto-filling the email address field if
 * the username looks like it might be an email address.
 * If we guess wrong, no big deal.
 */
const maybeEmailAddressOrNull = str => isDefined(str) && /^\S+@\S+\.\S+$/.test(str) ? str : null

const ContactFormDialog = ({ onClose }) => {
  const { user: { email, username } } = useCurrentUser()
  const emailAddress = useMemo(
    () => find(maybeEmailAddressOrNull)([
      email,
      username,
    ]) || '',
    [email, username],
  )

  const { isSent, send } = useSendContactForm()
  const dispatch = useDispatch()
  useEffect(
    () => {
      if (isSent) {
        onClose()
        dispatch(actions.addAlert({ message: 'Message received—thank you!' }))
      }
    },
    [dispatch, isSent, onClose],
  )

  return (
    <Dialog
      onClose={onClose}
      open
      showCloseButton
    >
      <Form
        defaultValues={{ sendCopyToMailer: false, emailAddress }}
        fullWidth
        onSubmit={send}
        suppressRequiredLabel
      >
        <DialogTitle>Contact Us</DialogTitle>

        <DialogContent>
          <FormFields />
        </DialogContent>

        <DialogActions>
          <SubmitButton>Send</SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

ContactFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ContactFormDialog
