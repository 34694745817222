/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { compose } from 'redux'
import Tooltip from '@mui/material/Tooltip'
import { get } from 'fp/objects'
import { suffix, toInt } from 'fp/strings'
import { fallsWithin } from 'fp/numbers'
import ExternalLinkConfirm from 'common/navigation/links/ExternalLinkConfirm'
import Centered from 'common/layout/Centered'
import MapMarker from 'apps/common/indicators/MapMarker'
import { actionShape } from './utils'

const Action = (props) => {
  const { action, currentFrame } = props

  const style = useMemo(() => {
    const [x1, y1, x2, y2] = compose(
      a => a.map(suffix('%')),
      a => a.map(i => (i / 10)),
      a => a.map(toInt),
      s => s.split(','),
      get('loc'),
    )(action)

    return {
      display: fallsWithin(currentFrame, action.startFrame, action.endFrame)
        ? 'unset'
        : 'none',
      left: x1,
      top: y1,
      width: x2,
      height: y2,
    }
  }, [action, currentFrame])

  return (
    <Centered
      style={style}
      sx={{
        pointerEvents: 'initial',
        position: 'absolute',
        top: 0,
        svg: { transform: 'scale(1.5)' },
      }}
    >
      <ExternalLinkConfirm href={action.data}>
        <Tooltip title={action.data}>
          <MapMarker />
        </Tooltip>
      </ExternalLinkConfirm>
    </Centered>
  )
}

Action.propTypes = {
  action: actionShape.isRequired,
  currentFrame: PropTypes.number.isRequired,
}

export default Action
