/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import useComponentSize from 'hooks/useComponentSize'
import { assertRange } from 'fp/numbers'
import { when } from 'fp/utils'

const ImageContainer = styled(
  (props) => {
    const {
      centerCoord,
      className,
      imageAltText,
      imageHeight,
      imageUrl,
      imageWidth,
      mouseMode,
      resetView,
      setCenterCoord,
      setZoomLevel,
      zoomLevel,
    } = props

    const ref = useRef()
    const { width: containerWidth } = useComponentSize(ref)

    const [zl, setZl] = useState(0)
    const [zoomRatio, setZoomRatio] = useState(1)
    const [apparentWidth, setApparentWidth] = useState(1)
    const [containerHeight, setContainerHeight] = useState(1)

    useEffect(() => {
      setZl(zoomLevel + 1)
    }, [zoomLevel])

    useEffect(() => {
      setApparentWidth(containerWidth * zl)
    }, [containerWidth, zl])

    useEffect(() => {
      setZoomRatio(apparentWidth / imageWidth)
    }, [apparentWidth, imageWidth])

    useEffect(() => {
      setContainerHeight((containerWidth / imageWidth) * imageHeight)
    }, [containerWidth, imageHeight, imageWidth])

    const style = useMemo(() => {
      const left = (containerWidth / 2) - (centerCoord[0] * zoomRatio)
      const top = (containerHeight / 2) - (centerCoord[1] * zoomRatio)

      return {
        cursor: mouseMode === 'move'
          ? 'crosshair'
          : 'zoom-in',
        // position: 'absolute',
        width: apparentWidth,
        transform: `translate(${left}px, ${top}px)`,
        transition: 'all 300ms ease-in-out 0ms',
      }
    }, [apparentWidth, centerCoord, containerHeight, containerWidth, mouseMode, zoomRatio])

    const handleClick = useCallback(
      (event) => {
        event.preventDefault()

        const { currentTarget, pageX, pageY, type } = event

        const direction = type === 'contextmenu' ? -1 : 1

        const currentTargetRect = currentTarget.getBoundingClientRect()
        const clientX = pageX - currentTargetRect.left
        const clientY = pageY - currentTargetRect.top

        const xDist = clientX - (containerWidth / 2)
        const yDist = clientY - (containerHeight / 2)

        const relativeX = centerCoord[0] + (xDist / zoomRatio)
        const relativeY = centerCoord[1] + (yDist / zoomRatio)

        const newZoomLevel = assertRange(zoomLevel + direction, 0, 2)

        switch (mouseMode) {
          case 'zoom':
            setZoomLevel(newZoomLevel)
            when(!newZoomLevel, resetView)
            when(direction > 0, setCenterCoord, [relativeX, relativeY])
            break

          case 'move':
            when(!zoomLevel, resetView)
            when(zoomLevel, setCenterCoord, [relativeX, relativeY])
            break
          default:
        }
      },
      [
        centerCoord,
        containerHeight,
        containerWidth,
        mouseMode,
        resetView,
        setCenterCoord,
        setZoomLevel,
        zoomLevel,
        zoomRatio,
      ],
    )

    return (
      <figure
        className={className}
        onClick={handleClick}
        onContextMenu={handleClick}
        ref={ref}
        style={{ height: containerHeight || 1 }}
      >
        <img
          alt={imageAltText}
          draggable="false"
          src={imageUrl}
          style={style}
        />
      </figure>
    )
  },
  { name: 'ImageZoomy-ImageContainer' },
)(({ theme: { mixins: { borderS }, palette } }) => ({
  overflow: 'hidden',
  position: 'relative',
  ...borderS(palette.grey[3]),
}))

ImageContainer.propTypes = {
  centerCoord: PropTypes.arrayOf(PropTypes.number).isRequired,
  imageAltText: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageWidth: PropTypes.number.isRequired,
  mouseMode: PropTypes.oneOf(['move', 'zoom']).isRequired,
  resetView: PropTypes.func.isRequired,
  setCenterCoord: PropTypes.func.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
  zoomLevel: PropTypes.oneOf([0, 1, 2]).isRequired,
}

export default ImageContainer
