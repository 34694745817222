import { useContext, useMemo } from 'react'
import { compose } from 'redux'
import { fallbackTo } from 'fp/utils'
import { filter, first, map } from 'fp/arrays'
import { inspect } from 'core/store/search/squery'
import { context } from './SearchProvider'

const useSearchingText = () => {
  const {
    searchFields,
    squery,
  } = useContext(context) || {}

  const searchingText = useMemo(() => compose(
    fallbackTo(''),
    first,
    filter(Boolean),
    map(fieldName => inspect(squery)
      .get.where(fieldName).contains()),
    fallbackTo([]),
  )(searchFields), [searchFields, squery])

  return searchingText
}

export default useSearchingText
