import { useContext } from 'react'
import PropTypes from 'prop-types'
import { entityIdShape } from 'core/shapes'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { filterAnnotations } from './utils'
import HighlightRenderer from './HighlightRenderer'

const ReadOnlyAnnotations = ({ contentId, onClick }) => {
  const { annotations: allAnnotations = [] } = useContext(contentViewerContext) || {}
  const annotations = filterAnnotations(allAnnotations, contentId)

  if (!annotations.length) return null

  return (
    <>
      {annotations.map(annotation => (
        <HighlightRenderer
          annotation={annotation}
          key={annotation.data.id}
          onClick={onClick}
          readOnly
        />
      ))}
    </>
  )
}

ReadOnlyAnnotations.propTypes = {
  contentId: entityIdShape.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ReadOnlyAnnotations
