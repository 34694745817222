import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { forwardRef } from 'react'
import Link from 'common/navigation/links/Link'
import { getContentViewerUrl } from 'selectors/contentViewer'
import { contentShape } from 'core/shapes'

const LinkToContent = forwardRef(({ children, content: { id: contentId, contentType } }, ref) => {
  const to = useSelector(getContentViewerUrl({ contentId, contentType }))

  return (
    <Link
      ref={ref}
      style={{ textDecoration: 'none' }}
      to={to}
    >
      {children}
    </Link>
  )
})

LinkToContent.propTypes = {
  children: PropTypes.element.isRequired,
  content: contentShape.isRequired,
}

export default LinkToContent
