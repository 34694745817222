import { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import { ABILITY_ASSIGNMENT_CREATION, CONTENT_SUB_TYPE_STANDALONE, CONTENT_TYPE_ECHO } from 'core/consts'
import { actions } from 'reducers/content'
import { getStandaloneEchoes } from 'selectors/echo'
import Card from 'hss/views/Card'
import { noop } from 'fp/utils'
import { capitalize } from 'fp/strings'
import CardTitle from 'common/text/CardTitle'
import LinkButton from 'common/navigation/links/LinkButton'
import { contentShape } from 'core/shapes'
import { buildContentUrl } from 'projections/content'
import { getTeacherAssignmentCreateRoute } from 'routing/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import ContentCarousel from './ContentCarousel'

const CardContent = ({ content, content: { contentType, id, name } }) => {
  const isAssignmentCreator = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION)

  return (
    <Stack sx={{ fontWeight: 300 }}>
      <CardHeader
        title={(
          <CardTitle
            eyebrow={capitalize(contentType)}
            HeadlineProps={{ fontWeight: 300 }}
            title={name}
          />
        )}
      />
      <CardActions sx={{ marginTop: 4 }}>
        <LinkButton
          aria-label={`View echo, ${name}`}
          color="secondary"
          size="small"
          to={buildContentUrl()(content)}
          variant="secondary"
        >
          View Echo
        </LinkButton>
        {Boolean(isAssignmentCreator) && (
          <LinkButton
            color="secondary"
            size="small"
            to={getTeacherAssignmentCreateRoute({ contentType, id })}
            variant="primary"
          >
            Assign
          </LinkButton>
        )}
      </CardActions>
    </Stack>
  )
}

CardContent.propTypes = {
  content: contentShape.isRequired,
}
const CardRenderer = forwardRef(({ content }, ref) => (
  <Card
    content={content}
    contentCreator={false}
    ContentRenderer={CardContent}
    gridModeImageAspectRatio="16/9"
    handlePreview={noop}
    ref={ref}
    viewMode="grid"
  />
))

CardRenderer.propTypes = {
  content: contentShape.isRequired,
}

const Echoes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.fetchContentList({ contentType: CONTENT_TYPE_ECHO, contentSubType: CONTENT_SUB_TYPE_STANDALONE }))
  }, [dispatch])

  const echoes = useSelector(getStandaloneEchoes)

  return (
    <ContentCarousel
      ItemRenderer={CardRenderer}
      items={echoes.slice(0, 10)}
      subtitle="Modern day stories that reveal the past."
      title="Echoes."
    />
  )
}

export default Echoes
