import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import { alter, assert } from 'core/store/search/squery'
import { restEndpoint } from 'reducers/utils'
import {
  SimpleCell,
  SimplePercentageCell,
  asDetail,
} from 'common/tables/DataTable/Cells'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import DataTable from 'common/tables/DataTable'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { get } from 'fp/objects'
import {
  avgInteractiveScoreCell,
  completedDateCell,
  firstNameCell,
  lastNameCell,
  progressCell,
  scoreCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import ReopenUserAssignmentCell from './ReopenUserAssignmentCell'

const tableId = 'clAssignmentsReport'

const buildColumns = (has) => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  return [
    firstNameCell('user.firstName'),

    lastNameCell('user.lastName'),

    progressCell,

    scoreCell,

    asDetail(completedDateCell),

    asDetail(avgInteractiveScoreCell),

    postMVP && [
      SimplePercentageCell({ header: 'My Review',
        id: 'teacherReviewScore',
        // TODO: enable once the api allows sorting by calculated fields
        enableSorting: false }),

      // TODO: We're not currently tracking this. Is this really a requirement?
      // asDetail(SimpleRelativeTimeCell({
      //   header: 'Last Viewed',
      //   id: 'lastViewed',
      // })),
    ],

    asDetail(SimplePercentageCell({ header: 'Avg. Assessment Score', id: 'averageAssessmentScore' })),

    asDetail(SimplePercentageCell({ header: 'Avg. Peer Review', id: 'averagePeerReviewScore' })),

    SimpleCell({
      accessorFn: compose(
        hasDate => hasDate ? 'Submitted' : 'Not submitted',
        Boolean,
        get('submittedDate'),
      ),
      cell: ReopenUserAssignmentCell,
      disableFilters: true,
      header: 'Reopen', // At some point we'll rename to an "Actions" menu but for now there's only reopen
      id: 'actions',
      enableSorting: false,
    }),
  ]
}

const searchConfig = (has, limit) => assignmentId => ({

  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.modifier('addUser').is(true),
    alter.set.where('assignmentId').is(assignmentId),
  )(assert()),

  pluralLabel: 'Students',

  restEndpoint: restEndpoint.userAssignments,

  searchFields: ['user.firstName', 'user.lastName'],

  singleLabel: 'Student',

})

const withSearchProvider = (WrappedComponent) => {
  const Enhanced = (initialTestingState) => {
    const { assignmentId } = useParams()

    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)(assignmentId)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table"
      >
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
