import Box from '@mui/material/Box'
import { useContext } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { get } from 'fp/objects'
import { arraySequence, findMaxValue, findObj, incrementByKey } from 'fp/arrays'
import { increment } from 'fp/numbers'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Results = () => {
  const {
    interactionData,
    interactiveData: {
      marks = [
        { value: 1, label: 'Not Important' },
        { value: 10, label: 'Most Important' },
      ],
    },
    peerInteractions,
  } = useContext(interactiveContext)

  const { palette: { charting } } = useTheme()

  const dataPoints = arraySequence(Math.min(findMaxValue('value')(marks), 10))
    .map(increment)
    .map(i => ({ id: i, label: i, votes: 0 }))

  const results = peerInteractions
    .map(get('interactionData'))
    .concat([interactionData])
    .map(get('selectedValue'))
    .reduce(
      (acc, selectedValue) => incrementByKey(selectedValue, 'votes')(acc),
      dataPoints,
    )

  return (
    <Stack
      gap={4}
      my={6}
    >
      {Boolean(interactionData.selectedValue) && (
        <Box textAlign="center">
          My answer: {interactionData.selectedValue}
        </Box>
      )}

      <ResponsiveContainer
        height={350}
        width="95%"
      >
        <BarChart data={results}>
          <CartesianGrid
            strokeDasharray="3 3"
            strokeWidth={0.5}
          />
          <Tooltip />
          <XAxis
            dataKey="label"
            dy={12}
            stroke={charting.grey}
          />
          <YAxis
            domain={[0, dataMax => dataMax + 5]}
            dx={-12}
            stroke={charting.grey}
          />
          <Bar
            dataKey="votes"
            fill={charting.cobalt}
          >
            <LabelList
              dataKey={/* istanbul ignore next */({ label }) => get('label')(findObj('value', Number(label))(marks))}
              position="top"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  )
}

export default Results
