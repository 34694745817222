import { useContext } from 'react'
import Preview from 'hss/ContentBuilder/interactives/Form/Preview'
import { getRegisteredInteractives } from 'hss/sections/contentBlocks/Interactive/utils'
import { context } from 'hss/views/Search/SearchProvider'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'

const PreviewDialog = () => {
  const { selectedPreview, setSelectedPreview } = useContext(context)
  const { contentSubType, data, ...rest } = selectedPreview || {}
  const registeredInteractives = getRegisteredInteractives()

  const Interactive = selectedPreview?.contentType === CONTENT_TYPE_RUBRIC
    ? Rubric
    : registeredInteractives[contentSubType]

  const handleClose = () => {
    setSelectedPreview(null)
  }

  return selectedPreview ? (
    <Preview
      additionalInteractiveProps={{ contentSubType, ...rest, ...data }}
      Interactive={Interactive}
      onClose={handleClose}
      open
    />
  ) : null
}

PreviewDialog.propTypes = {}

export default PreviewDialog
