import PropTypes from 'prop-types'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import PanTool from '@mui/icons-material/PanTool'
import { Search } from 'react-feather'

const MouseMode = (props) => {
  const { mouseMode, setMouseMode, zoomLevel } = props
  const handleChange = (e, newMode) => {
    setMouseMode(newMode)
  }

  return (
    <ToggleButtonGroup
      aria-label="Mouse mode"
      color="secondary"
      exclusive
      onChange={handleChange}
      size="small"
      value={mouseMode}
      variant="overlay"
    >

      <ToggleButton
        aria-label="Mode: click to zoom"
        value="zoom"
      >
        <Tooltip title="Mode: click to zoom">
          <Search />
        </Tooltip>
      </ToggleButton>

      <ToggleButton
        aria-label="Mode: click to move"
        disabled={zoomLevel === 0}
        value="move"
      >
        <Tooltip title="Mode: click to move">
          <PanTool />
        </Tooltip>
      </ToggleButton>

    </ToggleButtonGroup>
  )
}

MouseMode.propTypes = {
  mouseMode: PropTypes.oneOf(['move', 'zoom']).isRequired,
  setMouseMode: PropTypes.func.isRequired,
  zoomLevel: PropTypes.oneOf([0, 1, 2]).isRequired,
}

export default MouseMode
