import { XCircle } from 'react-feather'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import CheckCircle from 'hss/images/indicators/check-closed-circle.svg'
import Centered from 'common/layout/Centered'
import Popper from 'common/indicators/Popper'

const StatusLegend = () => (
  <Popper disableCloseButton>
    <Popper.Control>
      <Button>
        Legend
      </Button>
    </Popper.Control>
    <Popper.Content>
      <Stack gap={1}>
        <Centered><Typography variant="list-header">Status Icons</Typography></Centered>
        <List variant="horizontal">
          <ListItem>
            <Stack
              direction="row"
              gap={1}
            >
              <Icon label="check mark"><CheckCircle /></Icon>
              Active
            </Stack>
          </ListItem>
          <ListItem>
            <Stack
              direction="row"
              gap={1}
            >
              <Icon label="X icon"><XCircle /></Icon>
              Inactive
            </Stack>
          </ListItem>
        </List>
      </Stack>
    </Popper.Content>
  </Popper>
)

export default StatusLegend
