import { Fragment, useContext, useEffect, useMemo } from 'react'
import { compose } from 'redux'
import Box from '@mui/material/Box'
import { keyframes } from '@mui/styled-engine'
import cl from 'classnames'
import { find } from 'fp/arrays'
import { get } from 'fp/objects'
import ScrollToTop from 'common/navigation/ScrollToTop'
import Annotatable from 'common/annotations/Annotatable'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import RouteTransition from 'routing/RouteTransition'
import AudioPlayer from 'common/avclub/audio/AudioPlayer'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import LearnBanner from '../../Banner/LearnBanner'
import { ContentViewerProvider, contentViewerContext } from '../../ContentViewerProvider'
import Footnotes from './Footnotes'
import Navigation from './Navigation'
import Hero from './Hero'
import ContentBlocks from './ContentBlocks'
import SubsectionHeadline from './SubsectionHeadline'

const slideInUp = keyframes`
  0% {
    transform: translateY(78px);
  }
  100% {
    transform: translateY(0);
  }
`

const slideOutDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(78px);
  }
`

const Subsection = () => {
  const {
    allowAnnotations,
    audioClosing,
    audioTrackEnabled,
    blockBundles: blocks,
    setAudioAvailable,
    subsection,
  } = useContext(contentViewerContext)
  const contextContentId = subsection?.id

  const audio = useMemo(() => {
    const audioProp = get('block.uploadsMap.audio')

    return compose(
      audioProp,
      find(audioProp),
    )(blocks)
  }, [blocks])

  useEffect(() => {
    setAudioAvailable(Boolean(audio))
  }, [audio, setAudioAvailable])

  // Don't use RouteTransition for pinned content, otherwise it would
  // appear to reload every time you navigate between pages on the left side.
  const isPinned = useIsPinnedContent()
  const MaybeRouteTransition = isPinned ? Fragment : RouteTransition

  return contextContentId
    ? (
      <>

        <LearnBanner />

        {!isPinned && <ScrollToTop querySelector=".layout-pane-primary" />}

        <Annotatable
          contextContentId={contextContentId}
          disabled={!allowAnnotations}
          style={{ minHeight: '75vh' }}
        >

          <MaybeRouteTransition>

            <Hero />

            <SubsectionHeadline />

            <HeadlineLevelOffset>

              <ContentBlocks />

              <Footnotes />

            </HeadlineLevelOffset>

          </MaybeRouteTransition>

        </Annotatable>

        <Navigation />

        {Boolean(audio?.url && audioTrackEnabled) && (
          <Box
            className={cl({ closing: audioClosing })}
            sx={{
              animation: `${slideInUp} 0.5s`,
              backgroundColor: 'background.default',
              position: 'sticky',
              bottom: 0,
              zIndex: 'appBar',
              '&.closing': {
                animation: `${slideOutDown} 0.5s`,
              },
            }}
          >
            <AudioPlayer
              autoplay
              url={audio.url}
            />
          </Box>
        )}

      </>
    )
    : null
}

/**
 * NOTE:
 * IMPORTANT:
 *
 * The term 'subsection' has gotten stretched a bit as the app has evolved.
 *
 * Generally when we say subsection we're still referring to an actual piece
 * of content of type "subsection" but we could also be referring to an echo
 * or a source.
 *
 * Single page sources are also treated as subsections, however multi-page ones
 * are more akin to sections, with their children being the actual subsections.
 *
 * In all cases, they are wrapped with ContentViewerProvider.
 *
 * Subsections, Echoes and single page Sources all have their own separate
 * renderers, however multi-page sources are rendered by the Subsection renderer.
 */
const WithProvider = () => (
  <ContentViewerProvider>
    <Subsection />
  </ContentViewerProvider>
)

export default WithProvider
