import PropTypes from 'prop-types'
import { createElement, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import { compose } from 'redux'
import { useTheme } from '@mui/material/styles'
import { filter, map, reduce, xDifference } from 'fp/arrays'
import { equals, filterKeyedObject, get, set } from 'fp/objects'
import { curryRight, fallbackTo, identity, pipe } from 'fp/utils'
import Checkbox from 'common/formControls/switches/Checkbox'
import withProps from 'hoc/withProps'
import { componentShape } from 'core/shapes'
import Html from 'common/text/Html'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import Explanation from './Explanation'
import BaseMultipleChoice from './BaseMultipleChoice'
import AnswerChoiceBox from './AnswerChoiceBox'
import { getCorrectAnswerChoiceIds } from './utils'

const arrayToObjectKeys = compose(
  reduce((result, id) => set(id, true)(result), {}),
  fallbackTo([]),
)

const onlyTruthyKeys = compose(
  Object.keys,
  curryRight(filterKeyedObject, identity),
)

const ItemRenderer = ({
  checked = false,
  disabled = false,
  explanation,
  isCorrect = false,
  label,
  selectedAnswersAreCorrect = false,
  ...rest
}) => {
  const itemIsAnsweredCorrectly = checked === isCorrect
  const {
    completed,
    scoreData: { hidden: isScoreHidden } = {},
  } = useContext(interactiveContext)

  const showAnswers = useIsInAnswerKeyContext() || (completed && !isScoreHidden)

  const { palette, shadows } = useTheme()

  const selectedBoxShadow = { boxShadow: shadows.border, color: palette.boxshadowPrimary }

  const noBoxShadow = { boxShadow: 'none' }

  const barColor = showAnswers
    ? selectedAnswersAreCorrect && checked
      ? 'success.main'
      : itemIsAnsweredCorrectly
        ? 'transparent'
        : 'error.main'
    : 'transparent'

  return (
    <AnswerChoiceBox
      barColor={barColor}
      style={
        showAnswers
          ? { ...noBoxShadow, pointerEvents: 'none' }
          : checked
            ? selectedBoxShadow
            : disabled
              ? { pointerEvents: 'none' }
              : {}
      }
    >
      <Checkbox
        checked={checked}
        description={showAnswers
          ? (
            <Explanation
              explanation={explanation}
              isCorrectAnswer={isCorrect}
            />
          )
          : null}
        disabled={disabled}
        {...rest}
        label={(
          <Box
            color="grey.0"
            component="span"
          >
            <Html
              body={label}
              substituteInlineBlocks
            />
          </Box>
        )}
      />
    </AnswerChoiceBox>
  )
}

ItemRenderer.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  explanation: PropTypes.string,
  isCorrect: PropTypes.bool,
  label: componentShape.isRequired,
  selectedAnswersAreCorrect: PropTypes.bool,
}

const CheckboxGroup = ({
  answerChoiceStates,
  handleChange,
  limitReached = false,
  options,
  selectedAnswersAreCorrect = false,
}) => (
  <FormGroup>
    {options.map((option) => {
      const { id } = option
      return createElement(ItemRenderer, {
        ...option,
        checked: compose(Boolean, get(String(id)))(answerChoiceStates),
        disabled: limitReached && !compose(Boolean, get(String(id)))(answerChoiceStates),
        id: String(id),
        key: id,
        onChange: () => handleChange(id),
        selectedAnswersAreCorrect,
      })
    })}
  </FormGroup>
)

CheckboxGroup.propTypes = {
  answerChoiceStates: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  limitReached: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectedAnswersAreCorrect: PropTypes.bool,
}

const MultiSelect = () => {
  const {
    interactionData: { selectedAnswerChoiceIds: userSelectedAnswerChoiceIds },
    interactiveData,
    interactiveData: { answerChoices = [], limitSelectionsTo },
    onInteract,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const showAnswerKey = useIsInAnswerKeyContext()
  const selectedAnswerChoiceIds = useMemo(
    () => showAnswerKey ? getCorrectAnswerChoiceIds(interactiveData) : userSelectedAnswerChoiceIds,
    [interactiveData, showAnswerKey, userSelectedAnswerChoiceIds],
  )

  const [answerChoiceStates, setAnswerChoiceStates] = useState({})
  useLayoutEffect(
    () => { setAnswerChoiceStates(arrayToObjectKeys(selectedAnswerChoiceIds)) },
    [selectedAnswerChoiceIds],
  )
  const answered = Boolean(selectedAnswerChoiceIds?.length && onlyTruthyKeys(answerChoiceStates).length)

  const handleChange = useCallback((answerChoiceId) => {
    const newAnswerChoiceStates = set(answerChoiceId, !answerChoiceStates[answerChoiceId])(answerChoiceStates)
    setAnswerChoiceStates(newAnswerChoiceStates)
    onInteract({
      selectedAnswerChoiceIds: onlyTruthyKeys(newAnswerChoiceStates),
    })
  }, [answerChoiceStates, onInteract])

  const limitReached = !!limitSelectionsTo && onlyTruthyKeys(answerChoiceStates).length >= limitSelectionsTo

  useEffect(() => {
    setSubmitEnabled(answered)
  }, [answered, setSubmitEnabled])

  const selectedAnswersAreCorrect = pipe(
    filter(get('isCorrect')),
    map(get('id')),
    map(String),
    xDifference(selectedAnswerChoiceIds),
    get('length'),
    equals(0),
  )(answerChoices)

  const GroupRenderer = useMemo(
    () => withProps(CheckboxGroup, {
      answerChoiceStates,
      handleChange,
      limitReached,
      selectedAnswersAreCorrect,
    }),
    [answerChoiceStates, handleChange, limitReached, selectedAnswersAreCorrect],
  )

  return (
    <BaseMultipleChoice GroupRenderer={GroupRenderer} />
  )
}

export default MultiSelect
