import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { useContext, useEffect } from 'react'
import { arraySequence } from 'fp/arrays'
import { ordinalName, titleCase } from 'fp/strings'
import TextField from 'common/formControls/textInputs/TextField'
import { BUSY } from 'hooks/useReduxCallback'
import { when } from 'fp/utils'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const InputLabelProps = { shrink: true, margin: 'dense' }

const TextFields = ({ numPhrases, phrases, previewing = false, onChange }) => {
  const { isGrading, setSubmitEnabled, submitEnabled, transportStatus } = useContext(interactiveContext)

  const transportBusy = transportStatus === BUSY

  useEffect(() => {
    when(transportBusy && submitEnabled, setSubmitEnabled, false)
    when(!transportBusy && !submitEnabled, setSubmitEnabled, true)
  }, [setSubmitEnabled, submitEnabled, transportBusy])

  return (
    <List>
      {arraySequence(numPhrases)
        .map(i => (
          <li key={`phrase${i}`}>
            <TextField
              disabled={isGrading}
              InputLabelProps={InputLabelProps}
              inputProps={{
                'data-cardinal': i,
              }}
              label={
                numPhrases === 1
                  ? 'Word or phrase'
                  : titleCase(`${ordinalName(i + 1) } choice`)
              }
              name={`phrase${i}`}
              onChange={onChange}
              required={!previewing}
              value={phrases?.[i] || ''}
            />
          </li>
        ))}
    </List>
  )
}

TextFields.propTypes = {
  numPhrases: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  phrases: PropTypes.array.isRequired,
  previewing: PropTypes.bool,
}

export default TextFields
