import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getQueryParams } from 'selectors/routing'

export const userAssignmentSelectionContext = createContext()

const UserAssignmentSelectionProvider = (props) => {
  const { initialTestingState: { userAssignmentId: testingUaId } = {} } = props || {}
  const { uaid: userAssignmentIdFromParams } = useSelector(getQueryParams)
  const [currentUserAssignmentId, setCurrentUserAssignmentId] = useState(testingUaId || userAssignmentIdFromParams)

  const value = useMemo(
    () => ({
      currentUserAssignmentId,
      setCurrentUserAssignmentId,
    }),
    [currentUserAssignmentId],
  )

  return (
    <userAssignmentSelectionContext.Provider
      value={value}
      {...props}
    />
  )
}

export default UserAssignmentSelectionProvider
