
import { compose } from 'redux'
import { paginatedImageSlides } from 'common/carousels/configurations'
import { alter, assert } from 'core/store/search/squery'
import useDataset from 'hooks/useDataset'
import { restEndpoint } from 'reducers/utils'
import ContentCarousel from './ContentCarousel'

const squery = compose(
  alter.set.modifier('recentlyViewedChapters').is(true),
  alter.set.limit(10),
  assert,
)()

const RecentlyVisited = () => {
  const { data } = useDataset({ restEndpoint: restEndpoint.content, squery })

  return (
    data?.length > 0 ? (
      <ContentCarousel
        configuration={paginatedImageSlides}
        items={data}
        subtitle="For the classroom"
        title="Recently Visited."
      />
    ) : null
  )
}

export default RecentlyVisited
