import PropTypes from 'prop-types'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip'
import { Crosshair } from 'react-feather'

const Reset = ({ resetView }) => (
  <ToggleButtonGroup
    aria-label="Mouse mode"
    color="secondary"
    exclusive
    onChange={resetView}
    size="small"
    value="na"
    variant="overlay"
  >
    <ToggleButton value="reset">
      <Tooltip title="Reset view">
        <Crosshair />
      </Tooltip>
    </ToggleButton>
  </ToggleButtonGroup>
)

Reset.propTypes = {
  resetView: PropTypes.func.isRequired,
}

export default Reset
