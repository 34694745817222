import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/system/Stack'

const Definition = (props) => {
  const { definitions, form, pronunciation, syllabification, word } = props
  return (
    <Stack alignItems="center">
      <Typography
        component="dfn"
        variant="definition"
      >
        {word}
      </Typography>
      <Stack
        alignItems="baseline"
        direction="row"
        gap={2}
        mb={3}
      >
        <Typography variant="word-form">
          {`(${form})`}
        </Typography>
        <Typography
          component="span"
          fontWeight={300}
          textTransform="none"
          variant="h3"
        >
          {syllabification} | {pronunciation}
        </Typography>
      </Stack>
      {definitions.length === 1
        ? definitions[0]
        : (
          <ol style={{ listStylePosition: 'inside', padding: 0, textAlign: 'center' }}>
            {definitions.map((definition, idx) => <li key={idx}>{definition}</li>)}
          </ol>
        )}

    </Stack>
  )
}

Definition.propTypes = {
  definitions: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: PropTypes.string.isRequired,
  pronunciation: PropTypes.string.isRequired,
  syllabification: PropTypes.string.isRequired,
  word: PropTypes.string.isRequired,
}

export default Definition
