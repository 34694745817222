import MenuItem from '@mui/material/MenuItem'
import { useCallback, useContext } from 'react'
import { set } from 'fp/objects'
import SwatchSelect, { BorderedSwatch } from 'common/formControls/selects/SwatchSelect'
import url from 'hss/images/indicators/image-filter-sample.jpg'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import AppliedImageFilters from './AppliedImageFilters'

const filters = [
  { name: 'none', label: 'None' },
  { name: 'ocean', label: 'Ocean' },
  { name: 'sepia', label: 'Sepia' },
]

const renderSwatch = ({ size, value }) => (
  <BorderedSwatch size={size}>
    <AppliedImageFilters
      className={value}
      url={url}
    />
  </BorderedSwatch>
)

const FilterSelection = () => {
  const {
    interactionData,
    onInteract,
  } = useContext(interactiveContext)

  const handleChange = useCallback(({ target }) => {
    onInteract(set('interactionData.filter', target.value))
  }, [onInteract])

  return (
    <SwatchSelect
      defaultValue="medium"
      label="Filter"
      name="filter"
      onChange={handleChange}
      renderSwatch={renderSwatch}
      value={interactionData.filter}
    >
      {filters.map(({ name, label }) => (
        <MenuItem
          key={name}
          value={name}
        >
          {label}
        </MenuItem>
      ))}
    </SwatchSelect>
  )
}

export default FilterSelection
