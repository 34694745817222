import { useContext, useMemo } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { unary } from 'fp/utils'
import { SCORING_MODE_GNS_GROUP_AND_SORT, SCORING_MODE_GNS_SORT_GIVEN_GROUPS, interactiveVariants } from 'hss/ContentBuilder/consts'
import StyledGroups from 'hss/sections/contentBlocks/interactives/GroupAndSort/StyledGroups'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { vocabColumns } from 'hss/sections/contentBlocks/interactives/GroupAndSort/context'
import { aggregateGroupAndSortResponses, aggregateGroupOnlyResponses } from 'projections/interactions'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { toKeyedObject } from 'fp/arrays'
import { get } from 'fp/objects'
import { interactiveContext } from '../../../InteractiveProvider'
import Group from './Group'
import SortableGroupContents from './SortableGroupContents'
import NonSortableGroupContents from './NonSortableGroupContents'

const scoringModesWhereOrderMatters = [
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
]

const GroupAndSortAggregate = () => {
  const {
    contentId,
    interactive: { scoring: { mode: scoringMode } },
    interactiveData: {
      groups: groupsFromInteractiveData,
      items: itemsFromInteractiveData,
      variant,
    },
  } = useContext(interactiveContext)

  const isVocab = variant === interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY
  const groups = isVocab ? vocabColumns : groupsFromInteractiveData
  const orderMatters = scoringModesWhereOrderMatters.includes(scoringMode)

  const { assignmentId } = useParams()
  const allInteractions = useSelector(getInteractionsForAssignmentContent({ contentId, assignmentId }))

  const aggregatedResponses = useMemo(
    () => {
      const aggregateResponses = orderMatters ? aggregateGroupAndSortResponses : aggregateGroupOnlyResponses
      return aggregateResponses(groups, allInteractions)
    },
    [allInteractions, groups, orderMatters],
  )

  const contentVocabPhrases = useChapterVocabPhrases({ contentId, contentType: CONTENT_TYPE_INTERACTIVE })
  const itemsById = useMemo(
    () => {
      const vocabItems = isVocab && contentVocabPhrases.map(({ id, name }) => ({
        id,
        cellText: name,
      }))
      const allItems = vocabItems || itemsFromInteractiveData
      return unary(toKeyedObject)(allItems)
    },
    [contentVocabPhrases, isVocab, itemsFromInteractiveData],
  )

  const GroupContents = orderMatters ? SortableGroupContents : NonSortableGroupContents
  return (
    <Stack gap={4}>
      <StyledGroups
        display="flex"
        width="100%"
      >
        {aggregatedResponses.map(group => (
          <Box key={group.id}>
            <Group {...group}>
              <GroupContents
                {...group}
                renderItem={(itemId, count) => (
                  <>
                    <Typography variant="body1-semibold">{count}</Typography>
                    <div>{get(`${itemId}.cellText`)(itemsById)}</div>
                  </>
                )}
              />
            </Group>
          </Box>
        ))}
      </StyledGroups>
    </Stack>
  )
}

export default GroupAndSortAggregate
