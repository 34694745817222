import { compose } from 'redux'
import PropTypes from 'prop-types'
import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import { curryRight } from 'fp/utils'
import withFetchedItem from 'hoc/withFetchedItem'
import actionTypes from 'reducers/actionTypes'
import withSearchProvider from './searchConfig'
import HeroActions from './HeroActions'
import AssignmentLaunchActions from './AssignmentLaunchActions'
import Actions from './Actions'

const initialSorting = [
  {
    id: 'user.lastName',
    desc: false,
  },
  {
    id: 'user.firstName',
    desc: false,
  },
]

const Report = ({ item }) => (
  <CommonTableSearch
    initialSorting={initialSorting}
    renderActions={() => <Actions />}
    renderHeroActions={() => <HeroActions assignment={item} />}
    title={item?.name}
  >

    <AssignmentLaunchActions assignment={item} />

  </CommonTableSearch>
)

Report.propTypes = { item: PropTypes.object.isRequired }

const ReportOnAssignment = compose(
  withSearchProvider,
  curryRight(withFetchedItem, {
    actionType: actionTypes.ASSIGNMENT_FETCH,
    includeScoringData: true,
  }),
)(Report)

export default ReportOnAssignment
