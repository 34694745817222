import { useContext } from 'react'
import { compose } from 'redux'
import { alter, inspect } from 'core/store/search/squery'
import SearchInput from 'common/formControls/textInputs/SearchInput'
import { context } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_ASSESSMENT, SEARCH_TYPE_LIBRARY } from '../consts'

const searchLabels = {
  [SEARCH_TYPE_ASSESSMENT]: 'Search for assessments',
  [SEARCH_TYPE_LIBRARY]: 'Search library items',
}

const SearchBar = () => {
  const { searchType, setSquery, squery } = useContext(context)

  const searchText = inspect(squery).get.modifier('keywordSearch').is('')
  const label = searchLabels[searchType]

  const handleSearch = (value) => {
    compose(
      setSquery,
      alter.set.offset(0),
      alter.set.modifier('keywordSearch').is(value),
    )(squery)
  }

  return label
    ? (
      <SearchInput
        label={label}
        onChange={handleSearch}
        value={searchText}
      />
    )
    : null
}

export default SearchBar
