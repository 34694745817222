import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userAlertDataShape } from 'core/shapes'
import { actions } from 'reducers/userAlerts'
import withTransitionSlide from 'hoc/withTransitionSlide'
import UserAlertContent from './UserAlertContent'

const ProfileUserAlert = ({ alert, containerRef, shouldAnimate = false }) => {
  const [confirmedId, setConfirmedId] = useState()
  const transitionTimeout = 300
  const dispatch = useDispatch()

  useEffect(() => {
    if (confirmedId) {
      // Allow time for the transition animation to complete before dispatching the confirmation
      setTimeout(() => {
        dispatch(actions.confirmUserAlert({ id: confirmedId }))
      }, transitionTimeout)
    }
  }, [confirmedId, dispatch])

  return (
    containerRef && shouldAnimate
      ? withTransitionSlide(UserAlertContent)({
        alert,
        setConfirmedId,
        shouldSlideIn: !confirmedId,
        showButton: true,
        containerRef,
      })
      : (
        <UserAlertContent
          alert={alert}
          confirmedId={confirmedId}
          containerRef={containerRef}
          setConfirmedId={setConfirmedId}
          showButton
        />
      )
  )
}

ProfileUserAlert.propTypes = {
  alert: userAlertDataShape.isRequired,
  containerRef: PropTypes.object, // containerRef is required only if shouldAnimate is true
  shouldAnimate: PropTypes.bool,
}

export default ProfileUserAlert
