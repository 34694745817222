import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { contentShape } from 'core/shapes'
import withContentList from 'hoc/withContentList'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import HFSelect from 'common/formControls/selects/HFSelect'
import { orderBy } from 'fp/arrays'

const CourseSelect = ({ items, contentType, ...rest }) => {
  const sortedCourses = orderBy('name')(items)

  return (
    <HFSelect {...rest}>
      {sortedCourses.map(course => (
        <MenuItem
          key={course.id}
          value={course.name}
        >
          {course.name}
        </MenuItem>
      ))}
    </HFSelect>
  )
}

CourseSelect.propTypes = {
  items: PropTypes.arrayOf(contentShape).isRequired,
  contentType: PropTypes.string, // Not used, but gets passed in from withContentList
}

const CourseSelectWithContent = withContentList(CourseSelect, { contentType: CONTENT_TYPE_COURSE })

export default CourseSelectWithContent
