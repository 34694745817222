import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getAssignmentById } from 'selectors/assignments'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { fallbackTo } from 'fp/utils'
import { isPastMaxSubmitDate } from 'hss/utils'

const withCanSubmitAssignment = (WrappedComponent) => {
  const Enhanced = (props) => {
    const { userAssignmentId } = useParams()

    const { assignmentId, submittedDate } = compose(
      fallbackTo({}),
      useSelector,
      getUserAssignmentById,
    )({ userAssignmentId })

    const assignment = compose(
      fallbackTo({}),
      useSelector,
      getAssignmentById,
    )({ assignmentId })

    const isPast = isPastMaxSubmitDate(assignment)

    const canSubmit = useMemo(
      () => userAssignmentId && !submittedDate && !isPast,
      [isPast, submittedDate, userAssignmentId],
    )

    return (
      <WrappedComponent
        {...props}
        canSubmit={canSubmit}
      />
    )
  }

  return Enhanced
}

export default withCanSubmitAssignment
