import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import Link from 'common/navigation/links/Link'
import {
  classroomAssignmentsUrl,
  studentsUrl,
} from 'routing/consts'
import StatsFilter from 'hss/views/Search/Filters/Controls/StatsFilter'
import ScoreFilter from 'hss/views/Search/Filters/Controls/ScoreFilter'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import HeroActions from './HeroActions'
import Filters from './Filters'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'target.name',
    desc: false,
  },
]

const Groups = () => {
  const has = useAbilityChecker()
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  return (

    <CommonTableSearch
      initialSorting={initialSorting}
      renderHeroActions={() => <HeroActions />}
      renderPrimaryFilter={() => <Filters />}
      renderSearchControls={postMVP
        ? () => (
          <>
            <ScoreFilter />
            <StatsFilter />
          </>
        )
        : null}
      title="Groups"
    >

      <p>
        Choose the desired group(s) or student(s) and a table will be
        populated below. This table presents comprehensive information,
        including grades, assignments, and overall performance. You can
        also visit the {' '}
        <Link to={classroomAssignmentsUrl}>groups</Link>
        {' '} or {' '}
        <Link to={studentsUrl}>students</Link> {' '}
        pages to receive similar data.
      </p>

    </CommonTableSearch>

  )
}

const Searchable = withSearchProvider(Groups)

export default Searchable
