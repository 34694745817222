import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { Link as LinkIcon } from 'react-feather'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import LineReaderIcon from 'hss/images/controls/menu/line-reader.svg'
import AnnotateViewIcon from 'hss/images/controls/menu/annotate-view.svg'
import NotebookIcon from 'hss/images/controls/menu/notebook.svg'
import { isStudent } from 'selectors/users'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useToggleState from 'hooks/useToggleState'
import { ABILITY_STUDENT_INTERFACE, TOGGLE_STATE_LINE_READER_VISIBLE } from 'core/consts'
import MenuItemButton from 'hss/ContentViewer/Chapter/Toolbar/MenuItemButton'
import useIsLargeEnoughForPinning from 'routing/TraverseRoute/useIsLargeEnoughForPinning'
import Dialog from 'common/dialogs/Dialog'
import Notebook from 'hss/sections/Assignments/Notebook'
import AllAnnotations from 'hss/sections/Assignments/Annotations/AllAnnotations'
import AnnotationsOnOffButton from './AnnotationsOnOffButton'

const StudentMenuItems = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isLargeEnoughForPinning = useIsLargeEnoughForPinning()
  const [showLineReader, toggleShowLineReader] = useToggleState(false, TOGGLE_STATE_LINE_READER_VISIBLE)
  const [showAnnotationsDialog, toggleShowAnnotationsDialog] = useToggleState(false)
  const [showNotebookDialog, toggleShowNotebookDialog] = useToggleState(false)
  // const [createNavigationLink, toggleCreateNavigationLink] = useToggleState(false)

  const searchParams = new URLSearchParams(location.search)
  const forStudent = useSelector(isStudent)
  const paneParam = searchParams.get('pane')
  const annotationsSelected = paneParam === 'annotations'
  const notebookSelected = paneParam === 'notebook'

  const handleClick = (pane) => {
    if ((pane === 'annotations' && annotationsSelected) || (pane === 'notebook' && notebookSelected)) {
      navigate(`${location.pathname}`)
    } else {
      navigate(`${location.pathname}?pane=${pane}`)
    }
  }

  const handleAnnotationsClick = () => {
    if (isLargeEnoughForPinning) {
      handleClick('annotations')
    } else {
      toggleShowAnnotationsDialog()
    }
  }

  const handleNotebookClick = () => {
    if (isLargeEnoughForPinning) {
      handleClick('notebook')
    } else {
      toggleShowNotebookDialog()
    }
  }

  return (
    <>
      <MenuItemButton
        ButtonIcon={LineReaderIcon}
        onClick={toggleShowLineReader}
        selected={showLineReader}
        text="Line Reader"
        value="linereader"
      />
      <AnnotationsOnOffButton />
      <MenuItemButton
        ButtonIcon={AnnotateViewIcon}
        onClick={handleAnnotationsClick}
        selected={annotationsSelected}
        text="All Annotations"
        value="annotations"
      />
      {Boolean(forStudent) && (
        <MenuItemButton
          ButtonIcon={NotebookIcon}
          onClick={handleNotebookClick}
          selected={notebookSelected}
          text="Notebook"
          value="notebook"
        />
      )}
      {Boolean(showAnnotationsDialog) && (
        <Dialog
          onClose={toggleShowAnnotationsDialog}
          open
          showCloseButton
          title="All Annotations"
        >
          <DialogContent>
            <AllAnnotations hideHeader />
          </DialogContent>
        </Dialog>
      )}
      {Boolean(showNotebookDialog) && (
        <Dialog
          onClose={toggleShowNotebookDialog}
          open
          showCloseButton
          title="Notebook"
        >
          <DialogContent>
            <Box
              mb={2}
              mt={1}
            >
              <Notebook hideHeader />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {/* TODO: Need to make this functional */}
      {/* <MenuToggleButton
        onClick={toggleCreateNavigationLink}
        selected={createNavigationLink}
        value="navigation-link"
      >
        <LinkIcon style={{ color: getColor(createNavigationLink) }} />
      </MenuToggleButton> */}
    </>
  )
}

const GatedStudentMenuItems = withAbilityCheck(StudentMenuItems, ABILITY_STUDENT_INTERFACE)
export default GatedStudentMenuItems
