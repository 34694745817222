import { useContext } from 'react'
import Grid from '@mui/material/Grid'
import Pagination from 'common/tables/DataTable/Pagination'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { context } from '../SearchProvider'

const Results = () => {
  const {
    ItemRenderer,
    dataset,
    setSelectedPreview,
    setSquery,
    squery,
    tableId,
    topOfSearchDomRef: scrollToResultsRef,
    viewMode,
  } = useContext(context)

  const handleClick = result => () => {
    setSelectedPreview(result)
  }
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)

  const oneRecord = dataset?.data.length === 1

  const gridSizes = viewMode === 'list'
    ? { xs: 12 }
    : oneRecord
      ? { xs: 12, md: 6, xl: 4 }
      : { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }

  return (
    <>
      <Grid
        container
        spacing={3}
      >

        {dataset?.data.map(content => (
          <Grid
            item
            key={content.id}
            {...gridSizes}
          >
            <ItemRenderer
              content={content}
              contentCreator={isContentCreator}
              handlePreview={handleClick(content)}
            />
          </Grid>
        ))}

      </Grid>

      <Pagination
        {...{
          tableId,
          dataset,
          setSquery,
          scrollToResultsRef,
          squery,
        }}
      />
    </>
  )
}

export default Results
