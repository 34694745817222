import MenuItem from '@mui/material/MenuItem'
import MenuButton from 'common/menus/MenuButton'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import BulkDeletionToolButton from 'hss/views/Search/TableControlsAndFilters/BulkDeletionToolButton'

const Actions = withAbilityCheck(() => {
  const disabled = false

  return (
    <>
      <BulkDeletionToolButton
        disabled={disabled}
        label="Remove Selected Assignments"
      />

      <MenuButton
        disabled={disabled}
        label="Bulk Actions"
        variant="default"
      >
        <MenuItem>TODO</MenuItem>
      </MenuButton>
    </>
  )
}, FEATURE_FLAG_POST_MVP)

export default Actions
