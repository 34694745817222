import { useSelector } from 'react-redux'
import Check from '@mui/icons-material/Check'
import Warning from 'common/layout/Warning'
import { isStaff } from 'selectors/users'
import Plagiarism from './Plagiarism'
import Options from './Options'

const Accepted = (props) => {
  const mentor = useSelector(isStaff)
  return (
    <>
      <Warning icon={<Check />}>You have accepted the current Turnitin EULA.</Warning>

      {Boolean(mentor) && (
        <>
          <Plagiarism {...props} />
          <Options {...props} />
        </>
      )}
    </>
  )
}

export default Accepted
