import ListItem from '@mui/material/ListItem'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useContext } from 'react'
import TableBody from '@mui/material/TableBody'
import { compose } from 'redux'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { maybeParseJSON } from 'fp/strings'
import { get } from 'fp/objects'
import Legend from '../Legend'
import { PacingOptionLabel, PacingOptionLabelCapitalized } from '../PacingOptionLabel'
import usePacingGuideTheme from '../usePacingGuideTheme'
import { defaultPacingOptions, pacingOptionKeys } from './consts'
import SegmentRow from './SegmentRow'

const PacingOptionTableCell = styled(TableCell)(({
  width: '10rem',
}))

const UnitPacingGuide = () => {
  const { pacingOptionHeaderStyle } = usePacingGuideTheme()
  const { interactiveData } = useContext(interactiveContext)

  const config = compose(maybeParseJSON, get('config'))(interactiveData)
  const pacingOptions = config.pacingOptions || defaultPacingOptions
  const { segments } = config

  return (
    <>
      <Legend
        mb={4}
        title="Pacing Options"
      >
        {pacingOptionKeys.map(key => (
          <ListItem key={key}>
            <PacingOptionLabelCapitalized>
              {pacingOptions[key].name}
            </PacingOptionLabelCapitalized>
            : {pacingOptions[key].description}
          </ListItem>
        ))}
      </Legend>

      <Table
        data-subvariant="unit"
        variant="pacing-guide"
      >
        <TableHead>
          <TableRow>
            <TableCell
              scope="col"
              width="25%"
            >
              Chapter
            </TableCell>
            <TableCell scope="col">
              Features
            </TableCell>
            {pacingOptionKeys.map((key, index) => (
              <PacingOptionTableCell
                className="pacing-option-header"
                key={key}
                scope="col"
                sx={pacingOptionHeaderStyle(index)}
              >
                <PacingOptionLabel>
                  {pacingOptions[key].name}
                </PacingOptionLabel>
              </PacingOptionTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {segments.map((segment, index) => (
            <SegmentRow
              key={index}
              segment={segment}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default UnitPacingGuide
