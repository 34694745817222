import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
import { styled } from '@mui/material/styles'
import { Fragment } from 'react'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import { componentShape, contentShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import FallbackImage from 'common/indicators/FallbackImage'
import { viewModeShape } from '../Search/consts'
import LinkToContent from '../Library/LinkToContent'

export const CARD_IMAGE_MAX_WIDTH = 376

const ImageCardMedia = styled(
  CardMedia,
  { name: 'image-card-media' },
)(() => ({
  width: '100%',
  height: 'unset',
}))

const PosterImage = (props) => {
  const {
    Wrapper = 'div',
    aspectRatio,
    content,
    includeLinkToContent,
    viewMode = 'list',
  } = props
  const { uploadsMap } = content

  const thumbImage = viewMode === 'grid'
    ? getImageUrlFromUploadsMap(uploadsMap, 'thumb')
    : null
  const image = thumbImage || getImageUrlFromUploadsMap(uploadsMap)

  const style = image
    ? { position: 'relative' }
    : {
      position: 'relative',
      maxWidth: viewMode === 'list' ? CARD_IMAGE_MAX_WIDTH : 'unset',
      width: '100%',
    }

  const MaybeLinkToContent = includeLinkToContent ? withProps(LinkToContent, { content }) : Fragment

  return (
    <Wrapper
      style={style}
      {...Wrapper === 'div' ? null : props}
    >
      <MaybeLinkToContent>
        <ImageCardMedia
          alt={includeLinkToContent ? content.name : ''}
          className="here"
          component={image ? 'img' : withProps(FallbackImage, { viewMode })}
          image={image}
          sx={{ aspectRatio }}
        />
      </MaybeLinkToContent>
    </Wrapper>
  )
}

PosterImage.propTypes = {
  aspectRatio: PropTypes.string.isRequired,
  content: contentShape.isRequired,
  includeLinkToContent: PropTypes.bool,
  viewMode: viewModeShape,
  Wrapper: componentShape,
}

export default PosterImage
