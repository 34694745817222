import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import MinimizeIconButton from 'styling/theming/base/components/MinimizeIconButton'
import ExpanderBackdrop from '../../Interactive/ExpanderBackdrop'

const ExpandedContainer = styled(
  'div',
  { name: 'Chart-ExpandedContainer' },
)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.paper,
  cursor: 'default',
  height: '100vh',
  width: '100vw',
  span: { textAlign: 'center', maxHeight: '10vh' },
  svg: { cursor: 'default' },
  '> .MuiStack-root': {
    height: '100%',
    '> span': { marginTop: 20 },
    '> div': { flex: 1 },
  },

}))

const MaybeExpanded = ({ expanded, onClick, children }) => expanded
  ? (
    <ExpanderBackdrop expanded>
      <MinimizeIconButton onClick={onClick} />
      <ExpandedContainer>
        {children}
      </ExpandedContainer>
    </ExpanderBackdrop>

  )
  : children

MaybeExpanded.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default MaybeExpanded
