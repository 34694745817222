import { ECHO_FEATURE_RATE } from 'hss/ContentBuilder/consts'
import FeatureHeadline from '../FeatureHeadline'
import FeatureFootnotes from '../FeatureFootnotes'
import Preamble from './Preamble'
import CurrentReview from './CurrentReview'
import AllReviews from './AllReviews'

const feature = ECHO_FEATURE_RATE

const FeatureRate = () => (
  <>

    <FeatureHeadline title="Rate">

      <Preamble />

      <CurrentReview />

      <AllReviews />

    </FeatureHeadline>

    <FeatureFootnotes feature={feature} />

  </>
)

export default FeatureRate
