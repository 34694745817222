import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { compose } from 'redux'
import { camelCaseToWords, capitalize } from 'fp/strings'
import { contentShape } from 'core/shapes'
import { labels } from 'hss/ContentBuilder/consts'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'

const labeler = compose(
  s => s === 'D B I' ? 'DBI' : s,
  capitalize,
  camelCaseToWords,
)

const CardLabel = ({ content, customType }) => {
  const {
    contentSubType,
    contentType,
    name,
  } = content

  const firstHalf = contentType === CONTENT_TYPE_CHAPTER
    ? content.data?.subtitle
    : labels[contentType]

  return (
    <>
      <Typography
        gutterBottom
        variant="small-semibold"
      >
        {name}
      </Typography>

      <Typography
        component="p"
        variant="small"
      >
        {firstHalf}
        {Boolean(firstHalf) && ' • '}
        {Boolean(contentSubType || customType) && `${labels[contentSubType] || labeler(customType)}`}
      </Typography>
    </>
  )
}

CardLabel.propTypes = {
  content: contentShape.isRequired,
  customType: PropTypes.string, // escape hatch for non-content items
}

export default CardLabel
