import { compose } from 'redux'
import useCurrentUser from 'hooks/useCurrentUser'
import Headline from 'common/text/Headline'
import Form from 'common/formControls/Form'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import FormActions from 'common/formControls/Form/FormActions'
import actionTypes from 'reducers/actionTypes'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import useLocalSetting from 'hooks/useLocalSetting'
import { fallbackTo } from 'fp/utils'
import { join, map } from 'fp/arrays'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { ABILITY_ASSIGNMENT_CREATION, ABILITY_CONTENT_EDITING } from 'core/consts'
import SaveButton from '../SaveButton'
import ChangePassword from './ChangePassword'

const AccountField = props => (
  <HFTextField
    disabled
    variant="outlined"
    {...props}
  />
)
const Account = () => {
  const { user } = useCurrentUser()
  const { district, firstName, lastName, roleId, schools, username } = user
  const [hideLinkToEditor, setHideLinkToEditor] = useLocalSetting('hideLinkToEditor', false)

  const has = useAbilityChecker()
  const isContentCreator = has(ABILITY_CONTENT_EDITING)
  const isAssignmentCreator = has(ABILITY_ASSIGNMENT_CREATION)

  return (
    <Headline
      mb={12}
      name="Account"
      textTransform="capitalize"
      title="Account"
    >
      <Form
        actionType={actionTypes.USER_SAVE}
        defaultValues={{
          name: `${firstName} ${lastName}`,
          id: user.id,
          roleId,
          username,
          district,
          schools: compose(
            join(','),
            map(({ name }) => name),
            fallbackTo([]),
          )(schools),
        }}
      >

        <AccountField
          margin="none"
          name="name"
        />
        <AccountField
          label="Login"
          margin="none"
          name="username"
        />
        <br />
        <br />
        {Boolean(isAssignmentCreator) && (
          <>
            <AccountField
              label="District Name"
              name="district.name"
            />
            <AccountField
              label="District State"
              name="district.state"
            />
            <AccountField name="schools" />
          </>
        )}

        <ChangePassword />

        {Boolean(isContentCreator)
        && (
          <>
            <FormActions style={{ justifyContent: 'flex-start' }}>
              <SaveButton type="submit" />
            </FormActions>
            <SwitchFormControl
              checked={hideLinkToEditor}
              color="secondary"
              label="Hide Link to Editor"
              onChange={() => setHideLinkToEditor(!hideLinkToEditor)}
            />
          </>
        )}
      </Form>
    </Headline>
  )
}

export default Account
