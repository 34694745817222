/* eslint-disable @studysync/persnickety/jsx-use-headline-not-headings */
/* eslint-disable function-call-argument-newline */
import { Children, Fragment, createElement, useId } from 'react'
import PropTypes from 'prop-types'
import cl from 'classnames'
import { compose } from 'redux'
import Container from '@mui/material/Container'
import Html from 'common/text/Html'
import Interactive from 'hss/sections/contentBlocks/Interactive'
import { equals, get, mapKeys, omit, pick, set } from 'fp/objects'
import DualHeadline from 'common/text/DualHeadline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import { assignmentSettingsShape, componentShape, contentShape, contentTypeShape, entityIdShape } from 'core/shapes'
import Headline from 'common/text/Headline'
import { isDefined } from 'fp/utils'
import { isEmptyString, isNotEmptyString, isNotString, isString } from 'fp/strings'
import withProps from 'hoc/withProps'
import InteractiveRenderer from 'hss/sections/contentBlocks/Interactive/InteractiveRenderer'
import FunFactWrapper from 'hss/sections/contentBlocks/interactives/FunFacts/Wrapper'
import NumberCrunch from '../Echo/NumberCrunch'
import ListElement, { listVariant as possibleListVariants } from './ListElement'
import Wrapper from './Wrapper'
import Definition from './Definition'
import VocabPhrase from './VocabPhrase'

const pullDataAttributes = mapKeys(key => String(key).replace(/^data-/, ''))

const nextMeaningfulSibling = ({ nextSibling }) => {
  if (!nextSibling) {
    return false
  }
  if (nextSibling.type === 'text' && isEmptyString(String(nextSibling.data).trim())) {
    return nextMeaningfulSibling(nextSibling)
  }
  return nextSibling
}

const Figcaption = ({ index, node }) => {
  const key = useId()
  let level = 0

  const renderParsedNode = ({ attribs, children, data, name, type }) => {
    if (type === 'text') return data

    if (type === 'tag') {
      return createElement(
        name === 'em' ? 'cite' : name,
        // eslint-disable-next-line no-plusplus
        { ...attribs, key: `${key}-${level++}` },
        children.map(renderParsedNode),
      )
    }

    return null
  }

  return createElement(
    'figcaption',
    { key: `caption-${index}` },
    node.children.map(renderParsedNode),
  )
}

Figcaption.propTypes = {
  index: PropTypes.number.isRequired,
  node: PropTypes.object.isRequired,
}

const fullWidthVariants = ['callout-question', 'ordered-feature-list']

const EnhancedContentHtml = ({
  assignmentSettings,
  children: outerChildren,
  childrenMetadata,
  contained = true,
  body,
  ToolbarRenderer,
  variant,
  ...rest
}) => {
  const SubBlockWrapper = contained && !fullWidthVariants.includes(variant)
    ? Container
    : withProps('div', { className: 'block-full-width' })

  const processSourceTitleNode = ({ attribs }, children, index) => (
    <SubBlockWrapper key={index}>

      <Headline
        title={children}
        {...attribs}
        className={cl('tr-typography', attribs.className)}
      />

      <ToolbarRenderer />

    </SubBlockWrapper>
  )

  const processSourceTitle = {
    shouldProcessNode: node => node.name === 'h1' && node?.attribs?.className === 'source-title',
    processNode: processSourceTitleNode,
  }

  const processDualHeadlineNode = ({ attribs }, children, index) => {
    const attributes = pullDataAttributes(attribs)

    const BuiltHeadline = createElement(
      DualHeadline, {
        ...omit('textalign', 'dividerbelow')(attributes),
        dividerBelow: String(attributes.dividerbelow).toLowerCase() === 'true',
        mb: 8,
        mt: 12,
        multiline: String(attributes.multiline).toLowerCase() === 'true',
        textAlign: attributes.textalign || 'left',
        textTransform: 'none',
      },
      children,
    )

    return createElement(
      SubBlockWrapper,
      { key: index },
      createElement(HeadlineLevelOffset, { offset: 2 }, BuiltHeadline),
    )
  }

  const processDualHeaders = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'advanced-heading',
    processNode: processDualHeadlineNode,
  }

  const processInteractiveNode = ({ attribs }, children, index) => createElement(
    Interactive,
    { ...pullDataAttributes(attribs),
      key: index,
      Renderer: InteractiveRenderer,
      childrenMetadata,
      ...rest },
    children,
  )

  const processFunFactNode = ({ attribs }, children, index) => createElement(
    FunFactWrapper,
    { contentId: attribs['data-contentid'], name: attribs['data-name'], key: index },
    children,
  )

  const processFunFacts = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'fun-fact',
    processNode: processFunFactNode,
  }

  const processInteractives = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'interactive',
    processNode: processInteractiveNode,
  }

  const processListNode = ({ attribs, name, parent }, children, index) => {
    if (attribs['data-variant'] === 'source-header') {
      return (
        <Fragment key={index}>

          <ListElement
            {...attribs}
            component="ol"
            parent={parent}
            variant={variant}
          >
            {children}
          </ListElement>

          <ToolbarRenderer />
        </Fragment>
      )
    }

    const first = Children
      .toArray(children)
      .filter(isNotString)[0]

    const itemsVariant = get('data-variant')(first?.props || {})

    const ListWrapper = itemsVariant === 'numbered-card'
      ? withProps('div', { className: 'block-full-width block-partially-contained' })
      : ['ol', 'ul', 'li'].includes(parent?.tagName)
        ? Fragment // never wrap if the parent is a list or list element
        : Container

    const listVariant = attribs['data-variant'] || (
      Object
        .keys(possibleListVariants)
        .includes(variant) ? variant : undefined
    )

    return createElement(
      ListWrapper,
      { key: index },
      createElement(
        ListElement, {
          ...attribs,
          component: name,
          variant: listVariant,
        },
        children,
      ),
    )
  }

  const processLists = {
    shouldProcessNode: node => node.type === 'tag' && ['ol', 'ul'].includes(node.name),
    processNode: processListNode,
  }

  const processBlockQuotes = {
    shouldProcessNode: node => isDefined(node.processFigure),
    // eslint-disable-next-line react/no-unstable-nested-components
    processNode: (node, children, index) => {
      if (!node.processFigure) return null // removes the original credit paragraph

      const { className } = node.attribs
      const float = node.attribs['data-float']

      return createElement(
        SubBlockWrapper,
        { key: index }, (
          <figure
            className={cl({
              [className]: className,
              'quote-figure': true,
              [`float-${float}`]: !!float,
            })}
          >
            <blockquote>
              {children}
            </blockquote>

            {/* combines the blockquote and credit paragraph into a figcaption */}
            <Figcaption
              index={index}
              node={nextMeaningfulSibling(node)}
            />
          </figure>
        ),
      )
    },
  }

  const processNumberCrunchNode = ({ attribs }, children, index) => {
    const attributes = pullDataAttributes(attribs)

    const BuiltHeadline = createElement(
      NumberCrunch, pick('number', 'bodytext')(attributes),
      children,
    )

    return createElement(
      SubBlockWrapper,
      { key: index },
      createElement(HeadlineLevelOffset, { offset: 2 }, BuiltHeadline),
    )
  }

  const processNumberCrunch = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'number-crunch',
    processNode: processNumberCrunchNode,
  }

  const processWordDefinition = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'word-definition',
    // eslint-disable-next-line react/no-unstable-nested-components
    processNode: ({ attribs }, children, index) => {
      const { definition1, definition2, form, pronunciation, syllabification, word } = pullDataAttributes(attribs)
      const definitions = [definition1, definition2].filter(Boolean)
      return createElement(
        SubBlockWrapper,
        { key: index },
        createElement(Definition, { definitions, form, pronunciation, syllabification, word }),
      )
    },
  }

  const processTables = {
    shouldProcessNode: node => node.type === 'tag' && ['table', 'tbody', 'tr', 'td', 'th'].includes(node.name),
    // eslint-disable-next-line react/no-unstable-nested-components
    processNode: ({ attribs, name }, children, index) => {
      let hasCaption = false
      let hasTitle = false
      const caption = attribs['data-caption']
      const title = attribs['data-title']
      const textAlign = attribs['data-align'] || (name === 'th'
        ? 'center'
        : 'left')
      const props = {
        key: index,
        ...(textAlign ? { style: { textAlign } } : null),
      }
      if (name === 'table') {
        hasCaption = isNotEmptyString(caption)
        hasTitle = isNotEmptyString(title)
      }

      /**
       * Guard against getting:
       * Warning: validateDOMNesting(...): Whitespace text nodes cannot appear as a child of <t*>
       */
      const safeChildren = children.map(item => isString(item)
        ? String(item).trim() === '' ? '' : String(item)
        : item)

      return name === 'table'
        ? createElement(
          SubBlockWrapper,
          {
            key: index,
            'data-layouttablecontainer': '',
          },
          createElement(
            'table',
            omit('key')({
              ...props,
              'data-layouttable': '',
            }),
            (hasCaption || hasTitle)
              ? (
                <>
                  <caption>
                    <div className="body1-semibold">{title}</div>
                    {caption}
                  </caption>
                  {safeChildren}
                </>
              )

              : safeChildren,
          ),
        )
        : createElement(
          name,
          props,
          safeChildren,
        )
    },
  }

  const processTypography = {
    shouldProcessNode: node => node.type === 'tag' && ![
      'a',
      'caption',
      'del',
      'em',
      'li',
      'span',
      'strong',
      'sub',
      'sup',
      'table',
      'tbody',
      'td',
      'th',
      'tr',
      'u',
    ].includes(node.name),

    // eslint-disable-next-line react/no-unstable-nested-components
    processNode: ({ attribs, name }, children, index) => {
      const newAttribs = { ...attribs }
      if (/^h[1-6]$/.test(name)) {
        newAttribs.className = cl(attribs.className, 'tr-typography')
      }

      return createElement(
        SubBlockWrapper,
        { key: index },
        createElement(name, newAttribs, children),
      )
    },
  }

  // Vocab phrases are marked by the `markVocabPhrasesInBlocks` function in projections/content.js.
  // The format is `__vocab{id}__{phrase}__`.
  const vocabRegExp = /__vocab([^_]+)__([^_]+)__/i

  const processVocabPhrases = {
    shouldProcessNode: node => node.type === 'text' && vocabRegExp.test(node.data),
    processNode: (node) => {
      const resultComponents = []
      let unprocessedText = node.data
      let key = 0
      let match = unprocessedText.match(vocabRegExp)

      while (match) {
        const [fullMatch, vocabId, vocabPhraseName] = match
        const { index } = match

        if (index > 0) {
          resultComponents.push(unprocessedText.substring(0, index))
        }
        resultComponents.push(createElement(VocabPhrase, { key, vocabId }, vocabPhraseName))
        key += 1
        unprocessedText = unprocessedText.substring(index + fullMatch.length)

        match = unprocessedText.match(vocabRegExp)
      }
      resultComponents.push(unprocessedText)

      return resultComponents
    },
  }

  const preprocessClassnames = {
    shouldPreprocessNode: get('attribs.class'),
    preprocessNode: (node) => {
      // eslint-disable-next-line no-param-reassign
      node.attribs = compose(
        /**
         * TODO:
         * For now we're just passing them through.
         * There will be more logic here later to combine classes into typography variants
         */
        set('className', get('attribs.class')(node.attribs)),
        omit('class'),
      )(node.attribs)
    },
  }

  const preprocessTypography = {
    shouldPreprocessNode: get('attribs.data-typography'),
    preprocessNode: (node) => {
      // eslint-disable-next-line no-param-reassign
      node.attribs = compose(
        set('className', get('data-typography')(node.attribs)),
        omit('data-typography'),
      )(node.attribs)
    },
  }

  const preprocessExtendedTypography = {
    shouldPreprocessNode: get('attribs.style'),
    preprocessNode: (node) => {
      const { style } = node.attribs
      /**
       * If we wind up needing more inline styles, this will have to be made smarter
       */
      if (style.includes('background-color:accent')) {
        // eslint-disable-next-line no-param-reassign
        node.attribs = compose(
          set('className', 'highlighted'),
          omit('style'),
        )(node.attribs)
      }
    },
  }

  const preprocessLists = {
    shouldPreprocessNode: ({ children, name }) => (name === 'ol')
      ? children
        .filter(compose(
          equals('li'),
          get('name'),
        ))
        .some(compose(
          equals('source-header'),
          get('attribs.data-variant'),
        ))
      : false,
    preprocessNode: (node) => {
      // eslint-disable-next-line no-param-reassign
      node.attribs = set('data-variant', 'source-header')(node.attribs)
    },
  }

  const preprocessBlockQuotes = {
    shouldPreprocessNode: (node) => {
      const nextSibling = nextMeaningfulSibling(node)

      return node.name === 'blockquote'
        && nextSibling?.name === 'p'
        && (nextSibling.attributes.some(({ name, value }) => name === 'data-typography'
          && value === 'credit')
          || node.attribs?.className === 'credit')
    },
    preprocessNode: (node) => {
      /* eslint-disable no-param-reassign */
      node.processFigure = true
      nextMeaningfulSibling(node).processFigure = false
      /* eslint-enable no-param-reassign */
    },
  }

  return (
    <Wrapper
      style={{ position: 'relative' }}
      variant={variant}
      {...rest}
    >

      {outerChildren}

      <Html
        additionalInstructions={[
          processBlockQuotes,
          processDualHeaders,
          processFunFacts,
          processInteractives,
          processLists,
          processNumberCrunch,
          processSourceTitle,
          processWordDefinition,
          processTables,
          processTypography,
          processVocabPhrases,
        ]}
        additionalPreprocessingInstructions={[
          preprocessBlockQuotes,
          preprocessClassnames,
          preprocessLists,
          preprocessTypography,
          preprocessExtendedTypography,
        ]}
        assignmentSettings={assignmentSettings}
        body={body}
        options={{ xmlMode: true }}
        variant={variant}
      />
    </Wrapper>
  )
}

EnhancedContentHtml.propTypes = {
  assignmentSettings: assignmentSettingsShape,
  body: PropTypes.string.isRequired,
  children: PropTypes.node,
  childrenMetadata: PropTypes.arrayOf(PropTypes.shape({
    contentSubType: PropTypes.string,
    contentType: contentTypeShape,
    id: entityIdShape.isRequired,
    attachedScaffolds: PropTypes.arrayOf(contentShape),
  })).isRequired,
  contained: PropTypes.bool,
  ToolbarRenderer: componentShape,
  variant: PropTypes.string,
}

export default EnhancedContentHtml
