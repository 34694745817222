import { useContext } from 'react'
import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { context } from '../../SearchProvider'
import FilterButtons from '../FilterButtons'

const ContentSubTypeFilter = () => {
  const {
    alterContentSubTypeFilters,
    contentSubTypes,
    dataset,
  } = useContext(context)

  const squery = get('metadata.squery')(dataset)

  const selectedFilters = inspect(squery).get.where('contentSubType').in()

  return (
    <FilterButtons
      filters={contentSubTypes}
      handleFilterClick={alterContentSubTypeFilters}
      selectedFilters={selectedFilters.length === contentSubTypes.length
        ? []
        : selectedFilters}
      subContentType={contentSubTypes[0]}
    />
  )
}

export default ContentSubTypeFilter
