import { createElement } from 'react'
import FormSection from 'common/layout/FormSection'
import { filter } from 'fp/arrays'
import General from 'hss/AssignmentEditor/General'
import TestDetails from './TestDetails'

const Sections = () => {
  const sections = filter(Boolean)([
    {
      Component: General,
      title: 'General',
      isAssessment: true,
    },
    {
      Component: TestDetails,
      title: 'Test Details',
    },
  ])

  return sections.map(({ title, Component, ...rest }) => createElement(
    FormSection,
    { key: title, title },
    <Component {...rest} />,
  ))
}

export default Sections
