import { useCallback, useContext, useState } from 'react'
import { set } from 'fp/objects'
import { isDefined } from 'fp/utils'
import { INTERACTIVE_TYPE_VENN_DIAGRAM } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Content from './Content'

const VennDiagram = withQuestionPrompt(() => {
  const {
    contentId,
    interactionData,
    interactiveData,
    onInteract,
    submittedDate,
  } = useContext(interactiveContext)

  const { readOnly } = interactiveData
  const [responseState, setResponseState] = useState({
    sections: interactionData?.sections || interactiveData.sections,
  })

  const handleChange = useCallback((field, value) => {
    const newValue = set(field, value)(responseState)
    setResponseState(newValue)
    onInteract(newValue)
  }, [onInteract, responseState])

  return (
    <Content
      {...interactiveData}
      {...responseState}
      contentId={contentId}
      onChange={handleChange}
      readOnly={isDefined(submittedDate) || readOnly}
    />
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_VENN_DIAGRAM,
}

export default VennDiagram
