import { Fragment, useContext } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import QuestionPrompt from 'hss/sections/contentBlocks/Interactive/QuestionPrompt'
import { ECHO_FEATURE_TALK_BACK } from 'hss/ContentBuilder/consts'
import Html from 'common/text/Html'
import AudioRecording from 'hss/sections/contentBlocks/interactives/AudioRecording'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import withProps from 'hoc/withProps'
import Headline from 'common/text/Headline'
import { isStudent } from 'selectors/users'
import FeatureFootnotes from '../FeatureFootnotes'
import FeatureHeadline from '../FeatureHeadline'
import SubmitButton from '../SubmitButton'
import CollapsibleInstructions from '../CollapsibleInstructions'

const feature = ECHO_FEATURE_TALK_BACK

const FeatureTalkBack = () => {
  const {
    completed,
    interactiveData: {
      audioLengthLimit,
      interactiveInstructions,
      prompt,
      studentInstructions,
    },
    isGrading,
    submittable,
  } = useContext(interactiveContext)

  const userIsStudent = useSelector(isStudent)

  const InstructionsWrapper = isGrading ? CollapsibleInstructions : Fragment
  const ResponseWrapper = isGrading
    ? withProps(Headline, { my: 4, title: 'Student Response' })
    : Fragment

  return (
    <>
      <FeatureHeadline
        mb={2}
        title="Respond"
      >
        <Box mb={4}>
          <InstructionsWrapper>
            <Html body={interactiveInstructions} />

            <QuestionPrompt body={prompt} />

            <Html body={studentInstructions} />
          </InstructionsWrapper>
        </Box>

        <ResponseWrapper>
          <AudioRecording maxLength={audioLengthLimit} />
        </ResponseWrapper>

      </FeatureHeadline>

      {Boolean(submittable) && (
        <Box
          mb={2}
          mt={4}
        >
          <SubmitButton disabled={completed || !userIsStudent} />
        </Box>
      )}

      <FeatureFootnotes feature={feature} />
    </>
  )
}

export default FeatureTalkBack
