import PropTypes from 'prop-types'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import Headline from 'common/text/Headline'
import { get, set } from 'fp/objects'

const Images = ({ onChange, preferences }) => {
  const visible = get('imagesLongTextAlts')(preferences)
  const handleChange = () => {
    onChange(set('imagesLongTextAlts', !visible))
  }

  return (
    <Headline
      mt={6}
      size={4}
      title="Images"
    >
      <SwitchFormControl
        checked={visible}
        color="secondary"
        helperText="Images with a long text alternative are accessible to screen reader software. Enable this option to make the text visible as well."
        label="Show long text alternatives"
        onChange={handleChange}
      />
    </Headline>
  )
}

Images.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default Images
