import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import cl from 'classnames'
import ScreenReaderText from 'common/text/ScreenReaderText'
import Well from 'common/layout/Well'
import { DND_TYPE_CHART_COMPLETION } from 'core/consts'
import { itemShape } from '../utils'
import Option from './Option'

const DraggableOptions = ({ availableOptions, dispatch }) => {
  const handleDrop = (item) => {
    dispatch({ type: 'REMOVE_ITEM', item: { id: item.id } })
  }
  const [{ isOver }, dropRef] = useDrop({
    accept: DND_TYPE_CHART_COMPLETION,
    drop: handleDrop,
    collect: monitor => ({
      canDrop: !!monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
  })
  return (
    <Well
      className={cl({ 'drag-hover': isOver })}
      minHeight={100}
      ref={dropRef}
      style={{ transition: 'background 300ms' }}
      sxProps={{ img: { maxHeight: 150 } }}
    >
      <ScreenReaderText>
        Tab to empty table cells to enter your responses.
      </ScreenReaderText>

      {availableOptions.map(item => (
        <Option
          item={item}
          key={item.id}
          type="CHART_COMPLETION"
        />
      ))}
    </Well>
  )
}

DraggableOptions.propTypes = {
  availableOptions: PropTypes.arrayOf(itemShape).isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default DraggableOptions
