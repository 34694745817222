import { filter, map } from 'fp/arrays'
import { pipe } from 'fp/utils'
import { get } from 'fp/objects'

// eslint-disable-next-line import/prefer-default-export
export const getCorrectAnswerChoiceIds = pipe(
  get('answerChoices'),
  filter(get('isCorrect')),
  map(get('id')),
)
