import { useContext } from 'react'
import PropTypes from 'prop-types'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { get } from 'fp/objects'
import ItemWithFeedback from 'common/layout/ItemWithFeedback'
import { itemShape, responseItemShape } from '../utils'
import SelectedResponse from './SelectedResponse'
import ImageRenderer from './ImageRenderer'

const Image = ({ dispatch, item, item: { id, isStatic }, readOnly, responseItem, ...rest }) => {
  const { scoreData } = useContext(interactiveContext)
  const itemScoringResult = get(`result.byId.${id}`)(scoreData)
  return (

    isStatic ? (
      <ImageRenderer item={item} />
    )
      : readOnly
        ? (
          <ItemWithFeedback scoringResult={itemScoringResult}>
            <ImageRenderer item={item} />
          </ItemWithFeedback>
        )
        : (
          responseItem && (
            <SelectedResponse
              dispatch={dispatch}
              item={item}
              responseItem={responseItem}
              {...rest}
            />
          )
        )

  )
}

Image.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: itemShape.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responseItem: responseItemShape,
}

export default Image
