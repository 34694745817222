import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import actionTypes from 'reducers/actionTypes'
import Form from 'common/formControls/Form'
import ActionLink from 'common/formControls/buttons/ActionLink'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import Headline from 'common/text/Headline'
import LogMeInQuik from './LogMeInQuik'
import LoginTextField from './LoginTextField'

const FormContents = ({
  pageIsDisplayed,
  switchToForgotPassword,
}) => {
  const { setValue } = useFormContext()
  const submitRef = useRef()
  const headlineRef = useRef()

  const tabIndex = useMemo(() => (pageIsDisplayed ? 0 : -1), [pageIsDisplayed])

  const [logMeInQuikEngaged, setLogMeInQuikEngaged] = useState(false)

  useEffect(() => {
    if (pageIsDisplayed) {
      headlineRef.current?.focus()
    }
  }, [headlineRef, pageIsDisplayed])

  return (
    <>
      <Headline
        my={4}
        ref={headlineRef}
        tabIndex={-1}
        title="User Sign In"
      >
        <ActionLink
          onClick={() => {
          // eslint-disable-next-line @studysync/persnickety/use-pinnable-methods
            window.location = '/api/il-launch'
          }}
        >
          Sign In With Imagine Learning
        </ActionLink>
      </Headline>

      <Collapse
        collapsedSize={0}
        in={!logMeInQuikEngaged}
      >
        <Headline
          my={4}
          title="Admin Sign In"
        >
          <LoginTextField
            inputProps={{
              autoComplete: 'username',
              tabIndex,
            }}
            label="Email"
            name="username"
            required
          />

          <LoginTextField
            inputProps={{
              autoComplete: 'current-password',
              tabIndex,
            }}
            name="password"
            required
            type="password"
          />

          <SubmitButton
            data-testid="submit-btn"
            ref={submitRef}
            style={{ marginTop: 24 }}
            tabIndex={tabIndex}
          >
            Sign In
          </SubmitButton>

          <Divider
            sx={{
              mt: 2, borderColor: 'grey.3', display: { xs: 'none', sm: 'block' },
            }}
          />
          <Stack
            alignItems="baseline"
            divider={(
              <Box
                px={1}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                |
              </Box>
            )}
            justifyContent="center"
            mt={2}
            spacing={2}
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <Button
              data-subvariant="base"
              onClick={switchToForgotPassword}
              tabIndex={tabIndex}
              variant="mock-link"
            >
              Forgot Username or Password?
            </Button>

          </Stack>

        </Headline>
      </Collapse>

      <LogMeInQuik
        {...{
          logMeInQuikEngaged,
          setLogMeInQuikEngaged,
          setValue,
          submitRef,
          tabIndex,
        }}
      />

    </>
  )
}

FormContents.propTypes = {
  pageIsDisplayed: PropTypes.bool.isRequired,
  switchToForgotPassword: PropTypes.func.isRequired,
}

const DirectLoginForm = forwardRef(({
  pageIsDisplayed,
  switchToForgotPassword,
}, ref) => (
  <Form
    actionType={actionTypes.SESSION_LOGIN}
    defaultValues={{ username: '', password: '' }}
    mx={{ xs: 'auto', lg: 'inherit' }}
    name="DirectLoginForm"
    ref={ref}
    suppressDirtyNavigationWarning
    suppressRequiredLabel
  >
    <FormContents
      pageIsDisplayed={pageIsDisplayed}
      switchToForgotPassword={switchToForgotPassword}
    />
  </Form>
))

DirectLoginForm.propTypes = {
  pageIsDisplayed: PropTypes.bool.isRequired,
  switchToForgotPassword: PropTypes.func.isRequired,
}

export default DirectLoginForm
