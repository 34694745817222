import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import Popper from 'common/indicators/Popper'
import Lightbulb from 'hss/images/controls/draft-toolbar/lightbulb.svg'
import { when } from 'fp/utils'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import FunFactLink from './Link'

const paperStyle = {
  minWidth: 495,
  padding: 0,
}

const FunFactTooltip = ({ children }) => {
  const context = useContext(interactiveContext)
  const { completed, interactive: { name }, markComplete } = context

  const handleOpen = useCallback(() => {
    when(!completed, markComplete)
  }, [completed, markComplete])

  return (
    <Popper
      paperStyle={paperStyle}
      placement="auto-end"
    >
      <Popper.Control>
        <FunFactLink>
          <Lightbulb /> {name}
        </FunFactLink>
      </Popper.Control>

      <Popper.Content
        onOpen={handleOpen}
        sx={{
          '.MuiPaper-root *': {
            minWidth: 0,
            minHeight: 0,
          },
          img: {
            flex: 1,
            objectFit: 'contain',
          },
        }}
      >
        {children}
      </Popper.Content>
    </Popper>
  )
}

FunFactTooltip.propTypes = {
  children: PropTypes.node.isRequired,
}
export default FunFactTooltip
