import { useContext } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { set } from 'fp/objects'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import Instructions from 'common/layout/Instructions'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import useStaticRenderer from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeatureRate/useStaticRenderer'
import { interactiveGradingContext } from '../../InteractiveGradingProvider'
import Rubric from '../../../interactives/Rubric'
import { interactiveContext } from '../../InteractiveProvider'

const TextResponseRubricGrader = () => {
  const {
    gradingEnabled,
    interactionData: { response },
    interactive: { contentSubType, rubric },
    interactiveData: {
      prompt,
      question,
      sampleAnswer,
    },
  } = useContext(interactiveContext)

  const {
    scoreData,
    setSaveButtonDisabled,
    setScoreData,
  } = useContext(interactiveGradingContext)

  const { comment = '', rubricSelections = [] } = scoreData || {}
  const isEcho = contentSubType?.startsWith('echo')

  const StaticRenderer = useStaticRenderer({ responseType: contentSubType })

  const handleRubricChange = (newValues) => {
    setScoreData(set('rubricSelections', newValues))
    setSaveButtonDisabled(false)
  }

  const handleCommentChange = ({ target: { value } }) => {
    setScoreData(set('comment', value))
    setSaveButtonDisabled(false)
  }

  return (
    <Stack
      gap={4}
      sx={isEcho ? { marginTop: 3 } : {}}
    >
      <Instructions>
        <Html body={question || prompt} />
      </Instructions>

      {Boolean(sampleAnswer) && (
        <CollapsibleSection
          initiallyExpanded
          label={(
            <Headline
              textAlign="center"
              title="Sample answer"
              variant="feature-paragraph-semibold"
            />
          )}
          variant="interactive-grading"
        >
          <HeadlineLevelOffset>
            <Html body={sampleAnswer} />
          </HeadlineLevelOffset>
        </CollapsibleSection>
      )}

      <Box>
        <Headline
          hr
          textAlign="center"
          title="Response"
          variant="feature-paragraph-semibold"
        >
          {isEcho
            ? (<StaticRenderer />)
            : (<Html body={response} />)}
        </Headline>
      </Box>

      <Box component="fieldset">
        <Box
          component="legend"
          style={{ maxWidth: 'none', textAlign: 'center', width: '100%' }}
        >
          <Headline
            hr
            title="Grading & Review"
            variant="feature-paragraph-semibold"
          />
        </Box>
        <Rubric
          onChange={handleRubricChange}
          readOnly={!gradingEnabled}
          rubric={rubric}
          values={rubricSelections}
        />
        <DebouncedTextField
          disabled={!gradingEnabled}
          label="Optional Comment"
          multiline
          name="comment"
          onChange={handleCommentChange}
          value={comment}
          variant="filled"
        />
      </Box>
    </Stack>
  )
}

export default TextResponseRubricGrader
