import cl from 'classnames'
import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import Tab from '@mui/material/Tab'
import { labels } from 'hss/ContentBuilder/consts'
import useNavigation from 'hooks/useNavigation'
import TabList from 'common/layout/TabList'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import {
  filledIcons,
  icons,
} from 'hss/ContentBuilder/Curriculum/Echo/utils'
import { sidebarContext } from 'routing/shells/SidebarProvider'

const Tabs = ({ features }) => {
  const { isEchoSidebarShrunk, setIsEchoSidebarShrunk } = useContext(sidebarContext)

  const { location: { pathname }, navigate } = useNavigation()
  const { setSuppressNextDirtyNavigationWarning } = useContext(additionalContext)

  const setCurrentTab = useCallback((newValue) => {
    navigate(`${pathname}#${newValue}`, { replace: true })
  }, [navigate, pathname])

  const handleTabChange = (e, newValue) => {
    setSuppressNextDirtyNavigationWarning(true)
    setTimeout(() => { setCurrentTab(newValue) }, 100)
  }

  const handleClick = useCallback(() => {
    if (isEchoSidebarShrunk) setIsEchoSidebarShrunk(false)
  }, [isEchoSidebarShrunk, setIsEchoSidebarShrunk])

  return (
    <TabList
      className={cl({ 'is-collapsed': isEchoSidebarShrunk })}
      data-variant="echo"
      minSize="xs"
      onChange={handleTabChange}
      onClick={handleClick}
      orientation="vertical"
    >

      {features.map(key => (
        <Tab
          key={key}
          label={(
            <>
              {filledIcons[key]} {icons[key]}
              {labels[key]}
            </>
          )}
          value={key}
        />
      ))}

    </TabList>
  )
}

Tabs.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Tabs
