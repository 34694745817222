import { useContext } from 'react'
import KeyboardInstructions from 'common/layout/KeyboardInstructions'
import { INTERACTIVE_TYPE_EVALUATION_RANKING } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Content from './Content'

const EvaluationRanking = withQuestionPrompt(() => {
  const {
    isGrading,
    submittable,
  } = useContext(interactiveContext)

  return (
    <>
      {Boolean(!isGrading && submittable) && (
        <KeyboardInstructions>
          Press the space bar to select an item.
          Press j (or down arrow) to move down, k (or up arrow) to move up, space bar to drop and escape to cancel.
        </KeyboardInstructions>
      )}

      <Content />
    </>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_EVALUATION_RANKING,
}

export default EvaluationRanking
