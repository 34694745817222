import Container from '@mui/material/Container'
import { useCallback, useContext, useId, useState } from 'react'
import FormLabel from '@mui/material/FormLabel'
import Html from 'common/text/Html'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import Headline from 'common/text/Headline'
import { INTERACTIVE_TYPE_ESSAY } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'

const Essay = withQuestionPrompt(() => {
  const {
    interactionData: { response },
    interactiveData: { sampleAnswer },
    onInteract,
    previewing,
    submittable,
  } = useContext(interactiveContext)

  const [responseState, setResponseState] = useState(response || '')

  const onChange = useCallback(({ target }) => {
    const newValue = target?.value
    setResponseState(newValue)
    onInteract({ response: newValue })
  }, [onInteract])

  const responseLabelId = useId()

  const showAnswerKey = useIsInAnswerKeyContext()

  return (
    <div>
      {showAnswerKey
        ? sampleAnswer && (
          <Headline
            textAlign="center"
            title="Sample Answer"
            variant="feature-paragraph-semibold"
          >
            <Html body={sampleAnswer} />
          </Headline>
        )
        : (
          <Container variant="margin-auto">
            {!submittable
              ? <Html body={response || '(no response)'} />
              : (
                <>
                  <FormLabel id={responseLabelId}>Response</FormLabel>
                  <RichTextEdit
                    ariaLabelledBy={responseLabelId}
                    onChange={onChange}
                    required={!previewing}
                    value={responseState}
                  />
                </>
              )}
          </Container>
        )}
    </div>
  )
})

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_ESSAY,
}

export default Essay
