import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import LeftIcon from 'hss/images/nav/arrow-left-small.svg'
import RightIcon from 'hss/images/nav/arrow-right-small.svg'
import Image from 'apps/common/indicators/Image'
import { contentShape } from 'core/shapes'
import LinkButton from 'common/navigation/links/LinkButton'
import { getSubsectionOrEchoAccentColor, getSubsectionOrEchoHeroImage } from 'selectors/contentViewer'
import { get } from 'fp/objects'
import { CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION } from 'core/consts'
import useIsWaiting from './useIsWaiting'

const transition = 'all 250ms ease-in-out 0ms'

const NavigationButton = ({ baseUrl, content, prevOrNext, ...rest }) => {
  const { id: contentId, name } = content
  const { palette, palette: { sectionAccents } } = useTheme()
  const accentColor = useSelector(getSubsectionOrEchoAccentColor({ contentId, sectionAccents }))
  const bgImage = useSelector(getSubsectionOrEchoHeroImage({ contentId }))
  const isPrev = prevOrNext === 'prev'

  const to = content.parent?.contentType === CONTENT_TYPE_SOURCE
    // urg.  wish we didn't need a special carve out for structured sources 😕
    ? `../${CONTENT_TYPE_SOURCE}/${content.parent.id}/${CONTENT_TYPE_SUBSECTION}/${contentId}`
    : `${baseUrl}/${contentId}`

  const justifyContent = bgImage
    ? 'space-between'
    : isPrev ? 'flex-start' : 'flex-end'

  const Icon = isPrev
    ? LeftIcon
    : RightIcon

  const accentStyle = {
    backgroundColor: get(`${accentColor}.0`)(palette),
    justifyContent,
    paddingLeft: isPrev ? null : 0,
    paddingRight: isPrev ? 0 : null,
    transition,
  }
  const imageStyle = {
    height: '100%',
    maxWidth: '35%',
    objectFit: 'cover',
  }
  const isWaiting = useIsWaiting(to)

  return (
    <LinkButton
      {...rest}
      component="div"
      data-subvariant={isPrev ? 'align-left' : 'align-right'}
      data-testid={`nav-button-${prevOrNext}`}
      style={accentStyle}
      to={to}
      variant="full-size-nav"
    >
      {Boolean(!isPrev && bgImage) && (
        <Image
          percentHeight={0}
          src={bgImage}
          style={imageStyle}
        />
      )}
      <div
        className="accent"
        style={accentStyle}
      >
        <div className="title">{name}</div>
        <div className="label">
          {Boolean(!isPrev) && (
            <div>
              Next
            </div>
          )}
          {isWaiting
            ? (
              <div>
                <CircularProgress
                  color="inherit"
                  size={18}
                  style={{
                    marginLeft: isPrev ? '' : '8px',
                    marginRight: isPrev ? '8px' : '',
                  }}
                />
              </div>
            )
            : <Icon />}
          {Boolean(isPrev) && (
            <div>
              Back
            </div>
          )}
        </div>
      </div>
      {Boolean(isPrev && bgImage) && (
        <Image
          percentHeight={0}
          src={bgImage}
          style={imageStyle}
        />
      )}
    </LinkButton>
  )
}

NavigationButton.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  content: contentShape.isRequired,
  prevOrNext: PropTypes.oneOf(['prev', 'next']).isRequired,
}

export default NavigationButton
