import { compose } from 'redux'
import { get } from 'fp/objects'
import ChapterSegment from './ChapterSegment'
import SpecialSegment from './SpecialSegment'

const SegmentRow = (props) => {
  const isChapter = compose(Boolean, get('segment.chapter'))(props)
  const Segment = isChapter ? ChapterSegment : SpecialSegment
  return <Segment {...props} />
}

export default SegmentRow
