import { useContext } from 'react'
import OptionListItem from 'hss/sections/contentBlocks/interactives/GroupAndSort/OptionListItem'
import { interactiveContext } from '../../../InteractiveProvider'

const NonSortableGroupContents = ({ items, renderItem }) => {
  const { interactive: { scoring: { mode: scoringMode } } } = useContext(interactiveContext)

  return items.map(({ itemId, count, isCorrect }) => (
    <OptionListItem
      key={itemId}
      {...{
        isCorrect,
        scoringMode,
      }}
    >
      {renderItem(itemId, count)}
    </OptionListItem>
  ))
}

export default NonSortableGroupContents
