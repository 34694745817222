import { useCallback } from 'react'
import { Lock, Unlock } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import BusySpinner from 'common/indicators/BusySpinner'
import { get } from 'fp/objects'
import { getAssignmentById } from 'selectors/assignments'
import { isPastMaxSubmitDate } from 'hss/utils'
import useHoverFocus from 'hooks/useHoverFocus'
import { getUserAssignmentById } from 'selectors/userAssignments'

const ReopenUserAssignmentCell = (props) => {
  const { assignmentId } = useParams()
  const userAssignmentId = get('row.original.id')(props)
  const assignment = useSelector(getAssignmentById({ assignmentId }))
  const userAssignment = useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { submittedDate } = userAssignment
  const canBeReopened = !!submittedDate && !isPastMaxSubmitDate(assignment)

  const [dispatch, status] = useReduxCallback({ actionType: actionTypes.USER_ASSIGNMENT_REOPEN })

  const handleClick = useCallback(
    () => { dispatch(({ userAssignmentId })) },
    [dispatch, userAssignmentId],
  )

  const [ref, isHovered, isFocused] = useHoverFocus()

  return status === BUSY
    ? <BusySpinner />
    : Boolean(canBeReopened) && (
      <Tooltip title="Reopen Student Assignment">
        <IconButton
          aria-label="Submitted; click to reopen student assignment"
          onClick={handleClick}
          ref={ref}
        >
          {isFocused || isHovered
            ? <Unlock />
            : <Lock />}
        </IconButton>
      </Tooltip>
    )
}

export default ReopenUserAssignmentCell
