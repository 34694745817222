import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineConnector from '@mui/lab/TimelineConnector'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import cl from 'classnames'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET } from 'core/consts'
import { entityIdShape } from 'core/shapes'
import withEnterClicks from 'hoc/withEnterClicks'
import withTooltip from 'hoc/withTooltip'
import PageRenderer from './PageRenderer'

const Dot = withTooltip(withEnterClicks(TimelineDot))

const DotWithEnterClicks = ({ id, ...rest }) => {
  const { name } = useContent({ contentType: CONTENT_TYPE_PAGE, contentId: id })
  return (
    <Dot
      sx={{ cursor: 'pointer' }}
      TooltipProps={{ title: name }}
      {...rest}
    />
  )
}
DotWithEnterClicks.propTypes = {
  id: entityIdShape.isRequired,
}

const PagesetRenderer = (props) => {
  const { contentType, id, onTabChange, selectedTab, ...rest } = props
  const pages = useContent({ contentType, contentId: id })

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
      }}
    >
      <Timeline>
        {pages.children.map((page, index) => (
          <TimelineItem key={page.id}>
            <TimelineSeparator>
              <DotWithEnterClicks
                className={cl({ selected: index === selectedTab.currentPage })}
                id={page.id}
                onClick={() => { onTabChange({ ...selectedTab, currentPage: index }) }}
              />
              {index !== pages.children.length - 1
              && <TimelineConnector />}
            </TimelineSeparator>
          </TimelineItem>
        ))}
      </Timeline>
      <PageRenderer
        {...(pages.children[selectedTab.currentPage])}
        selectedTab={selectedTab}
        {...rest}
      />
    </Box>
  )
}

PagesetRenderer.propTypes = {
  contentType: PropTypes.oneOf([CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET]).isRequired,
  id: entityIdShape.isRequired,
  selectedTab: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    isFromPrevious: PropTypes.bool, // TODO: is this used?
    value: PropTypes.number.isRequired,
  }).isRequired,
  onTabChange: PropTypes.func.isRequired,
}

export default PagesetRenderer
