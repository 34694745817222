import Button from '@mui/material/Button'
import { Sliders } from 'react-feather'
import Box from '@mui/material/Box'
import Checkbox from 'common/formControls/switches/Checkbox'
import useToggleState from 'hooks/useToggleState'
import { FEATURE_FLAG_ADVANCED_FILTERS } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'

const checkedFilters = ['Added by me', 'Added by my district', 'Currently assigned']
// TODO: all of it, this is just a placeholder UI for now, it is not hooked up to search anything
const AdvancedFilters = withAbilityCheck(() => {
  const [advancedFiltersShown, toggleAdvancedFiltersShown] = useToggleState()

  return (
    <Box my={4}>
      <Button
        onClick={() => { toggleAdvancedFiltersShown() }}
        startIcon={<Sliders />}
      >
        Advanced Filters
      </Button>

      {Boolean(advancedFiltersShown) && (
        <Box
          display="flex"
          flexWrap="wrap"
          mt={3}
        >
          {checkedFilters.map(filter => (
            <Box
              key={filter}
              mb={2}
              mr={2}
            >
              <Checkbox
                label={filter}
                name=""
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}, FEATURE_FLAG_ADVANCED_FILTERS)

AdvancedFilters.propTypes = {}

export default AdvancedFilters
