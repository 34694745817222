import MenuItem from '@mui/material/MenuItem'
import { useContext } from 'react'
import useNavigation from 'hooks/useNavigation'
import { contentBuilderUrl } from 'routing/consts'
import { labels } from 'hss/ContentBuilder/consts'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'

const EditCurrentContentMenuItem = withAbilityCheck(() => {
  const { navigate } = useNavigation()

  const { subsection } = useContext(contentViewerContext)

  const { contentType, id } = subsection || {}

  const handleEdit = () => {
    navigate(`${contentBuilderUrl}/curriculum/${contentType}/${id}`)
  }

  return subsection
    ? <MenuItem onClick={handleEdit}>Edit {labels[contentType]}</MenuItem>
    : null
}, ABILITY_CONTENT_CREATION)

export default EditCurrentContentMenuItem
