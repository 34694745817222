import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import Form from 'common/formControls/Form'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import actionTypes from 'reducers/actionTypes'
import ContainerQuery from 'common/layout/ContainerQuery'
import { assessmentEditorUrl } from 'routing/consts'
import { deepMerge, set } from 'fp/objects'
import Sections from './Sections'
import { baseAssignment } from './utils'

const AssessmentForm = ({ assignment }) => {
  const defaultValues = useMemo(
    () => deepMerge(baseAssignment, assignment),
    [assignment],
  )

  const isNewAssignment = !assignment.id

  const navigate = useNavigate()
  const handleSuccess = useCallback(({ response: { id } }) => {
    if (isNewAssignment) {
      navigate(`${assessmentEditorUrl}/${id}`)
    }
  }, [isNewAssignment, navigate])

  const handleOnBeforeSubmit = useCallback(
    (data) => {
      const { data: { settings: { timeLimit } } } = data
      if (!timeLimit) return set('data.settings.timeLimit', null)(data)
      return data
    },
    [],
  )

  return (
    <ContainerQuery>
      <Form
        actionType={actionTypes.ASSIGNMENT_SAVE}
        defaultValues={defaultValues}
        mb={20}
        name="AssignmentForm"
        onBeforeSubmit={handleOnBeforeSubmit}
        onSuccess={handleSuccess}
        preventEnterSubmits
      >

        <Sections />

        <SubmitButton>{isNewAssignment ? 'Assign' : 'Save'}</SubmitButton>

      </Form>
    </ContainerQuery>
  )
}

AssessmentForm.propTypes = {
  assignment: PropTypes.object.isRequired,
}

export default AssessmentForm
