import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Suspense, lazy, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import Stack from '@mui/material/Stack'
import { isStaff } from 'selectors/users'
import { getAssignmentBehindUserAssignment } from 'selectors/userAssignments'
import BusySpinner from 'common/indicators/BusySpinner'
import { get, notEquals } from 'fp/objects'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import { fallbackTo } from 'fp/utils'
import { getLocalSetting } from 'selectors/localSettings'
import { isEmptyString } from 'fp/strings'
import { rem } from 'styling/theming/base/mixins'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { interactiveContext } from './InteractiveProvider'
import Expander from './Expander'
import Preamble from './Preamble'

const Container = styled(
  Box,
  { name: 'Interactive-Expander' },
)(() => ({
  minHeight: rem(6),
}))

const StyledStack = styled(
  Stack,
  {
    name: 'Styled-Header',
    shouldForwardProp: p => !['attachedScaffolds', 'frameless', 'headerContainsContent'].includes(p),
  },
)(({
  attachedScaffolds,
  headerContainsContent,
  frameless,
  theme: { palette },
}) => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: 2,
  padding: !frameless && (attachedScaffolds?.length && !headerContainsContent) ? 0 : rem(4, 4, 2),
  justifyContent: 'flex-end',
  width: '100%',
  borderBottom: frameless && headerContainsContent
    ? `1px solid ${palette.grey[3]}`
    : 'none',
}))

const TeacherHeader = lazy(() => import(/* webpackChunkName: "GradingHeader" */ './Teacher/Header'))

const Header = ({ displayConfig, expanded, toggleExpanded, ...rest }) => {
  const {
    attachedScaffolds,
    interactive,
    interactive: { name },
    interactiveData: {
      frameless,
      interactiveInstructions,
    },
    previewing,
    submittableInteractive,
  } = useContext(interactiveContext)

  const headerContainsContent = !isEmptyString(name)
  || !isEmptyString(interactiveInstructions)

  const headerSx = {
    ...displayConfig.headerSx,
    ...(expanded ? displayConfig.expandedHeaderSx : null),
    ...(frameless ? { bgcolor: 'background.default', ...displayConfig.framelessHeaderSx } : null),
  }

  const isCurrentUserStaff = useSelector(isStaff)
  const userAssignmentAssignment = useSelector(getAssignmentBehindUserAssignment)
  const { assignmentId: assignmentIdFromParams } = useParams()
  const assignmentId = isCurrentUserStaff ? assignmentIdFromParams : userAssignmentAssignment?.id

  const isScoreable = compose(
    notEquals(SCORING_MODE_NONE),
    fallbackTo(SCORING_MODE_NONE),
    get('scoring.mode'),
  )(interactive)

  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))

  const showTeacherHeader = isCurrentUserStaff
    && !presenterModeEnabled
    && assignmentId
    && (submittableInteractive || isScoreable) // Activity interactives are scoreable but not submittable.

  const expander = Boolean(displayConfig.expandable) && (
    <Expander
      displayConfig={displayConfig}
      expanded={expanded}
      frameless={frameless}
      onChange={toggleExpanded}
      top={previewing || expanded || !frameless ? 0 : 40}
    />
  )

  const teacherHeader = showTeacherHeader
    ? (
      <Suspense fallback={<BusySpinner />}>
        <TeacherHeader expander={expander} />
      </Suspense>
    )
    : null

  return (
    <Container
      sx={headerSx}
      {...rest}
    >
      {teacherHeader ? (
        <>
          {teacherHeader}
          <StyledStack {...{ attachedScaffolds, frameless, headerContainsContent }}>
            <Preamble displayConfig={displayConfig} />
          </StyledStack>

        </>
      ) : (
        <StyledStack {...{ frameless, headerContainsContent }}>
          <Preamble displayConfig={displayConfig} />
          {expander}
        </StyledStack>
      )}
    </Container>
  )
}

Header.propTypes = {
  displayConfig: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
}

export default Header
