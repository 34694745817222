import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import useNavigation from 'hooks/useNavigation'
import { contentBuilderUrl } from 'routing/consts'
import { ABILITY_CONTENT_CREATION, CONTENT_TYPE_COURSE } from 'core/consts'
import { contentShape } from 'core/shapes'
import MenuButton from 'common/menus/MenuButton'
import withAbilityCheck from 'hoc/withAbilityCheck'
import DeletionMenuItem from '../DeletionMenuItem'

const Menu = withAbilityCheck(({ content }) => {
  const { navigate } = useNavigation()

  const handleEdit = () => {
    navigate(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/${content.id}`)
  }

  return (
    <MenuButton label="Edit Menu">

      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <Divider />
      <DeletionMenuItem content={content} />

    </MenuButton>
  )
}, ABILITY_CONTENT_CREATION)

Menu.propTypes = {
  content: contentShape.isRequired,
}

export default Menu
