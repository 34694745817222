import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import StarFilled from 'hss/images/controls/radio/star-filled.svg'
import ReviewAccordion from 'common/reviews/ReviewAccordion'
import { contentShape } from 'core/shapes'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import { get } from 'fp/objects'
import { getEchoRubric } from 'selectors/echo'
import { findObj } from 'fp/arrays'
import { average } from 'fp/numbers'
import { withInteractiveProvider } from '../providerUtils'
import ImageRenderer from '../FeaturePictureThis/ImageRenderer'
import FeatureBlastStaticRenderer from '../FeatureBlast/FeatureBlastStaticRenderer'
import FeatureTalkBackStaticRenderer from '../FeatureTalkBack/FeatureTalkBackStaticRenderer'
import useEchoReactions from './useEchoReactions'

const FeatureBlast = withInteractiveProvider(FeatureBlastStaticRenderer, ECHO_FEATURE_BLASTY_BLAST)
const FeaturePictureThis = withInteractiveProvider(ImageRenderer, ECHO_FEATURE_PICTURE_THIS)
const FeatureTalkBack = withInteractiveProvider(FeatureTalkBackStaticRenderer, ECHO_FEATURE_TALK_BACK)

const YourResponse = ({ echo }) => {
  const { palette } = useTheme()
  const { received } = useEchoReactions()

  const rubricContent = useSelector(getEchoRubric(echo)) || {}
  const { options = [] } = rubricContent?.children[0]?.data || {}

  const { data: { responseType } } = echo

  const rating = useMemo(
    () => average(...received
      .map(get('reactionData.rubricSelections.0'))
      .map(value => findObj('id', value)(options))
      .filter(Boolean)
      .map(get('score'))),
    [options, received],
  )

  const render = useMemo(() => {
    switch (responseType) {
      case 'echo-audio': return FeatureTalkBack
      case 'echo-picture': return FeaturePictureThis
      case 'echo-text': return FeatureBlast
      default: return null
    }
  }, [responseType])

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="flex-end"
        pb={0}
        sx={{
          textTransform: 'uppercase',
          'svg path': {
            fill: palette.common.yellow,
            stroke: palette.common.yellow,
          },
        }}
      >
        <StarFilled />

        <Typography
          mb={0}
          variant="eyebrow"
        >
          Rating
        </Typography>
      </Box>

      <ReviewAccordion
        displayMode="numeric"
        expandable
        ratingOrRatingId={rating}
        readOnly
        render={render}
        title="Your Response"
      />
    </>
  )
}

YourResponse.propTypes = {
  echo: contentShape.isRequired,
}

export default YourResponse
