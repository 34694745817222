import PropTypes from 'prop-types'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { useSelector } from 'react-redux'
import { contentShape } from 'core/shapes'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { contentIsViewableByUser } from 'selectors/content'
import CardLabel from 'hss/views/Card/CardLabel'
import withProps from 'hoc/withProps'
import Card from 'hss/views/Card'
import { noop } from 'fp/utils'
import PosterImage from '../../Card/PosterImage'
import CardActionMenu from '../Search/CardActionMenu'

const viewMode = 'grid'

const ContentRenderer = (props) => {
  const {
    content,
    contentCreator = false,
    handlePreview = noop,
  } = props
  const { contentType } = content
  const isInteractive = contentType === CONTENT_TYPE_INTERACTIVE

  const viewable = useSelector(contentIsViewableByUser(content))

  return viewable
    ? (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ p: { margin: 0 } }}
      >

        <CardActionArea
          onClick={isInteractive ? handlePreview : null}
          variant="tertiary"
        >
          <CardContent>
            <CardLabel {...props} />
          </CardContent>
        </CardActionArea>

        {Boolean(contentCreator) && (
          <CardActionMenu
            contentId={content.id}
            contentSubType={content.contentSubType}
            contentType={contentType}
          />
        )}

      </Stack>
    )
    : null
}

ContentRenderer.propTypes = {
  content: contentShape.isRequired,
  contentCreator: PropTypes.bool,
  handlePreview: PropTypes.func,
}

const LibraryCard = withProps(
  Card,
  {
    ContentRenderer,
    gridModeDirection: 'ltr',
    gridModeImageAspectRatio: '16/9',
    ImageRenderer: withProps(
      PosterImage,
      {
        includeLinkToContent: true,
        viewMode,
      },
    ),
    listModeDirection: 'ltr',
    listModeImageAspectRatio: '1/1',
    viewMode,
  },
)

LibraryCard.propTypes = {
  content: contentShape.isRequired,
  contentCreator: PropTypes.bool,
  customType: PropTypes.string, // escape hatch for non-content items
  handlePreview: PropTypes.func,
}

export default LibraryCard
