import EnhancedContentHtml from 'hss/ContentViewer/Chapter/Block/EnhancedContentHtml'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { contentShape } from 'core/shapes'

const ScaffoldRenderer = (props) => {
  const { scaffold } = props

  /* istanbul ignore next */
  const body = scaffold.data.body || ''

  return (
    <EnhancedContentHtml
      body={(body)}
      childrenMetadata={[]}
      className="attached-scaffold"
      data-contentid={scaffold.id}
      data-contenttype={CONTENT_TYPE_SCAFFOLD}
    />
  )
}

ScaffoldRenderer.propTypes = {
  scaffold: contentShape.isRequired,
}

export default ScaffoldRenderer
