import PropTypes from 'prop-types'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import { get, set } from 'fp/objects'

const Options = ({ onChange, preferences }) => {
  const handleChange = propName => () => {
    const value = get(propName)(preferences)
    onChange(set(propName, !value))
  }

  return (
    <>
      <SwitchFormControl
        checked={get('audioDescriptionPause')(preferences)}
        color="secondary"
        helperText="This may allow you to better hear both the description and the video's sound. It works best when browser speech is also enabled (below)."
        label="Pause video during descriptions"
        onChange={handleChange('audioDescriptionPause')}
      />

      <SwitchFormControl
        checked={get('useBrowserSpeech')(preferences)}
        color="secondary"
        helperText="This may be useful if you are not using separate screen reader software."
        label="Use browser speech instead of screen reader"
        onChange={handleChange('useBrowserSpeech')}
      />
    </>
  )
}

Options.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default Options
