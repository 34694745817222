import { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cl from 'classnames'
import useSVGTextLength from 'hooks/useSVGTextLength'
import { dedupe } from 'fp/arrays'
import { isEmptyString } from 'fp/strings'
import { get, set } from 'fp/objects'
import { lineHeight, paragraphSpacing } from './config'

const VennDiagramText = (props) => {
  const {
    align,
    children = '',
    fontSize,
    getXBoundsAtY,
    middleX,
    middleY,
    readOnly = false,
    yMax,
    yMin,
  } = props

  const space = '\u00A0'
  const ref = useRef()
  const [widths, calculating, setCalculating] = useSVGTextLength(ref)
  const text = isEmptyString(children) ? (readOnly ? '(No response)' : 'Add thoughts…') : children

  const words = dedupe([...text.split(/\s+/), space])
  const paragraphs = children.split('\n').filter(p => p)
  const lineSpacing = lineHeight * fontSize
  const alignment = {
    left: { textAnchor: 'start', textX: 'min' },
    middle: { textAnchor: 'middle', textX: 'mid' },
    right: { textAnchor: 'end', textX: 'max' },
  }
  const { textAnchor, textX } = alignment[align]

  const firstWordWidth = calculating ? 0 : widths[words[0]]

  // find a space that will fit the first word
  let startY = yMin + fontSize
  while (firstWordWidth && getXBoundsAtY(startY).width < firstWordWidth) {
    startY += fontSize * 0.1
  }

  const wordsByLine = useMemo(
    () => !calculating && !isEmptyString(children)
      ? paragraphs.reduce((texts, p, pidx) => {
        const pwords = p.split(/\s+/)
        if (texts.full) {
          return texts
        }
        const isFirstParagraph = pidx === 0
        const pLines = pwords.reduce((acc, word, idx) => {
          const { currentWidth, currentY, full, textLines } = acc

          if (full) {
            return acc
          }
          const isFirstWord = idx === 0
          const xBounds = getXBoundsAtY(currentY)
          const lastLine = textLines[textLines.length - 1]
          const newWidth = currentWidth + widths[word] + (isFirstWord ? 0 : widths[space])

          const newY = isFirstWord && isFirstParagraph ? currentY : currentY + lineSpacing
          const nextLineXBounds = getXBoundsAtY(newY)
          const fitsOnCurrentLine = xBounds.width > newWidth
          const fitsOnNextLine = nextLineXBounds.width > widths[word]

          if (((!fitsOnCurrentLine && !fitsOnNextLine && !isFirstWord)
            || (!fitsOnCurrentLine && newY > yMax))) {
            return { ...acc, full: true }
          }

          const newTextLines = fitsOnCurrentLine && !isFirstWord
            ? set(`${textLines.length - 1}.text`, `${lastLine.text} ${word}`)(textLines)
            : [...textLines, {
              x: get(textX)(nextLineXBounds),
              y: newY,
              text: word,
            }]

          return {
            currentY: newWidth > xBounds.width || (isFirstWord && !isFirstParagraph)
              ? newY : currentY,
            textLines: newTextLines,
            currentWidth: fitsOnCurrentLine ? newWidth : widths[word],
          }
        }, { currentY: texts.paragraphY, textLines: [], currentWidth: 0 })

        return {
          paragraphY: pLines.currentY + lineSpacing * paragraphSpacing,
          tl: [...texts.tl, ...pLines.textLines],
          full: pLines.full,
        }
      }, { paragraphY: startY, tl: [] })
      : { tl: [{ text, x: middleX, y: middleY }] },
    [
      calculating,
      children,
      getXBoundsAtY,
      lineSpacing,
      middleX,
      middleY,
      paragraphs,
      startY,
      text,
      textX,
      widths,
      yMax,
    ],
  )

  useEffect(() => {
    setCalculating(true)
  }, [children, setCalculating])

  return (
    calculating
      ? (
        <text
          ref={ref}
          style={{ fontSize }}
        >
          {words.map(word => <tspan key={word}>{word}</tspan>)}
        </text>
      )
      : (
        <text
          aria-hidden="true"
          className={cl({ placeholder: isEmptyString(children) && !readOnly })}
          style={{ textAnchor: isEmptyString(children) ? 'middle' : textAnchor, fontSize }}
        >
          {wordsByLine.tl.map((line, idx) => (
            <tspan
              key={idx}
              x={line.x}
              y={line.y}
            >
              {line.text}
              {Boolean(wordsByLine.full && idx === wordsByLine.tl.length - 1) && '…'}
            </tspan>
          ))}
        </text>
      )

  )
}

VennDiagramText.propTypes = {
  align: PropTypes.string.isRequired,
  children: PropTypes.node,
  fontSize: PropTypes.number.isRequired,
  getXBoundsAtY: PropTypes.func.isRequired,
  middleX: PropTypes.number.isRequired,
  middleY: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  yMax: PropTypes.number.isRequired,
  yMin: PropTypes.number.isRequired,
}

export default VennDiagramText
