import Backdrop from '@mui/material/Backdrop'
import PropTypes from 'prop-types'
import { useFocusTrap } from 'react-use-focus-trap'
import { componentShape } from 'core/shapes'

const ExpanderBackdrop = ({ children, expanded, onClick }) => {
  const [focusTrapRef] = useFocusTrap()

  return expanded
    ? (
      <Backdrop
        onClick={onClick}
        open
        ref={focusTrapRef}
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        {/* We need *something* here -- an empty div works fine */}
        <div>{children}</div>
      </Backdrop>
    )
    : null
}

ExpanderBackdrop.propTypes = {
  children: componentShape,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

export default ExpanderBackdrop
