import PropTypes from 'prop-types'
import { contentTypeShape } from 'core/shapes'
import withProps from 'hoc/withProps'
import useContent from 'hooks/useContent'

const ContentBreadcrumb = ({ contentType, match: { params } }) => {
  const { contentId } = params
  const content = useContent({ contentType, contentId, disableFetch: true })

  return content?.name || null
}

ContentBreadcrumb.propTypes = {
  contentType: contentTypeShape.isRequired,
  match: PropTypes.object.isRequired,
}

const ContentBreadcrumbWithContent = contentType => withProps(ContentBreadcrumb, { contentType })

export default ContentBreadcrumbWithContent
