import Stack from '@mui/material/Stack'
import { useFormContext, useWatch } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { useContext, useEffect, useMemo } from 'react'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import useToggleState from 'hooks/useToggleState'
import { flatten } from 'core/fp/arrays'
import { isDefined, isUndefined } from 'fp/utils'
import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  LEVELED_TEXT_BODY_FIELD_NAME,
  SECTION_CONTENT_TYPES,
  USE_LEVELED_TEXT_BY_PROFICIENCY,
  USE_LEVELED_TEXT_FOR_ALL_STUDENTS,
} from 'core/consts'
import { hasAlternateBodiesForSelfOrDescendants } from 'selectors/content'
import Select from 'common/formControls/selects/Select'
import { get } from 'fp/objects'
import { getContentNav } from 'selectors/contentViewer'
import { assignmentEditContext } from './assignmentEditContext'

const flattenChildren = item => [
  item,
  ...flatten(item?.children?.map(flattenChildren)),
]

const StudentSupport = () => {
  const [contentId, includeChapterSummary] = useWatch({ name: [
    'contentId',
    'data.settings.includeChapterSummary',
  ] })

  const contentNav = useSelector(getContentNav({
    contentId,
    leafContentTypes: SECTION_CONTENT_TYPES,
  }))

  const hasLeveledText = compose(
    useSelector,
    hasAlternateBodiesForSelfOrDescendants,
  )({ kind: LEVELED_TEXT_BODY_FIELD_NAME, contentId })

  // Using the edit context (not the form context) as the source of truth for the leveled text setting, because:
  // a) We don't want it to reset if the user views some content in the assignment and then comes back to settings.
  // b) The edit context will provide the current value (possibly not yet saved) to other components that need it.
  // This behavior is similar to excludedContentIds in the Contents section.
  const {
    leveledNarrativeTextSetting,
    setIncludeChapterSummary,
    setLeveledNarrativeTextSetting,
  } = useContext(assignmentEditContext)
  const [leveledTextsAreEnabled, toggleLeveledTextsAreEnabled] = useToggleState(Boolean(leveledNarrativeTextSetting))

  // But still keeping the form context in sync with the edit context
  const { setValue } = useFormContext()
  useEffect(
    () => { setValue('data.settings.leveledNarrativeText', leveledNarrativeTextSetting) },
    [leveledNarrativeTextSetting, setValue],
  )

  const hasChapterSummary = useMemo(() => contentNav
    .map(flattenChildren)
    .flat()
    .map(get('contentSubType'))
    .includes(CONTENT_SUBTYPE_CHAPTER_SUMMARY), [contentNav])

  useEffect(() => {
    if (hasChapterSummary) {
      setIncludeChapterSummary(includeChapterSummary)
    } else {
      setIncludeChapterSummary(false)
    }
  }, [hasChapterSummary, includeChapterSummary, setIncludeChapterSummary])

  const handleToggle = () => {
    if (isDefined(leveledNarrativeTextSetting) && leveledTextsAreEnabled) {
      setLeveledNarrativeTextSetting(undefined)
    } else if (isUndefined(leveledNarrativeTextSetting) && !leveledTextsAreEnabled) {
      setLeveledNarrativeTextSetting(USE_LEVELED_TEXT_BY_PROFICIENCY)
    }
    toggleLeveledTextsAreEnabled()
  }

  const handleSelectionChange = compose(setLeveledNarrativeTextSetting, get('target.value'))

  return (
    <Stack>

      {!!hasChapterSummary && (
        <HFSwitch
          helperText="These appear as the second tab of the first TE section.  For students, they appear as the last subsection of the first section"
          label="Include Chapter Summary"
          name="data.settings.includeChapterSummary"
        />
      )}

      <HFSwitch
        helperText="Include audio voiceovers for text summaries."
        label="Audio Voiceovers"
        name="data.settings.audioVoiceovers"
      />

      <HFSwitch
        helperText="Show scaffolds to appropriate students."
        label="Scaffolds"
        name="data.settings.scaffolds"
      />

      {Boolean(hasLeveledText) && (
        <>
          <SwitchFormControl
            checked={leveledTextsAreEnabled}
            helperText="Show lower Lexile level content where available."
            label="Leveled Narrative Text"
            onChange={handleToggle}
            sx={{ mb: 1 }}
          />

          {Boolean(leveledNarrativeTextSetting) && (
            <Select
              label="Show lower Lexile level to:"
              name="_leveledNarrativeTextSetting"
              onChange={handleSelectionChange}
              sx={{ ml: 7 }}
              value={leveledNarrativeTextSetting}
            >
              <MenuItem value={USE_LEVELED_TEXT_BY_PROFICIENCY}>
                English learners and students with below-level proficiency
              </MenuItem>

              <MenuItem value={USE_LEVELED_TEXT_FOR_ALL_STUDENTS}>
                All students
              </MenuItem>
            </Select>
          )}
        </>
      )}
    </Stack>
  )
}

StudentSupport.formFields = [
  'data.settings.audioVoiceovers',
  'data.settings.leveledNarrativeText',
  'data.settings.scaffolds',
]

export default StudentSupport
