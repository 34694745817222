import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import { Lock, Unlock } from 'react-feather'
import Tooltip from '@mui/material/Tooltip'
import useHoverFocus from 'hooks/useHoverFocus'

const ReopenButton = ({ handleClick, studentName, reopenAssignment = false }) => {
  const [ref, isHovered, isFocused] = useHoverFocus()
  const title = `Reopen ${reopenAssignment ? 'Assignment' : 'Interactive'} for ${studentName}`

  return (
    <Tooltip title={title}>
      <IconButton
        aria-label={title}
        onClick={handleClick}
        ref={ref}
      >
        {isFocused || isHovered
          ? <Unlock />
          : <Lock />}
      </IconButton>
    </Tooltip>
  )
}
ReopenButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  studentName: PropTypes.string.isRequired,
  reopenAssignment: PropTypes.bool,
}

export default ReopenButton
