import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { Check, Info } from 'react-feather'
import { useContext } from 'react'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import Popper from 'common/indicators/Popper'
import StudentAssignmentPicker from 'hss/sections/contentBlocks/Interactive/Teacher/StudentAssignmentPicker'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { interactiveContext } from '../InteractiveProvider'
import SubmitButton from './SubmitButton'
import GradeIndicator from './GradeIndicator'
import ReopenButton from './ReopenButton'
import useGradingSummary from './useGradingSummary'
import { gradeIndicators } from './utils'
import GradingStatusBar from './GradingStatusBar'

const GradingSummary = (props) => {
  const gradingSummary = useGradingSummary()
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      {...props}
    >
      {Object.entries(gradingSummary)
        .filter(([, summaryUserAssignments]) => !!summaryUserAssignments.length)
        .map(([status, summaryUserAssignments]) => {
          const { Icon, color, label } = gradeIndicators[status]
          return (
            <Popper
              key={status}
              paperStyle={{ minWidth: '30rem' }}
            >
              <Popper.Control>
                <Button variant="mock-link">
                  <GradeIndicator {...{ color, numStudents: summaryUserAssignments.length }}>
                    <Icon />
                  </GradeIndicator>
                </Button>
              </Popper.Control>
              <Popper.Content>
                <Stack>
                  <Typography variant="feature-paragraph-semibold">{label}: {summaryUserAssignments.length}</Typography>
                  <List>
                    {summaryUserAssignments.map(({ user: { firstName, id, lastName } }) => (
                      <ListItem key={id}>{firstName} {lastName}</ListItem>
                    ))}
                  </List>
                </Stack>
              </Popper.Content>
            </Popper>
          )
        })}
    </Stack>
  )
}

const defaultLayoutProps = {
  container: {
    display: 'flex',
    direction: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  gradingSummary: {
    flex: 2,
  },
  studentAssignmentPicker: {
    flex: 3,
    maxWidth: '40rem',
  },
  other: {
    direction: 'row',
    flex: 2,
    gap: 1,
    justifyContent: 'flex-end',
  },
}

const smallLayoutProps = {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  gradingSummary: {
    gridRow: 1,
    gridColumn: 1,
  },
  studentAssignmentPicker: {
    gridRow: 2,
    gridColumn: '1 / span 2',
    width: '100%',
  },
  other: {
    gridRow: 1,
    gridColumn: 2,
    direction: 'row',
    gap: 1,
    justifyContent: 'flex-end',
  },
}

const Header = ({ expander, forEcho = false }) => {
  const {
    gradingEnabled,
    hideStudentPicker,
    interaction: { userAssignmentId } = {},
    scoreData: { requiresGrading } = {},
  } = useContext(interactiveContext)

  const isSmall = useContainerQuery().down('md') || forEcho
  const layoutProps = isSmall ? smallLayoutProps : defaultLayoutProps

  return (
    <>
      <Stack
        borderBottom="1px solid"
        borderColor="grey.3"
        gap={2}
        padding={2}
        style={{
          ...(forEcho && {
            marginBottom: 24,
            paddingTop: 10,
            border: 'none',
          }),
        }}
        width="100%"
        {...layoutProps.container}
      >
        <GradingSummary {...layoutProps.gradingSummary} />
        {!hideStudentPicker && <StudentAssignmentPicker {...layoutProps.studentAssignmentPicker} />}
        <Stack
          minHeight="40px"
          {...layoutProps.other}
        >
          <SubmitButton />
          <ReopenButton />
          {!forEcho && expander}
        </Stack>
      </Stack>

      {Boolean(!gradingEnabled && userAssignmentId)
      && (
        <GradingStatusBar
          backgroundColor="cobalt.4"
          Icon={<Info />}
          statusText="Grading enables when the interactive or assignment is submitted or the end date has passed."
        />
      )}

      {/* requiresGrading will be undefined if grading was never required i.e. auto-scored */}
      {Boolean(requiresGrading === false) && (
        <GradingStatusBar
          backgroundColor="success.light"
          Icon={<Check />}
          statusText="Graded"
        />
      )}
    </>
  )
}

Header.propTypes = {
  expander: PropTypes.node,
  forEcho: PropTypes.bool,
}

export default Header
