import { useContext, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { compose } from 'redux'
import { context } from 'hss/views/Search/SearchProvider'
import Select from 'common/formControls/selects/Select'
import { get } from 'fp/objects'
import { dedupeById } from 'fp/arrays'
import { alter } from 'core/store/search/squery'
import { identity } from 'fp/utils'

const CreatedByFilter = () => {
  const {
    dataset: { data },
    setSquery,
    squery,
  } = useContext(context)
  const [createdBy, setCreatedBy] = useState('')

  const createdByList = dedupeById(data.map(get('createdBy')), 'id')

  const handleChange = ({ target }) => {
    setCreatedBy(target.value)
    compose(
      setSquery,
      target.value !== '-1' ? alter.set.where('createdById').is(target.value) : identity,
      alter.remove.where('createdById'),
    )(squery)
  }

  return (
    <Select
      label="Created By"
      margin="dense"
      name="createdBy"
      onChange={handleChange}
      sx={{
        minWidth: 20 * 8,
        maxWidth: 30 * 8,
        mt: 0.5,
      }}
      value={createdBy}
    >
      <MenuItem
        key="-1"
        value="-1"
      >
        All
      </MenuItem>
      {createdByList.map(l => (
        <MenuItem
          key={l.id}
          value={l.id}
        >
          {l.firstName} {l.lastName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CreatedByFilter
