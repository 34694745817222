import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { labels } from 'hss/ContentBuilder/consts'
import CardIcon from 'hss/views/Card/CardIcon'

const StyledButton = styled(
  Button,
  { name: 'Filter-Button' },
)(() => ({
  marginBottom: '2rem',
  marginRight: '2rem',
}))

const FilterButtons = ({
  filters,
  handleFilterClick,
  selectedFilters = [],
  subContentType = null,
}) => (
  <Box
    display="flex"
    flexWrap="wrap"
    my={2}
  >
    {filters.map(filter => (

      <StyledButton
        color="secondary"
        data-subvariant="filter"
        key={filter}
        onClick={handleFilterClick(filter)}
        startIcon={(
          <CardIcon
            contentSubType={subContentType ? filter : null}
            contentType={subContentType ? null : filter}
          />
        )}
        variant={selectedFilters.indexOf(filter) >= 0 ? 'primary' : 'secondary'}
      >
        {labels[filter]}
      </StyledButton>

    ))}

  </Box>
)

FilterButtons.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  subContentType: PropTypes.string,
}

export default FilterButtons
