import PropTypes from 'prop-types'
import { useContext } from 'react'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { isUndefined } from 'fp/utils'
import { INTERACTIVE_TYPE_WORD_CLOUD } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import EntryForm from './EntryForm'
import DisplayResults from './DisplayResults'

const WordCloud = withQuestionPrompt(({ displayResults: displayResultsProp = false }) => {
  const {
    completed,
    isGrading,
    submittable,
  } = useContext(interactiveContext)
  const { currentUserAssignmentId } = useContext(userAssignmentSelectionContext) || {}

  const displayResults = displayResultsProp || (isGrading
    ? isUndefined(currentUserAssignmentId)
    : completed || !submittable)

  return (
    displayResults
      ? <DisplayResults />
      : <EntryForm />
  )
})

WordCloud.propTypes = {
  displayResults: PropTypes.bool,
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_WORD_CLOUD,
}

export default WordCloud
