import AddIcon from '@mui/icons-material/AddRounded'
import Button from '@mui/material/Button'
import useToggleState from 'hooks/useToggleState'
import useNavigation from 'hooks/useNavigation'
import { contentBuilderUrl } from 'routing/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { ABILITY_CONTENT_CREATION, CONTENT_TYPE_RUBRIC } from 'core/consts'
import SelectAssetTypeDialog from './SelectAssetTypeDialog'

const AddToLibrary = withAbilityCheck(() => {
  const [open, toggleOpen] = useToggleState(false)
  const { navigate } = useNavigation()

  const handleCreate = ({ contentType, contentSubType }) => {
    navigate(contentType === CONTENT_TYPE_RUBRIC
      ? `${contentBuilderUrl}/${contentType}/create`
      : contentSubType
        ? `${contentBuilderUrl}/${contentType}/${contentSubType}/create`
        : `${contentBuilderUrl}/curriculum/${contentType}/create`)
  }

  return (
    <div>
      <Button
        color="secondary"
        onClick={toggleOpen}
        startIcon={<AddIcon />}
        variant="primary"
      >
        Add to Library
      </Button>

      <SelectAssetTypeDialog
        onClose={toggleOpen}
        onProceed={handleCreate}
        open={open}
      />
    </div>
  )
}, ABILITY_CONTENT_CREATION)

export default AddToLibrary
