import MenuItem from '@mui/material/MenuItem'
import { useContext } from 'react'
import { Monitor } from 'react-feather'
import { useSelector } from 'react-redux'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { getLocalSetting } from 'selectors/localSettings'

const PresenterModeMenuItem = () => {
  const { presenterModeAvailable, togglePresenterMode } = useContext(contentViewerContext)
  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))

  return presenterModeAvailable
    ? (
      <MenuItem onClick={togglePresenterMode}>
        <Monitor />
        &nbsp;
        Turn Presenter Mode {presenterModeEnabled ? 'Off' : 'On'}
      </MenuItem>
    )
    : null
}

export default PresenterModeMenuItem
