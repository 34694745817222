import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import Centered from 'common/layout/Centered'
import Well from 'common/layout/Well'
import { getInteractiveShouldBeHidden } from 'selectors/content'
import { interactiveContext } from './InteractiveProvider'

const MaybeHiddenFromStudent = ({ children }) => {
  const { interactive: { id: interactiveId } } = useContext(interactiveContext)
  const hide = useSelector(getInteractiveShouldBeHidden({ interactiveId }))

  return hide
    ? (
      <Well>
        <Centered>
          This activity's details are now hidden for this assignment.
        </Centered>
      </Well>
    )
    : children
}

MaybeHiddenFromStudent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MaybeHiddenFromStudent
