import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import ScreenReaderText from 'common/text/ScreenReaderText'
import withFetchedItem from 'hoc/withFetchedItem'
import actionTypes from 'reducers/actionTypes'
import TeachBanner from 'hss/ContentViewer/Banner/TeachBanner'
import Headline from 'common/text/Headline'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_ASSESSMENT } from 'core/consts'
import AssessmentForm from './AssessmentForm'

const Edit = ({ assignment, title }) => (
  <>
    <TeachBanner title="Assignment Settings" />
    <Container>
      <Headline title={<ScreenReaderText>{title}</ScreenReaderText>}>
        <AssessmentForm assignment={assignment} />
      </Headline>
    </Container>
  </>
)
Edit.propTypes = {
  assignment: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export const EditExisting = withFetchedItem(({ item: assignment }) => (
  <Edit
    assignment={assignment}
    title="Edit Assignment"
  />
), { actionType: actionTypes.ASSIGNMENT_FETCH })

export const EditNew = () => {
  const { contentId } = useParams()
  const content = useContent({ contentType: CONTENT_TYPE_ASSESSMENT })
  const { name } = content || {}
  return content && (
    <Edit
      assignment={{
        contentId,
        name,
      }}
      title="Create Assignment"
    />
  )
}
