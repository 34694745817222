/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { pauseShape } from './utils'

const Pause = (props) => {
  const { className, currentFrame, frameCount, pause, setCurrentFrame } = props
  const { endFrame, startFrame } = pause

  const style = useMemo(() => {
    const percentLeft = currentFrame > startFrame
      ? (startFrame / frameCount) * 100
      : 0

    const percentWidth = ((Math.min(endFrame, currentFrame) - startFrame)
      / frameCount) * 100

    return ({
      left: `${percentLeft}%`,
      display: percentLeft ? 'unset' : 'none',
      width: `${percentWidth}%`,
    })
  }, [currentFrame, endFrame, frameCount, startFrame])

  return (
    <button
      aria-label="Jump to section"
      className={className}
      onClick={() => setCurrentFrame(pause.startFrame)}
      style={style}
      type="button"
    />
  )
}

Pause.propTypes = {
  currentFrame: PropTypes.number.isRequired,
  frameCount: PropTypes.number.isRequired,
  pause: pauseShape.isRequired,
  setCurrentFrame: PropTypes.func.isRequired,
}

export default Pause
