import { compose } from 'redux'
import add from 'date-fns/fp/add'
import set from 'date-fns/fp/set'
import { SCORING_FEEDBACK_CONTENT_ALL, SCORING_FEEDBACK_VISIBILITY_IMMEDIATE } from 'core/consts'

const defaultStartDate = set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 })
const defaultEndDate = compose(
  add({ days: 8, seconds: -1 }),
  set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
)
const defaultReviewEnd = compose(
  add({ days: 3 }),
  defaultEndDate,
)

// eslint-disable-next-line import/prefer-default-export
export const baseAssignment = {
  /**
   * any changes here need to be made within shared/schema/assignment.js too
   */
  data: {
    settings: {
      allowExternalLinks: true,
      allowLate: false,
      allowResearchLinks: true,
      audioVoiceovers: true,
      includeChapterSummary: false,
      reviewEndDate: defaultReviewEnd(new Date()),
      scaffolds: true,
      scoringFeedbackContent: SCORING_FEEDBACK_CONTENT_ALL,
      scoringFeedbackVisibility: SCORING_FEEDBACK_VISIBILITY_IMMEDIATE,
    },
  },
  endDate: defaultEndDate(new Date()),
  excludedContentIds: [],
  isActive: true,
  startDate: defaultStartDate(new Date()),
}
