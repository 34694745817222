import PropTypes from 'prop-types'
import Link from 'common/navigation/links/Link'
import { getTeacherAssignmentUserRoute } from 'routing/consts'
import RequiresGradingIndicator from './RequiresGradingIndicator'

const AssignmentScoreCell = ({ assignmentId, contentType, cell: { renderValue }, scoreAccessorFn }) => {
  const userAssignment = renderValue()
  const {
    id: userAssignmentId,
    requiresGrading,
    userId,
  } = userAssignment

  return userAssignment.id
    ? (
      <Link to={getTeacherAssignmentUserRoute({ assignmentId, contentType, userAssignmentId, userId })}>
        {scoreAccessorFn(userAssignment)}
        {Boolean(requiresGrading) && <RequiresGradingIndicator />}
      </Link>
    )
    : '—'
}

AssignmentScoreCell.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  cell: PropTypes.shape({
    renderValue: PropTypes.func.isRequired,
  }).isRequired,
  scoreAccessorFn: PropTypes.func.isRequired,
}

export default AssignmentScoreCell
