import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import { pluralize, suffix } from 'fp/strings'

export const StyledResults = styled(
  List,
  { name: 'Poll-ChartResult' },
)(({ theme: { mixins: { em, rem }, palette, typography } }) => ({
  li: {
    backgroundColor: palette.background.paper,
    position: 'relative',
    marginTop: rem(1),
    padding: em(1),
    display: 'flex',
    justifyContent: 'space-between',
    gap: rem(2),
    textAlign: 'left',

    span: {
      display: 'block',
    },

    'span:last-child': {
      whiteSpace: 'nowrap',
    },

    aside: {
      ...typography.variants.small,
      color: palette.text.primary,
      display: 'none',
      marginTop: rem(1.2),
    },

    '.poll-within-echo &': {
      backgroundColor: palette.grey[5],
    },

    '&.selected': {
      span: {
        color: palette.cobalt[0],
        ...typography.variants['body1-semibold'],
      },
      aside: { display: 'block' },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 6,
        height: '100%',
        backgroundColor: palette.cobalt[0],
      },
    },
  },

}))

const formatPercent = percent => suffix('%')((percent * 100).toFixed(2))

const ChartResult = ({ isAssignmentCreator, item, selectedValue }) => {
  const { id, label, percent, value } = item

  return (
    <li className={selectedValue === id ? 'selected' : undefined}>
      <span>
        <span>{label}</span>
        <aside>{isAssignmentCreator ? 'Their' : 'Your'} Answer</aside>
      </span>

      <span>
        {formatPercent(percent)}
        {' '}
        ({pluralize('vote')(value)})
      </span>
    </li>
  )
}

ChartResult.propTypes = {
  isAssignmentCreator: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    percent: PropTypes.number,
    value: PropTypes.number,
  }).isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default ChartResult
