import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import { useLocation } from 'react-router-dom'
import { settingsUrl } from 'routing/consts'
import Link from 'common/navigation/links/Link'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { ABILITY_ASSIGNMENT_CREATION, ABILITY_CONTENT_EDITING } from 'core/consts'

const sections = [
  {
    label:
    'Account',
    url: `${settingsUrl}/account`,
  },
  {
    abilityFlags: [
      ABILITY_CONTENT_EDITING,
      ABILITY_ASSIGNMENT_CREATION,
    ],
    label: 'Assignments & Grading',
    url: `${settingsUrl}/assignments`,
  },
  {
    label: 'Accessibility',
    url: `${settingsUrl}/accessibility`,
  },
  {
    abilityFlags: ABILITY_CONTENT_EDITING,
    label: 'Integrations',
    url: `${settingsUrl}/integrations`,
  },
  {
    abilityFlags: [
      ABILITY_CONTENT_EDITING,
      ABILITY_ASSIGNMENT_CREATION,
    ],
    label: 'Notifications',
    url: `${settingsUrl}/notifications`,
  },
]

const Nav = () => {
  const { pathname } = useLocation()
  const has = useAbilityChecker()

  return (
    <List
      style={{
        position: 'sticky',
        top: 100,
        display: 'flex',
        gap: 24,
        flexDirection: 'column',
      }}
    >
      {sections.map(({ label, url, abilityFlags }) => (
        (abilityFlags?.length ? has(abilityFlags) : true)
          ? (
            <ListItem
              disableGutters
              key={label}
              selected={pathname === url}
              style={{ gap: 32 }}
              variant="sub-nav"
            >
              <Link to={url}>
                {label}
              </Link>
            </ListItem>
          )
          : null
      ))}
    </List>
  )
}

export default Nav
