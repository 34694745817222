import { compose } from 'redux'
import { alter, assert } from 'core/store/search/squery'
import { restEndpoint } from 'reducers/utils'
import { schoolsUrl } from 'routing/consts'
import {
  // LinkToTableCell,
  RowActionsMenu,
  RowSelector,
  SimpleCell,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import DataTable from 'common/tables/DataTable'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import useAbilityChecker from 'hooks/useAbilityChecker'

const tableId = 'clSchoolsList'

const buildColumns = (/* has */) => {
  const viewUrl = ({ id }) => `${schoolsUrl}/${id}`

  const nameColumn = asPrimary(SimpleCell({
    // cell: LinkToTableCell(viewUrl),
    header: 'Name',
    id: 'name',
  }))

  return [
    RowSelector({ singleLabel: 'Select School', pluralLabel: 'Select all Schools' }),

    nameColumn,

    RowActionsMenu(() => [
      {
        label: 'View',
        action: { navigate: viewUrl },
      },
    ]),

  ]
}

const searchConfig = (has, limit) => ({

  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.orderBy('name', 'desc'),
  )(assert()),

  pluralLabel: 'Schools',

  restEndpoint: restEndpoint.schools,

  searchFields: ['name'],

  singleLabel: 'Schools',

})

const withSearchProvider = (WrappedComponent) => {
  const Enhanced = (initialTestingState) => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table"
      >
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
