import { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'
import DecorativeImage from 'hss/sections/contentBlocks/interactives/Image/DecorativeImage'
import { CONTENT_TYPE_RUBRIC, INTERACTIVE_TYPE_IMAGE, TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { isStudent } from 'selectors/users'
import { getLocalSetting } from 'selectors/localSettings'
import { getRegisteredInteractives } from './utils'
import { interactiveContext } from './InteractiveProvider'
import SubmissionWrapper from './SubmissionWrapper'
import RubricFeedback from './RubricFeedback'

const registeredInteractives = getRegisteredInteractives()

const InteractiveRenderer = () => {
  const {
    contentBlockVariant,
    interactive: { contentSubType, contentType },
  } = useContext(interactiveContext)

  const InteractiveComponent = contentSubType === INTERACTIVE_TYPE_IMAGE
    && contentBlockVariant === 'chapter-recap'
    ? DecorativeImage
    : registeredInteractives[contentType === CONTENT_TYPE_RUBRIC
      ? contentType
      : contentSubType]

  if (!InteractiveComponent) {
    throw new Error(`
        !!!!!! Invariant state detected !!!!!!

        A content block references an interactive of type "${contentSubType}"
        which is not a known, registered interactive type.

        Interactive types must be one of:

        ${Object.keys(registeredInteractives).join(', ')}
      `)
  }

  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))
  const userIsStudent = useSelector(isStudent)
  const MaybeSubmissionWrapper = userIsStudent || presenterModeEnabled ? SubmissionWrapper : Fragment

  return (
    <MaybeSubmissionWrapper>
      <InteractiveComponent />
      <RubricFeedback />
    </MaybeSubmissionWrapper>
  )
}

export default InteractiveRenderer
