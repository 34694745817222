import PropTypes from 'prop-types'
import { createContext, useCallback, useMemo, useRef, useState } from 'react'
import { compose } from 'redux'
import useRouteSquery from 'hooks/useRouteSquery'
import { alter, inspect } from 'core/store/search/squery'
import { componentShape, contentTypeShape } from 'core/shapes'
import { toggleItem } from 'fp/arrays'
import useLocalSetting from 'hooks/useLocalSetting'
import { fallbackTo, identity } from 'fp/utils'
import { get, omit, pick, set } from 'fp/objects'
import useToggleState from 'hooks/useToggleState'
import {
  assessmentUrl,
  libraryUrl,
} from 'routing/consts'
import {
  SEARCH_TYPE_ASSESSMENT,
  SEARCH_TYPE_LIBRARY,
  searchTypeShape,
} from './consts'

/**
 * Search was originally written around the 'content' endpoint.
 *
 * It has since been expanded, however there still is content-centric code here.
 * As of this writing, it seems more prudent to leave that in place.
 * It's ignored by non-content views, and it's not worth the effort to refactor
 * at this time.
 */

const context = createContext()

const nonSqueryFields = [
  'columns',
  'pluralLabel',
  'searchFields',
  'singleLabel',
]

const searchUrls = {
  [SEARCH_TYPE_ASSESSMENT]: `${assessmentUrl}/assessments`,
  [SEARCH_TYPE_LIBRARY]: `${libraryUrl}/search`,
}

const SearchProvider = (props) => {
  const {
    ItemRenderer,
    children,
    contentSubTypes = [],
    contentTypes = [],
    hideViewToggle = false,
    initialTestingState,
    searchConfig: searchConfigRaw = {},
    searchOnLoad = false,
    searchType,
    tableId,
    viewMode: initialViewMode = 'list',
  } = props

  const topOfSearchDomRef = useRef()

  const searchConfig = compose(
    set(
      'columns',
      fallbackTo([])(get('columns')(searchConfigRaw))
        .flat()
        .filter(Boolean),
    ),
    fallbackTo({}),
  )(searchConfigRaw)

  const rest = pick(nonSqueryFields)(searchConfig)

  const [squery, setSquery, dataset, dangerouslyOverrideDataset] = useRouteSquery({
    searchUrl: searchUrls[searchType],
    searchOnLoad,
    ...omit(nonSqueryFields)(searchConfig),
  })

  const [selectedPreview, setSelectedPreview] = useState()
  const [viewMode, setViewMode] = useLocalSetting(`${searchType}-viewMode`, initialViewMode)

  const [primaryFiltersExpanded, togglePrimaryFiltersExpanded] = useToggleState(false)
  const [secondaryFiltersExpanded, toggleSecondaryFiltersExpanded] = useToggleState(false)

  const [busySaving, setBusySaving] = useState(false)

  const selectedContentTypeFilters = inspect(squery)
    .get.where('contentType').in()

  const selectedContentSubTypeFilters = inspect(squery)
    .get.where('contentSubType').in()

  const alterContentTypeFilters = useCallback(contentType => () => {
    const allSelected = selectedContentTypeFilters.length === contentTypes.length
    const newFilters = allSelected
      ? [contentType]
      : toggleItem(selectedContentTypeFilters)(contentType)

    compose(
      setSquery,
      alter.set.offset(0),
      alter.set.where('contentType').in(newFilters.length === 0
        ? contentTypes
        : newFilters),
      alter.remove.where('contentType'),
    )(squery)
    // don't add squery here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTypes, selectedContentTypeFilters, setSquery])

  const alterContentSubTypeFilters = useCallback(contentSubType => () => {
    const allSelected = selectedContentSubTypeFilters.length === contentSubTypes.length
    const newFilters = allSelected
      ? [contentSubType]
      : toggleItem(selectedContentSubTypeFilters)(contentSubType)

    compose(
      setSquery,
      alter.set.offset(0),
      newFilters.length
        ? alter.set.where('contentSubType').in(newFilters)
        : identity,
      alter.remove.where('contentSubType'),
    )(squery)
    // don't add squery here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentSubTypeFilters, setSquery])

  const updateSearchResults = useCallback(() => {
    setSquery(squery)
    setBusySaving(false)
    // don't add squery here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSquery])

  const startingOptimisticSave = useCallback(({ id, newValue, rowKeyField, valueField }) => {
    setBusySaving(true)
    dangerouslyOverrideDataset(state => set('data', state.data.map(item => item[rowKeyField] === id
      ? set(valueField, newValue)(item)
      : item))(state))
  }, [dangerouslyOverrideDataset])

  const optimisticSaveComplete = () => setBusySaving(false)

  const value = useMemo(() => ({
    alterContentSubTypeFilters,
    alterContentTypeFilters,
    busySaving,
    contentSubTypes,
    contentTypes,
    dataset,
    hideViewToggle,
    initialViewMode,
    ItemRenderer,
    optimisticSaveComplete,
    primaryFiltersExpanded,
    searchType,
    secondaryFiltersExpanded,
    selectedPreview,
    setSelectedPreview,
    setSquery,
    setViewMode,
    squery,
    startingOptimisticSave,
    tableId,
    togglePrimaryFiltersExpanded,
    toggleSecondaryFiltersExpanded,
    topOfSearchDomRef,
    updateSearchResults,
    viewMode,
    ...rest,
    ...initialTestingState,
  }), [
    alterContentSubTypeFilters,
    alterContentTypeFilters,
    busySaving,
    contentSubTypes,
    contentTypes,
    dataset,
    hideViewToggle,
    initialTestingState,
    initialViewMode,
    ItemRenderer,
    startingOptimisticSave,
    primaryFiltersExpanded,
    rest,
    searchType,
    secondaryFiltersExpanded,
    selectedPreview,
    setSquery,
    setViewMode,
    squery,
    tableId,
    togglePrimaryFiltersExpanded,
    toggleSecondaryFiltersExpanded,
    topOfSearchDomRef,
    updateSearchResults,
    viewMode,
  ])

  return (
    <context.Provider value={value}>
      <div ref={topOfSearchDomRef}>
        {children}
      </div>
    </context.Provider>
  )
}

SearchProvider.propTypes = {
  children: componentShape.isRequired,
  contentTypes: PropTypes.arrayOf(contentTypeShape),
  contentSubTypes: PropTypes.arrayOf(PropTypes.string),
  hideViewToggle: PropTypes.bool,
  initialTestingState: PropTypes.object,
  ItemRenderer: componentShape.isRequired,
  searchConfig: PropTypes.object,
  searchOnLoad: PropTypes.bool,
  searchType: searchTypeShape.isRequired,

  tableId: PropTypes.string.isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list', 'table']),
}

export { context, SearchProvider }
