import { styled } from '@mui/material/styles'
import MuiDivider from '@mui/material/Divider'

const Divider = styled(
  props => (
    <MuiDivider
      light
      {...props}
    />
  ),
  { name: 'settings-Divider' },
)(({ theme: { mixins: { rem } } }) => ({
  margin: rem(3.5, 0, 3),
}))

export default Divider
