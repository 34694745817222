import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import { alter, assert } from 'core/store/search/squery'
import { get, set } from 'fp/objects'
import { restEndpoint } from 'reducers/utils'
import { studentsUrl } from 'routing/consts'
import {
  LanguageSelectTableCell,
  LinkToTableCell,
  ProficiencySelectTableCell,
  RowDetailsToggle,
  RowSelector,
  SimpleCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import { prefix } from 'fp/strings'
import {
  firstNameCell,
  lastNameCell,
  lastOnlineCell,
  nextDueDateCell,
  numGroupsCell,
  numTeachersCell,
  openAssignmentsCell,
  proficiencyCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import DataTable from 'common/tables/DataTable'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { FEATURE_FLAG_POST_MVP, FEATURE_FLAG_USERS_API } from 'core/consts'
import withSaving from 'common/tables/DataTable/Cells/withSaving'

const tableId = 'clGroupsReport'

const buildColumns = (has) => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  // Remove this flag when full user data is being pulled from api (Github issue # 111)
  const usersApiComplete = has(FEATURE_FLAG_USERS_API)

  return [

    postMVP && RowSelector({ singleLabel: 'Select Student', pluralLabel: 'Select all Students' }),

    asPrimary(set('cell', LinkToTableCell(compose(
      prefix(`${studentsUrl}/`),
      get('id'),
    ))))(firstNameCell()),

    asPrimary((lastNameCell())),

    set('cell', ProficiencySelectTableCell)(proficiencyCell),

    usersApiComplete && [
      lastOnlineCell,

      asDetail(openAssignmentsCell),

      asDetail(numGroupsCell),
    ],

    postMVP && asDetail(SimpleCell({
      cell: withSaving('users')(LanguageSelectTableCell),
      header: 'Supplemental Language',
      id: 'languageId',
    })),

    usersApiComplete && [
      asDetail(nextDueDateCell),

      asDetail(numTeachersCell),

      RowDetailsToggle(),
    ],

  ]
}

const searchConfig = (has, limit) => groupId => ({

  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.modifier('studentsInGroupId').is(groupId),
  )(assert()),

  pluralLabel: 'Students',

  restEndpoint: restEndpoint.users,

  searchFields: ['firstName', 'lastName'],

  singleLabel: 'Student',

})

const withSearchProvider = (WrappedComponent) => {
  const Enhanced = (initialTestingState) => {
    const { groupId } = useParams()

    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)(groupId)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table"
      >
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
