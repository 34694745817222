import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import useSession from 'hooks/useSession'
import TimeoutWarning from './TimeoutWarning'

let instanceCount = 0

const Session = ({ children }) => {
  const {
    session: {
      sessionActive,
      sessionChecked,
      token,
      warningVisible,
    },
  } = useSession()

  useEffect(() => {
    instanceCount += 1

    /* istanbul ignore next */
    if (instanceCount > 1 && process.env.NODE_ENV !== 'test') {
      throw new Error(`
        !!!!!! Invariant state detected !!!!!!

        There are multiple instances of <Session /> present in the document when
        there can only ever be one.

        The most probable cause is there being more than one protected route having
        been loaded.

        Check the route configuration and ensure there is only one protected route.
      `)
    }
    return () => {
      instanceCount -= 1
    }
  }, [])

  return token !== '' && sessionChecked && sessionActive
    ? (
      <>
        {Boolean(warningVisible) && <TimeoutWarning />}
        {children}
      </>
    )
    : (
      <Navigate
        replace
        to="/login"
      />
    )
}

Session.propTypes = { children: PropTypes.node.isRequired }

export default Session
