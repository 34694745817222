import { useFormContext, useWatch } from 'react-hook-form'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { Eye, EyeOff } from 'react-feather'
import Box from '@mui/material/Box'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { numberOrString } from 'core/shapes'
import { get } from 'fp/objects'
import { difference, filter, flatten, includes } from 'fp/arrays'
import { fallbackTo, matches, not } from 'fp/utils'
import { getContentNav } from 'selectors/contentViewer'
import { assignmentEditContext } from '../assignmentEditContext'
import TreeContentComponent from './TreeContentComponent'

const canBeHidden = item => compose(
  includes(CONTENT_TYPE_SECTION, CONTENT_TYPE_SUBSECTION),
  get('contentType'),
)(item) && !(matches('contentSubType', CONTENT_SUBTYPE_CHAPTER_SUMMARY)(item))

const ContentTreeItem = ({ children, id, name }) => (
  <TreeItem
    ContentComponent={TreeContentComponent}
    ContentProps={{
      notSelectedIcon: EyeOff,
      selectedIcon: Eye,
    }}
    label={name}
    nodeId={id}
  >
    {children
      .filter(canBeHidden)
      .map(child => (
        <ContentTreeItem
          key={child.id}
          {...child}
        />
      ))}
  </TreeItem>
)
ContentTreeItem.propTypes = {
  children: PropTypes.array.isRequired,
  id: numberOrString.isRequired,
  name: PropTypes.string.isRequired,
}

const flattenChildren = item => [
  item,
  ...flatten(item?.children?.map(flattenChildren)),
]

const Contents = () => {
  const contentId = useWatch({ name: 'contentId' })

  const contentNav = useSelector(getContentNav({
    contentId,
    leafContentTypes: SECTION_CONTENT_TYPES,
  }))
  const { setValue } = useFormContext()
  const { excludedContentIds, toggleExcludeContentId } = useContext(assignmentEditContext)

  const hidableContents = useMemo(
    () => compose(
      filter(canBeHidden),
      filter(compose(not, matches('teacherEdition', true))),
      fallbackTo([]),
    )(contentNav),
    [contentNav],
  )

  const allContentIds = useMemo(
    () => flatten((hidableContents || [])
      .map(flattenChildren))
      .map(get('id')),
    [hidableContents],
  )

  const selectedContentIds = difference(allContentIds)(excludedContentIds)

  useDeepCompareEffect(
    () => {
      // The excludedContentIds field is actually ignored by the assignment edit form when determining dirtiness.
      // But it should still be marked dirty here, because it's dirty.
      // (This code runs whenever the user toggles a content node.)
      setValue('excludedContentIds', excludedContentIds, { shouldDirty: true })
    },
    [excludedContentIds, setValue],
  )

  const handleNodeSelect = useCallback((event, nodeId) => {
    toggleExcludeContentId(nodeId)
  }, [toggleExcludeContentId])

  return (
    <Box mt={4}>
      {hidableContents.length
        ? (
          <TreeView
            aria-label="Contents"
            onNodeSelect={handleNodeSelect}
            selected={selectedContentIds}
            variant="content-selections"
          >
            {hidableContents.map(content => (
              <ContentTreeItem
                key={content.id}
                {...content}
              />
            ))}
          </TreeView>
        )
        : 'This type of assignment does not contain portions that can be hidden.'}

    </Box>
  )
}

Contents.formFields = [
  'excludedContentIds',
  'data.settings.includeChapterSummary',
]

export default Contents
