import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import withProps from 'hoc/withProps'

const BasePacingOptionLabel = withProps(Typography, { variant: 'paragraph-semibold' })

export const PacingOptionLabel = styled(
  BasePacingOptionLabel,
  { name: 'PacingOptionLabel' },
)(({ textTransform: 'uppercase' }))

export const PacingOptionLabelCapitalized = styled(
  BasePacingOptionLabel,
  { name: 'PacingOptionLabelCapitalized' },
)(({ textTransform: 'capitalize' }))
