import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import { get, set } from 'fp/objects'

const Options = ({ onChange, preferences }) => {
  const handleChange = propName => () => {
    const value = get(propName)(preferences)
    onChange(set(propName, !value))
  }

  return (
    <Box mt={2.5}>
      <SwitchFormControl
        checked={get('assignmentPrefs.turnitin')(preferences)}
        helperText="Sets the default value for future assignments. Turnitin can still be toggled on or off for each individual assignment during creation."
        label="Submit assignments to Turnitin"
        onChange={handleChange('assignmentPrefs.turnitin')}
      />

      <SwitchFormControl
        checked={get('assignmentPrefs.requireTurnitin')(preferences)}
        helperText="Disallows assignment submission for a Turnitin-enabled assignment unless the current EULA has been accepted in the student account."
        label="Require Turnitin activation before allowing writing assignment submission"
        onChange={handleChange('assignmentPrefs.requireTurnitin')}
      />
    </Box>
  )
}

Options.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default Options
