import { useSelector } from 'react-redux'
import { Fragment, useContext, useRef } from 'react'
import ScrollToTop from 'common/navigation/ScrollToTop'
import Annotatable from 'common/annotations/Annotatable'
import { StyledHeadline } from 'common/text/Headline'
import { getCurrentSection } from 'selectors/contentViewer'
import LearnBanner from 'hss/ContentViewer/Banner/LearnBanner'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import RouteTransition from 'routing/RouteTransition'
import useScrollToTopOnRouteChange from 'hooks/useScrollToTopOnRouteChange'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import Navigation from '../Subsection/Navigation'
import { ContentViewerProvider, contentViewerContext } from '../../ContentViewerProvider'
import Footnotes from '../Subsection/Footnotes'
import Tabs from './Tabs'
import ContentBlocks from './ContentBlocks'

const TabbedSection = () => {
  const isPinned = useIsPinnedContent()
  const { name: sectionName } = useSelector(getCurrentSection({ isPinned })) || {}
  const scrollRef = useRef()
  useScrollToTopOnRouteChange(scrollRef)

  const { subsection: { id: contextContentId, name: subsectionName } } = useContext(contentViewerContext)

  const headline = isPinned ? subsectionName : sectionName

  // Don't use RouteTransition for pinned content, otherwise it would
  // appear to reload every time you navigate between pages on the left side.
  const MaybeRouteTransition = isPinned ? Fragment : RouteTransition

  return Boolean(headline) && (
    <div ref={scrollRef}>

      <LearnBanner />

      {!isPinned && <ScrollToTop querySelector=".layout-pane-primary" />}

      <Annotatable
        contextContentId={contextContentId}
        style={{ minHeight: '75vh' }}
      >

        <StyledHeadline
          contained
          mb={3}
          mt={4}
          size={5}
        >
          {headline}
        </StyledHeadline>

        {!isPinned && (
          <Tabs />
        )}

        <MaybeRouteTransition>

          <HeadlineLevelOffset>

            <ContentBlocks />

            <Footnotes />

          </HeadlineLevelOffset>

        </MaybeRouteTransition>

      </Annotatable>

      <Navigation />

    </div>
  )
}

const WithProvider = () => (
  <ContentViewerProvider>
    <TabbedSection />
  </ContentViewerProvider>
)

export default WithProvider
