import { styled } from '@mui/material/styles'
import { border } from 'polished'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { useContext, useMemo } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import { important } from 'styling/theming/base/mixins'
import { getAggregatedStandardsFromContentChildren, getStandardsForContent } from 'selectors/standards'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { contentViewerContext } from '../../../ContentViewerProvider'
import InstructionalStandards from './InstructionalStandards'
import { variantShape } from './utils'
import { AcademicVocabulary, ContentVocabulary } from './VocabularyForType'

const Footnotes = styled(
  ({
    className,
    content: overriddenContent,
    variant = 'aggregate',
  }) => {
    const { subsection } = useContext(contentViewerContext) || {}
    const content = overriddenContent || subsection

    const Component = useMemo(
      () => (variant === 'aggregate' ? Container : Box),
      [variant],
    )

    const aggregateVocab = useSelector(getAggregatedVocabFromContentChildren({ content }))
    const { academicVocabPhrases, contentVocabPhrases, footnoteVocabPhrases } = aggregateVocab

    const { contentType, id: contentId } = content || {}
    const options = { contentType, contentId }

    const instructionStandards = compose(
      fallbackTo([]),
      get('instructionStandards'),
      useSelector,
      variant === 'aggregate'
        ? getAggregatedStandardsFromContentChildren
        : getStandardsForContent,
    )(options)

    const isEmpty = !academicVocabPhrases.length
      && !contentVocabPhrases.length
      && !footnoteVocabPhrases.length
      && !instructionStandards?.length

    return isEmpty ? (
      <Component sx={{ display: 'none' }} />
    ) : (
      <Component
        sx={
          variant === 'aggregate'
            ? {
              marginBottom: 12,
              marginTop: 12,
            }
            : null
        }
        variant="centered-content"
      >
        <HeadlineStyleOffset offset={5}>
          <Stack
            direction={variant === 'aggregate' ? 'row' : 'column-reverse'}
            spacing={3}
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
              gap: 4,
              '& > div': {
                marginLeft: important(0),
              },
            }}
          >
            <InstructionalStandards
              className={className}
              instructionStandards={instructionStandards}
              variant={variant}
            />

            <ContentVocabulary
              className={className}
              content={content}
              variant={variant}
            />

            <AcademicVocabulary
              className={className}
              content={content}
              variant={variant}
            />
          </Stack>
        </HeadlineStyleOffset>
      </Component>
    )
  },
  { name: 'Subsection-Footnotes' },
)(({ theme: { palette } }) => ({
  '&::before': {
    content: '""',
    display: 'block',
    ...border('top', 1, 'solid', palette.grey[4]),
    marginRight: 20,
    marginBottom: 25,
  },
  marginBottom: 10,
}))

Footnotes.propTypes = {
  variant: variantShape,
}

export default Footnotes
