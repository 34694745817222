import {
  CONTENT_TYPE_RUBRIC,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_SHORT_ANSWER,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
} from 'core/consts'
import Animator from 'hss/sections/contentBlocks/interactives/Animator'
import Audio from 'hss/sections/contentBlocks/interactives/Audio'
import AudioRecording from 'hss/sections/contentBlocks/interactives/AudioRecording'
import Chart from 'hss/sections/contentBlocks/interactives/Chart'
import ChartCompletion from 'hss/sections/contentBlocks/interactives/ChartCompletion'
import DrawTool from 'hss/sections/contentBlocks/interactives/DrawTool'
import Essay from 'hss/sections/contentBlocks/interactives/Essay'
import EvaluationRanking from 'hss/sections/contentBlocks/interactives/EvaluationRanking'
import Flipbook from 'hss/sections/contentBlocks/interactives/Flipbook'
import FunFacts from 'hss/sections/contentBlocks/interactives/FunFacts'
import Hotspot from 'hss/sections/contentBlocks/interactives/Hotspot'
import GroupAndSort from 'hss/sections/contentBlocks/interactives/GroupAndSort'
import Image from 'hss/sections/contentBlocks/interactives/Image'
import ImageClicker from 'hss/sections/contentBlocks/interactives/ImageClicker'
import ImageTitleDrag from 'hss/sections/contentBlocks/interactives/ImageTitleDrag'
import ImageZoomy from 'hss/sections/contentBlocks/interactives/ImageZoomy'
import LinearScale from 'hss/sections/contentBlocks/interactives/LinearScale'
import MultipleChoice from 'hss/sections/contentBlocks/interactives/MultipleChoice'
import PacingGuide from 'hss/sections/contentBlocks/interactives/PacingGuide'
import PlotDiagram from 'hss/sections/contentBlocks/interactives/PlotDiagram'
import Poll from 'hss/sections/contentBlocks/interactives/Poll'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import Activity from 'hss/sections/contentBlocks/interactives/Activity'
import ShortAnswer from 'hss/sections/contentBlocks/interactives/ShortAnswer'
import StackedSequence from 'hss/sections/contentBlocks/interactives/StackedSequence'
import Timeline from 'hss/sections/contentBlocks/interactives/Timeline'
import Video from 'hss/sections/contentBlocks/interactives/Video'
import WordCloud from 'hss/sections/contentBlocks/interactives/WordCloud'
import DGOTable from 'hss/sections/contentBlocks/interactives/DGOTable'
import VennDiagram from 'hss/sections/contentBlocks/interactives/VennDiagram'
import { ECHO_FEATURE_BLASTY_BLAST, ECHO_FEATURE_PICTURE_THIS, ECHO_FEATURE_TALK_BACK } from 'hss/ContentBuilder/consts'
import FeatureBlast from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeatureBlast'
import FeaturePictureThis from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeaturePictureThis'
import FeatureTalkBack from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeatureTalkBack'

// eslint-disable-next-line import/prefer-default-export
export const getRegisteredInteractives = () => ({
  [ECHO_FEATURE_BLASTY_BLAST]: FeatureBlast,
  [ECHO_FEATURE_PICTURE_THIS]: FeaturePictureThis,
  [ECHO_FEATURE_TALK_BACK]: FeatureTalkBack,
  [INTERACTIVE_TYPE_ACTIVITY]: Activity,
  [INTERACTIVE_TYPE_ANIMATOR]: Animator,
  [INTERACTIVE_TYPE_AUDIO]: Audio,
  [INTERACTIVE_TYPE_AUDIO_RECORDING]: AudioRecording,
  [INTERACTIVE_TYPE_CHART]: Chart,
  [INTERACTIVE_TYPE_CHART_COMPLETION]: ChartCompletion,
  [INTERACTIVE_TYPE_DGO_TABLE]: DGOTable,
  [INTERACTIVE_TYPE_DRAW_TOOL]: DrawTool,
  [INTERACTIVE_TYPE_ESSAY]: Essay,
  [INTERACTIVE_TYPE_EVALUATION_RANKING]: EvaluationRanking,
  [INTERACTIVE_TYPE_FLIPBOOK]: Flipbook,
  [INTERACTIVE_TYPE_FUN_FACTS]: FunFacts,
  [INTERACTIVE_TYPE_GROUP_AND_SORT]: GroupAndSort,
  [INTERACTIVE_TYPE_HOTSPOT]: Hotspot,
  [INTERACTIVE_TYPE_IMAGE]: Image,
  [INTERACTIVE_TYPE_IMAGE_CLICKER]: ImageClicker,
  [INTERACTIVE_TYPE_IMAGE_TITLE_DRAG]: ImageTitleDrag,
  [INTERACTIVE_TYPE_IMAGE_ZOOMY]: ImageZoomy,
  [INTERACTIVE_TYPE_LINEAR_SCALE]: LinearScale,
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: MultipleChoice,
  [INTERACTIVE_TYPE_PACING_GUIDE]: PacingGuide,
  [INTERACTIVE_TYPE_PLOT_DIAGRAM]: PlotDiagram,
  [INTERACTIVE_TYPE_POLL]: Poll,
  [CONTENT_TYPE_RUBRIC]: Rubric,
  [INTERACTIVE_TYPE_SHORT_ANSWER]: ShortAnswer,
  [INTERACTIVE_TYPE_STACKED_SEQUENCE]: StackedSequence,
  [INTERACTIVE_TYPE_TIMELINE]: Timeline,
  [INTERACTIVE_TYPE_VENN_DIAGRAM]: VennDiagram,
  [INTERACTIVE_TYPE_VIDEO]: Video,
  [INTERACTIVE_TYPE_WORD_CLOUD]: WordCloud,
})
