import { useCallback, useContext, useEffect, useState } from 'react'
import useImageSize from 'hooks/useImageSize'
import { isEmptyString } from 'fp/strings'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import { INTERACTIVE_TYPE_IMAGE_ZOOMY } from 'core/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Controls from './Controls'
import ImageContainer from './ImageContainer'

const ImageZoomy = () => {
  const {
    interactiveData: { imageAltText },
    uploadsMap,
  } = useContext(interactiveContext)

  const [mouseMode, setMouseMode] = useState('zoom')
  const [zoomLevel, setZoomLevel] = useState(0)

  const imageUrl = getImageUrlFromUploadsMap(uploadsMap)

  const [imageWidth, imageHeight] = useImageSize(imageUrl)
  const [centerCoord, setCenterCoord] = useState([0, 0])

  useEffect(() => {
    setCenterCoord([imageWidth / 2, imageHeight / 2])
  }, [imageWidth, imageHeight])

  useEffect(() => {
    if (zoomLevel === 0) {
      setCenterCoord([imageWidth / 2, imageHeight / 2])
    }
  }, [imageHeight, imageWidth, zoomLevel])

  const resetView = useCallback(() => {
    setZoomLevel(0)
    setCenterCoord([imageWidth / 2, imageHeight / 2])
  }, [imageHeight, imageWidth])

  return isEmptyString(imageUrl)
    ? null
    : (
      <div style={{ position: 'relative' }}>
        <div>
          yes, this is b0rken, when displaying within the content viewer<br />
          it's not worth fixing until we know for sure we'll keep it
        </div>
        <ImageContainer
          {...{
            centerCoord,
            imageAltText,
            imageHeight,
            imageUrl,
            imageWidth,
            mouseMode,
            resetView,
            setCenterCoord,
            setZoomLevel,
            zoomLevel,
          }}
        />

        <Controls {...{ mouseMode, resetView, setMouseMode, setZoomLevel, zoomLevel }} />

      </div>
    )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_IMAGE_ZOOMY,
}

export default ImageZoomy
