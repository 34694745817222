import { useContext } from 'react'
import Centered from 'common/layout/Centered'
import { isDefined } from 'fp/utils'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import { interactiveContext } from '../../InteractiveProvider'

const NoAggregate = () => {
  const { interactive: { scoring: { mode: scoringMode } } } = useContext(interactiveContext)
  const isScoreable = isDefined(scoringMode) && scoringMode !== SCORING_MODE_NONE

  return (
    <Centered>
      {!isScoreable && (
        <>This activity is not scored.<br /></>
      )}
      Select a student to view their response.
    </Centered>
  )
}

export default NoAggregate
