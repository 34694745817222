import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { compose } from 'redux'
import { contentBuilderUrl, libraryUrl } from 'routing/consts'
import { push } from 'fp/arrays'
import { labels } from 'hss/ContentBuilder/consts'
import { prefix } from 'fp/strings'
import { get } from 'fp/objects'
import BreadcrumbsRenderer from '../BreadcrumbsRenderer'

const labeller = verb => compose(
  prefix(verb),
  prefix(' '),
  contentSubType => labels[contentSubType],
  get('match.params.contentSubType'),
)

const breadcrumbConfig = [
  {
    path: `${contentBuilderUrl }/interactive/:contentSubType/create`,
    breadcrumb: labeller('New'),
  },
  {
    path: `${contentBuilderUrl }/interactive/:contentSubType/:contentId`,
    breadcrumb: labeller('Edit'),
  },
  {
    path: `${contentBuilderUrl }/rubric/create`,
    breadcrumb: 'New Rubric',
  },
  {
    path: `${contentBuilderUrl }/rubric/:contentId`,
    breadcrumb: 'Edit Rubric',
  },
]

const ContentBuilderBreadcrumbs = () => {
  const breadcrumbs = push({
    breadcrumb: 'Library',
    key: libraryUrl,
  })(useBreadcrumbs(breadcrumbConfig, { disableDefaults: true }))
    .reverse()

  return (
    <BreadcrumbsRenderer breadcrumbs={breadcrumbs} />
  )
}

export default ContentBuilderBreadcrumbs
