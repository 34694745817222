import Well from 'common/layout/Well'
import DateRangeFilter from 'hss/views/Search/Filters/Controls/DateRangeFilter'
import CreatedByFilter from './CreatedByFilter'
import TargetFilter from './TargetFilter'

const Filters = () => (
  <Well
    columnGap={2}
    direction="row"
    display="flex"
    flexWrap="wrap"
    mr={0}
    rowGap={4}
    sxProps={{ border: 'none', padding: '36px 0 0 0' }}
  >
    <TargetFilter />
    <CreatedByFilter />
    <DateRangeFilter
      label="Creation Date"
      name="dateCreated"
    />
  </Well>
)

export default Filters
