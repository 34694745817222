import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import { standardShape } from 'core/shapes'
import Headline from 'common/text/Headline'
import StandardItem from 'common/standards/StandardItem'
import { variantShape } from './utils'

const InstructionalStandards = ({
  className,
  instructionStandards = [],
  variant = 'aggregate',
}) => instructionStandards?.length
  ? (
    <div>
      {variant === 'aggregate'
        ? (
          <Headline
            className={className}
            title="Instructional Standards"
            variant="list-header"
          />
        )
        : <strong>Instructional Standards: </strong>}{' '}
      <List variant="csv">
        {instructionStandards.map(standard => (
          <Typography
            component="li"
            key={standard.id}
            variant="small"
          >
            <StandardItem standard={standard} />
          </Typography>
        ))}
      </List>
    </div>
  ) : null

InstructionalStandards.propTypes = {
  instructionStandards: PropTypes.arrayOf(standardShape),
  variant: variantShape,
}

export default InstructionalStandards
