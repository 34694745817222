import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import Stack from '@mui/material/Stack'
import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import Html from 'common/text/Html'
import ItemWithFeedback from 'common/layout/ItemWithFeedback'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { get } from 'fp/objects'
import { actions, itemShape, responseItemShape } from '../utils'
import TableTextField from '../../DGOTable/TableTextField'

const Input = forwardRef((props, ref) => {
  const { dispatch, item: { isRichInput, id, value }, readOnly, responseItem } = props
  const { scoreData } = useContext(interactiveContext)

  const onChange = (e) => {
    dispatch({ type: actions.SET_ITEM, item: { id, value: e.target.value } })
  }

  const itemScoringResult = get(`result.byId.${id}`)(scoreData)

  // Do not pass ref to RTE.
  // The ref is used to set focus on the text field when the containing cell is clicked.
  // That's convenient for plain text fields, but it would make the RTE buttons stop working.
  const editorRef = isRichInput ? undefined : ref

  const editorProps = {
    onChange,
    ref: editorRef,
    value: responseItem?.value || '',
  }

  return readOnly
    ? (
      <ItemWithFeedback scoringResult={itemScoringResult}>
        <Stack gap={2}>
          <div><Html body={responseItem?.value || '(blank)'} /></div>
          <div style={{ width: '100%' }}>Recommended: <Html body={value} /></div>
        </Stack>
      </ItemWithFeedback>
    )
    : isRichInput ? (
      <RichTextEdit
        {...editorProps}
        excludeToolButtons={['Format', 'Highlight', 'Indentation', 'Link', 'Lists']}
        // Show the entire response, with no scroll bars.
        sx={{ '.DraftEditor-root': { minHeight: 'unset !important', maxHeight: 'unset !important' } }}
      />
    ) : (
      <TableTextField
        {...editorProps}
        name={`${id}-input`}
        placeholder="Write your response…"
      />
    )
})

Input.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: itemShape.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responseItem: responseItemShape,
}

export default Input
