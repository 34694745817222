import { Fragment, createElement, useContext, useEffect, useRef } from 'react'
import {
  Area, AreaChart,
  Bar, BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line, LineChart,
  Pie, PieChart,
  RadialBar, RadialBarChart, ReferenceLine,
  ResponsiveContainer,
  Scatter, ScatterChart,
  Tooltip,
  XAxis, YAxis, ZAxis,
} from 'recharts'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import FileSaver from 'file-saver'
import Stack from '@mui/material/Stack'
import { toPng, toSvg } from 'html-to-image'
import { compose } from 'redux'
import Typography from '@mui/material/Typography'
import MaximizeIconButton from 'styling/theming/base/components/MaximizeIconButton'
import { isEmptyString, maybeParseJSON } from 'fp/strings'
import { get, merge, pick, set } from 'fp/objects'
import { isDefined } from 'fp/utils'
import { flatten, map, reduce } from 'fp/arrays'
import Headline from 'common/text/Headline'
import useWindowSize from 'hooks/useWindowSize'
import withProps from 'hoc/withProps'
import useToggleState from 'hooks/useToggleState'
import Html from 'common/text/Html'
import { INTERACTIVE_TYPE_CHART } from 'core/consts'
import Figure from '../Figure'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import CustomizedLabel from './CustomizedLabel'
import MaybeExpanded from './ExpandedContainer'

const types = {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  RadialBar,
  RadialBarChart,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
}

const exportFileTypes = { png: toPng, svg: toSvg }
const colorFields = ['fill', 'stroke', 'stopColor']

/**  TODO: there is an issue with animations and rerenders, so disable for now
  * https://github.com/recharts/react-smooth/issues/44
  * https://github.com/recharts/recharts/issues/1083
  * https://github.com/recharts/recharts/issues/2235
*/

const componentDefaults = {
  Pie: { label: CustomizedLabel, isAnimationActive: false, labelLine: false },
  // use the tooltip label if available in legend
  Legend: { formatter: (value, entry) => entry.payload.tooltipLabel || value },
}

const componentFormatters = {
  LabelList: 'formatter',
  XAxis: 'tickFormatter',
  YAxis: 'tickFormatter',
  Tooltip: 'formatter',
}

// Quick and dirty SVG export exploration - this doesn't include the legend which lives in its on div
const exportChart = (chartNode, fileType) => {
  exportFileTypes[fileType](chartNode.current.container.querySelector('svg'))
    .then((dataUrl) => {
      FileSaver.saveAs(dataUrl, `chart.${fileType}`)
    }).catch(/* istanbul ignore next */() => {
      // TODO: do anything here?
    })
}

const prefixPath = path => `${path}${isEmptyString(path) ? '' : '.'}`

// TODO: would this be useful in utils?  search deeply through object for a specific key
const deepFindByKey = (obj, keyToFind, path = '') => Object.entries(obj)
  .reduce(
    (acc, [key, value]) => (key === keyToFind)
      ? acc.concat(`${prefixPath(path)}${key}`)
      : (typeof value === 'object' && value !== null)
        ? acc.concat(deepFindByKey(value, keyToFind, `${prefixPath(path)}${key}`))
        : acc,
    [],
  )

const createComponent = ({ component, children, formatterConfig, ...rest }) => {
  const formatter = formatterConfig
    ? { [componentFormatters[component]]: value => new Intl.NumberFormat('en-US', formatterConfig).format(value) }
    : null
  return component ? createElement(
    types[component] || component,
    merge({ ...componentDefaults[component], ...formatter }, rest),
    children?.length > 0
      ? children.map((element, idx) => createComponent({
        ...element,
        key: `${get('id')(rest)}-child-${idx}`,
      }))
      : null,
  ) : null
}

const Chart = () => {
  const {
    interactive: { name },
    interactiveData: {
      caption = '',
      captionPosition,
      config,
      showExportButtons = false,
      studentInstructions,
      source,
    },
    setBoosted,
  } = useContext(interactiveContext)

  const parsedConfig = maybeParseJSON(config)
  const ref = useRef()
  const { palette: { charting } } = useTheme()
  const mappedColors = compose(
    reduce((acc, curr) => {
      const color = get(curr)(acc)
      const mapped = get(color)(charting) || color
      return set(curr, mapped)(acc)
    }, parsedConfig),
    flatten,
    map(field => deepFindByKey(parsedConfig, field)),
  )(colorFields)

  const { width } = useWindowSize()
  const [expanded, toggleExpanded] = useToggleState(false)

  useEffect(() => {
    setBoosted(expanded)
  }, [expanded, setBoosted])

  const MaybeHeadline = isDefined(name)
    ? withProps(Headline, { my: 1,
      size: 6,
      textAlign: 'center',
      textTransform: 'capitalize',
      title: name })
    : Fragment

  const Charts = (mappedColors.charts || [mappedColors.chart])
    .map(chart => createComponent({ ...mappedColors,
      ...chart,
      margin: expanded ? { left: 20, right: 20 } : chart.margin,
      ref }))
    .filter(Boolean)
    .map(chart => (
      <div
        key={`${chart.props.id}-${width}`}
        style={{
          ...pick(['minHeight', 'minWidth', 'maxWidth'])(chart.props),
          width: '100%',
          minWidth: expanded ? '85vw' : chart.props.width,
          height: expanded ? '80vh' : chart.props.height,
        }}
      >
        <ResponsiveContainer>
          {chart}
        </ResponsiveContainer>
      </div>
    ))
  return (
    <MaybeExpanded
      expanded={expanded}
      onClick={toggleExpanded}
    >
      <Stack
        alignItems="center"
        onClick={toggleExpanded}
        position="relative"
        spacing={2}
        sx={{ '> div': { width: '100%' } }}
      >
        {Boolean(expanded) && (
          <Typography variant="feature-paragraph">
            <Html body={studentInstructions} />
          </Typography>
        )}

        {!expanded && (
          <MaximizeIconButton />
        )}

        <Figure
          caption={caption}
          captionPosition={captionPosition}
        >

          <MaybeHeadline>

            <Stack
              alignItems="center"
              direction={{ sm: 'column', md: 'row' }}
              justifyContent="center"
              style={{ width: '100%' }}
            >
              {Charts.length > 0 ? Charts : 'No charts found in the configuration.'}
            </Stack>
          </MaybeHeadline>
        </Figure>

        {/* TODO: link is just text now */}
        {isDefined(source)
        && (
          <Typography
            component="p"
            fontWeight={400}
            letterSpacing={0.1}
            textDecoration="underline"
            textTransform="uppercase"
          >
            Source: <span style={{ textDecoration: 'underline' }}>{source}</span>
          </Typography>
        )}
        {Boolean(showExportButtons) && Object.keys(exportFileTypes).map(fileType => (
          <Button
            key={fileType}
            onClick={() => exportChart(ref, fileType)}
          >
            Export to {fileType}
          </Button>
        ))}
      </Stack>
    </MaybeExpanded>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_CHART,
}

export default Chart
