import { useContext } from 'react'
import { isDefined, isUndefined } from 'fp/utils'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { INTERACTIVE_TYPE_POLL } from 'core/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import ChartResults from './ChartResults'
import MultipleChoice from './MultipleChoice'

const Poll = () => {
  const {
    completed,
    interactionData: { selectedValue },
    isGrading,
    peerInteractions,
    submittable,
  } = useContext(interactiveContext)

  const { currentUserAssignmentId } = useContext(userAssignmentSelectionContext) || {}

  const [showAdminResults, setShowAdminResults] = useStateWithDynamicDefault(false)

  const showResults = isGrading
    ? isUndefined(currentUserAssignmentId) && peerInteractions?.length
    : isDefined(selectedValue) && completed

  return (showResults || showAdminResults || !submittable)
    ? (
      <ChartResults onResetClick={() => setShowAdminResults(false)} />
    )
    : (
      <MultipleChoice onShowResultsClick={() => { setShowAdminResults(true) }} />
    )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_POLL,
}

export default Poll
