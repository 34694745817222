import { contentShape } from 'core/shapes'
import ContentHero from 'hss/sections/Heros/ContentHero'
import ContentHeader from '../../ContentHeader'
import ContentRestrictionControls from '../../ContentRestrictionControls'
import Menu from './Menu'

const Hero = ({ content }) => (
  <ContentHero
    content={content}
    left={(
      <ContentHeader
        content={content}
        titleProps={{ variant: 'h1' }}
      />
    )}
    LeftComponent="span"
  >

    <Menu content={content} />

    <ContentRestrictionControls content={content} />

  </ContentHero>
)

Hero.propTypes = {
  content: contentShape.isRequired,
}

export default Hero
