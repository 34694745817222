import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import { StyledHeadline } from 'common/text/Headline'

const Legend = ({ children, title, ...rest }) => (
  <Box {...rest}>
    <StyledHeadline
      component="div"
      light
      variant="h4"
    >
      {title}
    </StyledHeadline>
    <Box mt={2}>
      <List variant="horizontal">
        {children}
      </List>
    </Box>
  </Box>
)

Legend.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Legend
