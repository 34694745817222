import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
import { contentShape } from 'core/shapes'
import { importantRem, rem } from 'styling/theming/base/mixins'
import { getAssignmentBehindUserAssignment } from 'selectors/userAssignments'
import { get } from 'fp/objects'
import { isStudent } from 'selectors/users'
import ScaffoldRenderer from './ScaffoldRenderer'
import DrawerButtons from './DrawerButtons'

export const baseSx = {
  '& .MuiDrawer-root': {
    position: 'absolute',
  },
  '& .MuiPaper-root': {
    position: 'absolute',
  },
}

export const openedMixin = ({ transitions, transitions: { duration, easing } }, styleProp, stylePropValue) => ({
  [styleProp]: stylePropValue,
  transition: transitions.create(styleProp, {
    easing: easing.sharp,
    duration: duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

export const closedMixin = ({ transitions, transitions: { duration, easing } }, styleProp, stylePropValue) => ({
  transition: transitions.create(styleProp, {
    easing: easing.sharp,
    duration: duration.leavingScreen,
  }),
  overflowX: 'hidden',
  [styleProp]: stylePropValue,
})

const ScaffoldsWrapper = ({ attachedScaffolds, children }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const forStudent = useSelector(isStudent)
  const [currentScaffoldIndex, setCurrentScaffoldIndex] = useState(0)

  const includeScaffolds = !!get('data.settings.scaffolds')(useSelector(getAssignmentBehindUserAssignment))
    || !forStudent

  /**
   * students only ever see one scaffold, and it's named 'scaffold'.  This means
   * we can assume there's enough room to show them either horizontally or vertically.
   *
   * For other roles that instead see the list of proficiencies as the labels, we
   * can only fit them horizontally.
   */
  // TRAV-267: Change student scaffolds view to be the same as Teacher view (for now) - horizontal
  const direction = 'horizontal'
  // const direction = forStudent
  //   ? ['xs', 'sm'].includes(breakpoint)
  //     ? 'horizontal'
  //     : 'vertical'
  //   : 'horizontal'

  const V = direction === 'vertical'
  const anchor = V ? 'left' : 'top'
  const styleProp = V ? 'width' : 'height'
  const antiStyleProp = V ? 'marginLeft' : 'marginTop'

  return includeScaffolds && attachedScaffolds.length
    ? (
      <Box
        mb={3}
        position="relative"
        zIndex="1"
      >
        <Drawer
          anchor={anchor}
          data-open={open}
          open={open}
          sx={{
            ...baseSx,
            ...open
              ? openedMixin(theme, styleProp, V ? '50%' : '400px')
              : closedMixin(theme, styleProp, 0),

            '.MuiDrawer-paper': open
              ? openedMixin(theme, styleProp, V ? '50%' : '400px')
              : closedMixin(theme, styleProp, 0),

            '.MuiPaper-root .MuiContainer-root:first-of-type.MuiContainer-root:first-of-type': {
              marginTop: importantRem(2),
              paddingRight: importantRem(2),
              paddingLeft: importantRem(2),
              '.block-full-width': {
                padding: rem(2, 0),
                borderStyle: 'none',
              },
            },
          }}
          variant="permanent"
        >

          <ScaffoldRenderer scaffold={attachedScaffolds[currentScaffoldIndex]} />

        </Drawer>

        <Box
          sx={{
            borderWidth: 7,
            borderColor: 'cobalt.0',
            [V ? 'borderLeftStyle' : 'borderTopStyle']: 'solid',
            position: 'relative',
            bgcolor: 'grey.5',
            top: V ? 0 : -1,
            zIndex: theme.zIndex.drawer,
            ...open
              ? {
                [styleProp]: '50%',
                [antiStyleProp]: V ? '50%' : 0,
              }
              : {
                [styleProp]: '100%',
              },
          }}
        >
          <DrawerButtons
            {...{
              attachedScaffolds,
              currentScaffoldIndex,
              direction,
              open,
              setCurrentScaffoldIndex,
              setOpen,
            }}
          />

          {children}

        </Box>

      </Box>
    )
    : children
}

ScaffoldsWrapper.propTypes = {
  attachedScaffolds: PropTypes.arrayOf(contentShape).isRequired,
  children: PropTypes.node.isRequired,
}

export default ScaffoldsWrapper
