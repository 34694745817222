/* eslint-disable function-call-argument-newline */
import PropTypes from 'prop-types'
import Html from 'common/text/Html'
import { get, mapKeys, renameKeys } from 'fp/objects'
import { noop } from 'fp/utils'
import Image from 'hss/sections/contentBlocks/interactives/Image'
import InteractiveProvider from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'

const pullDataAttributes = mapKeys(key => String(key).replace(/^data-/, ''))

const ImageContentHtml = ({ body, ...rest }) => {
  const preprocessTypography = {
    shouldPreprocessNode: get('attribs.data-typography'),
    preprocessNode: (node) => {
      // eslint-disable-next-line no-param-reassign
      node.attribs = renameKeys({ 'data-typography': 'className' }, node.attribs)
    },
  }

  // this currently process image interactive types
  const processInteractiveNode = ({ attribs }, children, index) => (
    <InteractiveProvider
      {...pullDataAttributes(attribs)}
      key={`interactive-${index}`}
      Renderer={noop} // Renderer is a required prop, but here we aren't using Interactive so it would never get used.
      {...rest}
    >
      {/*
        Using Image directly instead of Interactive, because:
        - We don't need to be able to render *any* type of interactive here -- just images.
        - Using Interactive here would cause a circular reference for Teacher/InteractiveRenderer.spec.js.
      */}
      <Image />
    </InteractiveProvider>
  )

  const processInteractives = {
    shouldProcessNode: node => node.type === 'tag' && node.attribs['data-variant'] === 'interactive',
    processNode: processInteractiveNode,
    replaceChildren: true,
  }

  // TODO: when creating blocks with interactives,it adds an extraneous <p></p>
  //  when this is fixed in the editor, won't need this anymore
  const processEmpty = {
    shouldProcessNode: node => node.type === 'tag' && node.childNodes.length === 0,
    processNode: noop,
  }

  return body
    ? (
      <Html
        additionalInstructions={[
          processInteractives,
          processEmpty,
        ]}
        additionalPreprocessingInstructions={[
          preprocessTypography,
        ]}
        body={body}
        options={{ xmlMode: true }}
        {...rest}
      />
    )
    : null
}

ImageContentHtml.propTypes = {
  body: PropTypes.string,
  captionRef: PropTypes.object,
}

export default ImageContentHtml
