import PropTypes from 'prop-types'
import { useContext } from 'react'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { availableEchoFeatures } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import Footnotes from 'hss/ContentViewer/Chapter/Subsection/Footnotes'
import { get } from 'fp/objects'

const FeatureFootnotes = ({ feature }) => {
  const context = useContext(contentViewerContext) || {}
  const contentId = get(`subsection.data.features.${feature}.contentId`)(context)
  const content = useContent({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
    disableFetch: true,
    /**
     * Perhaps useContent is trying to do too much?
     * When viewing the Chapter Grading page, contentId is undefined.
     * And when contentId is undefined, useContent falls back to the contentId from params, which is the chapter ID.
     * So on every render of FeatureFootnotes, useContent was seeing that
     * there was no interactive whose ID matches the chapter ID (which of course will always be true),
     * and it was fetching the chapter again.
     * Setting paramName here prevents that.
     */
    paramName: 'doNotDefaultToContentIdBecauseThatMightBeTheChapter',
  })

  return (
    <Footnotes
      content={content}
      variant="self"
    />
  )
}

FeatureFootnotes.propTypes = {
  feature: PropTypes.oneOf(availableEchoFeatures).isRequired,
}

export default FeatureFootnotes
