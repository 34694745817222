
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import IconCallout from 'common/text/IconCallout'
import { componentShape } from 'core/shapes'
import Subheader from '../Subheader'

const GradingStatusBar = (props) => {
  const { Icon, backgroundColor, statusText } = props
  return (
    <Box
      backgroundColor="grey.5"
      className="Graded-Bar"
    >
      <Subheader backgroundColor={backgroundColor}>
        <IconCallout Icon={Icon}>
          {statusText}
        </IconCallout>
      </Subheader>
    </Box>
  )
}

GradingStatusBar.propTypes = {
  Icon: componentShape.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
}
export default GradingStatusBar
