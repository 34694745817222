import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { PointSelector } from '@studysync/react-image-annotation/lib/selectors'
import { transparentize } from 'polished'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { compose } from 'redux'
import { useContext } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { get } from 'fp/objects'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { selectorTypeShape } from './utils'

const Styled = styled(
  Stack,
  { name: 'Image-AnnotationsOverlay' },
)(({ theme: { mixins: { important, importantRem, transition }, palette, zIndex } }) => ({
  '> div': {
    background: transparentize(0.6, palette.common.black),
    borderRadius: 5,
    padding: 10,
    '.MuiFormControlLabel-label': {
      fontSize: importantRem(1.4),
      '&:hover': {
        color: important(palette.accent.main),
      },
      '&.Mui-disabled': {
        color: important(palette.primary.contrastText),
        opacity: 0.5,
        fontStyle: 'italic',
      },
    },
  },
  bottom: 4,
  color: palette.common.white,
  fontWeight: 500,
  opacity: 0,
  position: 'absolute',
  right: 4,
  userSelect: 'none',
  zIndex: zIndex.tooltip,
  ...transition('opacity', 210),
}))

const AnnotationsOverlay = ({ allowAnnotations, annotationsEnabled, toggleAnnotationsEnabled, type }) => {
  const { subsection } = useContext(contentViewerContext) || {}
  const disabled = !subsection

  return (
    <Styled
      className="image-annotations-overlay"
      direction="row"
      spacing={2}
    >

      <div>
        {allowAnnotations && annotationsEnabled
          ? type === PointSelector.TYPE
            ? 'Click to add highlight'
            : 'Drag an area to highlight'
          : 'Pinch/wheel to zoom.  Drag to pan'}
      </div>

      {!!allowAnnotations && (
        <div>
          <Tooltip title={disabled ? 'Annotations are not available when previewing' : ''}>
            <FormControlLabel
              control={(
                <Switch
                  checked={annotationsEnabled}
                  disabled={disabled}
                  onChange={compose(
                    toggleAnnotationsEnabled,
                    get('target.checked'),
                  )}
                />
              )}
              label="Enable highlighting"
            />
          </Tooltip>
        </div>
      )}

    </Styled>
  )
}

AnnotationsOverlay.propTypes = {
  allowAnnotations: PropTypes.bool.isRequired,
  annotationsEnabled: PropTypes.bool.isRequired,
  toggleAnnotationsEnabled: PropTypes.func.isRequired,
  type: selectorTypeShape.isRequired,
}

export default AnnotationsOverlay
