import HFTextField from 'common/formControls/textInputs/HFTextField'

// label always on top of field even when blank/empty
const LoginTextField = props => (
  <HFTextField
    InputLabelProps={{ shrink: true }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    {...props}
    variant="filled"
  />
)

export default LoginTextField
