import PropTypes from 'prop-types'
import { useContext } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { get } from 'fp/objects'
import SplitHeadline from 'common/text/SplitHeadline'
import SearchControls from 'hss/views/Search/SearchControls'
import ActiveFilters from 'hss/views/Search/Filters/ActiveFilters'
import { context } from '../SearchProvider'
import { paginationHeader } from '../Filters/utils'

const TableControlsAndFilters = ({
  displayResultsHeader = false,
  renderActions,
  renderPrimaryFilter,
  renderRightSideControls,
  renderSearchControls,
  renderSecondaryFilter,
}) => {
  const {
    dataset,
    primaryFiltersExpanded,
    searchFields,
    secondaryFiltersExpanded,
  } = useContext(context)

  const squery = get('metadata.squery')(dataset)
  const { limit, offset } = squery || {}
  const count = get('metadata.count')(dataset) || 0

  const hasFilters = primaryFiltersExpanded || secondaryFiltersExpanded

  const needToRenderControls = searchFields?.length
    || !!renderSearchControls
    || hasFilters

  return (
    <>

      {needToRenderControls
        ? (
          <SearchControls
            renderActions={renderActions}
            renderPrimaryFilter={renderPrimaryFilter}
            renderRightSideControls={renderRightSideControls}
          >
            {renderSearchControls?.({ renderPrimaryFilter })}
          </SearchControls>
        )
        : null}

      <Collapse in={hasFilters}>
        <Box>
          {renderPrimaryFilter?.()}

          {Boolean(renderPrimaryFilter && renderSecondaryFilter) && <Divider />}

          {renderSecondaryFilter?.()}
        </Box>
      </Collapse>

      {displayResultsHeader
        ? (
          <SplitHeadline
            left={paginationHeader(count, offset, limit)}
            leftProps={{ size: 5 }}
            right={<SearchControls />}
          >

            {!!hasFilters && <ActiveFilters />}

          </SplitHeadline>
        )
        : !!hasFilters && <ActiveFilters />}
    </>
  )
}

TableControlsAndFilters.propTypes = {
  displayResultsHeader: PropTypes.bool,
  renderActions: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderRightSideControls: PropTypes.func,
  renderSearchControls: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
}

export default TableControlsAndFilters
