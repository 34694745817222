import Tooltip from '@mui/material/Tooltip'
import { X } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import { useLocation, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import PropTypes from 'prop-types'
import { componentShape } from 'core/shapes'

const Pane = ({ children, title }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    navigate(location.pathname)
  }

  return (
    <>
      <Toolbar variant="appbar">
        <Typography
          textAlign="center"
          variant="tab-title"
          width="100%"
        >
          {title}
        </Typography>
        <Tooltip
          placement="left-end"
          title={`Close ${title}`}
        >
          <IconButton
            data-bodyvariant="body1"
            onClick={handleClick}
            size="large"
          >
            <Icon>
              <X />
            </Icon>
          </IconButton>
        </Tooltip>
      </Toolbar>

      <Container>
        <Stack
          my={4}
          spacing={2}
        >

          {children}
        </Stack>
      </Container>
    </>
  )
}

Pane.propTypes = {
  children: componentShape.isRequired,
  title: PropTypes.string.isRequired,
}

export default Pane
