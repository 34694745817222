import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import cl from 'classnames'
import { styled } from '@mui/material/styles'
import { position } from 'polished'
import { componentShape, floatTypesShape } from 'core/shapes'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'

const MaybeFloat = ({ children, float }) => float
  ? (
    <div
      className={cl({
        'block-full-width': true,
        'block-partially-contained': false,
        [`float-${float}`]: true,
      })}
    >
      {children}
    </div>
  )
  : children

MaybeFloat.propTypes = {
  children: componentShape.isRequired,
  float: floatTypesShape,
}

const backgroundImage = ({ dark = false, url }) => ({ palette }) => {
  const start = 7.92
  const end = 96.72
  const startColor = dark
    ? palette.background.inverted
    : palette.background.default
  const endColor = 'rgba(0, 0, 0, 0)'

  return `
    linear-gradient(180deg, ${startColor} ${start}%, ${endColor} ${end}%),
    linear-gradient(180deg, ${endColor} ${start}%, ${startColor} ${end}%),
    url("${url}")
    `
}

const Backdrop = styled(
  Box,
  { name: 'Block-Backdrop' },
)(({ theme: { mixins: { featuredMaxWidth } }, fullwidth }) => ({
  position: 'relative',
  '&::before': {
    ...position('absolute', 0, 0, 0, 0),
    content: "''",
    display: 'block',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    mixBlendMode: 'luminosity',
    opacity: 0.25,
  },
  '> div': {
    maxWidth: fullwidth === 'true'
      ? 'unset'
      : featuredMaxWidth,
    position: 'relative',
  },
  '.block-wrapper-style-target': {
    paddingLeft: 'var(--containedPaddingLeft)',
  },
}))

const MaybeHasBackground = ({ children, content, dark = false, fullWidth = false, sx }) => {
  const { data: { float } = {}, uploadsMap } = content || {}

  const url = getImageUrlFromUploadsMap(uploadsMap, 'full')

  return (
    <MaybeFloat float={float}>
      {url
        ? (
          <Backdrop
            data-backdrop="true"
            fullwidth={fullWidth.toString()}
            sx={{
              '&::before': {
                backgroundImage: backgroundImage({ dark, url }),
              },
              width: '100%',
              '> div': {
                marginLeft: 'auto',
                marginRight: 'auto',
                '> p': {
                  maxWidth: 'unset',
                },
              },
              ...sx,
            }}
          >
            <div>
              {children}
            </div>
          </Backdrop>
        )
        : children}
    </MaybeFloat>
  )
}

MaybeHasBackground.propTypes = {
  children: componentShape.isRequired,
  content: PropTypes.shape({ uploadsMap: PropTypes.object.isRequired }),
  dark: PropTypes.bool,
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
}

export default MaybeHasBackground
