import { createContext, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import useWebSocket from 'react-use-websocket'
import { createSelector } from '@comfy/redux-selectors'
import { useDispatch } from 'react-redux'
import { get } from 'fp/objects'
import { generateId } from 'fp/utils'
import { getAuthValue } from 'sagas/api'
import actionTypes from 'reducers/actionTypes'

const TRPC_METHOD_MUTATION = 'mutation'
const TRPC_METHOD_SUBSCRIBE = 'subscription'
const TRPC_METHOD_UNSUBSCRIBE = 'subscription.stop'

const buildMessage = ({ id = generateId(), method, path, input }) => ({
  id,
  jsonrpc: '2.0',
  method,
  ...(method !== TRPC_METHOD_UNSUBSCRIBE && { params: {
    path, input,
  } }),
})

export const backendSocketContext = createContext({})

const socketUrl = createSelector(
  ({ protocol }) => protocol.replace(/^http/, 'ws'),
  get('hostname'),
  ({ port }) => port === '9002' ? ':9002/api/trpc' : ':/api/trpc',
  (...s) => s.join(''),
)(window.location)

const BackendSocketProvider = ({ children, initialTestingState }) => {
  const authSelector = getAuthValue('authSelector')
  const dispatch = useDispatch()

  const { readyState, sendJsonMessage } = useWebSocket(
    socketUrl,
    {
      onOpen: () => {
        sendJsonMessage(buildMessage({ id: 'session', method: TRPC_METHOD_SUBSCRIBE, path: 'identify', input: { authSelector } }))
      },
      onMessage: ({ data }) => {
        if (data) {
          let message
          let error
          try {
            message = JSON.parse(data)
            error = message.error
          } catch (E) {
            error = { ...E }
          }
          if (error) {
            // eslint-disable-next-line no-console
            console.error('Error parsing socket message', { error })
          } else if (message.id === 'session' && message.result.data?.msg === 'ping') {
            sendJsonMessage(buildMessage({ path: 'pong', method: TRPC_METHOD_MUTATION, input: { ts: message.result.data.ts } }))
          } else if (message.result.type === 'data' && message.result.data.length) {
            dispatch({ type: actionTypes.SOCKET_MESSAGE_RECEIVED, payload: message.result })
          }
        }
      },
      shouldReconnect: () => true,
      reconnectInterval: 10000,
      reconnectAttempts: 6,
    },
  )

  const subscribeToInteractive = useCallback(({ id, interactiveId, assignmentId = null }) => {
    sendJsonMessage(buildMessage({
      id,
      method: TRPC_METHOD_SUBSCRIBE,
      path: 'subscribeToPeerInteractions',
      input: { assignmentId, contentId: interactiveId },
    }))
  }, [sendJsonMessage])

  const unsubscribeFromInteractive = useCallback(({ id }) => {
    sendJsonMessage(buildMessage({ id, method: TRPC_METHOD_UNSUBSCRIBE }))
  }, [sendJsonMessage])

  const value = useMemo(
    () => ({
      readyState,
      subscribeToInteractive,
      unsubscribeFromInteractive,
      ...initialTestingState,
    }),
    [
      initialTestingState,
      readyState,
      subscribeToInteractive,
      unsubscribeFromInteractive,
    ],
  )

  return (
    <backendSocketContext.Provider value={value}>
      {children}
    </backendSocketContext.Provider>
  )
}

BackendSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialTestingState: PropTypes.object,
}

export default BackendSocketProvider
