import { useContext } from 'react'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import AnnotationsToggleIcon from 'hss/images/controls/menu/annotations-toggle.svg'
import MenuItemButton from 'hss/ContentViewer/Chapter/Toolbar/MenuItemButton'

const AnnotationsOnOffButton = () => {
  const {
    allowAnnotations,
    annotationsOn,
    toggleAnnotations,
  } = useContext(contentViewerContext)

  return allowAnnotations && (
    <MenuItemButton
      ButtonIcon={AnnotationsToggleIcon}
      onClick={toggleAnnotations}
      selected={annotationsOn}
      text="Annotations"
      value="annotations"
    />
  )
}

export default AnnotationsOnOffButton
