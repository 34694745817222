import { useContext, useMemo } from 'react'
import AudioPlayer from 'common/avclub/audio/AudioPlayer'
import { noop } from 'fp/utils'
import { INTERACTIVE_TYPE_AUDIO } from 'core/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Audio = () => {
  const {
    completed,
    contentWrappingAllowed,
    interactiveData: {
      float,
      minimal = false,
      url,
    },
    markComplete,
    uploadsMap,
  } = useContext(interactiveContext)

  const handlePlay = useMemo(
    () => completed ? noop : markComplete,
    [completed, markComplete],
  )

  return (
    <AudioPlayer
      {...{
        contentWrappingAllowed,
        float,
        minimal,
        onPlay: handlePlay,
      }}
      url={uploadsMap?.audio?.url || url}
    />
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_AUDIO,
}

export default Audio
