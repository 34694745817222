import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import StandardsList from 'common/standards/StandardsList'
import { contentShape } from 'core/shapes'
import { dedupeById } from 'fp/arrays'
import useChapterStandards from 'hooks/useChapterStandards'

const StandardsRollupContainer = styled(
  Container,
  { name: 'StandardsRollup_Container' },
)(({ theme: { mixins: { featuredMaxWidth } } }) => ({
  maxWidth: featuredMaxWidth,
}))

const StandardsRollup = ({ block }) => {
  const { contentType, id: contentId } = block
  const { applicationStandards, instructionStandards } = useChapterStandards({ contentId, contentType })
  const allStandards = dedupeById([...applicationStandards, ...instructionStandards])

  return (
    <StandardsRollupContainer>
      {allStandards.length
        ? <StandardsList standards={allStandards} />
        : '[none]'}
    </StandardsRollupContainer>
  )
}

StandardsRollup.propTypes = {
  block: contentShape.isRequired,
}

export default StandardsRollup
