import { useMemo } from 'react'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import { compose } from 'redux'
import HFOldMultiSelectReplaceMe from 'common/formControls/selects/HFOldMultiSelectReplaceMe'
import { first, sort } from 'fp/arrays'
import useGroups from 'hooks/useGroups'
import useStudents from 'hooks/useStudents'
import { get } from 'fp/objects'
import { ASSIGNMENT_TARGET_GROUP, ASSIGNMENT_TARGET_STUDENT } from 'core/consts'

const TargetSelectField = (props) => {
  const groups = useGroups()
  const students = useStudents()
  const allTargetOptions = useMemo(
    () => ({
      [ASSIGNMENT_TARGET_GROUP]: {
        label: 'Groups',
        options: groups.map(group => ({
          ...group,
          label: group.name,
          targetTypeId: ASSIGNMENT_TARGET_GROUP,
        })),
      },
      [ASSIGNMENT_TARGET_STUDENT]: {
        label: 'Students',
        options: students.map(student => ({
          ...student,
          label: `${student.lastName}, ${student.firstName}`,
          targetTypeId: ASSIGNMENT_TARGET_STUDENT,
        })),
      },
    }),
    [groups, students],
  )

  const { setValue } = useFormContext()
  const targetTypeId = useWatch({ name: 'targetTypeId' })
  const beforeChange = (newSelectedOptions) => {
    const newTargetTypeId = Maybe.fromFalsy(first(newSelectedOptions))
      .map(get('targetTypeId'))
      .orNull()
    setValue('targetTypeId', newTargetTypeId)
  }

  // You can select only one type of target. You can't select both a group and a student.
  const options = useMemo(
    () => {
      // Groups are expected to be the most commonly used type of assignment target.
      const sortValueOf = option => option === allTargetOptions[ASSIGNMENT_TARGET_GROUP] ? -1 : 0
      const sortGroupsFirst = (option1, option2) => sortValueOf(option1) - sortValueOf(option2)

      const filter = targetTypeId
        ? get(`${targetTypeId}.options`)
        : compose(sort(sortGroupsFirst), Object.values)

      return filter(allTargetOptions)
    },
    [allTargetOptions, targetTypeId],
  )

  return (
    <HFOldMultiSelectReplaceMe
      {...{
        beforeChange,
        name: 'targets',
        options,
      }}
      {...props}
    />
  )
}

TargetSelectField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default TargetSelectField
