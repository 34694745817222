import { Trash as Icon } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contentShape } from 'core/shapes'
import withConfirm from 'hoc/withConfirm'
import actionTypes from 'reducers/actionTypes'
import { produceLinkTo } from 'hss/ContentViewer/Banner/LearnBanner/LeftSide'
import { getNextPrevForContentViewer } from 'selectors/contentViewer'

const ConfirmButton = withConfirm(IconButton)

const ContentInsertionRemoval = ({ content }) => {
  const dispatch = useDispatch()

  const { prev } = useSelector(getNextPrevForContentViewer({ preferTeCopy: true }))
  const destUrl = produceLinkTo(prev)

  const onClick = useCallback(() => {
    dispatch({
      type: actionTypes.CONTENT_INSERTION_ALTERATION,
      payload: {
        insertionData: content.insertionData,
      },
      passThrough: { destUrl },
    })
  }, [content.insertionData, destUrl, dispatch])

  return (
    <Tooltip title={`Remove '${content.name}' (inserted)`}>
      <ConfirmButton
        {...{
          onClick,
          confirmationTitle: 'Remove Inserted Content',
          confirmationMessage: `Are you sure you want to remove '${content.name}'?`,
        }}
      >
        <Icon />
      </ConfirmButton>
    </Tooltip>
  )
}

ContentInsertionRemoval.propTypes = {
  content: contentShape.isRequired,
}

export default ContentInsertionRemoval
