
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_BLOCK } from 'core/consts'
import { entityIdShape } from 'core/shapes'
import ImageContentHtml from 'hss/ContentViewer/Chapter/Block/ImageContentHtml'

const Block = (props) => {
  const { id, ...rest } = props
  const block = useContent({ contentType: CONTENT_TYPE_BLOCK, contentId: id })

  return (
    <ImageContentHtml
      body={block.data.body}
      renderProps={{ hideCaption: true }}
      {...rest}
    />
  )
}

Block.propTypes = {
  id: entityIdShape.isRequired,
}

export default Block
