import { sortBy } from 'fp/arrays'
import { items as content } from './Content'
import { items as interactives } from './Interactives'
import withListGrid from './withListGrid'

const items = [
  ...content,
  ...interactives,
].sort(sortBy('label'))

const All = withListGrid({ items })

export default All
