import Stack from '@mui/material/Stack'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import Html from 'common/text/Html'
import { NON_ASSIGNABLE_CONTENT_TYPES, cardContentRendererShape } from 'hss/views/Search/consts'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import CardTitle from 'common/text/CardTitle'
import LinkButton from 'common/navigation/links/LinkButton'
import CardFooter from 'hss/views/Card/CardFooter'
import { labels } from 'hss/ContentBuilder/consts'
import { getContentViewerUrl } from 'selectors/contentViewer'
import { getTeacherAssignmentCreateRoute } from 'routing/consts'
import { ABILITY_ASSIGNMENT_CREATION, CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_RUBRIC, CONTENT_TYPE_VOCABULARY } from 'core/consts'
import ChapterAssignmentLink from 'hss/views/Curriculum/Units/Unit/ChapterCard/ChapterAssignmentLink'
import useAbilityCheck from 'hooks/useAbilityCheck'
import CardActionMenu from '../Search/CardActionMenu'

const buttonLabels = {
  [CONTENT_TYPE_VOCABULARY]: 'Details',
}
const SearchItemRenderer = (props) => {
  const {
    content,
    contentCreator,
    handlePreview,
    viewMode,
  } = props

  const {
    assetCode,
    contentSubType,
    contentType,
    data,
    id: contentId,
    name,
  } = content

  const isList = viewMode === 'list'
  const headlineVariant = isList ? 'h4' : 'h5'
  const assignable = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION) && !NON_ASSIGNABLE_CONTENT_TYPES.includes(contentType)
  const isInteractiveOrRubric = [CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_RUBRIC].includes(contentType)

  const viewUri = useSelector(getContentViewerUrl({ contentId, contentType }))
  const description = data.description || data.caption || data.definition

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div>
        <HeadlineLevelOffset>
          <HeadlineLevelOffset>
            <CardHeader
              title={(
                <CardTitle
                  eyebrow={labels[contentSubType] || labels[contentType]}
                  headlineVariant={headlineVariant}
                  title={name || assetCode}
                />
              )}
            />
          </HeadlineLevelOffset>
        </HeadlineLevelOffset>

        <CardContent>
          <Html body={description} />
        </CardContent>
      </div>

      <CardActions>
        <Stack
          direction="row"
          gap={2}
          justifyContent={isList ? 'space-after' : 'space-between'}
          padding={2}
          paddingLeft={0}
          paddingTop={0}
          width="100%"
        >
          {!isInteractiveOrRubric && (
            <LinkButton
              color="secondary"
              to={viewUri}
              variant="secondary"
            >
              View {buttonLabels[contentType] || labels[contentType]}
            </LinkButton>
          )}

          {assignable
            ? (
              <LinkButton
                color="secondary"
                to={getTeacherAssignmentCreateRoute({ contentType, id: contentId })}
                variant="primary"
              >
                Assign
              </LinkButton>
            )
            : isInteractiveOrRubric
              ? <Button onClick={handlePreview}>Preview</Button>
              : null}

          {Boolean(contentCreator) && (
            <CardActionMenu
              {...{
                contentId,
                contentSubType,
                contentType,
              }}
            />
          )}
        </Stack>
      </CardActions>

      {Boolean(assignable) && (
        <CardFooter>
          <ChapterAssignmentLink content={content} />
        </CardFooter>
      )}

    </Stack>
  )
}

SearchItemRenderer.propTypes = cardContentRendererShape

export default SearchItemRenderer
