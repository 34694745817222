import { useContext } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { dedupe, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { getUserAssignmentsById } from 'selectors/userAssignments'
import { getReviewedInteractions } from 'selectors/interactions'
import { asPercentageString, sum } from 'fp/numbers'
import AttributeColumns from './AttributeColumns'
import { gradebookContext } from './GradebookContextProvider'

const Stats = () => {
  const { userAssignmentIds = [] } = useContext(gradebookContext)

  const userAssignments = useSelector(getUserAssignmentsById({ userAssignmentIds })) || []
  const submittedUserAssignments = userAssignments.filter(get('submittedDate'))
  const reviewedInteractions = useSelector(getReviewedInteractions({ userAssignmentIds })) || []

  const statsAttributes = [
    {
      name: 'Assigned',
      value: userAssignments.length,
    },
    {
      name: 'Completed',
      value: submittedUserAssignments.length,
    },
    {
      name: 'Students',
      value: compose(
        get('length'),
        dedupe,
        map(get('userId')),
      )(userAssignments),
    },
    {
      name: 'Submission Rate',
      value: asPercentageString(submittedUserAssignments.length / userAssignments.length),
    },
    {
      name: 'My Avg. Review',
      value: compose(
        asPercentageString,
        scores => sum(...scores) / scores.length,
        map(get('scoreData.score')),
      )(reviewedInteractions),
    },
    {
      name: 'Reviews Given',
      value: reviewedInteractions.length,
    },
  ]

  return (
    <AttributeColumns
      attributes={statsAttributes}
      minColumnWidth="16rem"
    />
  )
}

export default Stats
