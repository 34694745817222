import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import { Send } from 'react-feather'
import Tooltip from '@mui/material/Tooltip'

const SubmitButton = ({ canBeSubmitted, handleClick, studentName, submitAssignment }) => {
  const title = `Submit ${submitAssignment ? 'Assignment' : 'Interactive'} for ${studentName}`

  return canBeSubmitted && (
    <Tooltip title={title}>
      <IconButton
        aria-label={title}
        onClick={handleClick}
      >
        <Send />
      </IconButton>
    </Tooltip>
  )
}

SubmitButton.propTypes = {
  canBeSubmitted: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  studentName: PropTypes.string.isRequired,
  submitAssignment: PropTypes.bool.isRequired,
}

export default SubmitButton
