import CardHeader from '@mui/material/CardHeader'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useRef, useState } from 'react'
import { contentShape } from 'core/shapes'
import MaybeDraft from 'common/indicators/Draft'
import ContentHeader from 'hss/views/Curriculum/ContentHeader'
import { importantPx } from 'styling/theming/base/mixins'
import useEffectOnce from 'hooks/useEffectOnce'
import LinkToContent from '../LinkToContent'

const StyledBox = styled(Box)(({ theme: { mixins: { important }, palette } }) => ({
  '&:hover': {
    color: important(palette.grey[0]),
  },
}))

const Header = ({ content }) => {
  const headerRef = useRef()
  const [swatchHeight, setSwatchHeight] = useState(0)

  useEffectOnce(() => {
    if (headerRef.current) {
      setSwatchHeight(headerRef.current?.getBoundingClientRect().height || 0)
    }
  })

  return (
    <LinkToContent content={content}>
      <StyledBox>
        <CardHeader
          sx={{
            '.tr-typography::before': {
              top: 0,
              minHeight: importantPx(swatchHeight),
            },
          }}
          title={(
            <ContentHeader
              content={content}
              headerRef={headerRef}
            >
              <MaybeDraft content={content} />
            </ContentHeader>
          )}
        />
      </StyledBox>
    </LinkToContent>
  )
}

Header.propTypes = {
  content: contentShape.isRequired,
}

export default Header
