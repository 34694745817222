import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { XCircle } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import { isDefined, matches } from 'fp/utils'
import { findObj } from 'fp/arrays'
import { equals } from 'fp/objects'
import withConfirm from 'hoc/withConfirm'
import { entityIdShape } from 'core/shapes'
import { orderedSections, threeCirclePositioning, twoCirclePositioning } from './config'
import Dialog from './Dialog'
import Container from './SVGContainer'
import HeaderLink from './HeaderLink'
import VennSections from './VennSections'

const RemoveButton = withConfirm(IconButton)

const Content = (props) => {
  const { circles, contentId = '', onChange, readOnly = false, sections } = props

  const [selectedSection, setSection] = useState()

  const handleRemoveCircle = useCallback(() => {
    onChange('circles', 2)
  }, [onChange])

  const handleAddCircle = useCallback(() => {
    onChange('circles', 3)
  }, [onChange])

  const positioning = useMemo(
    () => circles === 2 ? twoCirclePositioning() : threeCirclePositioning(),
    [circles],
  )

  const handleClick = useCallback(({ circleIds }) => (e) => {
    e?.preventDefault()
    const sectionIndex = sections.findIndex(sec => equals(sec.circleIds)(circleIds))
    setSection(sectionIndex)
  }, [sections])

  const getSectionByCircleIds = useCallback(
    circleIds => sections
      .find(({ circleIds: cIds }) => equals(circleIds)(cIds)),
    [sections],
  )

  const leftRight = ['left', 'right'].map(key => orderedSections.find(matches('key', key)))
  const bottomSection = findObj('key', 'bottom')(orderedSections)

  useEffect(() => {
    if ((circles === 3 && sections.length !== 7) || (circles === 2 && sections.length !== 3)) {
      onChange('sections', circles === 3
        ? orderedSections.map(({ circleIds }) => ({
          header: getSectionByCircleIds(circleIds)?.header || '',
          response: getSectionByCircleIds(circleIds)?.response || '',
          circleIds,
        }))
        : sections.filter(({ circleIds }) => !circleIds.includes(3)))
    }
  }, [circles, getSectionByCircleIds, onChange, sections])

  const { ariaDescription, circles: circlePositions, viewBox } = positioning

  return (
    <div style={{ position: 'relative' }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
      >
        {leftRight.map((s) => {
          const { header } = getSectionByCircleIds(s.circleIds)
          return (
            <HeaderLink
              header={header}
              key={s.key}
              onClick={handleClick(s)}
              readOnly={readOnly}
            />
          )
        })}
      </Stack>

      <Container>
        <svg
          aria-describedby="dgo-venn-desc"
          aria-labelledby="dgo-venn-title"
          role="group"
          viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
        >
          <title id="dgo-venn-title">Venn Diagram Image.</title>
          <desc id="dgo-venn-desc">
            {ariaDescription}{'\n'}
            Each section may contain text.
            {Boolean(readOnly) && '\nNavigate further to hear the text in each section.'}
          </desc>

          <defs>
            {Object.keys(positioning.clipPaths)
              .map(cp => (
                <clipPath
                  aria-hidden="true"
                  id={`${contentId }-${cp}`}
                  key={cp}
                >
                  <path d={positioning.clipPaths[cp]} />
                </clipPath>
              ))}
          </defs>

          <VennSections {...{ circles, contentId, onClick: handleClick, positioning, readOnly, sections }} />

          {circlePositions.map(circle => (
            <g key={circle.center.x}>
              <circle
                aria-hidden="true"
                className="outline"
                cx={circle.center.x}
                cy={circle.center.y}
                r={circle.radius}
                strokeWidth={0.5}
              />
            </g>
          ))}
        </svg>

        {isDefined(selectedSection) && (
          <Dialog
            index={selectedSection}
            onChange={onChange}
            onClose={() => { setSection(null) }}
            open
            section={sections[selectedSection]}
          />
        )}
      </Container>

      {circles === 3 && !readOnly
        ? (
          <>
            <RemoveButton
              aria-label="Remove Bottom Circle"
              confirmationMessage="You are about to remove the bottom section. Are you sure you want to do this?"
              confirmationTitle="Remove?"
              onClick={handleRemoveCircle}
              style={{ position: 'absolute', bottom: 70, right: '15%' }}
            >
              <XCircle />
            </RemoveButton>
            <HeaderLink
              header={getSectionByCircleIds(bottomSection.circleIds)?.header}
              onClick={handleClick(bottomSection)}
              readOnly={readOnly}
            />
          </>
        )
        : (
          !readOnly && (
            <Button
              color="secondary"
              onClick={handleAddCircle}
              variant="tertiary"
            >
              Add Bottom Circle
            </Button>
          )
        )}
    </div>
  )
}
Content.propTypes = {
  circles: PropTypes.number.isRequired,
  contentId: entityIdShape,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({
    circleIds: PropTypes.array.isRequired,
    header: PropTypes.string,
    response: PropTypes.string,
    sampleResponse: PropTypes.string,
  })).isRequired,
}

export default Content
