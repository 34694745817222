import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { actions } from 'reducers/interactions'
import { INTERACTION_TYPE_NOTEBOOK } from 'core/consts'
import { getUserAssignment } from 'selectors/userAssignments'
import { getViewerTopLevelContent } from 'selectors/contentViewer'
import { getInteractionByType } from 'selectors/interactions'
import useReduxCallback, { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import actionTypes from 'reducers/actionTypes'
import DebouncedRichTextEdit from 'common/formControls/textInputs/RichTextEdit/DebouncedRichTextEdit'
import Pane from './Pane'

const statusMessage = {
  [IDLE]: '',
  [BUSY]: 'Saving notes…',
  [SUCCESS]: 'Notes saved.',
  [ERROR]: 'Error saving notes.',
}

const Notebook = ({ hideHeader = false }) => {
  const dispatch = useDispatch()

  const [dispatchSave, status] = useReduxCallback({
    actionType: actionTypes.INTERACTION_POST,
  })

  const { id: contentId, id: contextContentId } = useSelector(getViewerTopLevelContent) || {}
  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const [showStatus, setShowStatus] = useState()

  const notebookInteraction = useSelector(getInteractionByType({
    type: INTERACTION_TYPE_NOTEBOOK,
    userAssignmentId,
  }))

  const handleChange = ({ target: { value } }) => {
    dispatchSave({
      contentId,
      contextContentId,
      interactionData: { value },
      interactionType: INTERACTION_TYPE_NOTEBOOK,
      userAssignmentId,
    })
  }

  const Header = useMemo(() => (hideHeader
    ? ({ children }) => <div>{children}</div>
    : ({ children }) => <Pane title="Notebook">{children}</Pane>
  ), [hideHeader])

  useEffect(() => {
    dispatch(actions.fetchInteractions({ interactionType: INTERACTION_TYPE_NOTEBOOK, userAssignmentId }))
  }, [contentId, dispatch, userAssignmentId])

  useEffect(() => {
    setShowStatus(true)
  }, [status])

  return (
    <Header>
      <DebouncedRichTextEdit
        excludeToolButtons={['Superscript', 'Subscript', 'Link', 'Highlight']}
        onChange={handleChange}
        placeholder="Add notes here…"
        showStatus
        status={statusMessage[status]}
        sx={{
          '.public-DraftEditorPlaceholder-root': {
            width: 'auto',
          },
          '.draft-statusbar > div': {
            opacity: showStatus ? 1 : 0,
            transition: 'all 250ms linear',
          },
        }}
        value={notebookInteraction?.interactionData?.value || ''}
      />
    </Header>
  )
}

Notebook.propTypes = {
  hideHeader: PropTypes.bool,
}

export default Notebook
