import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import cl from 'classnames'
import { useContext } from 'react'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import ImageSelectionPlaceholder from './ImageSelectionPlaceholder'
import BrowseButton from './BrowseButton'
import TextControl from './TextControl'
import AppliedImageFilters from './AppliedImageFilters'
import StaticTextControl from './StaticTextControl'

const ImageRenderer = ({ readOnly = false }) => {
  const {
    completed,
    interactionData,
    isGrading,
    onInteract,
  } = useContext(interactiveContext)

  const {
    filter,
    fontSize,
    imageBankItem: { url } = {},
  } = interactionData

  if (!completed && isGrading) {
    return (
      <Box
        display="block"
        mb={4}
      >
        (no response)
      </Box>
    )
  }

  if (!url && !readOnly) return <ImageSelectionPlaceholder onInteract={onInteract} />

  return (
    <>

      <Box
        sx={{
          aspectRatio: '9 / 16',
          width: '100%',
          maxWidth: 400,
          mx: 'auto',
          position: 'relative',
        }}
      >

        <AppliedImageFilters
          className={cl(`font-size-${fontSize}`, filter)}
          mb={2}
          mt={4}
          url={url}
        />

        {readOnly
          ? <StaticTextControl />
          : <TextControl />}

      </Box>

      {Boolean(url && !completed && !isGrading) && (
        <Box textAlign="right">
          <BrowseButton onInteract={onInteract} />
        </Box>
      )}

    </>
  )
}

ImageRenderer.propTypes = {
  readOnly: PropTypes.bool,
}

export default ImageRenderer
