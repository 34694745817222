import { Routes } from 'react-router-dom'
import { configToRoutes } from 'routing/TraverseRoute'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { chapterChildrenRouteConfig } from 'hss/ContentViewer/routeConfig'
import { set } from 'fp/objects'
import Grading from 'hss/ContentViewer/Chapter/Grading'
import UserAssignmentSelectionProvider from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import Edit from './Edit'

const routes = configToRoutes({
  ...set('subsection.pinnable', false)(chapterChildrenRouteConfig),
  settings: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <Edit />,
    path: 'settings',
  },
  grading: {
    element: <Grading />,
    hideFromStudent: true,
    path: 'grading',
    pinnable: true,
  },
})

const AssignmentEditorRoutes = () => (
  <ErrorBoundary moduleName="AssignmentEditorRoutes">

    <UserAssignmentSelectionProvider>
      <Routes>
        {routes}
      </Routes>
    </UserAssignmentSelectionProvider>

  </ErrorBoundary>
)

export default AssignmentEditorRoutes
