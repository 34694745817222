import { useContext } from 'react'
import { Monitor } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useSelector } from 'react-redux'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { getLocalSetting } from 'selectors/localSettings'

const PresenterModeToggleButton = (props) => {
  const { presenterModeAvailable, togglePresenterMode } = useContext(contentViewerContext)
  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))

  return presenterModeAvailable
    ? (
      <Tooltip
        title={`
          Presenter Mode is ${ presenterModeEnabled ? 'ON' : 'OFF'}.
          While enabled, items such as answer keys and teacher edition content are
          hidden from display.
        `}
      >
        <IconButton
          aria-label="Presenter Mode"
          aria-pressed={presenterModeEnabled}
          onClick={togglePresenterMode}
          size="large"
          sx={{ mx: 1 }}
          {...props}
          {...presenterModeEnabled && { color: 'primary', variant: 'primary' }}
        >
          <Monitor />
        </IconButton>
      </Tooltip>
    )
    : null
}

export default PresenterModeToggleButton
