import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import Lightbulb from 'hss/images/controls/draft-toolbar/lightbulb.svg'
import Dialog from 'common/dialogs/Dialog'
import useToggleState from 'hooks/useToggleState'
import { when } from 'fp/utils'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import FunFactLink from './Link'

const FunFactDialog = ({ children }) => {
  const context = useContext(interactiveContext)
  const {
    completed,
    interactive: { name },
    markComplete,
  } = context

  const [open, setOpen] = useToggleState()

  const handleClick = useCallback(() => {
    when(!completed, markComplete)
    setOpen()
  }, [completed, markComplete, setOpen])

  return (
    <>
      <FunFactLink
        onClick={handleClick}
        variant="mock-link"
      >
        <Lightbulb /> {name}
      </FunFactLink>
      <Dialog
        onClose={setOpen}
        open={open}
        PaperProps={{
          sx: {
            maxWidth: '70%',
          },
        }}
        showCloseButton
      >
        {children}
      </Dialog>
    </>
  )
}
FunFactDialog.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FunFactDialog
