import Headline from 'common/text/Headline'
import Options from './Options'
import SpeechSettings from './SpeechSettings'
import TestSpeechSettings from './TestSpeechSettings'

const AudioDescriptions = props => (
  <Headline
    mt={6}
    size={4}
    title="Audio Description (Video)"
  >
    <Options {...props} />

    <SpeechSettings {...props} />

    <TestSpeechSettings {...props} />

  </Headline>
)

export default AudioDescriptions
