import { useCallback, useContext } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { compose } from 'redux'
import Select from 'common/formControls/selects/Select'
import { alter, inspect } from 'core/store/search/squery'
import { context } from '../../SearchProvider'

const statusFilters = [
  { value: false, label: 'Active Only' },
  { value: true, label: 'Include Inactive' },
]

const AssignmentStatusFilter = () => {
  const {
    setSquery,
    squery,
  } = useContext(context)

  const status = inspect(squery).get.modifier('includeInactive').is(false)

  const handleStatusChange = useCallback(({ target }) => {
    compose(
      setSquery,
      alter.set.modifier('includeInactive').is(target.value),
    )(squery)

    // don't add squery here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSquery])

  return (
    <Select
      fullWidth={false}
      label="Status"
      name="Status"
      onChange={handleStatusChange}
      style={{
        marginTop: 0,
        minWidth: 160,
        width: 'auto',
      }}
      value={status}
    >
      {statusFilters.map(statusFilter => (
        <MenuItem
          key={statusFilter.value}
          value={statusFilter.value}
        >
          {statusFilter.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default AssignmentStatusFilter
