import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useContent from 'hooks/useContent'
import {
  childBlocksHaveTeContent,
  getAnyAncestorIsTe,
  getBlocksForSubsectionOrEcho,
  getSubsectionOrEchoAccentColor,
  getViewerTopLevelContent,
} from 'selectors/contentViewer'
import {
  ABILITY_CONTENT_EDITING,
  ABILITY_TEACHER_INTERFACE,
  CONTENT_TYPE_SUBSECTION,
  FEATURE_FLAG_SPANISH_CONTENT,
  LEVELED_TEXT_BODY_FIELD_NAME,
  PROFICIENCY_GROUPING_BELOW_LEVEL,
  PROFICIENCY_GROUPING_ENGLISH_LEARNER,
  ROLE_STAFF,
  SPANISH_BODY_FIELD_NAME,
  TOGGLE_STATE_PRESENTER_MODE,
  TOGGLE_STATE_TOOLBAR_ANNOTATIONS,
  TOGGLE_STATE_TOOLBAR_LEVELED_CONTENT,
  TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT,
  TOGGLE_STATE_TOOLBAR_TE_CONTENT,
  USE_LEVELED_TEXT_BY_PROFICIENCY,
  USE_LEVELED_TEXT_FOR_ALL_STUDENTS,
} from 'core/consts'
import AccentColorProvider from 'styling/theming/AccentColorProvider'
import useToggleState from 'hooks/useToggleState'
import { contentTypeShape } from 'core/shapes'
import { getAnnotationsForContent } from 'selectors/interactions'
import useCurrentUser from 'hooks/useCurrentUser'
import { assembleSideBySideSeAndTeBlocks, markVocabPhrasesInBlocks } from 'projections/content'
import { fallbackTo } from 'fp/utils'
import { getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'
import useReduxPromise from 'hooks/useReduxPromise'
import actionTypes from 'reducers/actionTypes'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { getCurrentRoleId, getCurrentUserProficiency, isStudent } from 'selectors/users'
import { getUserAssignment } from 'selectors/userAssignments'
import { getContextualAssignment } from 'selectors/assignments'
import { hasAlternateBodiesForSelfOrDescendants } from 'selectors/content'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { get } from 'fp/objects'
import useAbilityChecker from 'hooks/useAbilityChecker'
import Alerts from 'routing/shells/TocShell/Alerts'

const contentViewerContext = createContext()

const leveledProficiencyGroupings = [
  PROFICIENCY_GROUPING_BELOW_LEVEL,
  PROFICIENCY_GROUPING_ENGLISH_LEARNER,
]

const useCurrentUserShouldSeeLeveledContent = () => {
  const { grouping: userProficiencyGrouping } = useSelector(getCurrentUserProficiency) || {}

  const { leveledNarrativeTextSetting: settingFromAssignmentEditContext } = useContext(assignmentEditContext) || {}
  const settingFromExistingAssignment = compose(
    get('data.settings.leveledNarrativeText'),
    fallbackTo({}),
    useSelector,
  )(getContextualAssignment)
  const leveledTextAssignmentSetting = settingFromAssignmentEditContext || settingFromExistingAssignment

  return leveledTextAssignmentSetting === USE_LEVELED_TEXT_FOR_ALL_STUDENTS
    || (
      leveledTextAssignmentSetting === USE_LEVELED_TEXT_BY_PROFICIENCY
      && leveledProficiencyGroupings.includes(userProficiencyGrouping)
    )
}

const ContentViewerProvider = ({
  children,
  contentType = CONTENT_TYPE_SUBSECTION,
  initialTestState,
  paramName = 'subsectionId',
}) => {
  const has = useAbilityChecker()
  const { palette: { sectionAccents } } = useTheme()
  const sideBySideTeAllowed = useContainerQuery().up('md')
  const dispatch = useDispatch()
  const { user: { id: userId, proficiencyId } } = useCurrentUser()
  const currentUserIsStudent = useSelector(isStudent)
  const [videosInTheaterMode, setVideosInTheaterMode] = useState(false)
  const [retrievedInteractions, setRetrievedInteractions] = useState(false)
  const hasContentViewTeach = has(ABILITY_TEACHER_INTERFACE)

  const content = useContent({ contentType, paramName })

  const {
    academicVocabPhrases = [],
    contentVocabPhrases = [],
    footnoteVocabPhrases = [],
  } = useSelector(getAggregatedVocabFromContentChildren({ content }))

  const vocabPhrases = [academicVocabPhrases, contentVocabPhrases, footnoteVocabPhrases].flat()

  const blocks = useSelector(getBlocksForSubsectionOrEcho(
    content,
    {
      expandInteractives: true,
      currentUserIsStudent,
      userProficiencyId: proficiencyId,
    },
  ))

  const ancestorHasTe = useSelector(getAnyAncestorIsTe({ content }))

  const { id: contentId } = content || {}

  const chapter = useSelector(getViewerTopLevelContent) || {}

  const { id: userAssignmentId } = useSelector(getUserAssignment) || {}
  const { data: assignmentData, id: assignmentId } = useSelector(getContextualAssignment) || {}
  const { settings: assignmentSettings } = assignmentData || {}

  const inCurriculum = !userAssignmentId && !assignmentId
  const getOwnInteractions = inCurriculum || currentUserIsStudent

  const fetchInteractions = useReduxPromise(actionTypes.INTERACTION_FETCH_LIST)

  useEffect(() => {
    // Fetching all child interactions up front.
    // Individual interactives can assume they've been fetched.
    fetchInteractions({
      contentId: chapter.id,
      userAssignmentId,
      assignmentId,
      userId: getOwnInteractions ? userId : null,
    })
      .finally(() => { setRetrievedInteractions(true) })
  }, [chapter.id, contentId, getOwnInteractions, dispatch, fetchInteractions, userAssignmentId, assignmentId, userId])

  const [annotationsOn, toggleAnnotations] = useToggleState(true, TOGGLE_STATE_TOOLBAR_ANNOTATIONS)
  const [audioAvailable, setAudioAvailable] = useState(false)
  const [audioTrackEnabled, toggleAudioTrack] = useToggleState(false)
  const [displayingLeveledContent, toggleLeveledContent] = useToggleState(false, TOGGLE_STATE_TOOLBAR_LEVELED_CONTENT)
  const [displayingSpanishContent, toggleSpanishContent] = useToggleState(false, TOGGLE_STATE_TOOLBAR_SPANISH_CONTENT)
  const [displayingTeContent, toggleTeContent] = useToggleState(false, TOGGLE_STATE_TOOLBAR_TE_CONTENT)
  const [audioClosing, setAudioClosing] = useState(false)
  const audioContentId = useRef()
  const blocksHaveTe = useSelector(childBlocksHaveTeContent)
  const [presenterModeEnabled, togglePresenterMode] = useToggleState(false, TOGGLE_STATE_PRESENTER_MODE)
  const teContentAvailable = hasContentViewTeach && blocksHaveTe && !ancestorHasTe && !presenterModeEnabled

  // TRAV-389 - disallow annotations for teachers
  const allowAnnotations = useSelector(getCurrentRoleId) !== ROLE_STAFF

  // #538 hide spanish content for now
  const allowSpanish = has(FEATURE_FLAG_SPANISH_CONTENT)

  const blocksHaveLeveledContent = useSelector(hasAlternateBodiesForSelfOrDescendants({
    kind: LEVELED_TEXT_BODY_FIELD_NAME,
    contentId,
  }))

  const leveledContentToggleAvailable = has(ABILITY_CONTENT_EDITING) || (
    hasContentViewTeach
    && blocksHaveLeveledContent
  )
  const useLeveledContentWhereAvailable = useCurrentUserShouldSeeLeveledContent()
    || (displayingLeveledContent && leveledContentToggleAvailable)

  const spanishContentToggleAvailable = useSelector(hasAlternateBodiesForSelfOrDescendants({
    kind: SPANISH_BODY_FIELD_NAME,
    contentId,
  })) && allowSpanish
  const useSpanishContentWhereAvailable = displayingSpanishContent && spanishContentToggleAvailable

  const annotations = useSelector(getAnnotationsForContent({
    contentId,
    userId,
    displayingLeveledContent: useLeveledContentWhereAvailable,
    displayingSpanishContent: useSpanishContentWhereAvailable,
  }))

  const alternateBodyProp = useSpanishContentWhereAvailable
    ? 'spanishBody'
    : useLeveledContentWhereAvailable
      ? 'leveledBody'
      : undefined

  const accentColor = useSelector(getSubsectionOrEchoAccentColor({ contentId, sectionAccents }))

  useEffect(() => {
    if (audioTrackEnabled && audioContentId.current && audioContentId.current !== contentId) {
      // they clicked on a new page while playing audio
      // toggle the audio off in order to reset it
      toggleAudioTrack()
      audioContentId.current = null
    }
  }, [audioTrackEnabled, contentId, toggleAudioTrack])

  const toggleAudioOnOff = useCallback(() => {
    if (audioTrackEnabled) {
      setAudioClosing(true)
      // give the "slide down" animation time to work
      setTimeout(() => {
        toggleAudioTrack()
        setAudioClosing(false)
      }, 500)
      audioContentId.current = null
    } else {
      toggleAudioTrack()
      audioContentId.current = contentId
    }
  }, [audioTrackEnabled, contentId, toggleAudioTrack])

  const value = useMemo(() => {
    const blockBundles = compose(
      assembleSideBySideSeAndTeBlocks,
      markVocabPhrasesInBlocks(vocabPhrases, alternateBodyProp),
    )(blocks) || []

    const handleLeveledContentToggle = () => {
      if (!displayingLeveledContent && displayingSpanishContent) {
        toggleSpanishContent()
      }

      toggleLeveledContent()
    }

    const handleSpanishContentToggle = () => {
      if (!displayingSpanishContent && displayingLeveledContent) {
        toggleLeveledContent()
      }
      toggleSpanishContent()
    }

    return {
      allowAnnotations,
      annotations,
      annotationsOn,
      assignmentSettings,
      audioAvailable,
      audioClosing,
      audioTrackEnabled,
      blockBundles,
      contentWrappingAllowed: !displayingTeContent && sideBySideTeAllowed,
      displayingLeveledContent: useLeveledContentWhereAvailable,
      displayingSpanishContent: useSpanishContentWhereAvailable,
      displayingTeContent: displayingTeContent && teContentAvailable,
      leveledContentToggleAvailable,
      presenterModeAvailable: hasContentViewTeach,
      setAudioAvailable,
      setAudioClosing,
      setVideosInTheaterMode,
      sideBySideTeAllowed,
      spanishContentToggleAvailable,
      subsection: content,
      teContentAvailable,
      toggleAnnotations,
      toggleAudioOnOff,
      toggleAudioTrack,
      toggleLeveledContent: handleLeveledContentToggle,
      togglePresenterMode,
      toggleSpanishContent: handleSpanishContentToggle,
      toggleTeContent,
      videosInTheaterMode,
      ...initialTestState,
    }
  }, [
    alternateBodyProp,
    allowAnnotations,
    annotations,
    annotationsOn,
    assignmentSettings,
    audioAvailable,
    audioClosing,
    audioTrackEnabled,
    blocks,
    content,
    displayingLeveledContent,
    displayingSpanishContent,
    displayingTeContent,
    hasContentViewTeach,
    initialTestState,
    leveledContentToggleAvailable,
    sideBySideTeAllowed,
    spanishContentToggleAvailable,
    teContentAvailable,
    toggleAnnotations,
    toggleAudioOnOff,
    toggleAudioTrack,
    toggleLeveledContent,
    togglePresenterMode,
    toggleSpanishContent,
    toggleTeContent,
    useLeveledContentWhereAvailable,
    useSpanishContentWhereAvailable,
    videosInTheaterMode,
    vocabPhrases,
  ])

  return retrievedInteractions || initialTestState?.retrievedInteractions
    ? (
      <contentViewerContext.Provider value={value}>

        <AccentColorProvider accentColor={accentColor || 'cobalt'}>

          <Alerts />

          {children}

        </AccentColorProvider>

      </contentViewerContext.Provider>
    )
    : null
}

ContentViewerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  contentType: contentTypeShape,
  initialTestState: PropTypes.object,
  paramName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export { contentViewerContext, ContentViewerProvider }
