import PropTypes from 'prop-types'
import { contentShape } from 'core/shapes'
import SkillsRollup from './SkillsRollup'
import VocabRollup from './VocabRollup'
import StandardsRollup from './StandardsRollup'
import BlockWrapperStyleTarget from './BlockWrapperStyleTarget'

const rollupComponents = {
  'skills-rollup': SkillsRollup,
  'vocab-rollup': VocabRollup,
  'standards-rollup': StandardsRollup,
}
const MaybeHasContentSubType = (props) => {
  const { block, children, previewing = false } = props

  const RollupComponent = rollupComponents[block.contentSubType]

  return (
    <BlockWrapperStyleTarget block={block}>
      {RollupComponent
        ? <RollupComponent {...{ block, previewing }} />
        : children}
    </BlockWrapperStyleTarget>
  )
}

MaybeHasContentSubType.propTypes = {
  block: contentShape.isRequired,
  children: PropTypes.node.isRequired,
  previewing: PropTypes.bool,
}

export default MaybeHasContentSubType
