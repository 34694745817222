import { compose } from 'redux'
import Box from '@mui/material/Box'
import { LINKABLE_CONTENT_TYPES } from 'shared/consts'
import useDataset from 'hooks/useDataset'
import { alter, assert } from 'core/store/search/squery'
import { restEndpoint } from 'reducers/utils'
import Carousel from 'common/carousels/Carousel'
import { largeSingleSlides, largeSingleSlidesNoLoop } from 'common/carousels/configurations'
import { isDefined } from 'fp/utils'
import { sx } from 'hss/sections/Home/Subsequent'
import HeroSlide from './HeroSlide'
import SlideContent from './SlideContent'

const staffPickTagName = 'Staff Pick'

const squery = compose(
  alter.set.limit(10),
  alter.set.orderBy('name', 'asc'),
  alter.set.where('contentType').in(LINKABLE_CONTENT_TYPES),
  alter.set.orderBy('dateCreated', 'desc'),
  alter.set.modifier('whereHasTag').is(staffPickTagName),
  alter.set.modifier('forLibrary').is(true),
)(assert())

const Hero = () => {
  const { data } = useDataset({
    restEndpoint: restEndpoint.content,
    squery,
  })

  const minSlidesToLoop = largeSingleSlides.slidesPerGroup + largeSingleSlides.slidesPerView

  return (
    (
      isDefined(data) && (
        <Box
          sx={{
            ...sx,
          }}
        >
          {/* 'loop' is turned on by default in the config, but will throw a warning if there are less than 3 slides */}
          {/* Further reading: https://swiperjs.com/swiper-api#param-loop */}
          <Carousel configuration={data.length > minSlidesToLoop ? largeSingleSlides : largeSingleSlidesNoLoop}>

            {data.map((item, index) => (
              <HeroSlide
                content={item}
                index={index}
                key={item.id}
              >
                <SlideContent content={item} />
              </HeroSlide>
            ))}
          </Carousel>
        </Box>
      )
    )

  )
}

export default Hero
