import { useContext } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { ECHO_FEATURE_POLL } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import Poll from 'hss/sections/contentBlocks/interactives/Poll'
import { isStudent } from 'selectors/users'
import SubmitButton from './SubmitButton'
import FeatureHeadline from './FeatureHeadline'
import FeatureFootnotes from './FeatureFootnotes'

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  '.AnswerChoiceBox': {
    backgroundColor: palette.grey[5],
    '&:hover': { backgroundColor: palette.cobalt[4] },
  },
}))

const feature = ECHO_FEATURE_POLL

const FeaturePoll = () => {
  const { interactionData: { selectedValue: value = null } } = useContext(interactiveContext)
  const userIsStudent = useSelector(isStudent)

  return (
    <>

      <FeatureHeadline
        mb={2}
        title="Poll"
      >

        <StyledBox mb={3}>
          <Poll />
        </StyledBox>

        <SubmitButton disabled={!value || !userIsStudent} />

      </FeatureHeadline>

      <FeatureFootnotes feature={feature} />

    </>
  )
}

export default FeaturePoll
