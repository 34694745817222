import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { produce } from 'immer'
import { useSelector } from 'react-redux'
import { equals, filterKeyedObject, get, hasProperty, set } from 'fp/objects'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import { fallbackTo, matches } from 'fp/utils'
import { flatten, isEmpty, map, reduce } from 'fp/arrays'
import { componentShape } from 'core/shapes'
import { getContentNav } from 'selectors/contentViewer'
import { CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT, SECTION_CONTENT_TYPES } from 'core/consts'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { getContextualAssignment } from 'selectors/assignments'

const assignmentEditContext = createContext()
const arrayToLookup = reduce((result, next) => set(next, true)(result), {})

const findContent = (id, nodes) => {
  if (!nodes?.length) {
    return null
  }
  const match = nodes.find(matches('id', id))
  return match || compose(
    children => findContent(id, children),
    flatten,
    map(get('children')),
  )(nodes)
}

const useAutoHiddenContentIds = sections => useMemo(() => {
  const contentRestrictions = {}
  const flattenChildren = item => [
    item,
    ...flatten(item?.children?.map(flattenChildren)),
  ]
  sections.forEach((section) => {
    flattenChildren(section)
      .filter(hasProperty('contentRestriction'))
      .filter(compose(
        equals(CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT),
        get('contentRestriction.type'),
      ))
      .forEach((child) => {
        contentRestrictions[child.id] = child.contentRestriction
      })
  })

  return Object.keys(contentRestrictions)
}, [sections])

const AssignmentEditContextProvider = ({ children: providerChildren }) => {
  const assignment = useSelector(getContextualAssignment) || {}
  const { excludedContentIds: initiallyExcludedContentIds } = assignment

  const initiallyIncludeChapterSummary = get('data.settings.includeChapterSummary')(assignment)
  const initialLeveledNarrativeTextSetting = get('data.settings.leveledNarrativeText')(assignment)

  const [includeChapterSummary, setIncludeChapterSummary] = useState(initiallyIncludeChapterSummary)
  const [leveledNarrativeTextSetting, setLeveledNarrativeTextSetting] = useState(initialLeveledNarrativeTextSetting)

  const [excludedContentIdLookup, setExcludedContentIdLookup] = useState(compose(
    arrayToLookup,
    fallbackTo([]),
  )(initiallyExcludedContentIds))

  const { contentId } = useSelector(getContentViewerParams()) || {}
  const sections = useSelector(getContentNav({
    contentId,
    leafContentTypes: SECTION_CONTENT_TYPES,
  }))

  const updateExcludedContentIds = useCallback((newExcludedContentIds) => {
    setExcludedContentIdLookup(produce(
      newExcludedContentIds,
      (draft) => {
        const setSelectionBasedOnChildren = ({ children, id }) => {
          if (children?.length) {
            children.forEach((child) => { setSelectionBasedOnChildren(child) })
            const selectedChildren = children.filter(({ id: childId }) => draft[childId])
            draft[id] = selectedChildren.length === children.length
          }
        }
        (sections || []).forEach(setSelectionBasedOnChildren)
      },
    ))
  }, [sections])

  const isContentIdExcluded = useCallback(
    id => !!excludedContentIdLookup[id],
    [excludedContentIdLookup],
  )

  const excludedContentIds = useDeepCompareMemo(
    () => Object.keys(filterKeyedObject(excludedContentIdLookup, Boolean)),
    [excludedContentIdLookup],
  )

  const selectContent = useCallback((id, select) => {
    updateExcludedContentIds(produce(excludedContentIdLookup, (draft) => {
      const updateChildrenRecursive = ({ children = [] }) => {
        children.forEach((child) => {
          draft[child.id] = select
          updateChildrenRecursive(child)
        })
      }
      draft[id] = select
      updateChildrenRecursive(findContent(id, sections) || {})
    }))
  }, [excludedContentIdLookup, sections, updateExcludedContentIds])

  const toggleExcludeContentId = useCallback(
    (id) => { selectContent(id, !excludedContentIdLookup[id]) },
    [excludedContentIdLookup, selectContent],
  )

  const initialized = useRef(false)
  const initiallyHiddenContentIds = useAutoHiddenContentIds(sections)
  useEffect(
    () => {
      if (!isEmpty(sections) && !assignment.id && !initialized.current) {
        initialized.current = true
        initiallyHiddenContentIds.forEach(id => selectContent(id, true))
      }
    },
    [assignment.id, initiallyHiddenContentIds, sections, selectContent],
  )

  const value = useMemo(() => ({
    excludedContentIds,
    includeChapterSummary,
    isContentIdExcluded,
    leveledNarrativeTextSetting,
    sections,
    setIncludeChapterSummary,
    setLeveledNarrativeTextSetting,
    toggleExcludeContentId,
  }), [
    excludedContentIds,
    includeChapterSummary,
    isContentIdExcluded,
    leveledNarrativeTextSetting,
    sections,
    setLeveledNarrativeTextSetting,
    toggleExcludeContentId,
  ])

  return (
    <assignmentEditContext.Provider value={value}>
      {providerChildren}
    </assignmentEditContext.Provider>
  )
}

AssignmentEditContextProvider.propTypes = {
  assignment: PropTypes.shape({
    contentId: PropTypes.string,
    excludedContentIds: PropTypes.array,
  }),
  children: componentShape.isRequired,
}

export { assignmentEditContext, AssignmentEditContextProvider }
