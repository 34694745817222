import PropTypes from 'prop-types'
import MuiToolbar from '@mui/material/Toolbar'
import ToggleButton from '@mui/material/ToggleButton'
import Box from '@mui/material/Box'
import { useTheme } from '@emotion/react'
import MenuButton from 'common/menus/MenuButton'
import { useContainerQuery } from 'common/layout/ContainerQuery'

const ToolbarMenu = ({
  children,
  className,
  disableResponsive = false,
  sticky = false,
  ...rest
}) => {
  const { zIndex } = useTheme()
  const isSmallScreen = useContainerQuery().down('sm')

  const stickyProps = sticky
    ? {
      position: 'sticky',
      zIndex: zIndex.drawer + 1,
    }
    : {}

  return (
    <Box
      backgroundColor="transparent"
      display="flex"
      py={2}
      style={{
        zIndex: zIndex.drawer,
        maxWidth: 'fit-content',
        left: '100%',
        paddingRight: 'var(--containedPaddingRight)',
      }}
      top="80px"
      {...stickyProps}
    >
      {isSmallScreen && !disableResponsive ? (
        <MenuButton
          ButtonControl={ToggleButton}
          label="More Tools"
          value="toolbar-menu"
          variant="toolbutton"
          {...rest}
        >
          {children}
        </MenuButton>
      ) : (
        <MuiToolbar
          className={className}
          data-toolbar="subsection"
          variant="subsection"
          {...rest}
        >
          {children}
        </MuiToolbar>
      )}
    </Box>
  )
}

ToolbarMenu.propTypes = {
  children: PropTypes.node.isRequired,
  disableResponsive: PropTypes.bool,
  sticky: PropTypes.bool,
}

export default ToolbarMenu
