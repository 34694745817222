import { useContext } from 'react'
import { componentShape } from 'core/shapes'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import Table from '../../interactives/ChartCompletion/Table'
import { interactiveContext } from '../InteractiveProvider'

const ChartCompletion = withQuestionPrompt(({ ChartItemRenderer }) => {
  const {
    interactionData: { items: response = [] },
    interactiveData: { columns, items, rows },
  } = useContext(interactiveContext)

  return (
    <Table
      {...{
        ChartItemRenderer,
        columns,
        items,
        rows,
        readOnly: true,
        response,
      }}
    />
  )
})

ChartCompletion.propTypes = {
  ChartItemRenderer: componentShape.isRequired,
}

export default ChartCompletion
