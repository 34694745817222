import { padding } from './config'

const getX = (y, circle, radiusOffset) => {
  const rise = y - circle.center.y
  const hypotenuse = circle.radius + radiusOffset
  return Math.sqrt((hypotenuse * hypotenuse) - (rise * rise))
}

const getInnerX = (y, circle, fontSize) => getX((y <= circle.center.y ? y - fontSize : y), circle, -padding)
export const getLeftInnerX = (y, circle, fontSize) => circle.center.x - getInnerX(y, circle, fontSize)
export const getRightInnerX = (y, circle, fontSize) => circle.center.x + getInnerX(y, circle, fontSize)

const getOuterX = (y, circle, fontSize) => getX((y >= circle.center.y ? y - fontSize : y), circle, padding)
export const getLeftOuterX = (y, circle, fontSize) => circle.center.x - getOuterX(y, circle, fontSize)
export const getRightOuterX = (y, circle, fontSize) => circle.center.x + getOuterX(y, circle, fontSize)
