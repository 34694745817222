import { useTheme } from '@emotion/react'
import Drawer from '@mui/material/Drawer'
import { useContext } from 'react'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import Features from './Features'

const openedMixin = ({ transitions, transitions: { duration, easing } }, width) => ({
  width,
  transition: transitions.create('width', {
    easing: easing.sharp,
    duration: duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = ({ transitions, transitions: { duration, easing } }, width) => ({
  transition: transitions.create('width', {
    easing: easing.sharp,
    duration: duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width,
})

const EchoSidebar = () => {
  const theme = useTheme()
  const { echoSidebarWidth, isEchoSidebarShrunk } = useContext(sidebarContext)

  return (
    <Drawer
      anchor="right"
      open={isEchoSidebarShrunk}
      sx={{
        ...isEchoSidebarShrunk
          ? closedMixin(theme, echoSidebarWidth)
          : openedMixin(theme, echoSidebarWidth),

        '.MuiDrawer-paper': isEchoSidebarShrunk
          ? closedMixin(theme, echoSidebarWidth)
          : openedMixin(theme, echoSidebarWidth),
      }}
      variant="permanent"
    >
      <Features />
    </Drawer>
  )
}

export default EchoSidebar
