import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Actions from './Actions'
import DefaultColor from './DefaultColor'
import SegmentColors from './SegmentColors'

const Container = styled(
  Box,
  { name: 'GradeColors-index' },
)(({ theme: { mixins: { rem }, palette } }) => ({
  '.defaultSegment': {
    marginTop: rem(3.5),
    marginBottom: rem(2),
  },
  '.segments': {
    '.delete-button': {
      position: 'relative',
      left: -20,
      top: -10,
    },
    '.MuiGrid-item': {
      paddingBottom: 10,
    },
    '.MuiGrid-grid-xs-8': {
      maxWidth: '70%',
      flexBasis: '70%',
    },
    '.MuiGrid-grid-xs-2:nth-of-type(1)': {
      maxWidth: '18%',
      flexBasis: '18%',
    },
    '.MuiGrid-grid-xs-2': {
      maxWidth: '12%',
      flexBasis: '12%',
    },
    ...palette.gradebook.reduce(
      (acc, { colorId, value: color }) => ({
        ...acc,
        [`& .colorId-${colorId} .MuiSlider-thumb, .colorId-${colorId} .MuiSlider-track`]: {
          color,
        },
      }),
      {},
    ),
  },

}))

const GradeColors = props => (
  <Container>
    <DefaultColor {...props} />

    <SegmentColors {...props} />

    <Actions {...props} />
  </Container>
)

export default GradeColors
