import { Suspense, lazy, useContext } from 'react'
import { compose } from 'redux'
import Typography from '@mui/material/Typography'
import BusySpinner from 'common/indicators/BusySpinner'
import Centered from 'apps/common/layout/Centered'
import { get, set } from 'fp/objects'
import { filter, map, orderBy } from 'fp/arrays'
import { isNotEmptyString } from 'fp/strings'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const WordCloud = lazy(() => import(/* webpackChunkName: "WordCloud" */ 'common/text/WordCloud'))

const DisplayResults = () => {
  const {
    interactionData: { phrases: myPhrases = [] },
    interactiveData,
    peerInteractions,
  } = useContext(interactiveContext)

  const phrases = compose(
    orderBy('label'),
    map(([label, value]) => ({ label, value })),
    Object.entries,
    A => A.reduce((acc, phrase) => set(
      phrase,
      get(phrase)(acc) ? get(phrase)(acc) + 1 : 1,
      true,
      false,
      String.fromCharCode(25),
    )(acc), {}),
    filter(isNotEmptyString),
    A => A.concat(peerInteractions.map(get('interactionData.phrases') || []).flat()),
  )(myPhrases)

  return (
    <Suspense fallback={<BusySpinner />}>
      <Centered>
        {phrases.length
          ? <WordCloud {...{ ...interactiveData, phrases }} />
          : <Typography variant="information">(no responses)</Typography>}
      </Centered>
    </Suspense>
  )
}

export default DisplayResults
