import PropTypes from 'prop-types'

export const title = {
  academic: 'Academic Vocabulary',
  content: 'Content Vocabulary',
  footnote: 'Footnotes',
}

export const variantShape = PropTypes.oneOf([
  'aggregate',
  'self',
])
