import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

const SpeechSynthesisWarning = ({ browserSpeechEnabled }) => !window.speechSynthesis && browserSpeechEnabled
  ? (
    <Typography variant="caption">
      This browser does not support speech synthesis.
    </Typography>
  )
  : null

SpeechSynthesisWarning.propTypes = {
  browserSpeechEnabled: PropTypes.bool.isRequired,
}

export default SpeechSynthesisWarning
