import { useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { animated, useTransition } from 'react-spring'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { when } from 'fp/utils'
import Html from 'common/text/Html'
import { CONTENT_TYPE_PAGE, INTERACTIVE_TYPE_TIMELINE } from 'core/consts'
import MaybeHasBackground from 'hss/ContentViewer/Chapter/Block/MaybeHasBackground'
import { getContentHierarchy } from 'selectors/content'
import { get } from 'fp/objects'
import { filter } from 'fp/arrays'
import { isEmptyString } from 'fp/strings'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Content from './Content'
import TimelineTabs from './TimelineTabs'
import Navigation from './Content/Navigation'

const TIMELINE_HEIGHT = 670

const hasBlocks = ({
  contentType,
  children,
  childrenCount,
}) => contentType === CONTENT_TYPE_PAGE
  ? childrenCount > 0
  : children.some(({ childrenCount: blockCount }) => blockCount)

const Timeline = () => {
  const {
    completed,
    interactive: { id },
    interactiveData,
    markComplete,
    uploadsMap,
  } = useContext(interactiveContext)

  const {
    caption,
    previewing,
    variant,
  } = interactiveData

  const [navigateTab, setNavigateTab] = useState({ value: 0, isFromPrevious: false, currentPage: 0 })
  const handleChange = (_, tab) => {
    setNavigateTab({ value: tab, isFromPrevious: navigateTab.value > tab, currentPage: 0 })
  }

  useEffect(
    () => { when(!completed && navigateTab.value, markComplete) },
    [completed, markComplete, navigateTab.value],
  )

  const isSmallScreen = useContainerQuery().down('lg')
  const containerRef = useRef()

  const transitions = useTransition([navigateTab], {
    from: { y: navigateTab.isFromPrevious ? '20%' : '-20%', display: 'none' },
    enter: { y: '0%', display: 'block' },
    leave: { y: navigateTab.isFromPrevious ? '20%' : '-20%', display: 'none' },
  })

  const childrenContent = compose(
    filter(hasBlocks),
    get('children'),
    useSelector,
    getContentHierarchy,
  )({ contentId: id })

  return (
    childrenContent.length > 0 && (
      <figure>

        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            height: isSmallScreen ? '80vh' : TIMELINE_HEIGHT,
            overflow: 'hidden',
            figure: { minHeight: '30vh',
              height: isSmallScreen ? '100%' : TIMELINE_HEIGHT,
              img: { maxHeight: isSmallScreen ? '50vh' : TIMELINE_HEIGHT } },
          }}
        >

          <TimelineTabs
            onChange={handleChange}
            value={navigateTab.value}
          >
            {childrenContent}
          </TimelineTabs>

          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Navigation
              content={childrenContent}
              onTabChange={setNavigateTab}
              selectedTab={navigateTab}
              totalPanes={childrenContent.length}
            />

            <MaybeHasBackground
              content={{ uploadsMap }}
              fullWidth
              mapKey="image"
              sx={{
                bgcolor: 'background.paper',
                height: '100%',
                overflow: 'hidden',
                '&::before': {
                  // overriding the linear gradient filter/opacity here
                  // TODO: remove this after background images fixed with full color
                  backgroundImage: `url("${uploadsMap?.image?.url}")`,
                  height: isSmallScreen ? '80vh' : TIMELINE_HEIGHT,
                  opacity: 1,
                },
                '> div': {
                  height: '100%',
                },
              }}
            >
              {transitions((style, item) => (
                <animated.div style={{ ...style, height: '100%' }}>

                  <Content
                    onTabChange={setNavigateTab}
                    previewing={previewing}
                    selectedTab={navigateTab}
                    /**
                     * TODO:
                     * PageRenderer expects a `variant` prop, and will default to 'normal',
                     * however there seems to be a way to create pages where variant is an
                     * empty string.
                     *
                     * If we're sure that's not happening any more and these were just
                     * timelines that were ingested earlier, then a code migration should
                     * be used to fix that and this check for an empty string can be removed.
                     */
                    variant={isEmptyString(variant) ? interactiveVariants.NORMAL : variant}
                    {...childrenContent[item.value]}
                  />

                </animated.div>
              ))}
            </MaybeHasBackground>

          </div>

        </Box>

        {Boolean(caption) && (
          <figcaption>
            <Html body={caption} />
          </figcaption>
        )}

      </figure>
    )
  )
}

export const detachedInteractionOptions = {
  childrenAreContentChildren: true,
  contentSubType: INTERACTIVE_TYPE_TIMELINE,
}

export default Timeline
