import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import RangeSlider from 'common/formControls/sliders/RangeSlider'
import { identity } from 'fp/utils'
import { toInt } from 'fp/strings'
import { get, set } from 'fp/objects'

const Plagiarism = styled(
  ({ className, onChange, preferences }) => {
    const handleChange = propName => (newValue) => {
      onChange(set(propName, toInt(newValue)))
    }

    return (
      <div className={className}>
        <Headline
          gutterBottom
          mt={1}
          title="Plagiarism Flag Cutoff"
        >

          <Typography
            color="textSecondary"
            variant="caption"
          >
            Any assignment with at least one student's work that receives a
            "match percentage" higher than this cutoff will be flagged.
          </Typography>

          <Box
            mt={3}
            pl={3}
            pr={3}
          >
            <RangeSlider
              color="secondary"
              data-testid="turnitinCutoff"
              fixedLeft
              getAriaLabel={identity}
              leftTrackLabelAfterPercentage={5}
              onChange={handleChange('assignmentPrefs.turnitinCutoff')}
              rightTrackLabelAfterPercentage={0}
              thumb="pill"
              value={[0, get('assignmentPrefs.turnitinCutoff')(preferences)]}
              valueLabelDisplay="on"
              valueLabelFormat={identity}
            />
          </Box>
        </Headline>
      </div>
    )
  },
  { name: 'Turnitin-Plagiarism' },
)({
  '[data-index="0"]': { display: 'none' },
})

Plagiarism.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default Plagiarism
