import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import { alter, assert } from 'core/store/search/squery'
import { restEndpoint } from 'reducers/utils'
import {
  SimpleCell,
} from 'common/tables/DataTable/Cells'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import DataTable from 'common/tables/DataTable'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import useAbilityChecker from 'hooks/useAbilityChecker'

const tableId = 'clDistrictsReport'

const buildColumns = (/* has */) => [

  SimpleCell({ header: 'School', id: 'name' }),

]

const searchConfig = (has, limit) => districtId => ({

  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.where('districtId').is(districtId),
    alter.set.orderBy('name', 'asc'),
  )(assert()),

  pluralLabel: 'Schools',

  restEndpoint: restEndpoint.schools,

  searchFields: ['name'],

  singleLabel: 'School',

})

const withSearchProvider = (WrappedComponent) => {
  const Enhanced = (initialTestingState) => {
    const { districtId } = useParams()

    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)(districtId)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table"
      >
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
