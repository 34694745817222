import components from 'styling/theming/base/components'
import { deepMerge } from 'fp/objects'

const plugin = theme => deepMerge(components(theme), {
  components: {

  },
})

export default plugin
