import { useContext } from 'react'
import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { context } from '../../SearchProvider'
import FilterButtons from '../FilterButtons'

const ContentTypeFilter = () => {
  const {
    alterContentTypeFilters,
    contentTypes,
    dataset,
  } = useContext(context)

  const squery = get('metadata.squery')(dataset)

  const selectedFilters = inspect(squery).get.where('contentType').in()

  return (
    <FilterButtons
      filters={contentTypes}
      handleFilterClick={alterContentTypeFilters}
      selectedFilters={selectedFilters.length === contentTypes.length
        ? []
        : selectedFilters}
    />
  )
}

export default ContentTypeFilter
