import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import { formatDateStrLong } from 'locale/i18n'
import { userAlertDataShape } from 'core/shapes'

const UserAlertContent = ({ alert, confirmedId, setConfirmedId, showButton = false }) => (
  <>
    <div
      style={{
        alignItems: 'start',
        display: 'flex',
        gap: '1rem',
        justifyContent: 'space-between',
        marginTop: '1rem',
      }}
    >
      <div>
        <Headline
          size={4}
          title={alert.title}
        >
          <p style={{ margin: '0.5rem 0 0' }}>{formatDateStrLong(alert.dateCreated)}</p>
        </Headline>
      </div>
      {Boolean(showButton) && (
        <Button
          color="secondary"
          disabled={Boolean(alert.dateConfirmed || confirmedId)}
          onClick={() => setConfirmedId(alert.id)}
          sx={{ marginRight: '1rem', minWidth: '10rem' }}
          variant="primary"
        >
          Confirm
        </Button>
      )}
    </div>
    <Html body={alert.message} />
  </>
)

UserAlertContent.propTypes = {
  alert: userAlertDataShape.isRequired,
  confirmedId: PropTypes.string,
  setConfirmedId: PropTypes.func,
  showButton: PropTypes.bool,
}

export default UserAlertContent
