import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import MenuButton from 'common/menus/MenuButton'
import LinkButton from 'common/navigation/links/LinkButton'
import { contentBuilderUrl } from 'routing/consts'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
} from 'core/consts'
import { contentTypeShape, entityIdShape } from 'core/shapes'
import { renameKeys } from 'fp/objects'
import useAbilityCheck from 'hooks/useAbilityCheck'
import DeletionMenuItem from '../DeletionMenuItem'

const CardActionMenu = (props) => {
  const { contentId, contentSubType, contentType } = props
  const content = renameKeys({ id: 'contentId' }, props)
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)

  const editorUrl = (() => {
    switch (contentType) {
      case CONTENT_TYPE_INTERACTIVE:
        return `${contentBuilderUrl}/interactive/${contentSubType}/${contentId}`
      case CONTENT_TYPE_RUBRIC:
        return `${contentBuilderUrl}/${contentType}/${contentId}`
      default:
        return `${contentBuilderUrl}/curriculum/${contentType}/${contentId}`
    }
  })()

  return (
    <MenuButton
      label="More Options"
      sx={{ padding: 0,
        '&:hover': {
          backgroundColor: 'unset',
        } }}
    >

      <MenuItem
        component={LinkButton}
        style={{ width: '100%' }}
        to={editorUrl}
      >
        Edit
      </MenuItem>

      {Boolean(isContentCreator) && <Divider />}
      {Boolean(isContentCreator) && (
        <DeletionMenuItem content={content} />
      )}

    </MenuButton>
  )
}

CardActionMenu.propTypes = {
  contentId: entityIdShape.isRequired,
  contentType: contentTypeShape.isRequired,
  contentSubType: PropTypes.string,
}

export default CardActionMenu
